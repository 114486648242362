<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import { gApp, } from '../State.js'
import { makeValStr } from '../Common/Units.js'
import { WeatherDataMap } from '../Data/WeatherDataMap.js'
import BasicModal from './utils/BasicModal.vue'
import BasicSelector from './utils/BasicSelector.vue'
import FieldInput from './FieldInput.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import HelpButton from './HelpButton.vue'
import ComponentErrorsList from './utils/ComponentErrorsList.vue'
import { IsInvalidLocation } from '../Data/InvalidLocations.js'

const props = defineProps({
  modelValue: Object,
})
const emit = defineEmits(['update:modelValue'])

let showLocationChooser = ref(false);
let subPath = ref(null);

let requestLocationModal = ref(null);
let requestLocationText = ref("");

let requestLocationTemplate = ``;

let locDataVisible = ref(false);

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

function onValueChanged(newVal) {
  value.value.value = newVal;
}

function toggleLocationChooser() {
  if (!showLocationChooser.value) {
    console.log("Turning on");
    showLocationChooser.value = true;
    subPath.value = [];
  } else {
    console.log("Turning off");
    showLocationChooser.value = false;
  }
}

function makeColData(locParent, path) {
  let data = [];
  for (const elem of locParent.children) {
    let locPath = [...path, elem.name];
    if (IsInvalidLocation(locPath)) {
      continue;
    }
    data.push({
      name: elem.name,
      path: locPath,
      isLeaf: !('children' in elem),
      data: elem,
    });
  }
  return {
    colKey: path.join('/'),
    data: data,
  };
}

function lookupByName(locMap, locName) {
  for (const locElem of locMap.children) {
    if (locElem.name == locName) {
      return locElem;
    }
  }
  throw new Error(`Location not found: ${locName}`);
}

let colData = computed(() => {
  let cols = [];
  cols.push(makeColData(WeatherDataMap, []));

  let curMap = WeatherDataMap;
  let pathSoFar = [];
  for (let i = 0; i < subPath.value.length; ++i) {
    let part = subPath.value[i];
    pathSoFar.push(part);
    curMap = lookupByName(curMap, part);
    if (!curMap.children) {
      break;
    }
    cols.push(makeColData(curMap, pathSoFar));
  }
  return cols;
})

function onClickPathElem(pathElem) {
  if (pathElem.isLeaf) {
    console.log("Selected path: ", pathElem.path);
    showLocationChooser.value = false;
    props.modelValue.setLocation(pathElem.data);
  } else {
    subPath.value = pathElem.path;
  }
}

let fields = computed(() => {
  return props.modelValue.fields.map((fieldName) => props.modelValue[fieldName])
})

function addOverride(field) {
  field.data.useDefault = false;
  field.value = field.data.defaultValue;
}

function removeOverride(field) {
  field.data.useDefault = true;
  field.value = field.data.defaultValue;
}

function getFullName(name) {
  let stateNames = {
    'AK': 'Alaska (AK)',
    'AL': 'Alabama (AL)',
    'AR': 'Arkansas (AR)',
    'AS': 'American Samoa (AS)',
    'AZ': 'Arizona (AZ)',
    'CA': 'California (CA)',
    'CO': 'Colorado (CO)',
    'CT': 'Connecticut (CT)',
    'DC': 'District of Columbia (DC)',
    'DE': 'Delaware (DE)',
    'FL': 'Florida (FL)',
    'GA': 'Georgia (GA)',
    'GU': 'Guam (GU)',
    'HI': 'Hawaii (HI)',
    'IA': 'Iowa (IA)',
    'ID': 'Idaho (ID)',
    'IL': 'Illinois (IL)',
    'IN': 'Indiana (IN)',
    'KS': 'Kansas (KS)',
    'KY': 'Kentucky (KY)',
    'LA': 'Louisiana (LA)',
    'MA': 'Massachusetts (MA)',
    'MD': 'Maryland (MD)',
    'ME': 'Maine (ME)',
    'MI': 'Michigan (MI)',
    'MN': 'Minnesota (MN)',
    'MO': 'Missouri (MO)',
    'MP': 'Northern Mariana Islands (MP)',
    'MS': 'Mississippi (MS)',
    'MT': 'Montana (MT)',
    'NA': 'National (NA)',
    'NC': 'North Carolina (NC)',
    'ND': 'North Dakota (ND)',
    'NE': 'Nebraska (NE)',
    'NH': 'New Hampshire (NH)',
    'NJ': 'New Jersey (NJ)',
    'NM': 'New Mexico (NM)',
    'NV': 'Nevada (NV)',
    'NY': 'New York (NY)',
    'OH': 'Ohio (OH)',
    'OK': 'Oklahoma (OK)',
    'OR': 'Oregon (OR)',
    'PA': 'Pennsylvania (PA)',
    'PR': 'Puerto Rico (PR)',
    'RI': 'Rhode Island (RI)',
    'SC': 'South Carolina (SC)',
    'SD': 'South Dakota (SD)',
    'TN': 'Tennessee (TN)',
    'TX': 'Texas (TX)',
    'UT': 'Utah (UT)',
    'VA': 'Virginia (VA)',
    'VI': 'Virgin Islands (VI)',
    'VT': 'Vermont (VT)',
    'WA': 'Washington (WA)',
    'WI': 'Wisconsin (WI)',
    'WV': 'West Virginia (WV)',
    'WY': 'Wyoming (WY)',
  }
  let provinceNames = {
    'AB': 'Alberta (AB)',
    'BC': 'British Columbia (BC)',
    'MB': 'Manitoba (MB)',
    'NB': 'New Brunswick (NB)',
    'NL': 'Newfoundland and Labrador (NL)',
    'NS': 'Nova Scotia (NS)',
    'NT': 'Northwest Territories (NT)',
    'NU': 'Nunavut (NU)',
    'ON': 'Ontario (ON)',
    'PE': 'Prince Edward Island (PE)',
    'QC': 'Quebec (QC)',
    'SK': 'Saskatchewan (SK)',
    'YT': 'Yukon (YT)',
  };
  if (name in stateNames) {
    return stateNames[name];
  } else if (name in provinceNames) {
    return provinceNames[name];
  } else {
    return name;
  }
}

function openRequestLocation() {
  requestLocationText.value = requestLocationTemplate;
  requestLocationModal.value.showModal();
}

function sendLocationRequest() {
  let requestText = `Got location request from ${gApp.getUserEmail()}:\n\n` +
   `${requestLocationText.value}`;
  gApp.sendDeveloperFeedback(requestText);
  requestLocationText.value = "";
  gApp.toast('Location request sent!');
}

</script>

<template>
  <div class="Location">
    <ComponentErrorsList :component="modelValue" />
    <div class="LocSelector">
      <div v-if="!showLocationChooser" class="Flex GapS">
        <button @click="toggleLocationChooser" class="f-m MarginBotXS LinkBtn SetLocBtn">{{ value.locPath ? value.locPath.fullName : 'Set Location' }}</button>
        <button @click="locDataVisible = !locDataVisible" class="LinkBtn ShowLocDataBtn" v-if="value.locPath">{{!locDataVisible ? 'Show Data' : 'Close Data'}}</button>
      </div>
      <div v-else class="LocChooser MarginBotS">
        <p class="ChooseNewLoc help-text mb-xxs">Choose a new location. <button class="DontSeeYourLoc TextBtn" @click="openRequestLocation">Don't see your location?</button></p>
        <div class="LocTable MarginBotXXS">
          <div v-for="(col,i) of colData" :key="col.colKey" class="LocCol">
            <button v-for="entry in col.data" class="LinkBtn LocBtn" :key="entry.name" :class="{IsSelected:subPath[i] === entry.name}"
              @click="onClickPathElem(entry)">
              <i class="bi-geo-alt-fill"></i>
              {{ getFullName(entry.name) }}
            </button>
          </div>
        </div>
        <button @click="showLocationChooser = false">Cancel</button>
      </div>
    </div>
    <div v-if="value.locData">
      <div v-if="locDataVisible" title="Location Data" class="LocationData">
        <table class="SimpleTable">
          <tr>
            <th>Name</th>
            <th>Default</th>
            <th>Override</th>
          </tr>
          <tr v-for="(field, index) in fields">
            <td>{{ field.name }}</td>
            <td>{{ makeValStr(field.data.defaultValue, field.units) }}</td>
            <td>
              <button v-if="field.data.useDefault" @click="addOverride(field)" class="LinkBtn">Override</button>
              <div v-else class="Flex">
                <FieldInput v-model="modelValue[modelValue.fields[index]]" :compact="true" />
                <button @click="removeOverride(field)" class="LinkBtn">X</button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <BasicModal ref="requestLocationModal" title="Request Locations" doneText="Send" @onDone="sendLocationRequest">
    <div class="mb-s">
      <p class="mb-xs">Need more locations? Request them here, and we'll add them!</p>
      <p class="help-text">We'll notify you by email as soon as they're ready (usually within 1 day).</p>
    </div>
    <textarea v-model="requestLocationText" class="mb-s w-100 FeedbackArea"/>
  </BasicModal>
</template>

<style scoped>
.Location {
  max-width: 100%;
}

.FieldInput {
  display: flex;
  gap: 8px;
}

.LocChooser {
}

.LocTable {
  /* padding: var(--space-xs); */
  display: flex;
  flex-flow: row nowrap;
  min-height: 320px;
  max-height: 500px;
}

.LocCol {
  padding: var(--space-s);
  border: 1px solid var(--b-20);
  min-width: 220px;
  overflow-y: auto;
  scrollbar-width: thin;
}

/*
.LocCol:first-child {
  border-top-left-radius: var(--br-l);
  border-bottom-left-radius: var(--br-l);
}
*/

/*
.LocCol:last-child {
  border-top-right-radius: var(--br-l);
  border-bottom-right-radius: var(--br-l);
}
*/

.LocCol + .LocCol {
  border-left: none;
}

.LocBtn {
  text-transform: none;
  font-size: var(--f-m);
  text-decoration: none;
  text-align: left;
  margin-bottom: 6px;
}

.LocBtn.IsSelected {
  color: var(--red);
}

.LocationData {
}

.SetLocBtn {
  color: var(--pc);
}

.ShowLocDataBtn {
}

.ChooseNewLoc {
  color: var(--main-text);;
}

.FeedbackArea {
  resize: vertical;
  height: 100px;
  font-size: var(--f-xs);
}
</style>
