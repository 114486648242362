import { valOr } from '../SharedUtils'
import { Field } from './Field'
import * as ser from './SerUtil'

/*
Used to store an output value of a calculation, which
will be displayed in the UI. Meant to be like a fixed value
of 'Field'.
*/
export class OutputValue {
  static serInfo = {
    type: 'OutputValue',
  }

  constructor(name, valueStr, unitsStr, errorMsg) {
    this.name = name
    this.valueStr = valueStr
    this.unitsStr = unitsStr
    this.errorMsg = errorMsg

    this.serFields = [
      ser.staticField('_type', 'OutputValue'),
      'name',
      'valueStr',
      'unitsStr',
      'errorMsg',
    ]
  }

  getValueStr() {
    return this.valueStr
  }

  getUnitsLabel() {
    return this.unitsStr
  }

  getErrorMsg() {
    return this.errorMsg
  }

  static createSimple(name, fieldType, value, otherOpts) {
    otherOpts = valOr(otherOpts, {});
    let outputField = Field.makeOutput(name, fieldType, value, {
      ...otherOpts,
      // We don't want to clamp the value, since it's already calculated
      clampValue: false,
    })
    return OutputValue.createFromField(outputField)
  }

  static createFromField(field, opts) {
    opts = valOr(opts, {});
    let name = valOr(opts.name, field.name);
    let inputType = field.getInputType();
    let valueStr = field.getValueStr();
    let unitsStr = inputType == 'Number' ? field.getUnitsLabel() : null;
    let errorMsg = field.getEntryErrorMsg()
    return new OutputValue(name, valueStr, unitsStr, errorMsg)
  }
}
