import * as ser from './SerUtil.js'
import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, PleaseContactStr,
  IntervalTimer,
} from '../Base.js'
import {
  valOr,
  extendArray,
  formatNum,
} from '../SharedUtils.js'
import { Units } from './Units.js'
import { Watcher } from './Watcher.js'
import { gApp } from '../Globals.js'

export let ProjectUnits = makeEnum({
  Imperial: 'Imperial',
  Metric: 'Metric',
})

export let FieldType = {
  Length: 'Length',
  SmallLength: 'SmallLength',
  Area: 'Area',
  SmallArea: 'SmallArea',
  Volume: 'Volume',
  Temp: 'Temp',
  Temperature: 'Temp', // Alias
  Count: 'Count',
  Decimal: 'Decimal',
  // 0-100
  Percent: 'Percent',
  // 0-1
  Ratio: 'Ratio',
  Angle: 'Angle',
  AirFlow: 'AirFlow',
  Load: 'Load',
  LoadMBH: 'LoadMBH',
  RValue: 'RValue',
  UValue: 'UValue',
  Insulation: 'Insulation',
  SoilConductivity: 'SoilConductivity',
  Pressure: 'Pressure',
  SmallPressure: 'SmallPressure',
  Power: 'Power',
  WeightPerArea: 'WeightPerArea',
  HumidityRatio: 'HumidityRatio',
  SpecificVolume: 'SpecificVolume',
  Enthalpy: 'Enthalpy',
  People: 'People',
  PeoplePer1000ft2: 'PeoplePer1000ft2',
  //Direction: 'Direction',
  String: 'String',
  Select: 'Select',
};


// export let kFieldTypesData = {};
export let kFieldTypesData = {
  [FieldType.Count]: {
    unit: Units.None,
    min: 0,
  },
  [FieldType.Decimal]: {
    unit: Units.None,
  },
  [FieldType.Length]: {
    unit: Units.ft,
    min: 0,
    allowMin: false,
  },
  [FieldType.SmallLength]: {
    unit: Units.inches,
    min: 0,
    allowMin: false,
  },
  [FieldType.Area]: {
    unit: Units.ft2,
    min: 0,
    allowMin: false,
  },
  [FieldType.SmallArea]: {
    unit: Units.in2,
    min: 0,
    allowMin: false,
  },
  [FieldType.Volume]: {
    unit: Units.ft3,
    min: 0,
    allowMin: false,
  },
  [FieldType.Temp]: {
    unit: Units.F,
  },
  [FieldType.Angle]: {
    unit: Units.Degrees,
    min: 0,
    max: 360,
  },
  [FieldType.Percent]: {
    unit: Units.Percent,
    min: 0,
    max: 100,
    allowMin: true,
  },
  [FieldType.Ratio]: {
    unit: Units.Ratio,
    min: 0,
    max: 1.0,
  },
  [FieldType.AirFlow]: {
    unit: Units.AirFlow,
    min: 0,
  },
  [FieldType.Load]: {
    unit: Units.Load,
    min: 0,
  },
  [FieldType.LoadMBH]: {
    unit: Units.LoadMBH,
    min: 0,
  },
  [FieldType.RValue]: {
    unit: Units.RValue,
    min: 0,
    // R-value must be > 0 b/c we often calculate U=1/R
    allowMin: false,
  },
  [FieldType.UValue]: {
    unit: Units.UValue,
    min: 0,
  },
  [FieldType.Insulation]: {
    unit: Units.Insulation,
    min: 0,
  },
  [FieldType.SoilConductivity]: {
    unit: Units.SoilConductivity,
    min: 0,
  },
  [FieldType.Pressure]: {
    unit: Units.psi,
    min: 0,
  },
  [FieldType.SmallPressure]: {
    unit: Units.in_wc,
    min: 0,
  },
  [FieldType.Power]: {
    unit: Units.Power,
    min: 0,
  },
  [FieldType.WeightPerArea]: {
    unit: Units.PoundsPerFt2,
    min: 0,
    allowMin: false,
  },
  [FieldType.HumidityRatio]: {
    unit: Units.HumidityRatio,
    min: 0,
  },
  [FieldType.SpecificVolume]: {
    unit: Units.SpecificVolume,
    min: 0,
  },
  [FieldType.Enthalpy]: {
    unit: Units.Enthalpy,
  },
  [FieldType.People]: {
    unit: Units.People,
    min: 0,
  },
  [FieldType.PeoplePer1000ft2]: {
    unit: Units.PeoplePer1000ft2,
    min: 0,
  },
};

/*
Note: subclasses may add more types
*/
export let FieldInputType = makeEnum({
  'Number': 'Number',
  'Select': 'Select',
  'String': 'String',
})

export class Field {
  constructor(data) {
    this.name = data.name;
    this.key = data.key;
    this.type = valOr(data.type, FieldType.Length);
    if ('defaultValue' in data) {
      this.defaultValue = data.defaultValue;
    } else {
      if (this.type == "Select") {
        if (data.choices) {
          this.defaultValue = data.choices.length > 0 ? Field.getChoiceValue(data.choices[0]) : null;
        } else if (data.choicesFunc) {
          let choices = data.choicesFunc();
          this.defaultValue = choices.length > 0 ? Field.getChoiceValue(choices[0]) : null;
        } else {
          this.defaultValue = null;
        }
      } else if (this.type == "String") {
        this.defaultValue = "";
      } else {
        this.defaultValue = 0;
      }
    }
    this.value = valOr(data.value, this.defaultValue);
    if ('units' in data) {
      this.units = data.units;
    } else {
      if (this.type in kFieldTypesData) {
        this.units = kFieldTypesData[this.type].unit;
      } else {
        this.units = Units.None;
      }
    }
    this.min = valOr(data.min, this._getDefaultMin());
    // Aka: if true, min is inclusive. Otherwise, value must be > min.
    this.allowMin = valOr(data.allowMin, this._getDefaultAllowMin());
    this.max = valOr(data.max, this._getDefaultMax());

    // If true, the field will be highlighted and require the 
    // user to enter a value, before it is valid.
    this.requiresInput = valOr(data.requiresInput, false);

    this.choices = data.choices;
    this.choicesFunc = data.choicesFunc;
    this.hiddenChoices = data.hiddenChoices;
    this.lookupValueFunc = data.lookupValueFunc;

    this.visible = valOr(data.visible, true);
    this.isOutput = valOr(data.isOutput, false);
    this.isNA = false;

    this.showName = valOr(data.showName, true);
    this.debugOutput = valOr(data.debugOutput, null);

    // Bolded/emphasized in the UI
    this.bold = valOr(data.bold, false);
    // Number of decimals for numeric values
    this.numDecimals = valOr(data.numDecimals, 2);
    if (this.type == FieldType.Count) {
      this.numDecimals = 0;
    }
    // Error msg for empty selects
    this.errorWhenEmpty = valOr(data.errorWhenEmpty, null);

    // Misc:
    // The watcher used to register watchEffect handles with
    if (data.watcher) {
      this.watcher = data.watcher;
    } else {
      if (gApp && gApp.proj()) {
        this.watcher = gApp.proj();
      } else {
        this.watcher = Watcher.globalInstance();
      }
    }
    // Used to store other data (should be a simple map)
    this.data = valOr(data.data, {})
    this.errorMsg = null

    // The user may explicity set an entry error msg (but usually the entry error msg
    // is generated from the field's validation)
    this.entryErrorMsg = null

    // Call this to make sure all default vals are in the proper range
    let clampValue = valOr(data.clampValue, true);
    if (clampValue) {
      this._tryClampValue();
    }

    this.serFields = [
      'value',
      'requiresInput', 
      'data',
    ];
    this.childObjs = '$none'
    this.objInfo = {
      '_name': this.name,
    }
  }

  // ObjectUtils calls the field 'enabled', but it is equivalent to 'visible' here
  get enabled() {
    return this.visible;
  }

  set enabled(newVal) {
    this.visible = newVal;
  }

  getValue() {
    return this.value;
  }

  setValue(newVal) {
    this.value = newVal;
  }

  setValueAsUser(newVal) {
    this.value = newVal;
    // Clears the requiresInput flag
    this.requiresInput = false;
  }

  static makeName(fieldName, optName) {
    return new Field({
      name: fieldName,
      type: FieldType.String,
      value: optName || 'Untitled',
    });
  }

  // Shortcut for Select type
  static makeSelect(name, enumType, otherOpts) {
    if (!(typeof name == 'string')) {
      throw new Error("Unexpected string for Select name: " + name);
    }
    return new Field({
      name: name,
      type: FieldType.Select,
      choices: makeOptions(enumType),
      ...otherOpts,
    })
  }

  /*
  Every value in typesList should have an id and name.
  Note: only works if the typesList array is kept around (push and clear it but
  not assign a new array).
  */
  static makeTypeSelect(name, typesList, noneOptionName, otherOpts) {
    if (!(typeof name == 'string')) {
      throw new Error("Unexpected string for Select name: " + name);
    }
    return new Field({
      name: name,
      type: FieldType.Select,
      // Use func b/c must be reactive
      choicesFunc: () => {
        let options = typesList.map((elem) => {
          return {label: elem.name.value, value: elem.id};
        })
        if (typeof noneOptionName === 'string') {
          options = [{label: noneOptionName, value: noneOptionName}, ...options]
        }
        // console.log(`New options: ${prettyJson(options)}`)
        return options;
      },
      lookupValueFunc: (value) => {
        for (const elem of typesList) {
          if (elem.id == value) {
            return elem;
          }
        }
        return null;
      },
      ...otherOpts,
    })
  }

  static makeOutput(name, type, value, otherOpts) {
    otherOpts = valOr(otherOpts, {});
    let field = new Field({
      name: name,
      type: type,
      value: value,
      isOutput: true,
      ...otherOpts,
    })
    return field;
  }

  makeOutputCopy(otherOpts) {
    otherOpts = valOr(otherOpts, {});
    return new Field({
      name: this.name,
      type: this.type,
      value: this.value,
      choices: this.choices,
      min: this.min,
      allowMin: this.allowMin,
      max: this.max,
      isOutput: true,
      ...otherOpts,
    })
  }

  static getChoiceValue(choice) {
    return (typeof choice == 'object') ? choice.value : choice;
  }

  getInputType() {
    if (this.type == 'Select') {
      return FieldInputType.Select;
    } else if (this.type == 'String') {
      return FieldInputType.String;
    } else if (this.type == 'Number') {
      return FieldInputType.Number;
    } else if (this.type == 'MultiTieredSelect') {
      return 'MultiTieredSelect';
    }
    return FieldInputType.Number;
  }

  _tryClampValue() {
    if (this.getInputType() == 'Number') {
      let min = this.getMin();
      let max = this.getMax();
      // Note: allowMin may be false here, but we still clamp to the min, which is fine.
      // The user will be shown an error on the input ("Value must be > 0"). Better than clamping
      // to 0.0001, which is confusing.
      if (min !== null && this.value < min) {
        this.value = min;
      } else if (max !== null && this.value > max) {
        this.value = max;
      }
    }
  }

  _getDefaultMin() {
    if (this.type in kFieldTypesData) {
      return valOr(kFieldTypesData[this.type].min, null);
    } else {
      return null;
    }
  }

  _getDefaultAllowMin() {
    if (this.type in kFieldTypesData) {
      return valOr(kFieldTypesData[this.type].allowMin, true);
    } else {
      return true;
    }
  }

  _getDefaultMax() {
    if (this.type in kFieldTypesData) {
      return valOr(kFieldTypesData[this.type].max, null);
    } else {
      return null;
    }
  }

  getMin() {
    return this.min;
  }

  getMax() {
    return this.max;
  }

  requiresWholeNumbers() {
    return this.type == FieldType.Count;
  }

  makeUpdater(effectFunc, opts) {
    return this.watcher.addWatchEffect(() => {
      try {
        effectFunc(this);
        this.errorMsg = null;
      } catch (err) {
        console.error(`Error updating ${this.name}:\n${err}\n${err.stack}`);
        this.errorMsg = `There was an internal error while computing this field. ${PleaseContactStr}\n\nDetails:\n${err}`
        gApp.reportError(err);
      }
    }, opts)
  }

  setVisibility(func) {
    return this.watcher.addWatchEffect(() => {
      try {
        this.visible = func();
      } catch (err) {
        console.error(`Error updating ${this.name} visibility:\n${err}\n${err.stack}`);
        this.errorMsg = `There was an internal error while updating this field. ${PleaseContactStr}\n\nDetails:\n${err}`
        gApp.reportError(err);
      }
    })
  }

  makeChoicesUpdater(effectFunc) {
    return this.makeUpdater((field) => {
      let newChoices = effectFunc(field);
      this.setChoices(newChoices);
    })
  }

  // Allow changing the choices of a Select type Field.
  // Will change the cur choice if it is no longer valid.
  setChoices(newChoices) {
    this.choices = newChoices;
    let curChoiceValid = false;
    for (const choice of this.choices) {
      if (this.value == Field.getChoiceValue(choice)) {
        curChoiceValid = true;
        break;
      }
    }
    if (!curChoiceValid && newChoices.length > 0) {
      this.value = Field.getChoiceValue(this.choices[0]);
    }
  }

  getChoices() {
    if (this.choices) {
      return this.choices;
    } else if (this.choicesFunc) {
      return this.choicesFunc();
    } else {
      return null;
    }
  }

  getSelectLabelStr() {
    // console.log(`Cur value: ${props.modelValue.value}. Choices:\n${selectChoices.value}`);
    for (const choice of this.getChoices()) {
      if (this.value === choice) {
        return choice;
      } else if (this.value === choice.value) {
        return choice.label;
      }
    }
    return this.value;
  }

  lookupValue() {
    if (!this.lookupValueFunc) {
      throw new Error(`Field ${this.name} has no lookupValue func.`);
    }
    return this.lookupValueFunc(this.value);
  }

  getNativeUnits() {
    return this.units;
  }

  /*
  Note: The default/base units are Imperial, but we also displaying+editing in Metric,
  so that the user can work in metrics units. All underlying values are still stored in imperial
  and all the calculations are in imperial.

  TODO - not yet implemented.
  */
  getDisplayUnits() {
    let proj = gApp.proj();
    if (!proj) {
      return this.units;
    }
    let projUnits = proj.getProjectUnits();
    if (projUnits === ProjectUnits.Imperial) {
      return this.units;
    } else if (projUnits == ProjectUnits.Metric) {
      if (!this.units in Units._data) {
        return this.units;
      }
      let unitsData = Units._data[this.units];
      if (!unitsData.metric) {
        return this.units;
      }
      return unitsData.metric;
    } else {
      throw new Error(`Unknown project units: ${projUnits}`)
    }
  }

  getUnitsLabel() {
    return Units.getLabel(this.units);
  }

  getValueStr(optTargetUnits) {
    let inputType = this.getInputType();
    if (inputType == FieldInputType.Select) {
      return this.getSelectLabelStr();
    } else if (inputType == FieldInputType.String) {
      return this.value;
    } else if (inputType == FieldInputType.Number) {
      return this.getNumberValueStr(optTargetUnits);
    } else {
      return this.value;
    }
  }

  getNumberValueStr(optTargetUnits) {
    let units = valOr(optTargetUnits, this.units)
    let number = this.getValueInUnits(units);
    let formattedStr = formatNum(number, {maximumFractionDigits: this.numDecimals});
    return formattedStr;
  }

  getNumberValueStrWithUnits() {
    return `${this.getValueStr()}${this.getUnitsLabel()}`;
  }

  getDescStr() {
    if (this.errorMsg !== null) {
      return 'Error';
    }
    if (this.isNA) {
      return 'N/A';
    }
    return this.getValueStr();
  }

  getValueInUnits(targetUnitType) {
    if (!targetUnitType) {
      throw new Error("Must specify the target/desired units.");
    }
    return Units.convertValue(this.value, this.units, targetUnitType);
  }

  getValueInDisplayUnits() {
    return this.getValueInUnits(this.getDisplayUnits());
  }

  setValueInDisplayUnits(newVal) {
    this.value = Units.convertValue(newVal, this.getDisplayUnits(), this.units);
  }

  _getSelectErrors() {
    let didFind = false;
    let choices = this.getChoices();
    if (choices.length == 0) {
      if (this.errorWhenEmpty) {
        return [this.errorWhenEmpty];
      } else {
        // The field is NA for now. Allow any value.
        return [];
      }
    }
    if (this.value == null) {
      return ['Select a value.'];
    }

    let allChoices = choices;
    // Hidden choices are valid (will not cause an error), but the user will not be able to set
    // the field to a hidden choice through the UI. (Used for programmatically-set choices)
    if (this.hiddenChoices) {
      extendArray(allChoices, this.hiddenChoices);
    }
    for (const option of allChoices) {
      let optionValue = (typeof option == 'object') ? option.value : option;
      if (this.value == optionValue) {
        didFind = true;
        break;
      }
    }
    if (!didFind) {
      return [`Invalid option.`];
    }
    return [];
  }

  _getNumberErrors() {
    let min = this.getMin();
    if (min !== null) {
      if (this.allowMin) {
        if (this.value < min) {
          return [`Value must be >= ${min}`];
        }
      } else {
        if (this.value <= min) {
          return [`Value must be > ${min}`];
        }
      }
    }
    let max = this.getMax();
    if (max !== null && this.value > max) {
      return [`Value must be <= ${max}`];
    }
    return [];
  }

  getObjErrors(opts) {
    opts = valOr(opts, {});
    /*
    Returns a list of errors, for the project error tracker.
    */
    if (!this.visible) {
      return [];
    }
    let errors = [];
    if (this.errorMsg) {
      errors.push(this.errorMsg)
    }
    if (this.requiresInput && !this.isOutput) {
      errors.push(`You must fill in this value.`);
      return errors;
    }
    if (this.entryErrorMsg) {
      errors.push(this.entryErrorMsg);
    }
    // console.log(`GETTING ERRORS`);
    let inputType = this.getInputType();
    if (inputType == FieldInputType.Select) {
      extendArray(errors, this._getSelectErrors());
    } else if (inputType == FieldInputType.Number) {
      // console.log(`Adding number errors for ${this.name}`);
      extendArray(errors, this._getNumberErrors());
    }
    extendArray(errors, this.getOtherObjErrors());
    return errors;
  }

  getOtherObjErrors() {
    // Override in subclass if relevant
    return [];
  }

  setEntryErrorMsg(msg) {
    this.entryErrorMsg = msg;
  }

  getEntryErrorMsg() {
    /*
    Requires an error msg that is displayed under the input.
    */
    if (this.entryErrorMsg) {
      return this.entryErrorMsg;
    }
    let allErrors = this.getObjErrors();
    if (allErrors.length > 0) {
      return allErrors[0];
    }
    return null;
  }
};

export class FieldGroup {
  constructor(fields) {
    this.fields = fields;

    this.childObjs = '$auto'
    this.objInfo = {
      '_name': null,
    }
  }

  static fromDict(fieldsDict) {
    let fields = [];
    for (const key in fieldsDict) {
      let field = fieldsDict[key];
      field.key = key;
      fields.push(field);
    }
    return new FieldGroup(fields);
  }

  writeToJson() {
    let obj = {};
    for (const field of this.fields) {
      obj[field.key] = ser.writeToJson(field);
    }
    return obj;
  }

  readFromJson(obj) {
    // console.log(`Reading fieldGroup:\n${prettyJson(obj)}`);
    for (const field of this.fields) {
      if (field.key in obj) {
        // console.log(`Reading key: ${field.key}`);
        ser.readFromJson(field, obj[field.key]);
      }
    }
  }

  getField(fieldKey) {
    for (const field of this.fields) {
      if (field.key == fieldKey) {
        return field;
      }
    }
    throw new Error(`Could not find field: ${fieldKey}. Fields:\n${this.fields.map((elem) => { return elem.key})}`);
  }

  get(fieldKey) {
    return this.getField(fieldKey);
  }

  setVisibility(func) {
    return gApp.proj().addWatchEffect(() => {
      try {
        let isVisible = func();
        for (const field of this.fields) {
          field.visible = isVisible;
        }
      } catch (err) {
        console.error(`Error updating FieldGroup visibility:\n${err}\n${err.stack}`);
        gApp.reportError(err);
      }
    })
  }
};

export class SelectOrManualInput {
    init(name, optionsData, inputType) {
        this.optionsData = optionsData;

        // TODO - improve this (maybe make a single field?)
        this.optionPicker = Field.makeSelect(name, optionsData)
        this.value = new Field({
            name: 'Value',
            type: inputType
        })
        this.value.makeUpdater((field) => {
          let curOption = this.optionPicker.value;
          if (curOption !== 'Manual') {
            field.isOutput = true;
            field.value = this.optionsData._data[curOption].value;
          } else {
            field.isOutput = false;
          }
        });

        this.serFields = [
            'optionPicker',
            'value',
        ]
        this.childObjs = '$auto'
    }

    getValue() {
      return this.value.value;
    }

    setVisibility(func) {
      return gApp.proj().addWatchEffect(() => {
        try {
          let isVisible = func();
          this.optionPicker.visible = isVisible;
          this.value.visible = isVisible;
        } catch (err) {
          console.error(`Error updating SelectOrManual visibility:\n${err}\n${err.stack}`);
          gApp.reportError(err);
        }
      })
    }
}
setupClass(SelectOrManualInput)
