import { makeEnum, makeOptions,
  setupClass, lookupData, 
} from '../Base.js'
import { InputComponent } from '../Common/InputComponent.js'

import { DoorsData } from '../MaterialData/DoorsData.js'
import { Units } from '../Common/Units.js'

import { prettyJson, valOr,
} from '../SharedUtils.js'
export { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
} from '../Common/Field.js'

import {
  kDirectionChoices, YesNo,
  makeYesNoField, makeNoYesField,
  InstallationSealing,
 } from './Common.js'

 import {
  WindowType, WindowStyle, WindowBuilder
 } from './WindowType.js'

import { gApp, DebugOn } from '../Globals.js'


export let DoorInputType = makeEnum({
  Manual: 'Manual',
  BuildDoor: 'Build door',
})

let ScreenDoorTypes = makeEnum({
  None: 'None',
  Inside: 'Inside',
  Outside: 'Outside',
})

export let DoorColor = makeEnum({
  Light: 'Light',
  Medium: 'Medium',
  Dark: 'Dark',
})

export let DoorBuildType = makeEnum({
  SwingingDoor: 'Swinging door',
  SlidingGlassDoor: 'Sliding glass door',
  StileAndRailDoor: 'Swinging stile and rail glass door',
  RevolvingDoor: 'Revolving door',
  SteelEmergencyExitDoor: 'Steel emergency exit door',
  SteelSectional: 'Steel section / Tilt-up',
  AircraftHangarDoor: 'Aircraft hangar door',
});

let SwingingDoorType = makeEnum({
  WoodSlabInWoodFrame: 'Wood slab in wood frame',
  InsulatedSteelSlabWithWoodEdgeInWoodFrame: 'Insulated steel slab with wood edge in wood frame',
  InsulatedSteelSlabWithMetalEdgeInMetalFrame: 'Insulated steel slab with metal edge in metal frame',
  CardboardHoneycombSlab: 'Cardboard honeycomb slab with metal edge in metal frame',
})

let DoorGlassType = makeEnum({
  SinglePane: 'Single-pane',
  DoublePane: 'Double-pane',
  DoublePaneLowE: 'Double-pane, low-e',
})

class SwingingDoor {
  init() {
    let door = this;
    this.type = Field.makeSelect('Type', SwingingDoorType)
    this.percentGlazing = new Field({
      name: 'Percent Glass',
      type: FieldType.Percent,
    })
    this.glassType = Field.makeSelect('Glass type', DoorGlassType)
    this.glassType.makeUpdater((field) => {
      field.visible = door.percentGlazing.value > 0;
    })
    this.thermalBreak = Field.makeSelect('Thermal break', YesNo)

    this.fields = [
      'type',
      'percentGlazing',
      'glassType',
      'thermalBreak',
    ]
    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Swinging Door',
    }
  }
}
setupClass(SwingingDoor)

let AluminumDoorFrameType = makeEnum({
  AluminumWithoutThermalBreak: 'Aluminum without thermal break',
  AluminumWithThermalBreak: 'Aluminum with thermal break',
})

class SlidingGlassDoor {
  init() {
    this.windowBuilder = WindowBuilder.create(this, {isSlidingGlassDoor: true});

    this.serFields = [
      'windowBuilder',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Sliding Glass Door',
    }
  }
}
setupClass(SlidingGlassDoor)

class StileAndRailDoor {
  init() {
    let door = this;
    this.frameType = Field.makeSelect('Door frame type', AluminumDoorFrameType)
    // Note: we assume that this door type is 100% glass
    this.glassType = Field.makeSelect('Glass type', DoorGlassType)
    // this.thermalBreak = Field.makeSelect('Thermal break', YesNo)

    this.fields = [
      'frameType',
      'glassType',
      // 'thermalBreak',
    ]
    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Stile and Rail Door',
    }
  }

  getPercentGlazing() {
    // Note: we originally had this door allow some user-given % glass, but
    // now it is always 100%.
    return 100.0;
  }
}
setupClass(StileAndRailDoor)

let RevolvingDoorType = makeEnum({
  ThreeWing: '3-wing',
  FourWing: '4-wing',
})

let RevolvingDoorSize = makeEnum({
  n8by7: '8ft x 7ft',
  n10by8: '10ft x 8ft',
  n7by6p5: '7ft x 6.5ft',
  n7by7p5: '7ft by 7.5ft',
})

class RevolvingDoor {
  init() {
    let door = this;
    this.type = Field.makeSelect('Type', RevolvingDoorType)
    this.size = Field.makeSelect('Size', RevolvingDoorSize);
    this.size.makeChoicesUpdater(() => {
      if (door.type.value == RevolvingDoorType.ThreeWing) {
        return makeOptions(RevolvingDoorSize, [
          RevolvingDoorSize.n8by7,
          RevolvingDoorSize.n10by8,
        ])
      } else if (door.type.value == RevolvingDoorType.FourWing) {
        return makeOptions(RevolvingDoorSize, [
          RevolvingDoorSize.n7by6p5,
          RevolvingDoorSize.n7by7p5,
        ])
      }
      throw new Error("Uknown revolving door type");
    })

    this.fields = [
      'type',
      'size',
    ]
    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'RevolvingDoor',
    }
  }
}
setupClass(RevolvingDoor)

let SteelEmergExitInsulation = makeEnum({
  HoneycombKraftPaper: 'Honeycomb kraft paper',
  MineralWoolWithSteelRibs: 'Mineral wool with steel ribs',
  PolyurethaneFoam: 'Polyurethane foam',
})

let SteelEmergExitInsulationThickness = makeEnum({
  One3f8: '1 3/8"',
  One3f4: '1 3/4"',
})

let SteelEmergExitNumDoors = makeEnum({
  Single: 'Single',
  Double: 'Double',
})

class SteelEmergencyExitDoor {
  init() {
    this.insulation = Field.makeSelect('Insulation', SteelEmergExitInsulation)
    this.insulationThickness = Field.makeSelect('Insulation Thickness',
      SteelEmergExitInsulationThickness)
    this.thermalBreak = Field.makeSelect('Thermal break', YesNo)
    this.numDoors = Field.makeSelect('Number of doors', SteelEmergExitNumDoors)

    this.fields = [
      'insulation',
      'insulationThickness',
      'thermalBreak',
      'numDoors',
    ]

    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Steel Emergency Exit Door',
    }
  }
}
setupClass(SteelEmergencyExitDoor)

let SteelSectionalInsulationType = makeEnum({
  PolyurethaneThermallyBroken: 'Polyurethane, thermally broken',
  ExtrudedPolystreneWithSteelRibs: 'Extruded polystrene with steel ribs',
  ExpandedPolystreneWithSteelRibs: 'Expanded polystrene with steel ribs',
  Uninsulated: 'Uninsulated',
})

let SteelSectionalInsulationThickness = makeEnum({
  One_3f4: '1 3/4"',
  One_3f8: '1 3/8"',
  Two: '2"',
  Three: '3"',
  Four: '4"',
  Six: '6"',
})

class SteelSectionalDoor {
  init() {
    let door = this;
    this.insulationType = Field.makeSelect('Insulation type', SteelSectionalInsulationType)
    this.insulationThickness = Field.makeSelect('Insulation thickness', SteelSectionalInsulationThickness)
    this.insulationThickness.makeChoicesUpdater(() => {
      if (door.insulationType.value == SteelSectionalInsulationType.PolyurethaneThermallyBroken) {
        return makeOptions(SteelSectionalInsulationThickness, [
          SteelSectionalInsulationThickness.One_3f4,
        ]);
      } else {
        return makeOptions(SteelSectionalInsulationThickness, [
          SteelSectionalInsulationThickness.One_3f8,
          SteelSectionalInsulationThickness.Two,
          SteelSectionalInsulationThickness.Three,
          SteelSectionalInsulationThickness.Four,
          SteelSectionalInsulationThickness.Six,
        ])
      }
    })
    this.insulationThickness.setVisibility(() => {
      return door.insulationType.value != SteelSectionalInsulationType.Uninsulated;
    })

    this.fields = [
      'insulationType',
      'insulationThickness',
    ]

    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Steel Sectional Door',
    }
  }
}
setupClass(SteelSectionalDoor)

let AircraftHangarDoorInsulationType = makeEnum({
  ExpandedPolystrene: 'Expanded polystrene',
  MineralWoolWithSteelRibs: 'Mineral wool with steel ribs',
  ExtrudedPolystrene: 'Extruded polystrene',
  NoInsulation: 'No insulation',
})

let AircraftHangarDoorInsulationThickness  = makeEnum({
  Four: '4"',
  Six: '6"',
})

let AircraftHangarDoorApproxSize = makeEnum({
  n72by12: '72ft x 12ft (small private planes)',
  n240by50: '240ft x 50ft (commerical jet)',
})

class AircraftHangarDoor {
  init() {
    let door = this;
    this.insulationType = Field.makeSelect('Insulation type', AircraftHangarDoorInsulationType)
    this.insulationThickness = Field.makeSelect('Insulation thickness', AircraftHangarDoorInsulationThickness)
    this.insulationThickness.makeChoicesUpdater(() => {
      if (door.insulationType.value == AircraftHangarDoorInsulationType.NoInsulation) {
        return [];
      } else {
        return makeOptions(AircraftHangarDoorInsulationThickness)
      }
    })
    this.approxSize = Field.makeSelect('Approximate size', AircraftHangarDoorApproxSize)

    this.fields = [
      'insulationType',
      'insulationThickness',
      'approxSize',
    ]

    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Aircraft Hangar Door',
    }
  }
}
setupClass(AircraftHangarDoor)

class DoorBuilder {
  init(doorType) {
    this.doorType = doorType;

    // Note: for the residential app, only swinging door and sliding glass door
    // will be available
    let choices = []
    if (gApp.proj().isResidential()) {
      choices = makeOptions(DoorBuildType, [DoorBuildType.SwingingDoor, DoorBuildType.SlidingGlassDoor])
    } else {
      choices = makeOptions(DoorBuildType)
    }
    this.type = new Field({
      name: 'Door type',
      type: FieldType.Select,
      choices: choices
    })
    
    this.swingingDoor = SwingingDoor.create();
    this.slidingGlassDoor = SlidingGlassDoor.create();
    this.stileAndRailDoor = StileAndRailDoor.create();
    this.revolvingDoor = RevolvingDoor.create()
    this.steelEmergencyExitDoor = SteelEmergencyExitDoor.create();
    this.steelSectionalDoor = SteelSectionalDoor.create();
    this.aircraftHangarDoor = AircraftHangarDoor.create();

    let objMap = {
      [DoorBuildType.SwingingDoor]: this.swingingDoor,
      [DoorBuildType.SlidingGlassDoor]: this.slidingGlassDoor,
      [DoorBuildType.StileAndRailDoor]: this.stileAndRailDoor,
      [DoorBuildType.RevolvingDoor]: this.revolvingDoor,
      [DoorBuildType.SteelEmergencyExitDoor]: this.steelEmergencyExitDoor,
      [DoorBuildType.SteelSectional]: this.steelSectionalDoor,
      [DoorBuildType.AircraftHangarDoor]: this.aircraftHangarDoor,
    }
    this.doorType.updater.setMapItemEnabled('door-builder-type', objMap, () => {
      return this.type.value;
    })

    this.serFields = [
      'type',
      'swingingDoor',
      'slidingGlassDoor',
      'stileAndRailDoor',
      'revolvingDoor',
      'steelEmergencyExitDoor',
      'steelSectionalDoor',
      'aircraftHangarDoor',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Door Builder',
    }
  }

  getWindowBuilderIfUsing() {
    if (this.type.value == DoorBuildType.SlidingGlassDoor) {
      return this.slidingGlassDoor.windowBuilder;
    }
    return null;
  }
}
setupClass(DoorBuilder)

export class DoorType extends InputComponent {
  init(name, makeId) {
    let doorType = this;

    this.id = makeId ? gApp.proj().makeId('DoorType') : 0;

    this.name = Field.makeName('Name', name)
    this.height = new Field({
      name: 'Height',
      type: FieldType.Length,
      requiresInput: true,
    })
    this.width = new Field({
      name: 'Width',
      type: FieldType.Length,
      requiresInput: true,
    })
    
    this.installationSealing = Field.makeSelect('Installation Sealing', InstallationSealing)
    this.addScreenDoor = Field.makeSelect('Screen door', ScreenDoorTypes)
    this.colour = Field.makeSelect('Door Colour', DoorColor)
    this.inputType = Field.makeSelect('Input Type', DoorInputType, {bold:true})

    // Manual inputs:
    this.manualUValue = new Field({
      name: 'U-Value',
      type: FieldType.UValue,
      min: 0,
      allowMin: false,
      requiresInput: true,
    })
    this.percentGlass = new Field({
      name: 'Percent Glass',
      type: FieldType.Percent,
      min: 0,
      value: 0,
    })
    this.glassDoorShgc = new Field({
      name: 'Glass SHGC (total window)',
      type: FieldType.Ratio,
      min: 0,
      allowMin: false,
      requiresInput: true,
    })
    this.glassDoorUValue = new Field({
      name: 'Glass U-Value',
      type: FieldType.UValue,
      min: 0,
      allowMin: false,
      requiresInput: true,
    })

    // Note: the way we set the field visibility here is a bit gross b/c we have conditionally
    // visible manual fields, but whatever.
    this.manualFields = ['manualUValue', 'percentGlass', 'glassDoorShgc', 'glassDoorUValue']
    this.updater.setFieldsEnabled('manual-fields', this, ['manualUValue', 'percentGlass'], () => {
      return this.inputType.value == DoorInputType.Manual;
    })
    this.updater.setFieldsEnabled('glass-fields', this, ['glassDoorShgc', 'glassDoorUValue'], () => {
      return this.inputType.value == DoorInputType.Manual && this.percentGlass.value > 0;
    })

    this.doorBuilder = DoorBuilder.create(this)
    this.updater.setEnabledWhen('door-builder', this.doorBuilder, () => {
      return this.inputType.value == DoorInputType.BuildDoor;
    })

    this.outputUValue = new Field({
      name: 'Output U-Value',
      type: FieldType.UValue,
      isOutput: true,
      allowMin: false,
    })
    this.outputUValue.makeUpdater((field) => {
      let uValue = doorType.computeUValue();
      field.value = uValue.uValue;
      field.debugOutput = DebugOn() ? prettyJson(uValue) : null;
    })
    this.outputShgc = new Field({
      name: 'Output SHGC',
      type: FieldType.Ratio,
      isOutput: true,
    })
    this.outputShgc.makeUpdater((field) => {
      let shgcs = doorType.computeShgc()
      field.value = shgcs.shgc;
      field.debugOutput = DebugOn() ? prettyJson(shgcs) : null;
    })

    this.serFields = [
      'id',
      'name',
      'height',
      'width',
      'installationSealing',
      'addScreenDoor',
      'colour',
      'inputType',
      'manualUValue',
      'percentGlass',
      'glassDoorShgc',
      'glassDoorUValue',
      'doorBuilder',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Door Type',
    }
  }

  getInputPage() {
    return {
      label: `Doors - ${this.name.value}`,
      // TODO
      path: `doors/${this.id}`,
    };
  }

  getArea() {
    return this.height.value * this.width.value;
  }

  getPerimeter() {
    return 2 * (this.height.value + this.width.value);
  }

  getOpaqueArea() {
    return this.getArea() * (1.0 - this.getPercentGlass() / 100.0);
  }

  getGlassArea() {
    return this.getArea() * (this.getPercentGlass() / 100.0);
  }

  hasScreenDoor() {
    return this.addScreenDoor.value != ScreenDoorTypes.None;
  }

  getWindowStyle() {
    // We assume that for doors with glass, the window-style is operable
    return WindowStyle.Operable;
  }

  getWindowBuilderIfUsing() {
    if (this.inputType.value == DoorInputType.BuildDoor) {
      return this.doorBuilder.getWindowBuilderIfUsing();
    }
    return null;
  }

  isManualEntry() {
    return this.inputType.value == DoorInputType.Manual;
  }

  getDoorTypeStr() {
    return this.isManualEntry() ? "N/A" : DoorBuildType._labels[this.doorBuilder.type.value];
  }

  // Percent by area, [0-100]
  getPercentGlass() {
    if (this.inputType.value == DoorInputType.Manual) {
      return this.percentGlass.value;
    } else {
      let type = this.doorBuilder.type.value;
      if (type == DoorBuildType.SwingingDoor) {
        return this.doorBuilder.swingingDoor.percentGlazing.value;
      } else if (type == DoorBuildType.SlidingGlassDoor) {
        return 100.0;
      } else if (type == DoorBuildType.StileAndRailDoor) {
        return 100.0;
      } else {
        // Assume other types have no glass
        console.log(`Other door type: ${type}`);
        return 0;
      }
    }
  }

  computeUValue() {
    if (this.inputType.value == DoorInputType.Manual) {
      let percentGlass = this.percentGlass.value / 100.0;
      let uValueDoor = this.manualUValue.value;
      let uValueGlass = this.glassDoorUValue.value;
      let uValue = uValueDoor * (1 - percentGlass) + uValueGlass * percentGlass;
      return {uValue, uValueDoor, uValueGlass};
    } else {
      let builder = this.doorBuilder;
      let doorType = builder.type.value;
      if (doorType == DoorBuildType.SwingingDoor) {
        let door = builder.swingingDoor;
        let uValueDoor = lookupData(DoorsData, ['SlabDoor', door.type.value])
        let percentGlass = door.percentGlazing.value / 100.0;
        if (percentGlass == 0) {
          return {uValue: uValueDoor, uValueDoor, uValueGlass: 0};
        } else {
          // Note: these are row nums, not row ids
          let glassRowMap = {
            SinglePane: 2,
            DoublePane: 7,
            DoublePaneLowE: 24,
          };
          let glassRow = lookupData(glassRowMap, [door.glassType.value])

          let glassCol = null;
          let detailedDoorType = door.type.value;
          let thermalBreak = door.thermalBreak.value == YesNo.Yes;
          if (detailedDoorType == 'WoodSlabInWoodFrame') {
            glassCol = 'I';
          } else if (detailedDoorType == 'InsulatedSteelSlabWithWoodEdgeInWoodFrame') {
            glassCol = 'I';
          } else if (detailedDoorType == 'InsulatedSteelSlabWithMetalEdgeInMetalFrame' ||
            detailedDoorType == 'CardboardHoneycombSlab' || detailedDoorType == 'StileAndRailDoor') {
            if (thermalBreak) {
              glassCol = 'G';
            } else  {
              glassCol = 'F';
            }
          }
          if (!glassCol) {
            throw new Error("Unexpected: glassCol not found for door type: " + detailedDoorType);
          }

          let uValueGlass = gApp.proj().windowsData.uValues.lookupValue(glassRow, glassCol);
          let uValue = uValueDoor * (1 - percentGlass) + uValueGlass * percentGlass;
          return {uValue, uValueDoor, uValueGlass, debug: {uValueDoor, uValueGlass, percentGlass, glassRow, glassCol}}
        }
      } else if (doorType == DoorBuildType.SlidingGlassDoor) {
        // Treat like a window type. WindowStyle is assumed to be Operable.
        let door = builder.slidingGlassDoor;
        let winBuilder = door.windowBuilder;

        // console.log("WinBuilder: ", winBuilder);
        let inputData = {
          width: this.width.getValueInUnits(Units.ft),
          height: this.height.getValueInUnits(Units.ft),
          frameWidth: winBuilder.frameWidth.getValueInUnits(Units.ft),
          numPanes: winBuilder.numPanes.value,
          glazing: winBuilder.glazing.value,
          panesAppliedTo: winBuilder.panesAppliedTo.value,
          gapType: winBuilder.gapType.value,
          windowStyle: WindowStyle.Operable,
          frameStyle: winBuilder.frameStyle.value,
          windowsData: gApp.proj().windowsData,
        };
        let res = WindowType.computeUValue(inputData);
        return {...res, uValueGlass: res.uValue, uValueDoor: 0};
      } else if (doorType == DoorBuildType.StileAndRailDoor) {
        let door = builder.stileAndRailDoor;
        // Note: these are row-nums, not ids
        let glassRowMap = {
          SinglePane: 2,
          DoublePane: 12,
          DoublePaneLowE: 29,
        };
        let glassRow = lookupData(glassRowMap, [door.glassType.value])

        let glassCol = null;
        let frameType = door.frameType.value;
        if (frameType == AluminumDoorFrameType.AluminumWithoutThermalBreak) {
          glassCol = 'F';
        } else if (frameType == AluminumDoorFrameType.AluminumWithThermalBreak) {
          glassCol = 'G';
        }
        if (!glassCol) {
          throw new Error("Unexpected: glassCol not found for frame type: " + frameType);
        }

        let uValueGlass = gApp.proj().windowsData.uValues.lookupValue(glassRow, glassCol);
        //console.log(`U-Value Glass: ${uValueGlass}`);
        return {uValue: uValueGlass, uValueDoor: 0, uValueGlass, debug: {uValueGlass, glassRow, glassCol}}
      } else if (doorType == DoorBuildType.RevolvingDoor) {
        let door = builder.revolvingDoor;
        let uValueDoor = lookupData(DoorsData,
          ['RevolvingDoor', door.type.value, door.size.value])
        return {uValue: uValueDoor, uValueDoor, uValueGlass: 0};
      } else if (doorType == DoorBuildType.SteelEmergencyExitDoor) {
        let door = builder.steelEmergencyExitDoor;
        let thermalBreakField = door.thermalBreak.value == YesNo.Yes ? 'WithThermalBreak' : 'WithoutThermalBreak';
        let uValueDoor = lookupData(DoorsData,
          ['SteelEmergencyExitDoor', thermalBreakField, door.insulationThickness.value,
          door.insulation.value, door.numDoors.value]);
        return {uValue: uValueDoor, uValueDoor, uValueGlass: 0};
      } else if (doorType == DoorBuildType.SteelSectional) {
        let door = builder.steelSectionalDoor;
        let uValueDoor = lookupData(DoorsData, [
          'SteelSectional',
          door.insulationThickness.value,
          door.insulationType.value,
        ]);
        return {uValue: uValueDoor, uValueDoor, uValueGlass: 0};
      } else if (doorType == DoorBuildType.AircraftHangarDoor) {
        let door = builder.aircraftHangarDoor;
        let uValueDoor = lookupData(DoorsData, [
          'AircraftHangarDoor',
          door.insulationThickness.value,
          door.insulationType.value,
          door.approxSize.value,
        ]);
        return {uValue: uValueDoor, uValueDoor, uValueGlass: 0};
      } else {
        throw new Error("Unknown door type: " + doorType);
      }
    }
  }

  computeShgc() {
    if (this.inputType.value == DoorInputType.Manual) {
      if (this.percentGlass.value > 0) {
        let uValue = this.computeUValue().uValue;
        let shgcs = WindowType._estimateShgcs(this.glassDoorShgc.value, uValue);
        return {...shgcs, isNA: false};
      } else {
        // NA
        return {shgc: 0, isNA: true};
      }
    } else {
      let rowsMap = {
        SinglePane: '1b',
        DoublePane: '5b',
        DoublePaneLowE: '17d',
      };
      let builder = this.doorBuilder;
      let doorType = builder.type.value;
      if (doorType == DoorBuildType.SwingingDoor) {
        let door = builder.swingingDoor;
        if (door.percentGlazing.value == 0) {
          // NA
          console.log("Percent glazing: 0");
          return {shgc: 0, isNA: true};
        }
        let colChar = null;
        let detailedDoorType = door.type.value;
        if (detailedDoorType == SwingingDoorType.WoodSlabInWoodFrame ||
            detailedDoorType == SwingingDoorType.InsulatedSteelSlabWithWoodEdgeInWoodFrame) {
          colChar = 'R';
        } else {
          colChar = 'P';
        }
        let rowId = lookupData(rowsMap, [door.glassType.value])
        let shgcs = WindowType._lookupShgcs(gApp.proj().windowsData, rowId, colChar);
        WindowType.normalizeShgcValues(shgcs);
        return {
          ...shgcs,
          isNA: false,
          debug: {
            rowId: rowId,
            colChar: colChar
          },
        };
      } else if (doorType == DoorBuildType.StileAndRailDoor) {
        let door = builder.stileAndRailDoor;
        if (door.getPercentGlazing() == 0) {
          // NA
          return {shgc: 0, isNA: true};
        }
        let colChar = 'P';
        let rowId = lookupData(rowsMap, [door.glassType.value]);
        let shgcs = WindowType._lookupShgcs(gApp.proj().windowsData, rowId, colChar);
        WindowType.normalizeShgcValues(shgcs);
        return {
          ...shgcs,
          isNA: false,
          debug: {
            rowId: rowId,
            colChar: colChar
          },
        }
      } else if (doorType == DoorBuildType.SlidingGlassDoor) {
        // Do the same way as for windows. WindowStyle is assumed to be Operable.
        let door = builder.slidingGlassDoor;
        let winBuilder = door.windowBuilder;

        let inputData = {
          width: this.width.getValueInUnits(Units.ft),
          height: this.height.getValueInUnits(Units.ft),
          frameWidth: winBuilder.frameWidth.getValueInUnits(Units.ft),
          numPanes: winBuilder.numPanes.value,
          glazing: winBuilder.glazing.value,
          panesAppliedTo: winBuilder.panesAppliedTo.value,
          paneThickness: winBuilder.paneThickness.value,
          tint: winBuilder.tint.value,
          gapType: winBuilder.gapType.value,
          windowStyle: WindowStyle.Operable,
          frameStyle: winBuilder.frameStyle.value,
          windowsData: gApp.proj().windowsData,
        };
        return {...WindowType.computeShgc(inputData), isNA: false};
      } else {
        // NA
        return {shgc: 0, isNA: true};
      }
    }
  }
};
setupClass(DoorType);