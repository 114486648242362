
/*
Used to store Data that is loaded from files.
Loads the data on first use, and then caches it.
*/
export class DataCache {
    constructor() {
        this.cache = {}
        this.loaders = {}
    }

    registerTable(name, loader) {
      this.loaders[name] = loader;
    }

    getTable(name) {
      if (!(name in this.cache)) {
        if (!(name in this.loaders)) {
          throw new Error(`Table '${name}' not found. Did you forget to register it?`);
        }
        this.cache[name] = this.loaders[name]();
      }
      return this.cache[name];
    }

    toValString() {
      return 'DataCache...'
    }
};