<script setup>
import { ref } from 'vue'
import {
  gApp,
} from '../Globals.js'
import { DoorType, DoorBuildType, DoorInputType } from '../Components/DoorType.js'
import { formatNum, removeElem, resOr, generateItemName, isDupName, } from '../SharedUtils.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import ConfirmModal from './ConfirmModal.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import ComponentErrorsList from './utils/ComponentErrorsList.vue'

import EditButton from './utils/EditButton.vue'
import DeleteButton from './utils/DeleteButton.vue'
import BackButton from './utils/BackButton.vue'
import { GetTypeHelpString } from '../Common/UIStrings.js'

let proj = gApp.proj();
let doors = proj.doorTypes;
let selectedDoor = ref(null);
let deleteModal = ref(null);

function selectDoor(door) {
  selectedDoor.value = door;
}

function deleteDoor(door) {
  deleteModal.value.showModal("Delete", `Delete door type ${door.name.value}?`, () => {
    removeElem(doors, door);
  })
}

function addDoor() {
  doors.push(DoorType.create(generateItemName('Door', doors), true))
  selectDoor(doors[doors.length - 1]);
}

function doorShgc0(door) {
  return resOr(() => {
    let shgc = door.computeShgc();
    return !shgc.isNA ? formatNum(shgc.Deg0) : "N/A"
  }, 'Error');
}

function doorShgcDiffuse(door) {
  return resOr(() => {
    let shgc = door.computeShgc();
    return !shgc.isNA ? formatNum(shgc.Diffuse) : "N/A"
  }, 'Error');
}

</script>

<template>
  <div>
    <HelpSection id="DoorsView">
      <h4 class="mb-s">Door Types</h4>
      <p class="mb-m">
        Use this page to build different door types for use in the Build House tab.
      </p>
      <p class="mb-m">
        If <b>Manual Entry</b> is selected, the <b>U-value</b> should be entered for the entire door assembly, including the frame. The U-value will have an impact on both the heating and cooling loads.
        The <b>SHGC</b> value will only be considered for the portion of the door that is glass, given by the <b>Percent Glass</b> input. If the Glass Door option is selected, then the glass percentage is assumed to be 100%, and the SHGC value will be applied to the entire door assembly, including the frame.
        <b>SHGC</b> input must be the direct beam SHGC value, as reported by the manufacturer according to NFRC (National Fenestration Rating Council) testing standards. If a different standard is used, a conversion to NFRC standard is recommended to get accurate results. Based on the direct beam value given, SHGC values at different angles will be estimated based on patterns for similar door types.
        <br><br>
        Where <b>Percent glass</b> is less than 100% of the door, the portion of the door that is not glass will be treated as a wall for cooling conduction loads. If the door is known to be more than 90% glass, it is recommended that the Glass Door option be selected anyways, as the door will behave more like an operable window.
        <br><br>
          If <b>Build Door</b> is selected, pay close attention to the SHGC and U-value outputs, as they are estimated based on available data of doors with similar attributes to those selected.
          If <b>Sliding Glass Door</b> is selected as the door type, it will be assumed that the glass percentage is 100%, and the door will be treated very similarly to an operable window of the same dimensions.
      </p>
    </HelpSection>
    <div v-if="selectedDoor">
      <div class="mb-m">
        <BackButton @click="selectedDoor=null" />
        <h1>{{ selectedDoor.name.value || 'Untitled' }}</h1>
      </div>
      <ComponentErrorsList :component="selectedDoor" />
      <FieldInput v-model="selectedDoor.name" />
      <WarnText v-if="isDupName(selectedDoor.name.value, doors)">There is already a door with that name. Please choose another.</WarnText>
      <InputGroup title="General">
        <FieldInput v-model="selectedDoor.height" />
        <FieldInput v-model="selectedDoor.width" />
        <FieldInput v-model="selectedDoor.installationSealing" />
        <FieldInput v-model="selectedDoor.addScreenDoor" />
        <FieldInput v-model="selectedDoor.colour" />
      </InputGroup>

      <InputGroup title="U-Value and SHGC">
        <div class="mb-s">
          <FieldInput v-model="selectedDoor.inputType" class="MarginBotS" />
        </div>
        <div class="MarginBotM">
          <div v-if="selectedDoor.inputType.value == DoorInputType.Manual">
            <FieldList :obj="selectedDoor" :fieldNames="selectedDoor.manualFields" />
          </div>
          <div v-else>
            <div class="mt-m mb-xs">
              <p class="mute-header">Door Builder</p>
              <p class="help-text">Build your door here. The U-Value and SHGC will be calculated from the inputs.</p>
            </div>
            <FieldInput v-model="selectedDoor.doorBuilder.type" />
            <div v-if="selectedDoor.doorBuilder.type.value == DoorBuildType.SwingingDoor">
              <FieldList :obj="selectedDoor.doorBuilder.swingingDoor" />
            </div>
            <div v-else-if="selectedDoor.doorBuilder.type.value == DoorBuildType.SlidingGlassDoor">
              <FieldList :obj="selectedDoor.doorBuilder.slidingGlassDoor.windowBuilder" />
            </div>
            <div v-else-if="selectedDoor.doorBuilder.type.value == DoorBuildType.StileAndRailDoor">
              <FieldList :obj="selectedDoor.doorBuilder.stileAndRailDoor" />
            </div>
            <div v-else-if="selectedDoor.doorBuilder.type.value == DoorBuildType.RevolvingDoor">
              <FieldList :obj="selectedDoor.doorBuilder.revolvingDoor" />
            </div>
            <div v-else-if="selectedDoor.doorBuilder.type.value == DoorBuildType.SteelEmergencyExitDoor">
              <FieldList :obj="selectedDoor.doorBuilder.steelEmergencyExitDoor" />
            </div>
            <div v-else-if="selectedDoor.doorBuilder.type.value == DoorBuildType.SteelSectional">
              <FieldList :obj="selectedDoor.doorBuilder.steelSectionalDoor" />
            </div>
            <div v-else-if="selectedDoor.doorBuilder.type.value == DoorBuildType.AircraftHangarDoor">
              <FieldList :obj="selectedDoor.doorBuilder.aircraftHangarDoor" />
            </div>
          </div>
        </div>
      </InputGroup>
      <InputGroup title="Results">
        <FieldInput v-model="selectedDoor.outputUValue" />
        <FieldInput v-model="selectedDoor.outputShgc" />
      </InputGroup>
    </div>
    <div v-else>
      <h1 class="PageH1">Door Types</h1>
      <div class="mb-l Container">
        <p class="mute-header">Info</p>
        <p class="help-text w-80">
          Add a <b>Door Type</b> for each different type of door used in your project.
          {{ GetTypeHelpString('Walls') }}
        </p>
      </div>
      <button @click="addDoor" class="MarginBotXS CreateBtn">Add door type</button>
      <table class="SimpleTable" v-if="doors.length">
        <tr>
          <th>Name</th>
          <th>Style</th>
          <th>Height</th>
          <th>Width</th>
          <th>U-Value</th>
          <th>SHGC (0 Deg)</th>
          <th>SHGC (Diff.)</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="(door, index) in doors" class="WindowSection MarginBotL">
          <td>{{ door.name.value }}</td>
          <td>{{ door.getDoorTypeStr() }}</td>
          <td>{{ door.height.getValueStr() }}</td>
          <td>{{ door.width.getValueStr() }}</td>
          <td>{{ door.outputUValue.getValueStr() }}</td>
          <td>{{ doorShgc0(door) }}</td>
          <td>{{ doorShgcDiffuse(door) }}</td>
          <td><EditButton @click="selectDoor(door)" /></td>
          <td><DeleteButton @click="deleteDoor(door)" /></td>
        </tr>
      </table>
    </div>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<style scoped>
</style>
