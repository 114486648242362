<script setup>
import { ref, computed, onMounted, nextTick, } from 'vue'

let props = defineProps({
  value: Object,
  labelWidth: {
    type: Number,
    default: 250,
  },
  useLabelWidthVar: {
    type: Boolean,
    default: false,
  },
  compact: {
    type: Boolean,
    default: false,
  },
})

function getLabelWidthStyle() {
  if (props.useLabelWidthVar) {
    return {
      width: `var(--field-label-width)`
    }
  } else {
    return {
      width: `${props.labelWidth}px`
    }
  }
}

</script>

<template>
  <div class="OutputValue StdInput mb-xxs">
    <div v-if="!compact" class="InputLabel" :style="getLabelWidthStyle()" v-html="value.name">
    </div>
    <div class="OutputParent">
      <div class="InnerOutputParent" :class="{OutputHasError: value.errorMsg !== null}">
        <p class="">{{value.valueStr}}</p>
        <p v-if="value.unitsStr">{{value.unitsStr}}</p>
      </div>
      <div v-if="value.errorMsg">
        <p class="ErrorMsg help-text">{{ value.errorMsg }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.OutputValue {
  display: flex;
  align-items: center;
  gap: 8px;
}

.InputLabel {
  padding-right: var(--m);
}

.InnerOutputParent {
  display: flex;
  align-items: baseline;
  gap: 8px;
  color: blue;
}

.OutputHasError {
  color: #f39c12;
}

.ErrorMsg {
  font-size: var(--f-xs);
  line-height: 1.2;
}
</style>