<script setup>
import { ref, computed, } from 'vue'
import { gApp, } from '../Globals.js'
import { BufferSpaceType } from '../Components/BufferSpaceType.js'
import { formatNum, removeElem, generateItemName, isDupName, } from '../SharedUtils.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import ConfirmModal from './ConfirmModal.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import DeleteButton from './utils/DeleteButton.vue'
import EditButton from './utils/EditButton.vue'
import BackButton from './utils/BackButton.vue'
import ComponentErrorsList from './utils/ComponentErrorsList.vue'

import { GetTypeHelpString } from '../Common/UIStrings.js'

let proj = gApp.proj();
let spaces = proj.bufferSpaceTypes;
let space = ref(null);
let deleteModal = ref(null);

function selectSpace(newSpace) {
  space.value = newSpace;
}

function deleteSpace(someSpace) {
  deleteModal.value.showModal("Delete", `Delete buffer space type ${someSpace.name.value}?`, () => {
    removeElem(spaces, someSpace);
  });
}

function addSpace() {
  spaces.push(BufferSpaceType.create(generateItemName('Space', spaces), true))
  selectSpace(spaces[spaces.length - 1]);
}

</script>

<template>
  <div>
    <HelpSection id="BufferSpacesView">
      <h4 class="mb-s">Buffer Space Types</h4>
      <p class="mb-s">
        Use this page to build different buffer space types for use in the Build House tab.
      </p>
      <p>Buffer spaces refer to spaces attached to the building that may be heated or cooled but are not conditioned to the same temperature as the space in consideration. Typical examples include attached garages, porches, and possibly even large attics.</p>
      <p>For manually entering <b>buffer space temperatures</b>, enter the extreme temperatures that the buffer space is expected to reach. These are the temperatures that the buffer space will exert on the space for the heating and cooling loads.</p>
      <p>For building a buffer space, <b>infiltration</b> is difficult to estimate. For a buffer space with few windows, doors, etc., with tight, high quality construction, an estimate 0.5 ACH is a reasonable estimate of peak infiltration.
        However, for spaces with several doors, windows, etc., where doors and cracks are loosely fitted, an estimate of 2.0 ACH may be more appropriate.</p>
      <p>For converting from ACH (air changes per hour) to ft<sup>3</sup>/min, use the following equation:</p>
      <p><i>1 ACH = (1/60)*A<sub>F</sub>*H<sub>C</sub> ft<sup>3</sup>/min</i></p>
      <p>or</p>
      <p><i>1 ACH = (1/3.6)*A<sub>F</sub>*H<sub>C</sub> L/s</i></p>
      <p>Where A<sub>F</sub> is floor area (ft<sup>2</sup> or m<sup>2</sup>) and H<sub>C</sub> is ceiling height (ft or m)</p>
      <p>Heat generation inside the buffer space will not be considered for determining the winter temperature.</p>
      <p>Where indoor temperature is selected as the <b>adjacent temperature</b> to the selected wall, indoor winter temperature will be used for winter calculations and indoor summer temperature will be used for summer calculations. The same will be done for outdoor temperatures.</p>
      <p><b>Internal heat generation</b> is difficult to estimate in a buffer space. If any heat given off by machines or other sources is known and is constant or nearly constant, then that heat generation value should be given. If the heat is intermittent, irregular, or unknown, it may be best to leave the entry blank.</p>
    </HelpSection>
    <div v-if="space">
      <div class="mb-m">
        <BackButton @click="space=null" />
        <h1>{{ space.name.value || 'Untitled' }}</h1>
      </div>
      <ComponentErrorsList :component="space" />
      <FieldInput v-model="space.name" />
      <WarnText v-if="isDupName(space.name.value, spaces)">There is already a space with that name. Please choose another.</WarnText>
      <InputGroup title="Temperatures">
        <div class="mb-m">
          <FieldInput v-model="space.entryMethod" />
        </div>
        <FieldGroup v-if="space.entryMethod.value == 'Manual'" v-model="space.manualGroup" />
        <div v-else>
          <div class="mb-s">
            <p class="mute-header">Buffer Space Builder</p>
            <p class="help-text">Specify the walls of your buffer space. The buffer space temperatures will be calculated from the given inputs.</p>
          </div>
          <button @click="space.builder.addWall()" class="LinkBtn mb-xs">Add wall</button>
          <table class="SimpleTable MarginBotM">
            <tr>
              <th>Type</th>
              <th>R-value</th>
              <th>Area</th>
              <th>Adjacent Temp</th>
              <th>Summer Adjacent Temp</th>
              <th>Winter Adjacent Temp</th>
              <th></th>
            </tr>
            <tr v-for="(wall, index) in space.builder.walls" class="MarginBotS">
              <td><FieldInput v-model="wall.type" :compact="true"/></td>
              <td><FieldInput v-model="wall.rValue" :compact="true"/></td>
              <td><FieldInput v-model="wall.area" :compact="true"/></td>
              <td><FieldInput v-model="wall.adjacentTemp" :compact="true"/></td>
              <td>
                <FieldInput v-if="wall.adjacentTemp.value == 'Other'" v-model="wall.summerAdjacentTemp" :compact="true"/>
                <p v-else>NA</p>
              </td>
              <td>
                <FieldInput v-if="wall.adjacentTemp.value == 'Other'" v-model="wall.winterAdjacentTemp" :compact="true"/>
                <p v-else>NA</p>
              </td>
              <td><DeleteButton @click="space.builder.removeWall(wall)" /></td>
            </tr>
          </table>
          <FieldInput v-model="space.builder.estimatedInfiltration" />
          <FieldInput v-model="space.builder.internalHeatGain" />
          <div class="mt-m">
            <p class="mute-header">Results</p>
            <FieldInput v-model="space.builder.outputSummerTemp" />
            <FieldInput v-model="space.builder.outputWinterTemp" />
            <p class="help-text mt-s small-text">
              <b>Note:</b> The result temperatures shown here are approximate. More accurate values will be computed during final calculations.
              See the Quick Help for details.
            </p>
          </div>
        </div>
      </InputGroup>
      <!--
      <InputGroup title="Results">
      </InputGroup>
      -->
    </div>
    <div v-else>
      <h1 class="PageH1">Buffer Spaces</h1>
      <div class="mb-l Container">
        <p class="mute-header">Info</p>
        <p class="help-text w-80">
          Add a <b>Buffer Space Type</b> for each different type of buffer space used in your project.
          {{ GetTypeHelpString("Partitions") }}
        </p>
      </div>
      <button @click="addSpace" class="MarginBotXS CreateBtn">Add buffer space</button>
      <table class="SimpleTable" v-if="spaces.length">
        <tr>
          <th>Name</th>
          <th>Winter Temperature (F)</th>
          <th>Summer Temperature (F)</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="(space, index) in spaces" class="WindowSection MarginBotL">
          <td>{{ space.name.value }}</td>
          <td>{{ formatNum(space.getSummaryData().winterTemp) }}</td>
          <td>{{ formatNum(space.getSummaryData().summerTemp) }}</td>
          <td><EditButton @click="selectSpace(space)"/></td>
          <td><DeleteButton @click="deleteSpace(space)"/></td>
        </tr>
      </table>
    </div>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<style scoped>
.small-text {
  font-size: var(--f-xs);
  width: 80%;
}
</style>
