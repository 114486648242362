import * as ser from '../Common/SerUtil.js'
import { makeEnum, makeOptions,
  setupClass, lookupData, 
  interpolateInMap,
} from '../Base.js'
import { InputComponent } from '../Common/InputComponent.js'

import * as psy from './Psychrometrics.js'
import { CalcContext } from '../Common/CalcContext.js'

import { prettyJson, valOr,
  addElem, removeElem, elemIn,
} from '../SharedUtils.js'

import {
  FieldType,
  Field,
  FieldGroup,
} from '../Common/Field.js'

import { gApp, DebugOn } from '../Globals.js'


let AdjacentTemperature = makeEnum({
  OutdoorTemp: 'Outdoor temperature',
  IndoorTemp: 'Indoor temperature',
  Other: 'Other',
})

class BufferSpaceWall {
  init() {
    // TODO - Clean up the UI here. Mixing Manual with wallType chooser.
    this.type = Field.makeTypeSelect('Type', gApp.proj().wallTypes, 'Manual')
    this.rValue = new Field({
      name: 'R-value',
      type: FieldType.RValue,
      requiresInput: true,
    })
    this.rValue.makeUpdater((field) => {
      if (this.type.value == 'Manual') {
        field.isOutput = false;
      } else {
        field.isOutput = true;

        let wallType = this.type.lookupValue();
        if (!wallType) {
          throw new Error(`Could not find wallType '${this.type.value}`);
        }
        field.value = wallType.getRValue();
      }
    })
    this.area = new Field({
      name: 'Area',
      type: FieldType.Area,
      requiresInput: true,
    })
    this.adjacentTemp = new Field({
      name: 'Adjacent Temperature',
      type: FieldType.Select,
      choices: makeOptions(AdjacentTemperature)
    })
    this.summerAdjacentTemp = new Field({
      name: 'Summer adjacent temperature',
      type: FieldType.Temp,
    })
    this.winterAdjacentTemp = new Field({
      name: 'Summer adjacent temperature',
      type: FieldType.Temp,
    })

    this.serFields = [
      'type',
      'rValue',
      'area',
      'adjacentTemp',
      'summerAdjacentTemp',
      'winterAdjacentTemp',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Buffer Space Wall'
    }
  }

  getArea() {
    return this.area.value;
  }

  getRValue() {
    if (this.type.value == 'Manual') {
      return this.rValue.value;
    } else {
      let wallType = this.type.lookupValue();
      return wallType.getRValue();
    }
  }

  getAdjTemp(isHeating, t_o, t_i) {
    if (this.adjacentTemp.value == AdjacentTemperature.OutdoorTemp) {
      return t_o;
    } else if (this.adjacentTemp.value == AdjacentTemperature.IndoorTemp) {
      return t_i;
    } else if (this.adjacentTemp.value == AdjacentTemperature.Other) {
      return isHeating ? this.winterAdjacentTemp.value : this.summerAdjacentTemp.value;
    } else {
      throw new Error("Unexpected adjacentTemp: " + this.adjacentTemp.value);
    }
  }
}
setupClass(BufferSpaceWall)

class BufferSpaceBuilder {
  init() {
    this.walls = [];
    this.estimatedInfiltration = new Field({
      name: 'Estimated infiltration',
      type: FieldType.AirFlow,
      requiresInput: true,
    })
    this.internalHeatGain = new Field({
      name: 'Internal heat generation',
      type: FieldType.Load,
      requiresInput: true,
    })

    this.outputSummerTemp = new Field({
      name: 'Buffer Space Summer Temperature',
      type: FieldType.Temp,
      isOutput: true,
    })
    this.outputSummerTemp.makeUpdater((field) => {
      let res = this.canCalcTemp();
      if (!res.result) {
        field.value = 0;
        field.setEntryErrorMsg(res.reason);
        return;
      }
      let ctx = CalcContext.create();
      field.value = this._calcTempForUI(ctx, false);
      field.setEntryErrorMsg(null);
      field.debugOutput = DebugOn() ? ctx.getLogStr() : null;
    })

    this.outputWinterTemp = new Field({
      name: 'Buffer Space Winter Temperature',
      type: FieldType.Temp,
      isOutput: true,
    })
    this.outputWinterTemp.makeUpdater((field) => {
      let res = this.canCalcTemp();
      if (!res.result) {
        field.value = 0;
        field.setEntryErrorMsg(res.reason);
        return;
      }
      let ctx = CalcContext.create();
      field.value = this._calcTempForUI(ctx, true);
      field.setEntryErrorMsg(null);
      field.debugOutput = DebugOn() ? ctx.getLogStr() : null;
    })

    // Add a default wall
    this.addWall();

    this.serFields = [
      ser.arrayField('walls', () => { return BufferSpaceWall.create(); }),
      'estimatedInfiltration',
      'internalHeatGain',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Buffer Space Builder',
    }
  }

  addWall() {
    let wall = BufferSpaceWall.create();
    this.walls.push(wall);
    return wall;
  }

  removeWall(wall) {
    removeElem(this.walls, wall);
  }

  canCalcTemp() {
    let locationData = gApp.proj().getLocationData();
    if (!locationData.isLocationSet()) {
      return {result: false, reason: "Location is not set"};
    }
    if (this.walls.length == 0) {
      return {result: false, reason: "At least one wall must be added"};
    }
    for (const wall of this.walls) {
      if (wall.getArea() == 0 || wall.getRValue() == 0) {
        return {result: false, reason: "Wall area and R-value must be set"};
      }
    }
    return {result: true, reason: null};
  }

  _calcTempForUI(ctx, isHeating) {
    let locationData = gApp.proj().getLocationData();
    if (!locationData.isLocationSet()) {
      throw new Error("Location is not set");
    }
    let weatherData = gApp.proj().getLocationData().getOutputs();
    ctx.elevation = weatherData.elevation;
    ctx.P_loc = psy.calcLocalPressure(ctx.elevation);
    ctx.C_s = 1.1 * ctx.P_loc / psy.P_std;
    if (isHeating) {
      ctx.log("Using placeholder values of t_i=70F, t_o=heating 99% DB");
      ctx.t_i = 70;
      ctx.t_o = weatherData.heating99p6PerDryBulb;
    } else {
      ctx.log("Using placeholder values of t_i=75F, t_o=cooling 0.4% DB");
      ctx.t_i = 75;
      ctx.t_o = weatherData.cooling0p4PerDryBulb;
    }
    return this.calcTemp(ctx, ctx.C_s, ctx.t_o, ctx.t_i, isHeating);
  }

  calcTemp(ctx, C_s, t_o, t_i, isHeating) {
    ctx.startSection();

    let wallData = [];
    for (let i = 0; i < this.walls.length; ++i) {
      let wall = this.walls[i];
      if (wall.getArea() == 0 || wall.getRValue() == 0) {
        throw new Error("Wall area and R-value must be set");
      }
      wallData.push({
        A: wall.getArea(),
        R: wall.getRValue(),
        U: 1.0 / wall.getRValue(),
        t: wall.getAdjTemp(isHeating, t_o, t_i),
      });
    }
    ctx.wallData = wallData

    ctx.X_1 = ctx.evalSum('A*U*t', wallData, 'X_1')
    ctx.X_2 = ctx.evalSum('A*U', wallData, 'X_2');

    ctx.Q = this.estimatedInfiltration.value;
    // Note: internal gain gain is only added for summer/cooling
    ctx.q = isHeating ? 0 : this.internalHeatGain.value;
    //console.log(`C_s=${ctx.C_s}, Q=${ctx.Q}, X_2=${ctx.X_2}`);
    ctx.t_b = ctx.eval('(C_s*Q*t_o + X_1 + q) / (C_s*Q + X_2)',
      {C_s, t_o}, 't_b');
    let res = ctx.t_b;
    ctx.endSection();
    return res;
  }
}
setupClass(BufferSpaceBuilder)

export let BufferSpaceEntryMethod = makeEnum({
  Manual: 'Manual',
  BuildBufferSpace: 'Build buffer space',
})

export class BufferSpaceType extends InputComponent {
  init(name, makeId) {
    this.id = makeId ? gApp.proj().makeId('BufferSpaceType') : 0;

    this.name = Field.makeName('Name', name)

    this.entryMethod = new Field({
      name: 'Entry method',
      type: FieldType.Select,
      choices: makeOptions(BufferSpaceEntryMethod),
      bold: true,
    })

    this.manualGroup = new FieldGroup([
      new Field({
        key: 'summerTemperature',
        name: 'Buffer Space Summer Temperature',
        type: FieldType.Temp,
        requiresInput: true,
      }),
      new Field({
        key: 'winterTemperature',
        name: 'Buffer Space Winter Temperature',
        type: FieldType.Temp,
        requiresInput: true,
      })
    ])
    this.manualGroup.setVisibility(() => {
      return this.entryMethod.value == BufferSpaceEntryMethod.Manual;
    })

    this.builder = BufferSpaceBuilder.create();
    this.updater.setEnabledWhen('builder', this.builder, () => {
      return this.entryMethod.value == BufferSpaceEntryMethod.BuildBufferSpace;
    });

    this.serFields = [
      'id',
      'name',
      'entryMethod',
      'manualGroup',
      'builder',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Buffer Space',
    }
  }

  getInputPage() {
    return {
      label: `Buffer Spaces - ${this.name.value}`,
      // TODO
      path: `bufferspaces/${this.id}`,
    };
  }

  calcTemps(ctx, C_s, t_o, t_i, isHeating) {
    if (this.entryMethod.value == BufferSpaceEntryMethod.Manual) {
      return isHeating ? this.manualGroup.getField('winterTemperature').value :
        this.manualGroup.getField('summerTemperature').value;
    } else {
      return this.builder.calcTemp(ctx, C_s, t_o, t_i, isHeating);
    }
  }

  getSummaryData() {
    if (this.entryMethod.value == BufferSpaceEntryMethod.Manual) {
      return {
        summerTemp: this.manualGroup.getField('summerTemperature').value,
        winterTemp: this.manualGroup.getField('winterTemperature').value,
      }
    } else {
      return {
        summerTemp: this.builder.outputSummerTemp.value,
        winterTemp: this.builder.outputWinterTemp.value,
      }
    }
  }
}
setupClass(BufferSpaceType)