import { ref, reactive, watchEffect, } from 'vue'
import { valOr, prettyJson, } from '../SharedUtils'

/*
Create watch effects through here. It tracks the handles properly.
*/
export class Watcher {
  constructor(opts) {
    opts = valOr(opts, {});
    this.name = valOr(opts.name, "Watcher");
    this._handleCtr = 0;
    this._handleMap = {};
  }

  unwatchAll() {
    console.log(`${this.name}: unwatching ${Object.keys(this._handleMap).length} handles`);
    for (const key in this._handleMap) {
      let data = this._handleMap[key];
      if (data.debugStr) {
        console.log(`${this.name}: Unwatching handle (${key}): ${data.debugStr}`);
      }
      data.unwatchHandle();
    }
    this._handleMap = {};
  }

  addWatchEffect(func, opts) {
    opts = valOr(opts, {});

    let handle = this._handleCtr++;
    let handleData = {
      handle: handle,
      unwatchHandle: null,
      debugStr: valOr(opts.debugStr, null),
    };
    this._handleMap[handle] = handleData;

    if (handleData.debugStr) {
      console.log(`${this.name}: Adding watchEffect (${handle}): ${handleData.debugStr}`);
    }
    handleData.unwatchHandle = watchEffect(() => {
      if (handleData.debugStr) {
        console.log(`${this.name}: Running watchEffect (${handle}): ${handleData.debugStr}`);
      }
      func()
    }, opts);

    return handle;
  }

  // For convenience, we also have a "global" watcher, but be careful when using this b/c
  // the watch effects will not be removed
  static _globalInstance = null;

  static globalInstance() {
    if (!this._globalInstance) {
      this._globalInstance = new Watcher({name: "Global Watcher"});
    }
    return this._globalInstance;
  }
};