export let BufferSpacesViewMarkdown = `# Buffer Spaces
Buffer spaces refer to spaces attached to the building that may be heated or cooled but are not conditioned to the same temperature as the space in consideration. Common examples include parking garages, mechanical penthouses, and possibly even large attics. 

For manually entering buffer space temperatures, enter the extreme temperatures that the buffer space is expected to reach. These are the temperatures that the buffer space will exert on the space for the heating and cooling loads.

For building a buffer space, infiltration is difficult to estimate. For a buffer space with few windows, doors, etc., with tight, high quality construction, an estimate 0.5 ACH is a reasonable estimate of peak infiltration. However, for spaces with several doors, windows, etc., where doors and cracks are loosely fitted, an estimate of up to 2.0 ACH may be more appropriate.

For converting from ACH (air changes per hour) to cfm, use the following equation:

$$
\\text{ACH} = \\frac{1}{60} \\times A_F \\times H_C \\, \\text{cfm}
$$

Where A<sub>F</sub> is the floor area (ft<sup>2</sup>) and H<sub>C</sub> is the ceiling height (ft). 

Heat generation inside the buffer space will not be considered for determining the winter temperature.

Where indoor temperature is selected as the adjacent temperature to the selected wall, indoor winter and summer temperatures of the adjacent space in the system will be used for heating and cooling calculations. If outdoor temperature is selected for the adjacent temperature, then the value used in the simulation will be the outdoor temperature at the particular hour being calculated. The value displayed on this page uses the peak temperatures, and therefore that value does not represent exactly the temperatures that will be used for all calculations.

Internal heat generation is difficult to estimate in a buffer space. If any heat given off by machines or other sources is known and is constant or nearly constant, then that heat generation value should be given. If the heat is intermittent, irregular, or unknown, it should be left blank. 
`