export let SystemsMarkdown = `# Systems
Each simulation is performed on a single system. Add as many systems to your building as you would like, however, be aware that any simulation is only looking at one system at a time, and you must check the system that is showing up on the results page.
## Zones
Each system can consist of any number of zones, and each zone can consist of any number of spaces created on the Spaces Page. 

When adding zones to your system, keep in mind that if different zones have different setpoint temperatures, they will still require the same supply temperature from the central air handling unit (AHU. This supply temperature can only be changed by zone terminal reheat coils, which will not affect the AHU coil or airflow sizing.

If a space is repeated multiple times within a zone, you can enter a quantity for that space and it will be accounted for in the zone calculations.
## General
Using different ventilation for heating and cooling is only recommended if it can be ensured that this will happen. This option is available because of the different ventilation effectiveness values when supplying cool versus warm air, therefore changing the required zone outdoor airflow. If this option is not used, the higher value between heating and cooling will be used for both modes. 

Demand controlled ventilation (DCV) modulates linearly between the minimum and maximum ventilation values based on hourly occupancy relative to the maximum system occupancy, with the minimum value corresponding to the calculated value with 0 occupancy (or manually-entered minimum ventilation requirement).

Heat recovery can be done with a heat recovery ventilator (HRV) or an energy recovery ventilator (ERV). An HRV will recover only sensible heat whereas a an ERV will recover both sensible and latent heat. If the flow from the HRV or ERV is lower than the total ventilation flow, it will be assumed that the remainder of the ventilation flow does not have any energy recovery. An ERV total efficiency value refers to the combined sensible and latent energy recovery efficiency. 

If an economizer is used, you must specify the maximum outdoor air flow. The most common value for this is 100%, however some equipment manufacturers have lower limitations on the maximum outdoor air flow. 

Winter humidification may be added to increase space humidity to 50% to offset latent losses from ventilation and infiltration.
## Air Handler Fans
Fans in the central AHU will increase the temperature of the air. The amount of heat added is dependent on the fan specifications. Ensure that if separate supply and return fans are used that both are included, since the positioning of the fans will alter the psychrometric states upstream and downstream of the cooling coil. 
## System Details
First, select either a VAV or CAV system. If you are designing a CAV system, you must also decide if you would like to size the cooling coil for the sum of peak space loads or just for the peak cumulative peak. 

If you are interested in sizing a cooling only or heating only system, then select Yes for the “Separate airflow for heating and cooling?” input. This will ensure that airflow calculations for heating do not impact cooling airflow, and vice versa.

Enter the desired supply temperatures for cooling and heating modes. Typical values are 55<sup>o</sup>F for cooling and 90<sup>o</sup>F for heating.

Adding zone terminal reheats will size your reheat coils for bringing your zone airflow from cooling supply temperature to heating supply temperature.`