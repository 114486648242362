<script setup>
import { ref, computed, watch, } from 'vue'
import { gApp, } from '../State.js'
import { Schedule } from '../BuildingComponents/Schedule.js'
import { formatNum, removeElem, generateItemName, isDupName, } from '../SharedUtils.js'
import FieldInput from './FieldInput.vue'
import ConfirmModal from './ConfirmModal.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import HelpButton from './HelpButton.vue'
import SimpleCollapse from './SimpleCollapse.vue'

import EditButton from './utils/EditButton.vue'
import DeleteButton from './utils/DeleteButton.vue'
import BackButton from './utils/BackButton.vue'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

const props = defineProps({
})

let proj = gApp.proj();
let selectedSchedule = ref(null);
let deleteModal = ref(null);

let schedules = computed(() => {
  return proj.schedules;
})

function selectSchedule(schedule) {
  selectedSchedule.value = schedule;
}

function deleteSchedule(schedule) {
  deleteModal.value.showModal("Delete", `Delete ${schedule.name.value}?`, () => {
    removeElem(schedules.value, schedule);
  });
}

function addSchedule() {
  schedules.value.push(Schedule.create(generateItemName('Schedule', proj.schedules, 'TYPE_NAME-CTR'), true))
  selectSchedule(schedules.value[schedules.value.length - 1]);
}

function getFirstHalfIndices() {
  // Get [0,...11]
  return Array.from({length: 12}, (v, k) => k);
}

function getSecondHalfIndices() {
  // Get [12,...23]
  return Array.from({length: 12}, (v, k) => k + 12);
}

function getField(hourIndex) {
  return selectedSchedule.value.hourFields.fields[hourIndex];
}

</script>

<template>
  <div class="Container">
    <HelpSection id="SchedulesView">
    </HelpSection>
    <div v-if="selectedSchedule">
      <div class="mb-m">
        <BackButton @click="selectedSchedule=null" />
        <div class="Flex">
          <h1 class="">{{ selectedSchedule.name.value || 'Untitled' }}</h1>
          <!-- <button class="LinkBtn">Rename</button> -->
        </div>
      </div>
      <FieldInput v-model="selectedSchedule.name" class="MarginBotXS" />
      <WarnText v-if="isDupName(selectedSchedule.name.value, schedules)">There is already a system with that name. Please choose another.</WarnText>
      <InputGroup title="General">
        <p class="help-text mb-l w-80">
          Set a percentage "On" for each hour of the day. Schedules are referenced from the Systems and Spaces tabs.
        </p>
        <div v-if="!selectedSchedule.isEditable()" class="Flex RowNoWrap GapXXS mb-m w-80">
          <i class="bi-info-circle mr-xxs"></i>
          <p>
            This schedule was created by default and cannot be edited. If you want to make changes, create a new schedule.
          </p>
        </div>
        <!--
        <div v-for="(field, i) in selectedSchedule.hourFields.fields.slice(0, 12)" :key="field.key" class="Flex RowNoWrap GapM">
          <p class="Label">{{ selectedSchedule.hourFields.fields[i].name }}</p>
          <vue-slider class="Slider" :width="300" :duration="0.1"
            v-model="selectedSchedule.hourFields.fields[i].value" />
        </div>
        -->
        <div class="TwoColGrid">
          <!--<button @click="selectedSchedule.dumpValues()" class="LinkBtn">Dump Values</button>-->
          <div>
            <div v-for="(hourIndex, i) in getFirstHalfIndices()" :key="getField(hourIndex).key">
              <FieldInput v-model="selectedSchedule.hourFields.fields[hourIndex]" :labelWidth="80" :readonly="!selectedSchedule.isEditable()" />
            </div>
          </div>
          <div>
            <div v-for="(hourIndex, j) in getSecondHalfIndices()" :key="getField(hourIndex).key">
              <FieldInput v-model="selectedSchedule.hourFields.fields[hourIndex]" :labelWidth="80" :readonly="!selectedSchedule.isEditable()" />
            </div>
          </div>
        </div>
        <!--<FieldGroup v-model="selectedSchedule.hourFields" />-->
      </InputGroup>
    </div>
    <div v-else>
      <h1 class="PageH1">Schedules</h1>
      <div class="mb-l">
        <p class="mute-header Container">Info</p>
        <p class="help-text w-80">
          Add a <b>Schedule</b> for each different type of schedule used in your project.
          Schedules are referenced from the Systems and Spaces tabs, such as for Space occupancy and
          appliance usage.
        </p>
      </div>
      <button @click="addSchedule()" class="MarginBotXS CreateBtn">Add Schedule</button>
      <table class="SimpleTable" v-if="schedules.length">
        <colgroup>
          <col style="width: 300px;">
          <col>
          <!-- other columns -->
        </colgroup>
        <tr>
          <th>Name</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="schedule in schedules" class="WallSection MarginBotL">
          <td>{{ schedule.name.value }}</td>
          <td>
            <EditButton @click="selectSchedule(schedule)" />
          </td>
          <td>
            <DeleteButton @click="deleteSchedule(schedule)" />
          </td>
        </tr>
      </table>
    </div>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<style scoped>
.Result {
  color: blue;
}

.Slider {
  width: 200px;
}

.Label {
  width: 80px;
  text-align: right;
}

.TwoColGrid {
  width: 80%;
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--space-s);
}

</style>
