import { makeEnum, makeEnumWithDataAndLabels, makeOptions,
} from '../Base.js'

export let VentilationEffectivenessData = {
  CeilingSupplyOfCoolAir: {
    label: "Ceiling supply of cool air",
    value: 1
  },
  CeilingSupplyOfWarmAirAndFloorReturn: {
    label: "Ceiling supply of warm air and floor return",
    value: 1
  },
  CeilingSupplyOfWarmAir15DegFAboveSpaceTemperatureAndCeilingReturn: {
    label: "Ceiling supply of warm air 15 degF (8 degC) above space temperature and ceiling return",
    value: 0.8
  },
  CeilingSupplyOfWarmAirLessThan15DegFAboveAverageSpaceTemperatureWhereTheSupplyAirJetVelocityIsLessThan150fpmWithin4_5FtOfTheFloorAndCeilingReturn: {
    label: "Ceiling supply of warm air less than 15 degF (8 degC) above average space temperature where the supply air-jet velocity is less than 150fpm (0.8 m/s) within 4.5 ft (1.4m) of the floor and ceiling return",
    value: 0.8
  },
  CeilingSupplyOfWarmAirLessThan15DegFAboveAverageSpaceTemperatureWhereTheSupplyAirJetVelocityIsEqualToOrGreaterThan150fpmWithin4_5FtOfTheFloorAndCeilingReturn: {
    label: "Ceiling supply of warm air less than 15 degF (8 degC) above average space temperature where the supply air-jet velocity is equal to or greater than 150fpm (0.8 m/s) within 4.5 ft (1.4m) of the floor and ceiling return",
    value: 1
  },
  FloorSupplyOfWarmAirAndFloorReturn: {
    label: "Floor supply of warm air and floor return",
    value: 1
  },
  FloorSupplyOfWarmAirAndCeilingReturn: {
    label: "Floor supply of warm air and ceiling return",
    value: 0.7
  },
  MakeupSupplyOutletLocatedMoreThanHalfTheLengthOfTheSpaceFromTheExhaustReturnOrBoth: {
    label: "Makeup supply outlet located more than half the length of the space from the exhaust, return, or both",
    value: 0.8
  },
  MakeupSupplyOutletLocatedLessThanHalfTheLengthOfTheSpaceFromTheExhaustReturnOrBoth: {
    label: "Makeup supply outlet located less than half the length of the space from the exhaust, return, or both",
    value: 0.5
  },
  FloorSupplyOfCoolAirWhereTheVerticalThrowIsGreaterThanOrEqualTo60fpmAtAHeightOf4_5FtAboveTheFloorAndCeilingReturnAtAHeightLessThanOrEqualTo18ftAboveTheFloor: {
    label: "Floor supply of cool air where the vertical throw is greater than or equal to 60 fpm (0.25 m/s) at a height of 4.5 ft (1.4m) above the floor and ceiling return at a height less than or equal to 18ft (5.5m) above the floor",
    value: 1.05
  },
  FloorSupplyOfCoolAirWhereTheVerticalThrowIsLessThan60fpmAtAHeightOf4_5FtAboveTheFloorAndCeilingReturnAtAHeightLessThanOrEqualTo18ftAboveTheFloor: {
    label: "Floor supply of cool air where the vertical throw is less than 60 fpm (0.25 m/s) at a height of 4.5 ft (1.4m) above the floor and ceiling return at a height less than or equal to 18ft (5.5m) above the floor",
    value: 1.2 
  },
  FloorSupplyOfCoolAirWhereTheVerticalThrowIsLessThan60fpmAtAHeightOf4_5FtAboveTheFloorAndCeilingReturnAtAHeightGreaterThan18ftAboveTheFloor: {
    label: "Floor supply of cool air where the vertical throw is less than 60 fpm (0.25 m/s) at a height of 4.5 ft (1.4m) above the floor and ceiling return at a height greater than 18ft (5.5m) above the floor",
    value: 1.5
  },
  PersonalizedAirAtAHeightOf4_5ftAboveTheFloorCombinedWithCeilingSupplyOfCoolAirAndCeilingReturn: {
    label: "Personalized air at a height of 4.5ft (1.4m) above the floor combined with ceiling supply of cool air and ceiling return",
    value: 1.4
  },
  PersonalizedAirAtAHeightOf4_5ftAboveTheFloorCombinedWithCeilingSupplyOfWarmAirAndCeilingReturn: {
    label: "Personalized air at a height of 4.5ft (1.4m) above the floor combined with ceiling supply of warm air and ceiling return",
    value: 1.4
  },
  PersonalizedAirAtAHeightOf4_5ftAboveTheFloorCombinedWithAStratifiedDistributionSystemWithNonaspiratingFloorSupplyDevicesAndCeilingReturn: {
    label: "Personalized air at a height of 4.5ft (1.4m) above the floor combined with a stratified distribution system with nonaspirating floor supply devices and ceiling return",
    value: 1.2
  },
  PersonalizedAirAtAHeightOf4_5ftAboveTheFloorCombinedWithAStratifiedDistributionSystemWithAspiratingFloorSupplyDevicesAndCeilingReturn: {
    label: "Personalized air at a height of 4.5ft (1.4m) above the floor combined with a stratified distribution system with aspirating floor supply devices and ceiling return",
    value: 1.5
  },
};

