import { makeEnum, makeOptions,
  setupClass, lookupData, 
} from '../Base.js'

import * as psy from './Psychrometrics.js'
import * as calc from './ResidentialCalculations.js'

export { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
  FieldGroup,
} from '../Common/Field.js'

import {
  ManualOrAutomatic,
  AutomaticOrManual,
 } from './Common.js'


export let RecoveryType = makeEnum({
  None: 'None',
  HRV: 'HRV',
  ERV: 'ERV',
})

let BuildingQuality = makeEnum({
  Tight: 'Tight',
  Good: 'Good',
  Average: 'Average',
  Leaky: 'Leaky',
  VeryLeaky: 'VeryLeaky',
})

export class HouseVentilationInfiltration {
  init() {
    this.entryMethod = Field.makeSelect('Entry Method', AutomaticOrManual, {bold: true})
    // Manual:
    this.totalVentilation = new Field({
      name: 'Total Ventilation',
      type: FieldType.AirFlow,
      defaultValue: 100,
      min: 0,
      allowMin: true,
    });
    this.totalVentilation.makeUpdater((field) => {
      field.visible = this.entryMethod.value == AutomaticOrManual.Manual;
    });
    
    this.continuousExhaust = new Field({
      name: 'Continuous Exhaust',
      type: FieldType.AirFlow,
      defaultValue: 0,
      min: 0,
      allowMin: true,
    });

    // Heat recovery:
    this.recoveryType = Field.makeSelect('Recovery Type', RecoveryType, {bold: true})
    this.hrvGroup = new FieldGroup([
      new Field({
        key: 'flow',
        name: 'Flow',
        type: FieldType.AirFlow,
        defaultValue: 100,
        min: 0,
        allowMin: true,
      }),
      new Field({
        key: 'summerEfficiency',
        name: 'Summer Efficiency',
        type: FieldType.Percent,
        defaultValue: 55,
      }),
      new Field({
        key: 'winterEfficiency',
        name: 'Winter Efficiency',
        type: FieldType.Percent,
        defaultValue: 75,
      })
    ]);
    this.hrvGroup.setVisibility(() => {
      return this.recoveryType.value == RecoveryType.HRV;
    })

    this.ervGroup = new FieldGroup([
      new Field({
        key: 'flow',
        name: 'Flow',
        type: FieldType.AirFlow,
        defaultValue: 100,
        min: 0,
        allowMin: true,
      }),
      new Field({
        key: 'summerSensibleEfficiency',
        name: 'Summer sensible efficiency',
        type: FieldType.Percent,
        defaultValue: 55,
      }),
      new Field({
        key: 'summerTotalEfficiency',
        name: 'Summer total efficiency',
        type: FieldType.Percent,
        defaultValue: 60,
      }),
      new Field({
        key: 'winterSensibleEfficiency',
        name: 'Winter sensible efficiency',
        type: FieldType.Percent,
        defaultValue: 75,
      }),
      new Field({
        key: 'winterTotalEfficiency',
        name: 'Winter total efficiency',
        type: FieldType.Percent,
        defaultValue: 80,
      }),
    ]);
    this.ervGroup.setVisibility(() => {
      return this.recoveryType.value == RecoveryType.ERV;
    })

    this.otherBalancedAirflows = new Field({
      name: "Other Balanced Airflows",
      type: FieldType.AirFlow,
      defaultValue: 0,
      min: 0,
      allowMin: true,
    });

    this.infiltrationEntryMethod = Field.makeSelect('Entry Method', AutomaticOrManual, {bold: true})
    this.infiltrationManual = new FieldGroup([
      new Field({
        key: 'summerInfiltration',
        name: 'Summer Infiltration',
        type: FieldType.AirFlow,
        min: 0,
        allowMin: true,
      }),
      new Field({
        key: 'winterInfiltration',
        name: 'Winter Infiltration',
        type: FieldType.AirFlow,
        min: 0,
        allowMin: true,
      })
    ]);
    this.infiltrationManual.setVisibility(() => {
      return this.infiltrationEntryMethod.value == ManualOrAutomatic.Manual;
    })
    this.infiltrationAutomatic = new FieldGroup([
      new Field({
        key: 'totalExposedArea',
        name: 'Total Exposed Building Area',
        type: FieldType.Area,
        defaultValue: 3500,
        min: 0,
        allowMin: true,
      }),
      new Field({
        key: 'buildingQuality',
        name: 'Building Construction Quality',
        type: FieldType.Select,
        choices: makeOptions(BuildingQuality),
        defaultValue: BuildingQuality.Good,
      }),
      new Field({
        key: 'fluePipeLeakageArea',
        name: 'Flue pipe effective leakage area',
        type: FieldType.SmallArea,
        defaultValue: 0,
        min: 0,
        allowMin: true,
      })
    ]);
    this.infiltrationAutomatic.setVisibility(() => {
      return this.infiltrationEntryMethod.value == ManualOrAutomatic.Automatic; 
    })

    this.serFields = [
      'entryMethod',
      'totalVentilation',
      'continuousExhaust',
      'recoveryType',
      'hrvGroup',
      'ervGroup',
      'otherBalancedAirflows',
      'infiltrationEntryMethod',
      'infiltrationManual',
      'infiltrationAutomatic',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Ventilation & Infiltration',
    }
  }

  calcOutputs(ctx) {
    ctx.startSection("Ventilation&Infiltration");
    let results = {
      heating: this._calcLoads(ctx, true),
      cooling: this._calcLoads(ctx, false),
    }
    ctx.log("");
    ctx.res.ventilationInfiltration = results;
    ctx.endSection();
  }

  _calcLoads(ctx, isHeating) {
    ctx.startSection(`${isHeating ? 'Heating' : 'Cooling'}`);

    let weatherData = ctx.toplevelData.locationData;
    ctx.elevation = weatherData.elevation;

    ctx.P_loc = psy.calcLocalPressure(ctx.elevation);
    ctx.C_s = 1.1 * ctx.P_loc / psy.P_std;
    ctx.C_t = 4.5 * ctx.P_loc / psy.P_std;
    ctx.C_l = 4840 * ctx.P_loc / psy.P_std;
    ctx.t_i = isHeating ? ctx.toplevelData.indoorWinterTemp
      : ctx.toplevelData.indoorSummerTemp;
    ctx.t_o = isHeating? ctx.designTemps.heating : ctx.designTemps.cooling;
    if (!isHeating) {
      ctx.t_wb_F = ctx.designTemps.coolingMCWB;
    }
    ctx.A_floor = ctx.toplevelData.totalFloorArea;
    ctx.avgCeilH = ctx.toplevelData.averageCeilingHeight;
    ctx.N_stories = ctx.toplevelData.numberStoreys;
   
    if (isHeating) {
      [ctx.h_i, ctx.W_i] = ctx.call(psy.calcIFull, ctx.t_i, ctx.toplevelData.indoorWinterHumidity, ctx.P_loc);
      [ctx.h_o, ctx.W_o] = ctx.call(psy.calcIFull, ctx.t_o, psy.RH_winter_outdoor, ctx.P_loc);
    } else {
      [ctx.h_i, ctx.W_i] = ctx.call(psy.calcIFull, ctx.t_i, ctx.toplevelData.indoorSummerHumidity, ctx.P_loc);
      [ctx.h_o, ctx.W_o] = ctx.call(psy.calcIFullKnownTwb, ctx.t_o, ctx.t_wb_F, ctx.P_loc);
    }

    // TODO - is this automatic vs manual method correct?
    if (this.entryMethod.value == ManualOrAutomatic.Manual) {
      ctx.Q_sup = this.totalVentilation.value;
    } else {
      ctx.N_br = ctx.toplevelData.numberBedrooms;
      ctx.Q_sup = calc.calcQ_V(ctx, ctx.A_floor, ctx.N_br);
    }
    ctx.Q_exh = this.continuousExhaust.value;
    ctx.Q_bal = ctx.eval('min(Q_sup, Q_exh)',
      {Q_sup: ctx.Q_sup, Q_exh: ctx.Q_exh}, 'Q_bal');
    ctx.Q_unbal = ctx.eval('max(Q_sup, Q_exh) - Q_bal',
        {Q_sup:ctx.Q_sup, Q_exh:ctx.Q_exh, Q_bal:ctx.Q_bal}, 'Q_unbal');

    ctx.infiltrationEntryMethod = this.infiltrationEntryMethod.value;
    if (this.infiltrationEntryMethod.value == ManualOrAutomatic.Manual) {
      // Manual calculation of Q_i
      ctx.Q_i = isHeating ? this.infiltrationManual.getField('winterInfiltration').value
        : this.infiltrationManual.getField('summerInfiltration').value;
    } else {
      // Automatic calculation of Q_i
      let A_es = this.infiltrationAutomatic.getField('totalExposedArea').value;
      let A_ul = lookupData(calc.A_ul_Table, [
        this.infiltrationAutomatic.getField('buildingQuality').value,
      ]);
      ctx.A_L = ctx.eval('A_es * A_ul', {A_es, A_ul}, 'A_L');
      ctx.A_L_flue = this.infiltrationAutomatic.getField('fluePipeLeakageArea').value;
      ctx.IDF = calc.calcIDF(ctx, ctx.A_L_flue, ctx.A_L, ctx.avgCeilH*ctx.N_stories, ctx.t_i, ctx.t_o, isHeating);
      ctx.Q_i = ctx.eval('IDF * A_L', {IDF:ctx.IDF, A_L:ctx.A_L}, 'Q_i');
    }
    ctx.Q_vi = ctx.eval('max(Q_unbal, Q_i + 0.5*Q_unbal)',
      {Q_unbal:ctx.Q_unbal,Q_i:ctx.Q_i}, 'Q_vi');

    let heatingSign = isHeating ? 1 : -1;
    if (this.recoveryType.value == RecoveryType.None) {
      ctx.q_A_s = ctx.eval('Q_vi*C_s*heatingSign*(t_i - t_o)',
        {heatingSign}, 'q_A_s');
      ctx.q_A_l = ctx.eval('Q_vi*C_l*heatingSign*(W_i - W_o)',
        {heatingSign}, 'q_A_l');
    } else if (this.recoveryType.value == RecoveryType.HRV) {
      ctx.Eff_s = this.hrvGroup.getField(
        isHeating ? 'winterEfficiency' : 'summerEfficiency').value;
      ctx.Q_HRV = this.hrvGroup.getField('flow').value;
      ctx.Q_bal_other = this.otherBalancedAirflows.value;
      ctx.q_A_s = ctx.eval('C_s*(Q_vi + (1 - Eff_s/100.0)*Q_HRV + Q_bal_other)*heatingSign*(t_i - t_o)',
        {heatingSign}, 'q_A_s');
      ctx.q_A_l = ctx.eval('C_l*(Q_vi + Q_bal_other)*heatingSign*(W_i - W_o)',
        {heatingSign}, 'q_A_l');
    } else if (this.recoveryType.value == RecoveryType.ERV) {
      ctx.Eff_s = this.ervGroup.getField(
        isHeating ? 'winterSensibleEfficiency' : 'summerSensibleEfficiency').value;
      ctx.Eff_t = this.ervGroup.getField(
        isHeating ? 'winterTotalEfficiency' : 'summerTotalEfficiency').value;
      ctx.Q_HRV = this.ervGroup.getField('flow').value;
      ctx.Q_bal_other = this.otherBalancedAirflows.value;

      ctx.q_A_s = ctx.eval('C_s*(Q_vi + (1 - Eff_s/100.0)*Q_HRV + Q_bal_other)*heatingSign*(t_i - t_o)',
        {heatingSign}, 'q_A_s');
      ctx.q_A = ctx.eval('C_t*(Q_vi + (1 - Eff_t/100.0)*Q_HRV + Q_bal_other)*heatingSign*(h_i - h_o)',
        {heatingSign}, 'q_A');
      ctx.q_A_l = ctx.eval('q_A - q_A_s', {}, 'q_A_l');
    }

    let q_A_s = ctx.q_A_s;
    let q_A_l = ctx.q_A_l;
    ctx.endSection();

    return {
      sensible: q_A_s,
      latent: q_A_l,
    };
  }
};
setupClass(HouseVentilationInfiltration)