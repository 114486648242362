import { makeEnum, makeOptions,
  setupClass, lookupData, 
} from '../Base.js'

export { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
  FieldGroup,
} from '../Common/Field.js'

import { gApp, DebugOn } from '../Globals.js'


export let InteriorShadeType = makeEnum({
  FabricCurtain: 'Fabric Curtain',
  LouveredShades: 'Louvered Shades',
  RollerShades: 'Roller Shades',
})

export let CurtainOpenness = makeEnum({
  Open: 'Open',
  SemiOpen: 'Semi-open',
  Closed: 'Closed',
})

export let CurtainColour = makeEnum({
  Light: 'Light',
  Medium: 'Medium',
  Dark: 'Dark',
})

export let SlatsLocation = makeEnum({
  IndoorSide: 'Indoor side',
  BetweenGlazing: 'Between glazing',
  OutdoorSide: 'Outdoor side',
})

export let ShadeReflectiveness = makeEnum({
  R0_15: '0.15',
  R0_5: '0.5',
  R0_8: '0.8',
})

export let RollerShadesDetail = makeEnum({
  Opaque: 'Opaque',
  Translucent: 'Translucent',
})

export let RollerShadesColour = makeEnum({
  Light: 'Light',
  LightGrey: 'Light grey',
  DarkGrey: 'Dark grey',
  ReflectiveWhite: 'Reflective white',
})

export let ShadesOrientation = makeEnum({
  Horizontal: 'Horizontal',
  Vertical: 'Vertical',
})

export let LouverAngle = makeEnum({
  Unknown: 'Unknown',
  ZeroDeg: '0°',
  TrackSolarAngle: 'Track solar angle',
  FortyFiveDeg: '45°',
  FullyClosed: 'Fully closed',
})

export class InteriorShadingType {
  init(name, makeId) {
    this.id = makeId ? gApp.proj().makeId('InteriorShadingType') : 0;

    this.name = Field.makeName('Name', name)
    this.type = new Field({
      name: 'Type',
      type: FieldType.Select,
      choices: makeOptions(InteriorShadeType),
      bold: true,
    })

    this.fabricCurtainGroup = new FieldGroup([
      new Field({
        key: 'openness',
        name: 'Openness',
        type: FieldType.Select,
        choices: makeOptions(CurtainOpenness)
      }),
      new Field({
        key: 'colour',
        name: 'Colour',
        type: FieldType.Select,
        choices: makeOptions(CurtainColour)
      }),
    ])
    this.fabricCurtainGroup.setVisibility(() => {
      return this.type.value == InteriorShadeType.FabricCurtain;
    })

    this.louveredShadesGroup = new FieldGroup([
      new Field({
        key: 'location',
        name: 'Location',
        type: FieldType.Select,
        choices: makeOptions(SlatsLocation)
      }),
      new Field({
        key: 'reflectiveness',
        name: 'Reflectiveness',
        type: FieldType.Select,
        choices: makeOptions(ShadeReflectiveness)
      }),
      Field.makeSelect('Louver angle', LouverAngle, {key: 'louverAngle'}),
    ])
    this.louveredShadesGroup.setVisibility(() => {
      return this.type.value == InteriorShadeType.LouveredShades;
    })

    this.rollerShadesGroup = new FieldGroup([
      new Field({
        key: 'detail',
        name: 'Visibility',
        type: FieldType.Select,
        choices: makeOptions(RollerShadesDetail)
      }),
      new Field({
        key: 'colour',
        name: 'Colour',
        type: FieldType.Select,
        choices: makeOptions(RollerShadesColour)
      })
    ])
    this.rollerShadesGroup.setVisibility(() => {
      return this.type.value == InteriorShadeType.RollerShades;
    })

    this.orientation = new Field({
      name: 'Orientation',
      type: FieldType.Select,
      choices: makeOptions(ShadesOrientation, [
        ShadesOrientation.Horizontal,
        ShadesOrientation.Vertical,
      ])
    })

    this.serFields = [
      'id',
      'name',
      'type',
      'fabricCurtainGroup',
      'louveredShadesGroup',
      'rollerShadesGroup',
      'orientation',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Interior Shading',
    }
  }

  getInputPage() {
    return {
      label: `Internal Shadings - ${this.name.value}`,
      // TODO
      path: `internalshadings/${this.id}`,
    };
  }

  getDetailsStr() {
    let details = this.getDetails();
    return `${details.detail1}, ${details.detail2}`
  }

  getDetails() {
    if (this.type.value == InteriorShadeType.FabricCurtain) {
      return {
        detail1: CurtainOpenness._labels[this.fabricCurtainGroup.getField('openness').value],
        detail2: CurtainColour._labels[this.fabricCurtainGroup.getField('colour').value],
      }
    } else if (this.type.value == InteriorShadeType.LouveredShades) {
      return {
        detail1: SlatsLocation._labels[this.louveredShadesGroup.getField('location').value],
        detail2: ShadeReflectiveness._labels[this.louveredShadesGroup.getField('reflectiveness').value],
      }
    } else if (this.type.value == InteriorShadeType.RollerShades) {
      return {
        detail1: RollerShadesDetail._labels[this.rollerShadesGroup.getField('detail').value],
        detail2: RollerShadesColour._labels[this.rollerShadesGroup.getField('colour').value],
      }
    } else {
      return {
        detail1: 'N/A',
        detail2: 'N/A',
      }
    }
  }
};
setupClass(InteriorShadingType)