export let WallsViewMarkdown = `# Walls
The R-value of the wall will impact both the heating and cooling loads. The absorptance value will only affect the cooling load.

Wall R-values or wall construction will come from the architect’s design. For absorptance, if the material and/or colour are unknown, a conservative estimate would be 0.9. Values above 0.9 are unusual and would likely cause unrealistic results. Walls that are a clean, white colour can be expected to have an absorptance of approximately 0.3. Values below 0.3 are quite rare unless the wall is highly reflective.

Certain materials have a standard thickness, and a thickness input is not required for those materials. Thermal resistance of convection on both interior and exterior surfaces has already been built into the program and should not be taken into account when building a wall. However, it is not included if the R-value is entered manually.

The order that materials are placed in will not affect heating or cooling loads, only the total R-value and solar absorptance will affect the loads. The total R-value is the sum of R-values of each layer.

Even if the R-value of the wall is known from architectural information, it may still be useful to build the wall by material, because that will give important information on the thermal mass of the wall. Thermal mass information is usually not as accessible as R-value, but is very important when looking at the Conduction Time Series (CTS) of cooling loads. The wall type input is important even when building the wall by material as it will help refine the thermal mass calculations. 

If you decide to enter wall information manually, click Recommended Wall Weights for a list of typical mass values by wall type. 

Walls built in this section can be used as wall types or partition types when creating a space.`