<script setup>
import { ref, onMounted, reactive, computed, watch } from 'vue'
import CloseButton from './CloseButton.vue';

const props = defineProps({
  title: {
    default: "",
  },
  showDone: {
    default: true,
  },
  doneText: {
    default: 'Done',
  },
  doneButtonEnabled: {
    default: true,
  },
  showCancel: {
    default: true,
  },
  cancelText: {
    default: 'Cancel',
  },
});

const emit = defineEmits(['onCancel', 'onDone'])

let dialog = ref(null);

function showModal() {
  dialog.value.showModal();
  // Without this, the scroll starts in weird place sometimes
  dialog.value.scrollTop = 0;
}

function closeModal() {
  dialog.value.close();
}

function toggleModal() {
  if (!dialog.value.open) {
    showModal();
  } else {
    closeModal();
  }
}

function onDone() {
  emit('onDone');
  closeModal();
}

function onCancel() {
  emit('onCancel');
  closeModal();
}

function handleNativeCancel() {
  emit('onCancel');
}

defineExpose({
  showModal, closeModal, toggleModal
})

/*
Note to future self: Do not attempt to do a `click away to dismiss` trick here, in javascript.
Ends up being very finnicky.
*/

</script>

<template>
  <dialog class="BasicModal" ref="dialog" @close="" @cancel="handleNativeCancel">
    <!-- Note: Only rendering the body when isOpen messes up the autofocus -->
    <!-- <div v-if="isOpen"> -->
      <div class="InnerModal">
        <div class="TitlePart">
          <CloseButton v-if="showCancel" @click="onCancel" class="CloseBtn" />
          <h2 v-if="title" class="Title">{{ title }}</h2>
        </div>
        <div class="BodyPart">
          <div class="Body">
            <slot>Default Body</slot>
          </div>
          <div class="Footer Flex">
            <button class="TertiaryButton" v-if="showCancel" @click="onCancel">{{ cancelText }}</button>
            <button v-if="showDone" @click="onDone" class="CreateBtn DoneBtn" :disabled="!doneButtonEnabled">{{ doneText }}</button>
          </div>
        </div>
      </div>
    <!-- </div> -->
  </dialog>
</template>

<style scoped>

/* For some reason, the the regular CSS does not seem to work in the modal */
.BasicModal {
  /* min-width: 600px; */
  /* max-width: 90%; */
  width: 500px;
  max-height: 90%;

  border: 1px solid var(--b-40);
  border-radius: var(--br-l);
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  padding: var(--space-s) var(--space-m);

  overflow-y: auto;
  scrollbar-width: thin;

  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

dialog {
  /* transition: all 0.1s ease; */
  display: block;
}

dialog[open] {
  opacity: 1;
  /* transform: scale(1.0); */
  /* animation: leaves 2s; */
}

dialog:not([open]) {
  pointer-events: none;
  opacity: 0;
  /* transform: scale(1.1); */
  /* animation: leaves 2s; */
}

/*
@keyframes leaves {
  from {
    transform: scale(1.0);
  }
  to {
    transform: scale(2.0);
  }
}
  */

dialog::backdrop {
  /*backdrop-filter: blur(4px) invert(80%);*/
  /* Note - this invert looks oddly good */
  /*backdrop-filter: invert(80%);*/

  /*backdrop-filter: blur(4px);*/
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
  .BasicModal {
    position: fixed;
    top: 0;
    left: 0;
    border: none;
    border-radius: 0;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
  }
}

/*
.TitlePart {
  padding: var(--space-s) var(--space-m);
  border-bottom: 1px solid var(--b-20);
}
*/

/*
.BodyPart {
  padding: var(--space-m) var(--space-m) var(--s) var(--space-m);
}
*/

.BasicModal .Title {
  margin-bottom: var(--space-m);
}

.Body {
  margin-bottom: var(--space-l);
}

.Footer {
  justify-content: flex-end;
}

.Footer button {
  margin-right: var(--space-xxs);
}

.Footer button:last-child {
  margin-right: 0;  
}

/*
.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  transform: scale(1.1);
}
 */

 .DoneBtn:disabled {
  background-color: grey;
 }

 .CloseBtn {
  float: right;
 }

</style>
