export let WindowsViewMarkdown = `# Windows
If Manual Entry is selected for the window data entry, the U-value and solar heat gain coefficient (SHGC) must be entered for the entire fenestration product (i.e. including the frame). SHGC is the portion of solar radiation entering the space versus solar radiation hitting the window. For example, an SHGC value of 0.9 would indicate that 90% of solar radiation hitting the window is entering the space, with the other 10% being reflected away. SHGC is a function of solar angle, and manufacturers publish the SHGC as test at direct beam conditions. If manual entry is selected, enter the value as reported by the manufacturer - the SHGC for non-direct angles will then be estimated based on the direct beam value provided.

For building a window type, it is recommended to check the U-value and SHGC outputs before proceeding. Values are estimated based on available empirical data, and caution should be taken when considering the impacts of the outputs.

For selecting which panes have low-e coatings are applied to, refer to the diagram below:
![Window_Panes](Pane_Numbering.png)
For three panes, the pattern continues, where surface 6 would be the surface immediately adjacent to the indoors.

Frame thickness is the thickness of the frame as seen from the frontal view on the outside of the building; it is not related to the thickness of the wall that the window is installed in.
![Frames](Frame_Dimensions.png)`