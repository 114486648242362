/*
For now, these locations are not supported because they contain invalid location data
(like N/A values).
Generate this list by running LocationData.test.js
*/
export let kInvalidLocationsSet = new Set([
  'Canada/AB/Glenwood',
  'Canada/NT/Cape Parry',
  'Canada/NT/Holman',
  'Canada/NT/Mould Bay',
  'Canada/NT/Sachs Harbour',
  'Canada/NT/Thomsen River',
  'Canada/NT/Trail Valley',
  'Canada/NT/Tuktoyaktuk',
  'Canada/NT/Tuktut Nogait',
  'Canada/NU/Alert',
  'Canada/NU/Arctic Bay',
  'Canada/NU/Cambridge Bay',
  'Canada/NU/Cape Dorset',
  'Canada/NU/Cape Dyer',
  'Canada/NU/Cape Young',
  'Canada/NU/Clyde River',
  'Canada/NU/Dewar Lakes',
  'Canada/NU/Eureka',
  'Canada/NU/Gjoa Haven',
  'Canada/NU/Grise Fiord',
  'Canada/NU/Hall Beach',
  'Canada/NU/Isachsen',
  'Canada/NU/Pelly Bay',
  'Canada/NU/Pond Inlet',
  'Canada/NU/Rea Point',
  'Canada/NU/Resolute Bay',
  'Canada/NU/Spence Bay',
  'Canada/NU/Stefansson Island',
  'Canada/ON/Chapleau',
  'Canada/QC/Kuujjuarapik',
  'United States/AK/Anaktuvuk',
  'United States/AK/Anchorage',
  'United States/AK/Deadhorse',
  'United States/AK/Fairbanks',
  'United States/AK/Fort Yukon',
  'United States/AK/Noatak',
  'United States/AK/Point Lay',
  'United States/AK/Ruby',
  'United States/AK/Utqiagvik'
])

export function IsInvalidLocation(locPath) {
  return kInvalidLocationsSet.has(locPath.join('/'))
}