import { ref, reactive } from 'vue'
import { describeFunc, Units, } from '../Common/CalcContext.js'
import {PI, cos, sin, toRads, arctan, sqrt, log, exp, } from '../Common/Math.js'
import * as cool from './CoolingCalculations.js'
import * as solar from './SolarCalculations.js'
import * as psy from './Psychrometrics.js'
import { FFsVals, getSLFVal, } from '../MaterialData/Windows/WindowsData.js' 
import { lookupData, } from '../Base.js'

// Ventilation airflow
export function calcQ_V(ctx, area, numBedrooms) {
  return ctx.eval('0.03*area + 7.5*(numBedrooms + 1)', {
    area, numBedrooms
  }, 'Q_V');  
}

// Infiltration airflow
// IDF: infiltration driving force (cfm/in^2)
// A_L: Leakage are (in^2)
function calcQ_I(IDF, A_L) {
  return IDF * A_L;
}

export let A_ul_Table = {
  Tight: 0.01,
  Good: 0.02,
  Average: 0.04,
  Leaky: 0.08,
  VeryLeaky: 0.15,
}

// A_es: exposed building area (ft^2)
// A_ul: factor from table
function calcA_L(A_es, A_ul) {
  return A_es * A_ul;
}

let I_Table = {
  I0: {
    Cooling: 343,
    Heating: 698,
  },
  I1: {
    Cooling: 0.88,
    Heating: 0.81,
  },
  I2: {
    Cooling: 0.28,
    Heating: 0.53,
  }
};

// A_L_flue: leakage area from flue vent (in^2)
// H: average ceiling height (ft) x NumStories
// t_i, t_o (F)
// I0, I1, I2 from table
export function calcIDF(ctx, A_L_flue, A_L, H, t_i, t_o, isHeating) {
  let I0 = I_Table.I0[isHeating ? 'Heating' : 'Cooling'];
  let I1 = I_Table.I1[isHeating ? 'Heating' : 'Cooling'];
  let I2 = I_Table.I2[isHeating ? 'Heating' : 'Cooling'];
  return ctx.eval('(I0 + H*abs(t_i - t_o)*(I1 + I2*(A_L_flue/A_L)))/1000.0', {
    I0, I1, I2, H, t_i, t_o, A_L_flue, A_L: (A_L !== 0 ? A_L : 1),
  }, 'IDF');
}

// Heating calculations

// q_E: heat transfer through external surfaces
// q_A: heat required to heat outdoor air
function calc_q(q_A, q_E) {
  return q_A + q_E;
}

// q_A sensible portion
// Q: flow rate (of either vent. or infil.)
// Q = Q_I + Q_V
// C_s: air sensible heat factor
// C_s = 1.1 at sea level
// C_l: air latent heat factor
// C_l = 4840 at sea level
// t_i: winter indoor temperature
// t_o: winter outdoor temperature
// W_i: humidity ratio of indoor air
// W_o: humidity ratio of outdoor air
// Adjust C_s and C_l when not at sea level
function calc_q_A_s(Q, C_s, t_i, t_0) {
  return Q*C_S*(t_i - t_0);
}

// q_A latent portion
function calc_q_A_l(C_l, W_i, W_o) {
  return C_l*(W_i - W_o);
}

// q_A latent portion
function calc_q_A(q_A_s, q_A_l) {
  return q_A_S + q_A_l;
}

const P_std = 14.69595;

function calc_C_adj(C, P_loc) {
  return C*P_loc/P_std;
}

// A: surface area (ft^2)
// HF: Heating factor
function calc_q_E(A, HF) {
  return A*HF;
}

// U: U-factor
// deltaT: temp diff across the surfacetemp diff across the surfacetemp diff across the surfacetemp diff across the surface
function calc_HF(U, deltaT) {
  return U*deltaT;
}

// t_i: inside winter temp (F)
// t_o: outdoor winter temp (F)
function calc_deltaT_general(t_i, t_o) {
  return t_i - t_o;
}

// t_b: buffer space temp
function calc_deltaT_partition(t_i, t_b) {
  return t_i - t_b;
}

/*
Requires certain vars already in ctx.
*/
/*
export let calcOpaqueQ = describeFunc('calcOpaqueQ', {
  isHeating: 'None',
  surfaceType: 'None',
  weatherData: 'None',
}, (ctx, isHeating, surfaceType, weatherData) => {
});
*/

export function calcOpaqueQ(ctx, A, U, t_i, t_o, alpha,
  isHeating, surfaceType, weatherData) {
  ctx.startSection("calcOpaqueQ");
  let q = null;
  if (isHeating) {
    ctx.HF = ctx.eval('U*(t_i - t_o)', {U, t_i, t_o}, 'HF')
    q = ctx.eval('A*HF', {A}, 'q_opq');
  } else {
    // TODO - see note about fully glass doors
    let DR = weatherData.meanDailyDryBulbRange;
    ctx.CF = ctx.call(cool.calcCF_opq, U, t_i, t_o, surfaceType, alpha, DR);
    q = ctx.eval('A*CF', {A}, 'q_opq');
  }
  ctx.endSection();
  return q;
}

export function calcPartitionOpaqueQ(ctx, A, U, t_i, t_b, isHeating) {
  ctx.startSection("calcPartitionOpaqueQ");
  let q = null;
  if (isHeating) {
    // TODO - see Calculation note about if t_b > t_i
    ctx.HF = ctx.eval('U*(t_i - t_b)', {U, t_i, t_b}, 'HF')
    q = ctx.eval('A*HF', {A}, 'q_opq');
  } else {
    ctx.CF = ctx.eval('U*(t_b - t_i)', {U, t_i, t_b}, 'CF');
    q = ctx.eval('A*CF', {A}, 'q_opq');
  }
  ctx.endSection();
  return q;
}

export function calcWindowQ(ctx,
  A_win, U_win, t_i, t_o, hasBugScreen, direction, H, W,
  isHeating, shgcValues, IAC,
  interiorShading, exteriorShading, weatherData, windowsData) {
  ctx.startSection("calcWindowQ");
  let q = null;
  if (isHeating) {
    ctx.HF = ctx.eval('U_win*(t_i - t_o)', {U_win, t_i, t_o}, 'HF');
    q = ctx.eval('A_win*HF', {A_win}, 'q_win')
  } else {
    let DR = weatherData.meanDailyDryBulbRange;
    let SHGC_0 = shgcValues.Deg0;

    ctx.T_x = ctx.hasBugScreen ? 0.64 : 1.0;
    ctx.hasExteriorShading = Boolean(exteriorShading);
    ctx.phi = solar.calcPhi(ctx, ctx.direction);
    ctx.E_t = cool.calc_E_t_window(ctx, ctx.phi, weatherData.latitude)
    if (!exteriorShading) {
      ctx.PXI = ctx.eval('T_x*E_t', {}, 'PXI');
    } else {
      ctx.E_d = cool.calc_E_d_window(ctx, ctx.phi, weatherData.latitude);
      ctx.E_D = ctx.eval('E_t - E_d', {}, 'E_D')
      ctx.SLF = getSLFVal(ctx.direction, weatherData.latitude);
      ctx.F_shd = ctx.eval('min(1, max(0, (SLF*D_oh - X_oh)/h))', {
        D_oh: exteriorShading.horizontalFinDepth.value,
        X_oh: exteriorShading.horizontalFinDist.value,
        h: ctx.H,
      }, 'F_shd')
      ctx.PXI = ctx.eval('T_x*(E_d + (1 - F_shd)*E_D)', {}, 'PXI');
    }
    let FF_s = lookupData(FFsVals, [ctx.direction, 'Single-family']);
    ctx.CF = ctx.call(cool.calc_CF_full,
      ctx.U_win, ctx.t_o, ctx.t_i,
      DR, ctx.PXI, SHGC_0, ctx.IAC, FF_s);
    q = ctx.eval('A_win*CF', {}, 'q_win')
  }
  ctx.endSection();
  return q;
}


