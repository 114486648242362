import { SystemHeatingDesignTemps, SystemCoolingDesignTemps } from './SystemDesignTemps.js'
import {
  Field,
} from '../Common/Field.js'
import { DailyFractionalDBRange } from '../Data/DailyFractionalDBRange.js'


import {
  setupClass, lookupData,
} from '../Base.js'
import {
  Units,
  makeValStr,
} from '../Common/Units.js'

/*
Helper object to make looking up outdoor temps easy. Used by System & Space calculations.
*/
export class SystemDesignTemps {
  constructor(heatingDesignTemp, coolingDesignTemp, weatherData) {
    this.heatingDesignTemp = heatingDesignTemp;
    this.coolingDesignTemp = coolingDesignTemp;
    this.weatherData = weatherData;

    let heatingTempMap = {
      [SystemHeatingDesignTemps.Temp99p6]: {dryBulb: weatherData.heating99p6PerDryBulb},
      [SystemHeatingDesignTemps.Temp99p0]: {dryBulb: weatherData.heating99PerDryBulb},
    }
    let heatingTemps = lookupData(heatingTempMap, [this.heatingDesignTemp])
    this.heatingDryBulbOut = heatingTemps.dryBulb;
  }

  getHeatingDesignTemp() {
    return this.heatingDesignTemp;
  }

  getCoolingDesignTemp() {
    return this.coolingDesignTemp;
  }

  getHeatingDesignTempStr() {
    return SystemHeatingDesignTemps.getLabel(this.heatingDesignTemp);
  }

  getCoolingDesignTempStr() {
    return SystemCoolingDesignTemps.getLabel(this.coolingDesignTemp);
  }

  getHeatingDesignTempValueStr() {
    return makeValStr(this.heatingDryBulbOut, Units.F);
  }

  getCoolingDesignTempValueStr() {
    // TODO - implement
    //return makeValStr(0, Units.F);
    return "TODO";
  }

  getHeatingDryBulbOut() {
    return this.heatingDryBulbOut;
  }

  getCoolingDryBulbOut(ctx, mo, hr) {
    let locData = this.weatherData;
    return ctx.eval("T_db_max_mo - DBR_mo*F_dbr_hr", {
      T_db_max_mo: locData.dryBulbDesignTempsByMonth[this.coolingDesignTemp][mo],
      DBR_mo: locData.dryBulbRangeByMonth[mo],
      F_dbr_hr: DailyFractionalDBRange[hr],
    }, 'T_db_out')
  }

  getCoolingWetBulbOut(ctx, mo, hr) {
    let locData = this.weatherData;
    return ctx.eval("T_wb_max_mo - WBR_mo*F_dbr_hr", {
      T_wb_max_mo: locData.wetBulbDesignTempsByMonth[this.coolingDesignTemp][mo],
      WBR_mo: locData.wetBulbRangeByMonth[mo],
      F_dbr_hr: DailyFractionalDBRange[hr],
    }, 'T_wb_out')
  }

  getCoolingDryBulbOutForMonth(mo) {
    return this.weatherData.dryBulbDesignTempsByMonth[this.coolingDesignTemp][mo];
  }

  getCoolingWetBulbOutForMonth(mo) {
    return this.weatherData.wetBulbDesignTempsByMonth[this.coolingDesignTemp][mo];
  }
}

/*
Used to toggle the design temperatures used to calculate the outputs, for a System
*/
export class SystemDesignTempInputs {
  init() {
    this.heatingDesignTemp = Field.makeSelect('Heating Design Temp.', SystemHeatingDesignTemps)
    this.coolingDesignTemp = Field.makeSelect('Cooling Design Temp.', SystemCoolingDesignTemps)

    this.serFields = [
      'heatingDesignTemp',
      'coolingDesignTemp',
    ]
  }

  getDesignTemps(ctx) {
    let weatherData = ctx.toplevelData.locationData;
    return new SystemDesignTemps(
      this.heatingDesignTemp.value,
      this.coolingDesignTemp.value, weatherData);
  }
}
setupClass(SystemDesignTempInputs)
