import { makeEnum, makeOptions,
  setupClass, lookupData, 
} from '../Base.js'

export { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
} from '../Common/Field.js'

import { gApp, DebugOn } from '../Globals.js'


export class ExteriorShadingType {
  init(name, makeId) {
    this.id = makeId ? gApp.proj().makeId('ExteriorShadingType') : 0;
    this.name = Field.makeName('Name', name)

    this.horizontalFinDepth = new Field({
      name: 'Depth',
      type: FieldType.Length,
      min: 0,
      allowMin: true,
      requiresInput: true,
    })
    this.horizontalFinDist = new Field({
      name: 'Distance from parallel edge',
      type: FieldType.Length,
      min: 0,
      allowMin: true,
      requiresInput: true,
    })
    this.horizontalFinFields = [
      'horizontalFinDepth',
      'horizontalFinDist',
    ]

    this.leftFinDepth = new Field({
      name: 'Depth',
      type: FieldType.Length,
      min: 0,
      allowMin: true,
      requiresInput: true,
    })
    this.leftFinDist = new Field({
      name: 'Distance from parallel edge',
      type: FieldType.Length,
      min: 0,
      allowMin: true,
      requiresInput: true,
    })
    this.leftFinFields = [
      'leftFinDepth',
      'leftFinDist',
    ]

    this.rightFinDepth = new Field({
      name: 'Depth',
      type: FieldType.Length,
      min: 0,
      allowMin: true,
      requiresInput: true,
    })
    this.rightFinDist = new Field({
      name: 'Distance from parallel edge',
      type: FieldType.Length,
      min: 0,
      allowMin: true,
      requiresInput: true,
    })
    this.rightFinFields = [
      'rightFinDepth',
      'rightFinDist',
    ]

    this.serFields = [
      'id',
      'name',
      'horizontalFinDepth',
      'horizontalFinDist',
      'leftFinDepth',
      'leftFinDist',
      'rightFinDepth',
      'rightFinDist',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Exterior Shading',
    }
  }

  getInputPage() {
    return {
      label: `Exterior Shadings - ${this.name.value}`,
      // TODO
      path: `externalshadings/${this.id}`,
    };
  }
};
setupClass(ExteriorShadingType)