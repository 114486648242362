<script setup>
import { ref, onMounted, watch, computed, } from 'vue'
import { gApp } from '../State.js'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, } from 'firebase/auth'
import { prettyJson } from '../SharedUtils.js'
import { useRoute } from "vue-router"

const route = useRoute();

const props = defineProps({
  signIn: Boolean
})

let username = ref("");
let password = ref("");
let retypePassword = ref("");
let errorMsg = ref("");

function getAuthErrorMsg(error) {
  console.log(`Auth error: ${prettyJson(error)}`)
  let msgMap = {
    "auth/credential-already-in-use": `There is already an account with that email.`,
    "auth/email-already-in-use": `There is already an account with that email.`,
    "auth/invalid-email": `That email is invalid.`,
    "auth/invalid-credential": `Invalid username or password.`,
    "auth/wrong-password": `That password is incorrect.`,
    "auth/quota-exceeded": `Too many attempts. Access temporarily disabled. Please try again later.`,
    "auth/too-many-requests": `Too many attempts. Access temporarily disabled. Please try again later.`,
    "auth/weak-password": `Please choose a stronger password.`,
  };
  if (error.code in msgMap) {
    return msgMap[error.code];
  } else {
    return `An error occurred (${error.code}). Please contact support@heatwise-hvac.com`
  }
}

async function completeSignIn() {
  if (props.signIn) {
    try {
      console.log("Trying to sign in");
      if (!username.value) {
        errorMsg.value = "Please enter a valid email";
        return;
      }
      if (!password.value) {
        errorMsg.value = "Please enter a password";
        return;
      }
      errorMsg.value = "";
      let userCredential = await signInWithEmailAndPassword(gApp.auth, username.value, password.value);
      console.log("Signed in");
      errorMsg.value = "";
    } catch (err) {
      console.log("Error signing in:", err);
      // errorMsg.value = `Could not sign in. Please check you entered the right username and password.`;
      errorMsg.value = getAuthErrorMsg(err);
    }
  } else {
    try {
      if (!username.value) {
        errorMsg.value = "Please enter a valid email";
        return;
      }
      if (!password.value) {
        errorMsg.value = "Please enter a password";
        return;
      }
      if (password.value !== retypePassword.value) {
        errorMsg.value = "Passwords must match."
        return;
      }
      errorMsg.value = "";
      let userCredential = await createUserWithEmailAndPassword(gApp.auth, username.value, password.value);
      console.log("Created account!");
      errorMsg.value = "";
    } catch (err) {
      console.log("Error signing up:", err);
      // errorMsg.value = `Please check you entered a valid username and password.`;
      errorMsg.value = getAuthErrorMsg(err);
    }
  }
}

function reset() {
  errorMsg.value = null;
  username.value = "";
  password.value = "";
  retypePassword.value = "";
}

function changeToSignUp() {
  gApp.router.push({name: 'signup'})
}

function changeToSignIn() {
  gApp.router.push({name: 'signin'})
}

watch(gApp.user, (newUser) => {
  if (newUser) {
    gApp.router.replace('/');
  }
}, {immediate: true});

watch(() => {
  return route.fullPath;
},
(newPath) => {
  reset();
}, {immediate: true})

</script>

<template>
  <div class="SignIn FlexCol GapXS AlignCenter">
    <div class="FlexCol GapXS mb-l">
      <div class="Flex AppLogoDiv AlignCenter mb-xl">
        <img class="AppLogo mr-xs" src="../assets/cpu-fan-cooling-icon.png"/>
        <h1 class="AppTitle">HeatWise</h1>
      </div>
      <h1 class="TextAlignCenter MainHeader">{{ signIn ? 'Sign in to your account' : 'Create new account for free'}}</h1>
      <p class="MuteText TextAlignCenter">{{ signIn ? "Sign in to access your HeatWise projects." : 'Register and start creating HeatWise projects.'}}</p>
    </div>
    <p v-if="errorMsg" class="TextAlignCenter"><i class="bi-exclamation-circle mr-xs delete-red"></i>{{ errorMsg }}</p>
    <div class="W100 mb-xxs">
      <p class="mb-xs Bold">Email</p>
      <input class="TextInput Block BigInput W100" placeholder="Email" v-model="username" />
    </div>
    <div class="W100">
      <p class="mb-xs Bold">Password</p>
      <input class="TextInput Block BigInput W100 mb-xs" type="password" placeholder="Password" v-model="password" />
      <input v-if="!signIn" class="TextInput Block BigInput W100" type="password" placeholder="Retype Password" v-model="retypePassword" />
    </div>
    <button class="BigBtn MarginBotXS W100 CreateBtn mt-m" @click="completeSignIn">{{ signIn ? 'Sign In' : 'Sign Up' }}</button>
    <!-- <p class="MuteText TextAlignCenter">Trouble signing {{ signIn ? 'in' : 'up'}}? Please contact support@heatwise-hvac.com</p> -->
    <p class="Flex GapXS">
      {{signIn ? "Don't have an account?" : 'Already have an account?'}}
      <button class="ToggleBtn LinkBtn mt-m" @click="signIn ? changeToSignUp() :  changeToSignIn()">{{signIn ? "Sign up" : 'Sign in' }}</button>
    </p>
  </div>
</template>

<style scoped>
.AppLogoDiv {
  margin-left: auto;
  margin-right: auto;
}

.AppLogo {
  width: 36px;
  /* height: 32px; */
  /* height: 28px; */
  /* filter: brightness(0) invert(1); */
}

.AppTitle {
  font-size: 32px;
}

.SignIn {
  margin: 0 auto;
  margin-top: 40px;
  max-width: 400px;
}

.BigInput {
  padding: 12px;
}

.BigBtn {
  padding: 12px;
  border-radius: var(--br-m);
}

.ToggleBtn {
  text-transform: none;
  font-size: var(--p-size);
}

.MainHeader {
  line-height: 1.25;
}
</style>
