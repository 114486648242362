import { prettyJson, clearArray, valOr, getElemNames,
  addElem, removeElem, elemIn, StrictParseNumber, } from '../SharedUtils.js'
import { lookupData } from '../Base.js'

import Papa from 'papaparse';

// Cols are numbered A-Z, then AA-AZ, and so on
export function colStrToIndex(colStr) {
  if (!colStr) {
    throw new Error(`Invalid colStr: ${colStr}`);
  }
  let colIndex = 0;
  for (let i = 0; i < colStr.length; ++i) {
    let offset = colStr.charCodeAt(colStr.length - 1 - i) - 'A'.charCodeAt(0) + 1;
    colIndex += offset * Math.pow(26, i);
  }
  return colIndex - 1;
}

function dumpCsvRows(rows) {
  let str = "";
  for (let i = 0; i < rows.length; ++i) {
    str += `${i}. ${rows[i].join(', ')}\n`
  }
  console.log("CSV:\n" + str);
}

export class CsvHelper {
  constructor(csvData) {
    // TODO - handle parse errors (unit test that this throws, or wrap it)
    this.csv = Papa.parse(csvData);
    // console.log("Data:\n" + prettyJson(this.csv.data));
  }

  numRows() {
    return this.csv.data.length;
  }

  numCols() {
    return this.csv.data[0].length;
  }

  colStrToIndex(colStr) {
    return colStrToIndex(colStr);
  }

  lookupValue(rowObj, colObj, resultType) {
    let rowIndex = null;
    if (typeof rowObj == 'number') {
      rowIndex = rowObj;
    } else if (typeof rowObj == 'string') {
      rowIndex = this._getRowIndexFromId(rowObj);
    } else {
      throw new Error(`Unexpected type for rowObj: ` + (typeof rowObj))
    }
    let colIndex = null;
    if (typeof colObj == 'number') {
      colIndex = colObj;
    } else if (typeof colObj == 'string') {
      colIndex = this.colStrToIndex(colObj);
    } else {
      throw new Error(`Unexpected type for colObj: ` + (typeof colObj))
    }

    if (!(0 <= rowIndex && rowIndex < this.csv.data.length)) {
      throw new Error("Row is out of range: " + rowIndex);
    }
    let rowData = this.csv.data[rowIndex];
    if (!(0 <= colIndex && colIndex < rowData.length)) {
      console.log(`Col is out of range: ${colIndex}, rowData:`, rowData);
      throw new Error("Col is out of range: " + colIndex);
    }
    resultType = (resultType === undefined) ? Number : resultType;
    return resultType(rowData[colIndex]);
  }

  _getRowIndexFromId(rowIdStr) {
    // The first column contains some "row ids". We find
    // the row with the one we want.
    for (let i = 0; i < this.csv.data.length; ++i) {
      let row = this.csv.data[i];
      if (row[0] == rowIdStr) {
        return i;
      }
    }
    throw new Error("Row not found for the given rowId: " + rowIdStr);
  }

  _parseIdAndLabel(str) {
    // Format is "$SomeItemFoo Some Item Foo", where
    // "SomeItemFoo" is the id and "Some Item Foo" is the label.
    let parts = str.split(' ');
    if (parts.length < 2) {
      throw new Error(`Unexpected idAndLabel format: ${str}`);
    }
    let id = parts[0].slice(1);
    let label = parts.slice(1).join(' ');
    return {
      id: id,
      label: label,
    };
  }

  _isBlankRow(row) {
    for (let i = 0; i < row.length; ++i) {
      if (row[i] != '') {
        return false;
      }
    }
    return true;
  }

  parseSimpleTable(tableName, tableInfo) {
    /*
    Finds a row with the table name, then parses the simple table after it.
    */
    let requiredCols = valOr(tableInfo.requiredCols, []);
    let colInfo = valOr(tableInfo.colInfo, {});
    let tableData = {
      headers: [],
      rows: [],
    };
    let startIndex = null;
    if (tableName) {
      startIndex = this._getRowIndexFromId(tableName);
    } else {
      startIndex = -1;
    }
    tableData.headers = this.csv.data[startIndex + 1];
    for (const colName of requiredCols) {
      if (!elemIn(colName, tableData.headers)) {
        throw new Error(`Required column not found: ${colName}. Found cols: ${tableData.headers.join(', ')}`);
      }
    }
    let prevIdAndLabels = {};
    for (let i = startIndex + 2; i < this.csv.data.length; ++i) {
      let rowData = this.csv.data[i];
      if (this._isBlankRow(rowData)) {
        // Empty row, we're done
        break;
      }
      let rowObj = {};
      for (let col = 0; col < tableData.headers.length; ++col) {
        let colName = tableData.headers[col];
        let colValue = rowData[col];
        let colInfoEntry = valOr(colInfo[colName], {});
        let colType = valOr(colInfoEntry.type, 'String');
        if (colType === 'Number') {
          rowObj[colName] = StrictParseNumber(colValue);
        } else if (colType === 'String') {
          rowObj[colName] = colValue;
        } else if (colType === 'IdAndLabel') {
          if (colValue !== '') {
            rowObj[colName] = this._parseIdAndLabel(colValue);
            prevIdAndLabels[colName] = rowObj[colName];
          } else {
            // If the col is blank, use the previous {id, label}
            rowObj[colName] = prevIdAndLabels[colName];
          }
        } else {
          throw new Error(`Unexpected colType: ${colType}`);
        }
      }
      // Check we parsed all required cols
      for (const requiredCol of requiredCols) {
        if (rowObj[requiredCol] === undefined) {
          throw new Error(`Required column is undefined: ${requiredCol}. Row: ${prettyJson(rowObj)}`);
        }
      }
      tableData.rows.push(rowObj);
    }
    return tableData;
  }
};

