<script setup>
import { ref, computed, onMounted, watch, } from 'vue'
import { gApp, DebugOn, } from '../State.js'
import BasicModal from './utils/BasicModal.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import ProfileIcon from './utils/ProfileIcon.vue'

let shareModal = ref(null);
let sharedUserEmail = ref("");

let renameProjModal = ref(null);
let newProjName = ref("");

let deleteProjModal = ref(null);
let confirmDeleteText = ref("");

let proj = computed(() => {
  return gApp.proj();
})

function startShareWithUser() {
  sharedUserEmail.value = "";
  shareModal.value.showModal();
}

function shareWithUser() {
  if (!sharedUserEmail.value) {
    gApp.toast(`Invalid user email. Please give a valid email.`, {type: 'error'})
    return;
  }
  gApp.proj().addSharedUser(sharedUserEmail.value);
  sharedUserEmail.value = "";
}

function removeSharedUser(userEmail) {
  gApp.proj().removeSharedUser(userEmail);
}

async function testSaveOnExit() {
  let res = await fetch("http://127.0.0.1:5001/buildingcalc/us-central1/save");
  let text = await res.text();
  console.log("Text: ", text);
}

function startRenameProj() {
  newProjName.value = "";
  renameProjModal.value.showModal();
}

async function submitRenameProj() {
  await gApp.proj().rename(newProjName.value);
}

async function exportProject() {
  gApp.proj().exportToFile()
}

function startDeleteProj() {
  confirmDeleteText.value = "";
  deleteProjModal.value.showModal();
}

function tryDeleteProj() {
  deleteProjModal.value.closeModal();
  gApp.deleteCurProj();
}

let isDeleteBtnEnabled = computed(() => {
  return confirmDeleteText.value == gApp.proj().name;
});

</script>

<template>
  <div class="ProjectSettings BorderedSection mb-m">
    <div class="mb-m">
      <h3 class=""><i class="bi-gear mr-xs"></i>Settings</h3>
      <p class="help-text">Change project settings.</p>
    </div>
    <SimpleCollapse title="General" class="ProjectInfo mb-xs">
      <!-- <h1 class="PageH1">Overview</h1> -->
      <div title="Name" class="GeneralInfo mt-xs">
        <div class="mb-m">
          <p class="mute-header">Name</p>
          <div class="Flex GapS">
            <p class="f-m Bold">{{ gApp.proj().getUiName() }}</p>
            <button @click="startRenameProj" class="LinkBtn">Rename</button>
          </div>
        </div>
        <div class="mb-m">
          <p class="mute-header mb-xs">Other</p>
          <button @click="exportProject" class="LinkBtn">Export Project</button>
        </div>
        <div>
          <p class="mute-header mb-xs">Delete</p>
          <button @click="startDeleteProj" class="LinkBtn delete-text">Delete Project</button>
        </div>
      </div>
      <div v-if="gApp.isAdminUser()" class="AdminDiv mt-m">
        <p class="mute-header">Dev Info</p>
        <p>LOCKED BY: {{gApp.proj().lockedBy ? gApp.proj().lockedBy : '' }}</p>
        <p>My email: {{gApp.user.value.email}}</p>
        <p>My Id: {{gApp.user.value.uid}}</p>
        <p class="mb-s">Project Id: {{ gApp.proj().id }}</p>
        <button class="LinkBtn" @click="gApp.proj().debugOn = !gApp.proj().debugOn">Toggle Debug Mode (Currently {{gApp.proj().debugOn ? 'On' : 'Off'}})</button>
        <button class="LinkBtn" @click="gApp.saveAll()">Save Now</button>
        <button class="LinkBtn" @click="testSaveOnExit">Test Save On Exit</button>
        <button class="LinkBtn" @click="testError()">Throw test error</button>
        <button class="LinkBtn" @click="gApp.checkUserNotifs()">Refresh Notifs</button>
      </div>
    </SimpleCollapse>
    <SimpleCollapse title="Sharing" class="ProjectInfo">
      <div class="mb-l">
        <p class="mute-header">Share Project</p>
        <p class="MarginBotXS f-s help-text">These users have access to the project.</p>
        <table class="SimpleTable UsersTable">
          <tr><th>Project Users</th></tr>
          <tr>
            <td>
              <div class="Flex">
                <ProfileIcon :username="gApp.proj().userEmail" class="mr-xs" />
                <p>{{gApp.proj().userEmail}} (Owner)</p>
              </div>
            </td>
          </tr>
          <tr v-for="user of gApp.proj().sharedUsers">
            <td>
              <div class="Flex">
                <ProfileIcon :username="user" class="mr-xs" />
                <p>{{user}}</p>
                <button @click="removeSharedUser(user)" class="LinkBtn SharingRemoveBtn pl-s">Remove</button>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <button @click="startShareWithUser" class="LinkBtn ShareBtn">Add User</button>
            </td>
          </tr>
        </table>
      </div>
    </SimpleCollapse>
  </div>
  <BasicModal ref="shareModal" title="Share Project" doneText="Share" @onDone="shareWithUser">
    <p class="mute-header">User email</p>
    <input class="MarginBotM" v-model="sharedUserEmail" />
    <p class="">The user will be able to read and edit the project. Only one user can edit the project at a time.</p>
  </BasicModal>
  <BasicModal ref="renameProjModal" title="Rename Project" doneText="Done" @onDone="submitRenameProj">
    <p class="mute-header">New name</p>
    <input class="MarginBotM" v-model="newProjName" />
  </BasicModal>
  <BasicModal ref="deleteProjModal" title="Delete Project" :showDone="false">
    <p class="mb-m">Deleting a project cannot be undone. We recommend you export the project to your files before continuing.</p>
    <p class="mute-header">Confirm</p>
    <p>To confirm, please type the project name ("{{gApp.proj().name}}") below:</p>
    <input class="MarginBotM" v-model="confirmDeleteText" />
    <button @click="tryDeleteProj" :disabled="!isDeleteBtnEnabled">Delete</button>
  </BasicModal>
</template>

<style scoped>
.LastSection {
  margin-bottom: var(--space-xl);
}

.UsersTable {
  border: 1px solid var(--b-20);
  min-width: 600px;
}

.ShareBtn {
  padding: var(--space-xxs);
  /* margin-left: auto; */
}

.SharingRemoveBtn {
  margin-left: auto;
}

.MainHelp {
  width: 520px;
}

</style>