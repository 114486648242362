import { Updater } from './Updater.js';

/*
Meant to be used as a base-class to various input classes.
*/
export class InputComponent {
  constructor() {
    this.updater = new Updater();
  }

  // For manually registering errors, which may not come
  // from a watchEffect
  setErrorWithId(id, errorMsg) {
    this.updater.setErrorWithId(id, errorMsg);
  }

  unsetErrorWithId(id) {
    this.updater.unsetErrorWithId(id);
  }

  getObjErrors() {
    return this.updater.getErrors();
  }
};