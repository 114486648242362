<script setup>
import { ref, computed, onMounted, nextTick, } from 'vue'
import { prettyJson, valOr, } from '../../SharedUtils.js'
import { PleaseContactStr } from '../../Base.js';

let props = defineProps({
  component: Object,
})

let errors = computed(() => {
  let objErrors = props.component.getObjErrors();
  let errors = [];
  for (const error of objErrors) {
    if (typeof error === 'string') {
      errors.push(error);
    } else {
      if (!valOr(error.showInComponentErrorsList, true)) {
        continue;
      }
      errors.push(error.msg);
    }
  }
  return errors;
});

</script>

<template>
  <div v-if="errors.length > 0" class="ErrorsParent mb-s">
    <p class="mute-header">Found Errors</p>
    <p class="help-text mb-s w-80">There were internal errors on this page. {{PleaseContactStr}}</p>
    <div v-for="(error, i) in errors" class="ErrorDiv mb-xs">
      <div class="Flex RowNoWrap GapS AlignStart">
        <p><i class="bi-exclamation-circle ErrorIcon"></i></p>
        <p>{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ErrorsParent {
  /*border: 1px solid var(--b-20);*/
  border: 1px solid var(--delete-red);
  border-radius: var(--br-l);
  padding: var(--s);
  max-width: 1000px;
}

.ErrorIcon {
  color: var(--delete-red);
}

.ErrorDiv {
}

.ErrorMsgText:deep(.AndMore) {
  font-style: italic;
}

</style>