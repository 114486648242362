import { 
  setupClass, 
  makeEnum,
  clamp,
} from '../Base.js'

import {
  valOr,
  prettyJson,
  deepCopyObject,
  getMonthHourString,
  getShortMonthName,
} from '../SharedUtils.js'
import { IsTestEnv } from '../Globals.js'

import { Field, FieldType, } from '../Common/Field.js'
import { Units } from '../Common/Units.js'
import { CalcContext } from '../Common/CalcContext.js'

import {
  SystemType,
  ZoneAirflowCalcMethod,
} from './System.js'
import * as Space from './Space.js'
import { RecoveryType, } from '../Components/HouseVentilationInfiltration.js'
import { YesNo } from '../Components/Common.js'

import { DataCache } from '../MaterialData/DataCache.js'
import * as MaterialDataTableRegistry from '../MaterialData/MaterialDataTableRegistry.js'

import { WorkerPool, MockWorkerPool, } from '../Common/WorkerPool.js'
import SpaceWorkerURL from '../workers/SpaceWorker?url&worker'

import * as math from 'mathjs'
import { MatrixUtils, scalarSum, makeVector, makeMonthVector, makeHourVector, } from '../Common/Math.js'

import { OutputValue } from '../Common/OutputValue.js'

import * as psy from '../Components/Psychrometrics.js'
import { PsyCalcMethod } from '../Components/Psychrometrics.js'

let RegularOrMin = makeEnum({
  Regular: 'Regular',
  Min: 'Min',
})

let HeatingOrCooling = makeEnum({
  Heating: 'Heating',
  Cooling: 'Cooling',
})

function applySpaceQuantities(matrixArr, spaceQuantities) {
  let res = []
  for (let i = 0; i < matrixArr.length; ++i) {
    res.push(math.multiply(matrixArr[i], spaceQuantities[i]))
  }
  return res
}

function findMaxLoadInMatrix(loadsMatrix) {
  let maxTime = null;
  let maxValue = null;
  for (let mo = 0; mo < 12; ++mo) {
    for (let hr = 0; hr < 24; ++hr) {
      let value = loadsMatrix.get([mo, hr])
      if (maxValue == null || value > maxValue) {
        maxValue = value
        maxTime = [mo, hr]
      }
    }
  }
  return {
    value: maxValue,
    time: maxTime,
  }
}

function findMaxValueInArray(arr) {
  let maxValue = null;
  let maxIndex = null;
  for (let i = 0; i < arr.length; ++i) {
    if (maxValue == null || arr[i] > maxValue) {
      maxValue = arr[i]
      maxIndex = i
    }
  }
  return {
    value: maxValue,
    index: maxIndex,
  }
}

class QSupplyValues {
  constructor(q_supply_values) {
    this.q_supply_values = q_supply_values
  }

  getSystemSupplyCooling(mo, hr) {
    return this.q_supply_values.Q_supply_cooling.get([mo, hr])
  }

  findPeakSystemSupplyCooling() {
    return findMaxLoadInMatrix(this.q_supply_values.Q_supply_cooling)
  }

  getSystemSupplyHeating() {
    return this.q_supply_values.Q_supply_heating
  }

  getZoneSupplyCooling(zoneIndex, mo, hr) {
    return this.q_supply_values.Q_zone_supply_cooling_values[zoneIndex].get([mo, hr])
  }

  getZoneSupplyHeating(zoneIndex) {
    return this.q_supply_values.Q_zone_supply_heating_values[zoneIndex]
  }

  getZoneSupplyCoolingPeak(zoneIndex) {
    return findMaxLoadInMatrix(this.q_supply_values.Q_zone_supply_cooling_values[zoneIndex]).value;
  }

  getSumOfZoneCoolingAirflows(mo, hr) {
    let sum = 0;
    for (const zoneSupplyCooling of this.q_supply_values.Q_zone_supply_cooling_values) {
      sum += zoneSupplyCooling.get([mo, hr])
    }
    return sum;
  }

  getSpaceSupplyCooling(zoneIndex, spaceIndex, mo, hr) {
    return this.q_supply_values.Q_space_supply_cooling_values[zoneIndex][spaceIndex].get([mo, hr])
  }

  getSpaceSupplyCoolingPeak(zoneIndex, spaceIndex) {
    let peakInfo = findMaxLoadInMatrix(this.q_supply_values.Q_space_supply_cooling_values[zoneIndex][spaceIndex])
    return peakInfo.value;
  }

  getSpaceSupplyHeating(zoneIndex, spaceIndex) {
    return this.q_supply_values.Q_space_supply_heating_values[zoneIndex][spaceIndex]
  }
}

class SpaceVentilationValues {
  constructor() {
    this.spaceIdToValues = {}
  }

  setValuesForSpaceType(spaceType, values) {
    this.spaceIdToValues[spaceType.id] = values
  }

  getValuesForSpaceType(spaceType) {
    return this.spaceIdToValues[spaceType.id]
  }

  hasValuesForSpaceType(spaceType) {
    return this.spaceIdToValues.hasOwnProperty(spaceType.id)
  }
}

class OutdoorAirIntakeValues {
  constructor(values) {
    this.values = values
  }

  get_V_ot_cooling_for_hr(hr) {
    return this.values.V_ot_cooling_by_hr[hr]
  }

  get_V_ot_cooling_max() {
    return findMaxValueInArray(this.values.V_ot_cooling_by_hr).value;
  }

  getMaxOutdoorAirPercentCooling() {
    return this.values.maxOutdoorAirPercentCooling;
  }

  getMaxOutdoorAirPercentHeating() {
    return this.values.maxOutdoorAirPercentHeating;
  }

  get_V_ot_heating() {
    return this.values.V_ot_heating
  }

  get_D_cooling() {
    return this.values.D_cooling;
  }

  get_E_v_cooling() {
    return this.values.E_v_cooling;
  }

  get_V_ou_cooling() {
    return this.values.V_ou_cooling;
  }
}

class SpaceResult {
  constructor(zoneSpace) {
    this.zoneSpace = zoneSpace
    this.loadResults = null;
    this.calcLog = null;
  }

  getLoadResults() {
    return this.loadResults
  }

  setLoadResults(loadResults) {
    this.loadResults = loadResults
  }

  getCalcLog() {
    return this.calcLog
  }

  setCalcLog(calcLog) {
    this.calcLog = calcLog
  }
}

class ZoneResult {
  constructor(zone, numSpaces) {
    this.zone = zone
    this.spaceResults = new Array(numSpaces).fill(null)
    this.totals = null
  }

  getSpaceResult(spaceIndex) {
    return this.spaceResults[spaceIndex]
  }

  setSpaceResult(spaceIndex, spaceResult) {
    this.spaceResults[spaceIndex] = spaceResult
  }

  getSpaceLoadResults(spaceIndex) {
    return this.spaceResults[spaceIndex].getLoadResults()
  }

  calculateZoneTotals(ctx) {
    ctx.startSection(`Calc zone totals - Zone ${this.zone.getName()}`)
    
    ctx.cooling_sensible = math.zeros(12, 24)
    ctx.cooling_latent = math.zeros(12, 24)
    ctx.cooling_total = math.zeros(12, 24)
    ctx.heating_sensible = 0
    ctx.heating_latent = 0
    ctx.heating_total = 0
    for (const spaceResult of this.spaceResults) {
      let zoneSpace = spaceResult.zoneSpace
      ctx.startLocalSection(`Space ${zoneSpace.getSpaceName()} (x${zoneSpace.getQuantity()})`)
      let loadResults = spaceResult.getLoadResults()
      ctx.cooling_sensible = ctx.eval('cooling_sensible + N*space_cooling_sensible', {
        N: zoneSpace.getQuantity(),
        space_cooling_sensible: loadResults.cooling.q_sensible,
      }, 'cooling_sensible')
      ctx.cooling_latent = ctx.eval('cooling_latent + N*space_cooling_latent', {
        N: zoneSpace.getQuantity(),
        space_cooling_latent: loadResults.cooling.q_latent,
      }, 'cooling_latent')
      ctx.heating_sensible = ctx.eval('heating_sensible + N*space_heating_sensible', {
        N: zoneSpace.getQuantity(),
        space_heating_sensible: loadResults.heating.q_sensible,
      }, 'heating_sensible')
      ctx.heating_latent = ctx.eval('heating_latent + N*space_heating_latent', {
        N: zoneSpace.getQuantity(),
        space_heating_latent: loadResults.heating.q_latent,
      }, 'heating_latent')
      ctx.endSection()
    }
    ctx.cooling_total = ctx.eval('cooling_sensible + cooling_latent', {}, 'cooling_total')
    ctx.heating_total = ctx.eval('heating_sensible + heating_latent', {}, 'heating_total')

    ctx.log("Zone totals:")
    ctx.logLoadMatrix('Cooling sensible', ctx.cooling_sensible)
    ctx.logLoadMatrix('Cooling latent', ctx.cooling_latent)
    ctx.logLoadMatrix('Cooling total', ctx.cooling_total)
    ctx.logValue('Heating sensible', ctx.heating_sensible)
    ctx.logValue('Heating latent', ctx.heating_latent)
    ctx.logValue('Heating total', ctx.heating_total)

    this.totals = {
      cooling: {
        q_sensible: ctx.cooling_sensible,
        q_latent: ctx.cooling_latent,
        q_total: ctx.cooling_total,
      },
      heating: {
        q_sensible: ctx.heating_sensible,
        q_latent: ctx.heating_latent,
        q_total: ctx.heating_total,
      }
    }
    ctx.endSection()
  }

  getZoneTotals() {
    return this.totals
  }
}

class ZoneResults {
  constructor(numZones) {
    this.zoneResults = new Array(numZones).fill(null)
  }

  getZoneResult(zoneIndex) {
    return this.zoneResults[zoneIndex]
  }

  getSpaceResult(zoneIndex, spaceIndex) {
    return this.getZoneResult(zoneIndex).getSpaceResult(spaceIndex)
  }

  setZoneResult(zoneIndex, zoneResult) {
    this.zoneResults[zoneIndex] = zoneResult
  }

  calculateZoneTotals(ctx) {
    ctx.startSection("Calculate Zone totals")
    for (const zoneResult of this.zoneResults) {
      zoneResult.calculateZoneTotals(ctx)
    }
    ctx.endSection()
  }
}

export class SystemLoadCalculator {
  constructor(system, proj, ctx, debugOpts) {
    this.system = system
    this.proj = proj;
    this.ctx = ctx
    this.debugOpts = valOr(debugOpts, {})

    this.zoneResults = new ZoneResults(system.zones.length)
  }

  calc_T_db_out_heating() {
    return this.ctx.designTemps.getHeatingDryBulbOut()
  }

  calc_T_db_out_cooling(hr, mo) {
    return this.ctx.designTemps.getCoolingDryBulbOut(this.ctx, mo, hr)
  }

  calc_T_wb_out_cooling(hr, mo) {
    return this.ctx.designTemps.getCoolingWetBulbOut(this.ctx, mo, hr)
  }

  calc_V_ou_multi_zone(regularOrMin, diversityFactor) {
    let ctx = this.ctx
    ctx.startSection(`Calc V_ou multizone - ${regularOrMin}`)
    ctx.D = diversityFactor

    ctx.V_ou_sum = 0
    this.system.forEachSpace((space) => {
      let spaceType = space.spaceType;
      ctx.startLocalSection(`Space ${spaceType.getName()}`)
      if (spaceType.ventilation.usesManualInput()) {
        ctx.log("Using manual ventilation values")
        ctx.V_values = spaceType.ventilation.getManualVentilationValues(ctx)
        if (regularOrMin == RegularOrMin.Regular) {
          ctx.V_ou = ctx.eval("V_bz", {
            V_bz: ctx.V_values.V_bz,
          }, 'V_ou')
        } else {
          ctx.V_ou = ctx.eval("V_bz_min", {
            V_bz_min: ctx.V_values.V_bz_min,
          }, 'V_ou')
        }
      } else {
        ctx.log("Using automatic ventilation values")
        ctx.V_values = spaceType.ventilation.getAutomaticVentilationValues(ctx)
        if (regularOrMin == RegularOrMin.Regular) {
          ctx.log("Calculating regular/occupied ventilation")
          ctx.V_ou = ctx.eval("D*P_space*R_p_space + A_space*R_a_space", {
            P_space: spaceType.getNumOccupants(),
            R_p_space: ctx.V_values.cfm_per_person,
            A_space: spaceType.floorArea.value,
            R_a_space: ctx.V_values.cfm_per_ft2,
          }, 'V_ou')
        } else {
          ctx.log("Calculating minimum ventilation")
          ctx.V_ou = ctx.eval("0 + A_space*R_a_space_min", {
            A_space: spaceType.floorArea.value,
            R_a_space_min: ctx.V_values.cfm_per_ft2,
          }, 'V_ou')
        }
      }
      ctx.V_ou_sum = ctx.eval('V_ou_sum + N*V_ou', {
        N: space.quantity,
      }, 'V_ou_sum')
      ctx.endSection()
    });

    let res = ctx.V_ou_sum;
    ctx.endSection()
    return res
  }

  calc_V_ot_multi_zone_CAV() {
    let ctx = this.ctx
    ctx.startSection("V_ot multi zone CAV")

    ctx.P_system = this.system.estimatedTotalOccupancy.value
    ctx.P_sum_spaces = 0
    this.system.forEachSpace((space) => {
      ctx.P_sum_spaces += space.quantity * space.spaceType.getNumOccupants();
    })
    ctx.D = ctx.eval('P_system/max(1, P_sum_spaces)', {
    }, 'D')

    ctx.V_ou = this.calc_V_ou_multi_zone(RegularOrMin.Regular, ctx.D)
    ctx.V_ou_min = this.calc_V_ou_multi_zone(RegularOrMin.Min, ctx.D)

    ctx.E_v = ctx.eval('max(0.75, 0.6*D + 0.22)', {}, 'E_v')
    ctx.V_ot = ctx.eval('V_ou / E_v', {
    }, 'V_ot')
    ctx.V_ot_min = ctx.eval('V_ou_min / E_v', {
    }, 'V_ot_min')

    let res = {
      V_ot: ctx.V_ot,
      V_ot_min: ctx.V_ot_min,
      D: ctx.D,
      E_v: ctx.E_v,
      V_ou: ctx.V_ou,
      V_ou_min: ctx.V_ou_min,
    }
    ctx.endSection()
    return res
  }

  calc_V_ot() {
    let ctx = this.ctx
    ctx.startSection(`Calc V_ot (Outdoor air intake)`)
    if (!this.system.overridesSystemVentilationIntake()) {
      ctx.log("Note - we always use the multi-zone CAV calculations (for CAV and VAV), now")
      ctx.V_ot_results = this.calc_V_ot_multi_zone_CAV()
    } else {
      ctx.log("Using system-level ventilation intake override")
      ctx.V_ot = this.system.get_V_ot_max_override();
      ctx.V_ot_min = this.system.get_V_ot_min_override();
      ctx.V_ot_results = {
        V_ot: ctx.V_ot,
        V_ot_min: ctx.V_ot_min,
        D: null,
        E_v: null,
        V_ou: null,
        V_ou_min: null,
      }
    }
    let res = ctx.V_ot_results
    ctx.endSection()
    return res
  }

  calc_V_ot_values() {
    let ctx = this.ctx
    ctx.startSection("Calc V_ot values")

    // Calculate V_ot and V_ot_min for heating and cooling
    ctx.log("Note - these are the same for heating and cooling")
    let V_ot_values = this.calc_V_ot()
    ctx.V_ot_heating = V_ot_values.V_ot
    ctx.V_ot_heating_min = V_ot_values.V_ot_min
    ctx.V_ot_cooling = V_ot_values.V_ot
    ctx.V_ot_cooling_min = V_ot_values.V_ot_min

    // Apply "Change ventilation for modes" setting.
    if (this.system.changeVentilationForModes.value == YesNo.No) {
      ctx.log("Change ventilation for modes is No, so take the maxes")
      ctx.V_ot_max = ctx.eval('max(V_ot_heating, V_ot_cooling)', {
      }, 'V_ot_max')
      ctx.V_ot_min_max = ctx.eval('max(V_ot_heating_min, V_ot_cooling_min)', {
      }, 'V_ot_min_max')
      ctx.V_ot_heating = ctx.eval('V_ot_max', {}, 'V_ot_heating')
      ctx.V_ot_cooling = ctx.eval('V_ot_max', {}, 'V_ot_cooling')
      ctx.V_ot_cooling_min = ctx.eval('V_ot_min_max', {}, 'V_ot_cooling_min')
    } else {
      ctx.log("Change ventilation for modes is Yes, so keep the values separate")
    }

    // If Demand-controlled ventilation is on, apply it here.
    if (this.system.usingDemandControlledVentilation()) {
      ctx.log("Using demand-controlled ventilation. V_ot values depend on time.")
      ctx.startLocalSection("Demand-controlled ventilation")
      ctx.V_ot_cooling_by_hr = makeVector(24)
      ctx.schedule = this.system.getDemandControlledVentilationSchedule(
        ctx.systemOccupancySchedule)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startLocalSection(`Hour ${hr}`)
        let V_ot_hr = ctx.eval('V_ot_cooling_min + S*(V_ot_cooling - V_ot_cooling_min)', {
          S: ctx.schedule[hr],
        }, 'V_ot_hr')
        ctx.V_ot_cooling_by_hr[hr] = V_ot_hr
        ctx.endSection()
      }
      ctx.logValue('V_ot_cooling_by_hr', ctx.V_ot_cooling_by_hr)
      ctx.endSection()
    } else {
      ctx.log("Not using demand-controlled ventilation. V_ot is not time-dependent.")
      ctx.V_ot_cooling_by_hr = makeVector(24).fill(ctx.V_ot_cooling)
    }

    ctx.Q_supply_heating = ctx.Q_supply_values.getSystemSupplyHeating()
    ctx.maxOutdoorAirPercentHeating = ctx.eval('100.0*V_ot_heating/Q_supply_heating', {
    }, 'maxOutdoorAirPercentHeating')

    ctx.log("Finding max outdoor air percent for cooling... (trying each mo/hr)")
    let maxOutdoorAirPercentCooling = null;
    for (let mo = 0; mo < 12; ++mo) {
      for (let hr = 0; hr < 24; ++hr) {
        let percent = 100.0*ctx.V_ot_cooling_by_hr[hr]/ctx.Q_supply_values.getSystemSupplyCooling(mo, hr);
        if (maxOutdoorAirPercentCooling == null || percent > maxOutdoorAirPercentCooling) {
          maxOutdoorAirPercentCooling = percent
        }
      }
    }
    ctx.maxOutdoorAirPercentCooling = maxOutdoorAirPercentCooling

    let res = new OutdoorAirIntakeValues({
      V_ot_heating: ctx.V_ot_heating,
      V_ot_cooling_by_hr: ctx.V_ot_cooling_by_hr,
      D_cooling: V_ot_values.D,
      E_v_cooling: V_ot_values.E_v,
      V_ou_cooling: V_ot_values.V_ou,
      maxOutdoorAirPercentCooling: ctx.maxOutdoorAirPercentCooling,
      maxOutdoorAirPercentHeating: ctx.maxOutdoorAirPercentHeating,
    })
    ctx.endSection()
    return res
  }

  getSpaceLoadResults(zoneIndex, spaceIndex) {
    return this.zoneResults.getZoneResult(zoneIndex).getSpaceLoadResults(spaceIndex)
  }

  getSpaceType(zoneIndex, spaceIndex) {
    return this.system.zones[zoneIndex].spaces[spaceIndex].getSpaceType()
  }

  calcSpaceVentilations() {
    let ctx = this.ctx
    ctx.startSection("Calc all space ventilations")
    let spaceVentilations = new SpaceVentilationValues()
    this.system.forEachSpace((space) => {
      ctx.startLocalSection(`Zone ${space.zoneIndex}, Space ${space.spaceIndex}`)
      if (spaceVentilations.hasValuesForSpaceType(space.spaceType)) {
        ctx.log("Already have values for this space type")
        ctx.endSection();
        return;
      }
      ctx.ventilations = space.spaceType.ventilation.calc_Ventilation_values(ctx)
      spaceVentilations.setValuesForSpaceType(space.spaceType, ctx.ventilations)
      ctx.endSection()
    })
    let res = spaceVentilations
    ctx.endSection()
    return res
  }

  calc_Q_supply_cool_space(zoneIndex, spaceIndex) {
    let ctx = this.ctx
    ctx.startSection(`Q_supply_cool_space - Zone ${zoneIndex} Space ${spaceIndex}`)
    
    let space = this.getSpaceType(zoneIndex, spaceIndex)
    let spaceResults = this.getSpaceLoadResults(zoneIndex, spaceIndex)
    let q_cool_space_matrix = spaceResults.cooling.q_sensible
    ctx.v_supply_cool = ctx.call(psy.CalcPsychrometrics, ctx.T_supply_cool, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.90
      }).v
    ctx.V_pz_min_space = ctx.spaceVentilations.getValuesForSpaceType(space).V_pz_min_cooling

    ctx.Q_supply_cool_space = math.zeros(12, 24)
    for (let mo = 0; mo < 12; ++mo) {
      ctx.startLocalSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startLocalSection(`Hour ${hr}`)
        ctx.T_cool_zone = this.get_T_zone_cooling(zoneIndex, hr)
        ctx.assert(ctx.T_supply_cool < ctx.T_cool_zone, "T_supply_cool must be less than T_cool_zone in order to " + 
          "cool the Space to the desired temperature.")
        ctx.Q_supply = ctx.eval('max(q_cool_space*v_supply_cool/(60*0.24*(T_cool_zone - T_supply_cool)), ' +
          'V_pz_min_space)', {
            q_cool_space: q_cool_space_matrix.get([mo, hr]),
          }, 'Q_supply')
        ctx.Q_supply_cool_space.set([mo, hr], ctx.Q_supply)
        ctx.endSection()
      }
      ctx.endSection()
    }

    ctx.logLoadMatrix('Q_supply_cool_space', ctx.Q_supply_cool_space)
    let res = ctx.Q_supply_cool_space
    ctx.endSection()
    return res
  }

  calc_Q_supply_heat_space(zoneIndex, spaceIndex) {
    let ctx = this.ctx
    ctx.startSection(`Q_supply_heat_space - Zone ${zoneIndex} Space ${spaceIndex}`)

    let space = this.getSpaceType(zoneIndex, spaceIndex)
    let spaceResults = this.getSpaceLoadResults(zoneIndex, spaceIndex)
    ctx.q_heat_space = spaceResults.heating.q_sensible;
    ctx.v_supply_heat = ctx.call(psy.CalcPsychrometrics, ctx.T_supply_heat, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.20,
      }).v
    ctx.T_heat_zone = this.get_T_zone_heating(zoneIndex)
    ctx.V_pz_min_space = ctx.spaceVentilations.getValuesForSpaceType(space).V_pz_min_heating
    ctx.assert(ctx.T_supply_heat > ctx.T_heat_zone, "T_supply_heat must be greater than T_heat_zone in order to " +
      "heat the Space to the desired temperature.")
    ctx.Q_supply_heat_space = ctx.eval('max(q_heat_space*v_supply_heat/(60*0.24*(T_supply_heat - T_heat_zone)), ' +
      'V_pz_min_space)', {
      }, 'Q_supply_heat_space')
    
    let res = ctx.Q_supply_heat_space
    ctx.endSection()
    return res
  }

  get_Q_supply_cool_spaces(zoneIndex) {
    let ctx = this.ctx
    ctx.startSection("Q_supply_cool_spaces")
    let data = []
    this.system.forEachSpaceInZone(zoneIndex, (space) => {
      let Q_supply_cool_space = this.calc_Q_supply_cool_space(space.zoneIndex, space.spaceIndex)
      data.push(Q_supply_cool_space)
    })
    ctx.endSection()
    return data
  }

  get_Q_supply_heat_spaces(zoneIndex) {
    let ctx = this.ctx
    ctx.startSection("Q_supply_heat_spaces")
    let data = []
    this.system.forEachSpaceInZone(zoneIndex, (space) => {
      let Q_supply_heat_space = this.calc_Q_supply_heat_space(space.zoneIndex, space.spaceIndex)
      data.push(Q_supply_heat_space)
    })
    ctx.endSection()
    return data
  }

  calc_Q_supply_CAV() {
    /*
    Note: For CAV, Q_supply_cooling is a matrix (of all the same value) and Q_supply_heating is a scalear
    */
    let ctx = this.ctx;
    ctx.startSection("Calc Q_supply CAV")
    ctx.separateAirflows = this.system.separateAirflow.value;
    ctx.zoneAirflowCalcMethod = this.system.cavSystemInputs.zoneAirflowCalcMethod.value;

    ctx.Q_zone_supply_cooling_values = []
    ctx.Q_zone_supply_heating_values = []

    ctx.Q_space_supply_cooling_values = []
    ctx.Q_space_supply_heating_values = []

    this.system.forEachZone((zone) => {
      ctx.startLocalSection("Zone " + zone.zoneIndex)

      let Q_supply_cooling_arr = this.get_Q_supply_cool_spaces(zone.zoneIndex)
      let Q_supply_heating_arr = this.get_Q_supply_heat_spaces(zone.zoneIndex)
      ctx.spaceQuantities = zone.zone.getSpaceQuantities()

      ctx.Q_space_supply_cooling_values.push(Q_supply_cooling_arr)
      ctx.Q_space_supply_heating_values.push(Q_supply_heating_arr)
      
      if (ctx.zoneAirflowCalcMethod == ZoneAirflowCalcMethod.SumOfPeaks) {
        let Q_zone_supply_cooling = ctx.eval('sumOfMatrixMaxs(applySpaceQuantities(Q_supply_cooling_arr, spaceQuantities))', {
          sumOfMatrixMaxs: MatrixUtils.sumOfMatrixMaxs,
          applySpaceQuantities,
          Q_supply_cooling_arr,
        }, 'Q_zone_supply_cooling')

        let Q_zone_supply_heating = ctx.eval('sum(applySpaceQuantities(Q_supply_heating_arr, spaceQuantities))', {
          sum: scalarSum,
          applySpaceQuantities,
          Q_supply_heating_arr,
        }, 'Q_zone_supply_heating')

        if (ctx.separateAirflows == YesNo.Yes) {
          ctx.log("Separate airflows")
          ctx.Q_zone_supply_cooling = Q_zone_supply_cooling
          ctx.Q_zone_supply_heating = Q_zone_supply_heating
        } else {
          ctx.log("Combined airflows, taking max")
          let max_Q_zone_supply = Math.max(Q_zone_supply_cooling, Q_zone_supply_heating)
          ctx.Q_zone_supply_cooling = max_Q_zone_supply
          ctx.Q_zone_supply_heating = max_Q_zone_supply
        }
      } else if (ctx.zoneAirflowCalcMethod == ZoneAirflowCalcMethod.TotalPeak) {
        let Q_zone_supply_cooling = ctx.eval('maxInMatrix(matrixSum(applySpaceQuantities(Q_supply_cooling_arr, spaceQuantities)))', {
          maxInMatrix: MatrixUtils.maxInMatrix,
          matrixSum: MatrixUtils.sumOfMatrices,
          applySpaceQuantities,
          Q_supply_cooling_arr,
        }, 'Q_zone_supply_cooling')

        let Q_zone_supply_heating = ctx.eval('sum(applySpaceQuantities(Q_supply_heating_arr, spaceQuantities))', {
          sum: scalarSum,
          Q_supply_heating_arr,
          applySpaceQuantities,
        }, 'Q_zone_supply_heating')

        if (ctx.separateAirflows == YesNo.Yes) {
          ctx.log("Separate airflows")
          ctx.Q_zone_supply_cooling = Q_zone_supply_cooling
          ctx.Q_zone_supply_heating = Q_zone_supply_heating
        } else {
          ctx.log("Combined airflows, taking max")
          let max_Q_zone_supply = Math.max(Q_zone_supply_cooling, Q_zone_supply_heating)
          ctx.Q_zone_supply_cooling = max_Q_zone_supply
          ctx.Q_zone_supply_heating = max_Q_zone_supply
        }
      } else {
        throw new Error("Unsupported zoneAirflowCalcMethod: " + ctx.zoneAirflowCalcMethod)
      }

      ctx.log("Making Q_supply_cooling matrix from value")
      ctx.Q_zone_supply_cooling = ctx.eval('makeMatrixOfValue(12, 24, Q_zone_supply_cooling)', {
        makeMatrixOfValue: MatrixUtils.makeMatrixOfValue,
      }, 'Q_supply_cooling')

      ctx.Q_zone_supply_cooling_values.push(ctx.Q_zone_supply_cooling)
      ctx.Q_zone_supply_heating_values.push(ctx.Q_zone_supply_heating)
      ctx.endSection()
    })

    ctx.Q_supply_cooling = ctx.eval('matrixSum(Q_zone_supply_cooling_values)', {
      matrixSum: MatrixUtils.sumOfMatrices,
      Q_zone_supply_cooling_values: ctx.Q_zone_supply_cooling_values,
    }, 'Q_supply_cooling')
    ctx.Q_supply_heating = ctx.eval('sum(Q_zone_supply_heating_values)', {
      sum: scalarSum,
      Q_zone_supply_heating_values: ctx.Q_zone_supply_heating_values,
    }, 'Q_supply_heating')

    ctx.logLoadMatrix('Q_supply_cooling', ctx.Q_supply_cooling)
    ctx.logValue('Q_supply_heating', ctx.Q_supply_heating)

    let res = {
      Q_space_supply_cooling_values: ctx.Q_space_supply_cooling_values,
      Q_space_supply_heating_values: ctx.Q_space_supply_heating_values,
      Q_zone_supply_cooling_values: ctx.Q_zone_supply_cooling_values,
      Q_zone_supply_heating_values: ctx.Q_zone_supply_heating_values,
      Q_supply_cooling: ctx.Q_supply_cooling,
      Q_supply_heating: ctx.Q_supply_heating,
    }
    ctx.endSection()
    return res
  }

  calc_Q_supply_VAV() {
    /*
    Note: For VAV, Q_supply_cooling is a matrix, and Q_supply_heating is a scalar
    */
    let ctx = this.ctx
    ctx.startSection("Calc Q_supply VAV")

    ctx.Q_zone_supply_cooling_values = []
    ctx.Q_zone_supply_heating_values = []

    ctx.Q_space_supply_cooling_values = []
    ctx.Q_space_supply_heating_values = []

    this.system.forEachZone((zone) => {
      ctx.startLocalSection("Zone " + zone.zoneIndex)

      let Q_supply_cooling_arr = this.get_Q_supply_cool_spaces(zone.zoneIndex)
      let Q_supply_heating_arr = this.get_Q_supply_heat_spaces(zone.zoneIndex)
      ctx.spaceQuantities = zone.zone.getSpaceQuantities()

      ctx.Q_space_supply_cooling_values.push(Q_supply_cooling_arr)
      ctx.Q_space_supply_heating_values.push(Q_supply_heating_arr)

      ctx.Q_zone_supply_cooling = ctx.eval('matrixSum(applySpaceQuantities(Q_supply_cooling_arr, spaceQuantities))', {
        matrixSum: MatrixUtils.sumOfMatrices,
        applySpaceQuantities,
        Q_supply_cooling_arr,
      }, 'Q_zone_supply_cooling')

      ctx.Q_zone_supply_heating = ctx.eval('sum(applySpaceQuantities(Q_supply_heating_arr, spaceQuantities))', {
        sum: scalarSum,
        applySpaceQuantities,
        Q_supply_heating_arr,
      }, 'Q_zone_supply_heating')

      ctx.Q_zone_supply_cooling_values.push(ctx.Q_zone_supply_cooling)
      ctx.Q_zone_supply_heating_values.push(ctx.Q_zone_supply_heating)
      ctx.endSection()
    })

    ctx.Q_supply_cooling = ctx.eval('matrixSum(Q_zone_supply_cooling_values)', {
      matrixSum: MatrixUtils.sumOfMatrices,
      Q_zone_supply_cooling_values: ctx.Q_zone_supply_cooling_values,
    }, 'Q_supply_cooling')
    ctx.Q_supply_heating = ctx.eval('sum(Q_zone_supply_heating_values)', {
      sum: scalarSum,
      Q_zone_supply_heating_values: ctx.Q_zone_supply_heating_values,
    }, 'Q_supply_heating')

    ctx.logLoadMatrix('Q_supply_cooling', ctx.Q_supply_cooling)
    ctx.logValue('Q_supply_heating', ctx.Q_supply_heating)

    let res = {
      Q_space_supply_cooling_values: ctx.Q_space_supply_cooling_values,
      Q_space_supply_heating_values: ctx.Q_space_supply_heating_values,
      Q_zone_supply_cooling_values: ctx.Q_zone_supply_cooling_values,
      Q_zone_supply_heating_values: ctx.Q_zone_supply_heating_values,
      Q_supply_cooling: ctx.Q_supply_cooling,
      Q_supply_heating: ctx.Q_supply_heating,
    }
    ctx.endSection()
    return res
  }

  calc_Q_supply() {
    let ctx = this.ctx
    ctx.startSection("Calc Q_supply")

    ctx.systemType = this.system.getSystemType()
    if (ctx.systemType == SystemType.CAV) {
      ctx.res = this.calc_Q_supply_CAV()
    } else if (ctx.systemType == SystemType.VAV) {
      ctx.res = this.calc_Q_supply_VAV()
    } else {
      throw new Error("Unsupported system type: " + ctx.systemType)
    }

    let res = new QSupplyValues(ctx.res)
    ctx.endSection()
    return res
  }

  // System occupancy
  calc_O_system(hr) {
    let ctx = this.ctx
    ctx.startSection("Calc O_system")
    ctx.O_system = 0
    this.system.forEachSpace((space) => {
      ctx.O_space = space.spaceType.getNumOccupants()
      ctx.S_space = space.spaceType.getOccupancySchedule().getData()[hr]
      ctx.O_space = ctx.eval('N*O_space*S_space', {
        N: space.quantity,
      }, 'O_space')
      ctx.O_system += ctx.O_space
    })
    let res = ctx.O_system
    ctx.endSection()
    return res
  }

  get_T_zone_cooling(zoneIndex, hr) {
    // NOTE - we used to have a check for systemOccupied here, but now just use a fixed value
    let zone = this.system.zones[zoneIndex]
    return zone.summerIndoorTemp.value
  }

  get_T_zone_heating(zoneIndex) {
    let zone = this.system.zones[zoneIndex]
    return zone.winterIndoorTemp.value
  }

  calc_v_zone_cooling(zoneIndex, hr) {
    let ctx = this.ctx
    ctx.startSection(`Calc v_zone cooling - Zone ${zoneIndex}, Hr ${hr}`)
    let zone = this.system.zones[zoneIndex]
    ctx.T_zone = this.get_T_zone_cooling(zoneIndex, hr)
    ctx.Humidity_setpoint_zone = zone.humiditySetpoint.value / 100.0
    ctx.v_zone = ctx.call(psy.CalcPsychrometrics, ctx.T_zone, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: ctx.Humidity_setpoint_zone,
      }).v
    let res = ctx.v_zone
    ctx.endSection()
    return res
  }

  calc_v_zone_heating(zoneIndex) {
    let ctx = this.ctx
    ctx.startSection(`Calc v_zone heating - Zone ${zoneIndex}`)
    ctx.T_zone = this.get_T_zone_heating(zoneIndex)
    ctx.v_zone = ctx.call(psy.CalcPsychrometrics, ctx.T_zone, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.30,
      }).v
    let res = ctx.v_zone
    ctx.endSection()
    return res
  }

  calc_W_return_cooling(mo, hr) {
    let ctx = this.ctx
    ctx.startSection("Calc W_return")
    ctx.require('m_return_zones')
    ctx.require('m_return')

    ctx.W_return = 0
    this.system.forEachZone((zone) => {
      ctx.startLocalSection("Zone " + zone.zoneIndex)
      ctx.T_zone = this.get_T_zone_cooling(zone.zoneIndex, hr)
      ctx.Humidity_setpoint_zone = zone.zone.humiditySetpoint.value / 100.0
      ctx.W_zone = ctx.call(psy.CalcPsychrometrics, ctx.T_zone, ctx.altitude,
        PsyCalcMethod.CalcWithRelativeHumidity, {
          RH: ctx.Humidity_setpoint_zone,
        }).W
      ctx.m_return_zone = ctx.m_return_zones[zone.zoneIndex]
      ctx.W_return_item = ctx.eval('W_zone*m_return_zone', {
      }, 'W_return_item')
      ctx.W_return += ctx.W_return_item
      ctx.endSection()
    })
    ctx.W_return /= ctx.m_return
    let res = ctx.W_return
    ctx.endSection()
    return res
  }

  calc_W_return_heating() {
    let ctx = this.ctx
    ctx.startSection("Calc W_return")
    ctx.require('m_return_zones')
    ctx.require('m_return')

    ctx.W_return = 0
    this.system.forEachZone((zone) => {
      ctx.startLocalSection("Zone " + zone.zoneIndex)
      ctx.log("Note: We assume winter humidity is 0.30 in all zones")
      ctx.T_zone = this.get_T_zone_heating(zone.zoneIndex)
      ctx.W_zone = ctx.call(psy.CalcPsychrometrics, ctx.T_zone, ctx.altitude,
        PsyCalcMethod.CalcWithRelativeHumidity, {
          RH: 0.30,
        }).W
      ctx.m_return_zone = ctx.m_return_zones[zone.zoneIndex]
      ctx.W_return_item = ctx.eval('W_zone*m_return_zone', {
      }, 'W_return_item')
      ctx.W_return += ctx.W_return_item
      ctx.endSection()
    })
    ctx.W_return = ctx.eval('W_return / m_return', {}, 'W_return');
    let res = ctx.W_return
    ctx.endSection()
    return res
  }

  calc_q_plenum_matrix() {
    let ctx = this.ctx
    ctx.startSection("Calc q_plenum matrix")
    ctx.q_plenum = math.zeros(12, 24)
    this.system.forEachSpace((space) => {
      ctx.startLocalSection(`Zone ${space.zoneIndex}, Space ${space.spaceIndex}`)
      let results = this.getSpaceLoadResults(space.zoneIndex, space.spaceIndex)
      ctx.item = results.plenum_loads
      ctx.item = math.multiply(ctx.item, space.quantity)
      ctx.q_plenum = math.add(ctx.q_plenum, ctx.item)
      ctx.endSection()
    })
    let res = ctx.q_plenum
    ctx.logLoadMatrix('q_plenum', ctx.q_plenum)
    ctx.endSection()
    return res
  }

  calc_q_plenum(mo, hr) {
    return this.ctx.q_plenum_matrix.get([mo, hr])
  }

  calc_q_fan_supply() {
    let ctx = this.ctx
    ctx.startSection("Calc q_fan_supply")
    let systemFans = this.system.systemFans
    if (systemFans.useSupplyFan.value == YesNo.No) {
      ctx.log("No supply fan")
      ctx.endSection()
      return 0;
    }
    let supplyFan = systemFans.supplyFan
    if (supplyFan.motorIsInAirStream.value == YesNo.Yes) {
      ctx.log("Motor is in air stream")
      ctx.q_fan_supply = ctx.eval('2545*P_M_supply', {
        P_M_supply: supplyFan.power.value,
      }, 'q_fan_supply')
    } else {
      ctx.log("Motor is not in air stream")
      ctx.require('m_recirc')
      ctx.require('m_vent')
      // Air power of supply fan
      ctx.P_A_supply = ctx.eval('0.00209*(m_recirc+m_vent)*p_supply', {
        p_supply: supplyFan.externalStaticPressure.value,
      }, 'P_A_supply')
      // Fan power of supply fan
      ctx.P_F_supply = ctx.eval('P_A_supply/n_F_supply', {
        n_F_supply: supplyFan.fanEfficiency.value / 100.0,
      }, 'P_F_supply')
      ctx.q_fan_supply = ctx.eval('2545*P_F_supply', {}, 'q_fan_supply')
    }
    let res = ctx.q_fan_supply
    ctx.endSection()
    return res
  }

  calc_q_fan_return(m_return) {
    let ctx = this.ctx
    ctx.startSection("Calc q_fan_return")
    let systemFans = this.system.systemFans
    if (systemFans.useReturnFan.value == YesNo.No) {
      ctx.log("No return fan")
      ctx.endSection()
      return 0;
    }
    let returnFan = systemFans.returnFan
    if (returnFan.motorIsInAirStream.value == YesNo.Yes) {
      ctx.log("Motor is in air stream")
      ctx.q_fan_return = ctx.eval('2545*P_M_return', {
        P_M_return: returnFan.power.value,
      }, 'q_fan_return')
    } else {
      ctx.log("Motor is not in air stream")
      ctx.m_return = m_return
      ctx.P_A_return = ctx.eval('0.00209*m_return*p_return', {
        p_return: returnFan.externalStaticPressure.value,
      }, 'P_A_return')
      ctx.P_F_return = ctx.eval('P_A_return/n_F_return', {
        n_F_return: returnFan.fanEfficiency.value / 100.0,
      }, 'P_F_return')
      ctx.q_fan_return = ctx.eval('2545*P_F_return', {
      }, 'q_fan_return')
    }

    let res = ctx.q_fan_return
    ctx.endSection()
    return res
  }

  calc_W_supply_cooling() {
    let ctx = this.ctx
    ctx.startSection("Calc W_supply")

    ctx.require('T_supply_cool')
    ctx.W_supply = ctx.call(psy.CalcPsychrometrics, ctx.T_supply_cool, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.95,
    }).W

    // For version 2:
    /*
    ctx.require('v_supply')
    ctx.require('peak_Q_supply')
    ctx.require('peak_Q_supply_mo')
    ctx.require('peak_Q_supply_hr')
    let peakMo = ctx.peak_Q_supply_mo
    let peakHr = ctx.peak_Q_supply_hr
    ctx.mixValuesAtPeak = this.calc_mix_values_cooling(peakMo, peakHr)
    ctx.T_mix_peak = ctx.mixValuesAtPeak.T_mix
    ctx.W_return_peak = ctx.mixValuesAtPeak.W_return
    // TODO - how to get this?
    ctx.q_lat_peak = 0
    ctx.T_db_avg_peak = ctx.eval('(T_supply_cool+T_mix_peak)/2.0', {
    }, 'T_db_avg_peak')
    ctx.W_supply = ctx.eval('W_return_peak - q_lat_peak/(60*peak_Q_supply*(1.0/v_supply))*' +
      '(1061+0.444*T_db_avg_peak)', {
      }, 'W_supply')
    */

    let res = ctx.W_supply
    ctx.endSection()
    return res
  }

  calc_m_return_zones_cooling(mo, hr) {
    let ctx = this.ctx
    ctx.startSection("Calc m_return cooling")

    let m_return_zones = []
    this.system.forEachZone((zone) => {
      ctx.startLocalSection(`Zone ${zone.zoneIndex}`)
      ctx.Q_supply_zone = ctx.Q_supply_values.getZoneSupplyCooling(zone.zoneIndex, mo, hr)
      ctx.v_zone = this.calc_v_zone_cooling(zone.zoneIndex, hr)
      ctx.m_return_zone = ctx.eval('Q_supply_zone/v_zone', {
      }, 'm_return_zone')
      m_return_zones.push(ctx.m_return_zone)
      ctx.endSection()
    })

    let res = m_return_zones
    ctx.endSection()
    return res
  }

  calc_m_return_zones_heating() {
    let ctx = this.ctx
    ctx.startSection("Calc m_return heating")

    let m_return_zones = []
    this.system.forEachZone((zone) => {
      ctx.startLocalSection(`Zone ${zone.zoneIndex}`)
      ctx.Q_supply_zone = ctx.Q_supply_values.getZoneSupplyHeating(zone.zoneIndex)
      ctx.v_zone = this.calc_v_zone_heating(zone.zoneIndex)
      ctx.m_return_zone = ctx.eval('Q_supply_zone/v_zone', {
      }, 'm_return_zone')
      m_return_zones.push(ctx.m_return_zone)
      ctx.endSection()
    })

    let res = m_return_zones
    ctx.endSection()
    return res
  }

  calc_return_values_cooling(mo, hr) {
    let ctx = this.ctx
    ctx.startSection("Calc return values cooling")

    ctx.m_return_zones = this.calc_m_return_zones_cooling(mo, hr)
    ctx.m_return = ctx.eval('sum(m_return_zones)', {
      sum: scalarSum,
    }, 'm_return')

    ctx.Q_supply = ctx.Q_supply_values.getSystemSupplyCooling(mo, hr)

    let innerSumElems = []
    this.system.forEachZone((zone) => {
      let T_zone = this.get_T_zone_cooling(zone.zoneIndex, hr)
      let Q_supply_zone = ctx.Q_supply_values.getZoneSupplyCooling(zone.zoneIndex, mo, hr)
      innerSumElems.push({
        T_zone: T_zone,
        Q_supply_zone: Q_supply_zone,
      })
    })
    ctx.innerSum = ctx.evalSum('T_zone*Q_supply_zone', innerSumElems, 'innerSum')  
    ctx.q_plenum = this.calc_q_plenum(mo, hr)
    ctx.q_fan_return = this.calc_q_fan_return(ctx.m_return)
    ctx.T_return = ctx.eval('(1.0/Q_supply)*innerSum + (q_plenum+q_fan_return)/'+
      '(60*0.24*m_return)', {
    }, 'T_return')
    ctx.W_return = this.calc_W_return_cooling(mo, hr)
    ctx.h_return = ctx.eval('0.24*T_return+W_return*(1061+0.444*T_return)', {
    }, 'h_return')
    ctx.v_return = ctx.call(psy.CalcPsychrometrics, ctx.T_return, ctx.altitude,
      PsyCalcMethod.CalcWithHumidityRatio, {
        W: ctx.W_return,
      }).v

    let res = {
      T_return: ctx.T_return,
      W_return: ctx.W_return,
      h_return: ctx.h_return,
      v_return: ctx.v_return,
    }
    ctx.endSection("Calc return values cooling")
    return res
  }

  calc_return_values_heating() {
    let ctx = this.ctx
    ctx.startSection("Calc return values heating")

    ctx.m_return_zones = this.calc_m_return_zones_heating()
    ctx.m_return = ctx.eval('sum(m_return_zones)', {
      sum: scalarSum,
    }, 'm_return')
    ctx.Q_supply = ctx.Q_supply_values.getSystemSupplyHeating()

    let innerSumElems = []
    this.system.forEachZone((zone) => {
      let T_zone = this.get_T_zone_heating(zone.zoneIndex)
      let Q_supply_zone = ctx.Q_supply_values.getZoneSupplyHeating(zone.zoneIndex)
      innerSumElems.push({
        T_zone: T_zone,
        Q_supply_zone: Q_supply_zone,
      })
    })
    ctx.innerSum = ctx.evalSum('T_zone*Q_supply_zone', innerSumElems, 'innerSum')  
    ctx.q_fan_return = this.calc_q_fan_return(ctx.m_return)
    ctx.T_return = ctx.eval('(1.0/Q_supply)*innerSum + q_fan_return/'+
      '(60*0.24*m_return)', {
    }, 'T_return')
    ctx.W_return = this.calc_W_return_heating()
    ctx.h_return = ctx.eval('0.24*T_return+W_return*(1061+0.444*T_return)', {
    }, 'h_return')
    ctx.v_return = ctx.call(psy.CalcPsychrometrics, ctx.T_return, ctx.altitude,
      PsyCalcMethod.CalcWithHumidityRatio, {
        W: ctx.W_return,
      }).v

    let res = {
      T_return: ctx.T_return,
      W_return: ctx.W_return,
      h_return: ctx.h_return,
      v_return: ctx.v_return,
    }

    ctx.endSection()
    return res
  }

  calc_mix_values_cooling(mo, hr) {
    let ctx = this.ctx
    ctx.startSection("Calc mix values cooling")

    let heatRecovery = this.system.heatRecovery;
    ctx.recoveryType = heatRecovery.recoveryType.value

    let t_db_out = this.calc_T_db_out_cooling(hr, mo)
    let t_wb_out = this.calc_T_wb_out_cooling(hr, mo)
    let psychrometrics = ctx.call(psy.CalcPsychrometrics, t_db_out, ctx.altitude,
      PsyCalcMethod.CalcWithWetBulbTemp, {
        t_wb_F: t_wb_out
      })
    ctx.h_oa = psychrometrics.h
    ctx.W_oa = psychrometrics.W
    ctx.T_oa = t_db_out

    let returnValues = this.calc_return_values_cooling(mo, hr)
    ctx.T_return = returnValues.T_return
    ctx.W_return = returnValues.W_return
    ctx.h_return = returnValues.h_return
    ctx.v_return = returnValues.v_return

    ctx.V_ot_hr = ctx.V_ot_values.get_V_ot_cooling_for_hr(hr)
    ctx.m_vent = ctx.eval('V_ot_hr*air_density', {
    }, 'm_vent')
    ctx.Q_return = ctx.Q_supply_values.getSystemSupplyCooling(mo, hr)
    ctx.m_recirc = ctx.eval('(Q_return-V_ot_hr)/v_return', {
    }, 'm_recirc')
    if (ctx.recoveryType != RecoveryType.None) {
      // These vars are only needed if RecoveryType is not None
      ctx.Q_exhaust = heatRecovery.calc_Q_exhaust(ctx, ctx.V_ot_hr)
      ctx.m_exhaust = ctx.eval('Q_exhaust/v_return', {
      }, 'm_exhaust')
      ctx.m_min = ctx.eval('min(m_exhaust, m_vent)', {
      }, 'm_min')
    }

    if (ctx.recoveryType == RecoveryType.None) {
      ctx.log(`Calc h_vent, T_vent, W_vent for recovery type None`)
      ctx.h_vent = ctx.h_oa
      ctx.T_vent = ctx.T_oa
      ctx.W_vent = ctx.W_oa
    } else if (ctx.recoveryType == RecoveryType.HRV) {
      ctx.log(`Calc h_vent, T_vent, W_vent for recovery type HRV`)
      let hrvInputs = heatRecovery.hrvGroup
      ctx.T_vent = ctx.eval('T_oa-(epsilon_s*m_min/max(m_vent, 0.0001) * (T_oa-T_return))', {
        epsilon_s: hrvInputs.get('summerEfficiency').value / 100.0,
      }, 'T_vent')
      ctx.W_sat_vent = ctx.call(psy.CalcPsychrometrics, ctx.T_vent, ctx.altitude,
        PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 1.0,
      }).W
      ctx.W_vent = ctx.eval('min(W_oa, W_sat_vent)', {
      }, 'W_vent')
      ctx.h_vent = ctx.eval('0.24*T_vent+W_vent*(1061+0.444*T_vent)', {
      }, 'h_vent')
    } else if (ctx.recoveryType == RecoveryType.ERV) {
      ctx.log(`Calc h_vent, T_vent, W_vent for recovery type ERV`)
      let ervInputs = heatRecovery.ervGroup
      ctx.h_vent = ctx.eval('h_oa-(epsilon_T*m_min/max(m_vent, 0.0001)*(h_oa-h_return))', {
        epsilon_T: ervInputs.get('summerTotalEfficiency').value / 100.0,
      }, 'h_vent')
      ctx.T_vent = ctx.eval('T_oa-(epsilon_s*m_min/max(m_vent, 0.0001)*(T_oa-T_return))', {
        epsilon_s: ervInputs.get('summerSensibleEfficiency').value / 100.0,
      }, 'T_vent')
      ctx.W_sat_vent = ctx.call(psy.CalcPsychrometrics, ctx.T_vent, ctx.altitude,
        PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 1.0,
      }).W
      ctx.W_vent = ctx.eval('min((h_vent-0.24*T_vent)/(1061+0.444*T_vent), W_sat_vent)', {
      }, 'W_vent')
    } else {
      throw new Error("Unsupported recovery type: " + recoveryType)
    }

    // Account for economizer
    ctx.usingEconomizer = this.system.isUsingEconomizer();
    let economizerOn = ctx.usingEconomizer ? ctx.h_oa <= ctx.h_return : false;
    if (economizerOn) {
      ctx.log("Economizer on (h_oa <= h_return)")
      ctx.Q_supply = ctx.Q_supply_values.getSystemSupplyCooling(mo, hr)
      ctx.F_min = ctx.eval('V_ot_hr / Q_supply', {
      }, 'F_min')
      ctx.T_supply = this.system.getCoolingSupplyTemp()
      ctx.F_econ = ctx.eval('(h_return-(0.24+0.444*W_return)*T_supply-1061*W_return) / ' +
        '((0.444*T_supply+1061)*(W_oa-W_return)+h_return-h_oa)', {
      }, 'F_econ')
      ctx.F_econ = ctx.eval('clamp(F_econ, F_min, 1.0)', {
        clamp: clamp,
      }, 'F_econ')
      ctx.h_mix = ctx.eval('F_econ*h_oa + (1.0-F_econ)*h_return', {
      }, 'h_mix')
      ctx.W_mix = ctx.eval('F_econ*W_oa+(1-F_econ)*W_return', {
      }, 'W_mix')
    } else {
      ctx.log("Economizer off")
      ctx.h_mix = ctx.eval('(h_vent*m_vent + h_return*m_recirc)/(m_vent+m_recirc)', {
      }, 'h_mix')
      ctx.W_mix = ctx.eval('(W_vent*m_vent+W_return*m_recirc)/(m_vent+m_recirc)', {
      }, 'W_mix')
    }
    
    ctx.T_mix = ctx.eval('(h_mix - 1061*W_mix)/(0.24+0.444*W_mix)', {
    }, 'T_mix')

    let res = {
      h_mix: ctx.h_mix,
      W_mix: ctx.W_mix,
      T_mix: ctx.T_mix,

      T_return: ctx.T_return,
      W_return: ctx.W_return,
      h_return: ctx.h_return,
      v_return: ctx.v_return,

      m_vent: ctx.m_vent,
      m_recirc: ctx.m_recirc,
    }
    ctx.endSection("Calc mix values cooling")
    return res
  }

  async calc_q_cooling_system() {
    let ctx = this.ctx
    ctx.startSection("Calc q_cooling_system")

    ctx.q_cooling_sens = math.zeros(12, 24)
    ctx.q_cooling_lat = math.zeros(12, 24)

    let peakValues = ctx.Q_supply_values.findPeakSystemSupplyCooling()
    ctx.peak_Q_supply= peakValues.value
    ctx.peak_Q_supply_mo = peakValues.time[0]
    ctx.peak_Q_supply_hr = peakValues.time[1]

    ctx.v_supply = ctx.call(psy.CalcPsychrometrics, ctx.T_supply_cool, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.95
    }).v
    ctx.W_supply = this.calc_W_supply_cooling()

    for (let mo = 0; mo < 12; mo++) {
      if (valOr(this.debugOpts.calcFirstHourOnly, false) && mo != 0) {
        break;
      }
      ctx.startLocalSection("Month " + mo)
      for (let hr = 0; hr < 24; hr++) {
        if (valOr(this.debugOpts.calcFirstHourOnly, false) && hr != 0) {
          break;
        }
        ctx.startLocalSection("Hour " + hr)
        ctx.setProgressText(`Calculating system cooling for month ${mo+1}, hour ${hr+1}`)
        await ctx.briefWait()

        ctx.Q_supply = ctx.Q_supply_values.getSystemSupplyCooling(mo, hr)

        ctx.mix_values = this.calc_mix_values_cooling(mo, hr)
        ctx.h_mix = ctx.mix_values.h_mix
        ctx.W_mix = ctx.mix_values.W_mix
        ctx.T_mix = ctx.mix_values.T_mix
        // Required by calc_q_fan_supply
        ctx.m_vent = ctx.mix_values.m_vent
        ctx.m_recirc = ctx.mix_values.m_recirc

        ctx.q_fan_supply = this.calc_q_fan_supply()
        let q_sens = ctx.eval('60*0.24*Q_supply*(1.0/v_supply)*(T_mix-T_supply_cool) + ' +
          'q_fan_supply', {
        }, 'q_sens');
        ctx.q_cooling_sens.set([mo, hr], q_sens)

        ctx.T_db_avg = ctx.eval('(T_mix + T_supply_cool)/2.0', {
        }, 'T_db_avg')
        let q_lat = ctx.eval('max(60*Q_supply*(1.0/v_supply)*(W_mix-W_supply)*(1061+0.444*T_db_avg), 0)', {
          max: Math.max,
        }, 'q_lat');
        ctx.q_cooling_lat.set([mo, hr], q_lat)

        ctx.endSection("Hour " + hr)
      }
      ctx.endSection("Month " + mo)
    }

    ctx.logLoadMatrix('q_cooling_sens', ctx.q_cooling_sens)
    ctx.logLoadMatrix('q_cooling_lat', ctx.q_cooling_lat)

    let res = {
      q_cooling_sens: ctx.q_cooling_sens,
      q_cooling_lat: ctx.q_cooling_lat,
      q_cooling_total: math.add(ctx.q_cooling_sens, ctx.q_cooling_lat),
    }

    ctx.endSection()
    return res;
  }

  calc_W_supply_heating() {
    let ctx = this.ctx
    ctx.startSection("Calc W_supply")

    // Yes the "Add Winter Humidification" section of System Load Calculations doc
    // Note - for V1, we are not supporting winter humidification
    let addWinterHumidification = false;
    if (!addWinterHumidification) {
      ctx.log("No winter humidification (not yet supported). W_supply = W_mix")
      ctx.require('W_mix')
      ctx.W_supply = ctx.eval('W_mix', {}, 'W_supply')
    } else {
      ctx.assert(false, "Winter humidification not supported")
      /*
      ctx.m_supply_heat = ctx.eval('m_recirc+m_vent', {
      }, 'm_supply_heat')
      // TODO
      ctx.T_room = 0
      ctx.T_supply_heat = 0
      ctx.T_db_avg = ctx.eval('(T_room+T_supply_heat)/2.0', {
      }, 'T_db_avg')

      // TODO - fill in vars
      ctx.peak_mo = 0
      ctx.peak_hr = 0
      ctx.W_return_peak = 0
      ctx.q_lat_peak = 0
      ctx.Q_supply = 0
      ctx.v_supply = 0
      ctx.T_db_avg_peak = 0
      ctx.W_supply = ctx.eval('W_return_peak - q_lat_peak/(60*Q_supply*air_density*' +
        '(1061+0.444*T_db_avg_peak)', {
        }, 'W_supply')
      */
    }

    let res = ctx.W_supply
    ctx.endSection()
    return res
  }

  calc_mix_values_heating() {
    let ctx = this.ctx
    ctx.startSection("Calc mix values heating")

    let heatRecovery = this.system.heatRecovery;
    ctx.recoveryType = heatRecovery.recoveryType.value

    let t_db_out = this.calc_T_db_out_heating()
    let psychrometrics = ctx.call(psy.CalcPsychrometrics, t_db_out, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.50,
      })
    ctx.h_oa = psychrometrics.h
    ctx.W_oa = psychrometrics.W
    ctx.T_oa = t_db_out

    let returnValues = this.calc_return_values_heating()
    ctx.T_return = returnValues.T_return
    ctx.W_return = returnValues.W_return
    ctx.h_return = returnValues.h_return
    ctx.v_return = returnValues.v_return
    
    ctx.V_ot = ctx.V_ot_values.get_V_ot_heating()
    ctx.m_vent = ctx.eval('V_ot*air_density', {
    }, 'm_vent')
    ctx.Q_return = ctx.Q_supply_values.getSystemSupplyHeating()
    ctx.m_recirc = ctx.eval('(Q_return-V_ot)/v_return', {
    }, 'm_recirc')
    if (ctx.recoveryType != RecoveryType.None) {
      // These vars are only needed if RecoveryType is not None
      ctx.Q_exhaust = heatRecovery.calc_Q_exhaust(ctx, ctx.V_ot)
      ctx.m_exhaust = ctx.eval('Q_exhaust/v_return', {
      }, 'm_exhaust')
      ctx.m_min = ctx.eval('min(m_exhaust, m_vent)', {
      }, 'm_min')
    }

    if (ctx.recoveryType == RecoveryType.None) {
      ctx.log(`Calc h_vent, T_vent, W_vent for recovery type None`)
      ctx.h_vent = ctx.h_oa
      ctx.T_vent = ctx.T_oa
      ctx.W_vent = ctx.W_oa
    } else if (ctx.recoveryType == RecoveryType.HRV) {
      ctx.log(`Calc h_vent, T_vent, W_vent for recovery type HRV`)
      let hrvInputs = heatRecovery.hrvGroup
      ctx.W_vent = ctx.W_oa
      ctx.T_vent = ctx.eval('T_oa - (epsilon_s*m_min/max(m_vent, 0.0001))*(T_oa-T_return)', {
        epsilon_s: hrvInputs.get('winterEfficiency').value / 100.0,
      }, 'T_vent')
      ctx.h_vent = ctx.eval('0.24*T_vent+W_vent*(1061+0.444*T_vent)', {
      }, 'h_vent')
    } else if (ctx.recoveryType == RecoveryType.ERV) {
      ctx.log(`Calc h_vent, T_vent, W_vent for recovery type ERV`)
      let ervInputs = heatRecovery.ervGroup
      ctx.h_vent = ctx.eval('h_oa-(epsilon_T*m_min/max(m_vent, 0.0001)*(h_oa-h_return))', {
        epsilon_T: ervInputs.get('winterTotalEfficiency').value / 100.0,
      }, 'h_vent')
      ctx.T_vent = ctx.eval('T_oa-(epsilon_s*m_min/max(m_vent, 0.0001))*(T_oa-T_return)', {
        epsilon_s: ervInputs.get('winterSensibleEfficiency').value / 100.0,
      }, 'T_vent')
      ctx.W_vent = ctx.eval('(h_vent-0.24*T_vent)/(1061+0.444*T_vent)', {
      }, 'W_vent')
    } else {
      throw new Error("Unsupported recovery type: " + ctx.recoveryType)
    }

    ctx.h_mix = ctx.eval('(m_vent*h_vent + m_recirc*h_return)/(m_vent+m_recirc)', {
    }, 'h_mix')
    ctx.W_mix = ctx.eval('(W_vent*m_vent + W_return*m_recirc)/(m_vent+m_recirc)', {
    }, 'W_mix')
    ctx.T_mix = ctx.eval('(h_mix - 1061*W_mix)/(0.24+0.444*W_mix)', {
    }, 'T_mix')

    let res = {
      h_mix: ctx.h_mix,
      W_mix: ctx.W_mix,
      T_mix: ctx.T_mix,
      m_vent: ctx.m_vent,
      m_recirc: ctx.m_recirc,
    }

    ctx.endSection()
    return res
  }

  calc_v_supply_heating() {
    let ctx = this.ctx
    ctx.startSection("Calc v_supply heating")

    let addWinterHumidification = false;
    if (!addWinterHumidification) {
      ctx.log("Add winter humidification is off (not yet supported)")
      ctx.W_supply = this.calc_W_supply_heating()
      ctx.v_supply = ctx.call(psy.CalcPsychrometrics, ctx.T_supply_heat, ctx.altitude,
        PsyCalcMethod.CalcWithHumidityRatio, {
          W: ctx.W_supply
      }).v
    } else {
      ctx.log("Add winter humidification is on")
      ctx.assert(false, "Winter humidification not supported")
      ctx.v_supply = ctx.call(psy.CalcPsychrometrics, ctx.T_supply_heat, ctx.altitude,
        PsyCalcMethod.CalcWithRelativeHumidity, {
          RH: 0.80
      }).v
    }

    let res = ctx.v_supply
    ctx.endSection()
    return res
  }

  async calc_q_heating_system() {
    let ctx = this.ctx
    ctx.startSection("Calc q_heating_system")

    // Note: For heating, q_heat does not depend on (mo, hr).
    // We return q_heating_sens, which is a single scalar value

    ctx.require('T_supply_heat')
    ctx.Q_supply = ctx.Q_supply_values.getSystemSupplyHeating()

    ctx.mixValues = this.calc_mix_values_heating()
    ctx.T_mix = ctx.mixValues.T_mix
    ctx.W_mix = ctx.mixValues.W_mix
    // Required by calc_q_fan_supply
    ctx.m_vent = ctx.mixValues.m_vent
    ctx.m_recirc = ctx.mixValues.m_recirc

    ctx.v_supply = this.calc_v_supply_heating()
    ctx.q_fan_supply = this.calc_q_fan_supply()
    ctx.q_heat = ctx.eval('60*0.24*Q_supply*(1.0/v_supply)*(T_supply_heat-T_mix)-q_fan_supply', {
    }, 'q_heat')

    let res = {
      q_heating_sens: ctx.q_heat,
    }

    ctx.endSection()
    return res;
  }

  /*
  This is called by the SpaceWorker WebWorker to calculate loads.
  Creates own CalcContext and calculates the loads independently.
  */
  static async calculateSpaceLoads_AsJob(project, system, zone, spaceType,
                                            jobData, opts) {
    opts = valOr(opts, {});
    let sendProgressUpdate = (progressText) => {
    };
    if (opts.onProgressUpdate) {
      sendProgressUpdate = opts.onProgressUpdate;
    }

    sendProgressUpdate("Calculating - 0%");
    let results = null
    let calcContext = CalcContext.create({
      skipWaits: true,
      progressUpdateIntervalSecs: 1,
      progressUpdateFunc: (ctx, progressText) => {
        sendProgressUpdate(`Calculating - ${ctx.getProgressPercent()}%`);
      }
    });
    try {
      SystemLoadCalculator.setupCalcContext(calcContext, project, system);

      let zoneInfo = Space.ZoneInfo.createFromZone(zone);

      let loadResults = null;
      let useDummySpaceLoads = valOr(jobData.debugOpts.useDummySpaceLoads, false);
      if (!useDummySpaceLoads) {
        loadResults = await spaceType.calculateLoadsAsync(calcContext, zoneInfo, {})
      } else {
        console.log("Using dummy space loads");
        loadResults = spaceType.getDummyLoadResults(calcContext, {})
      }

      sendProgressUpdate("Done");
      console.log("Worker calculation complete, returning results");
      let serializedLoadResults = Space.Space.writeWorkerResultsToJson(loadResults);
      console.log("Returning load results: ", serializedLoadResults);
      results = {
        loadResults: serializedLoadResults,
        calcLog: deepCopyObject(calcContext.getFullStructuredLog()),
        didError: false,
      }
    } catch (err) {
      // Note: we don't rethrow here b/c we'd like to return the calcLog intact.
      // Worker should ideally never throw.
      console.log("Caught fatal error in worker, returning: " + err);
      calcContext.logFatalError(err);
      calcContext.cleanupSectionStack();
      sendProgressUpdate("Error during calculations");
      results = {
        loadResults: null,
        calcLog: deepCopyObject(calcContext.getFullStructuredLog()),
        didError: true,
        errMsg: err.toString(),
      }
    }

    return results;
  }

  async calcSpaceLoadResults_Serially(jobData) {
    /*
    This is used for testing. Usually we calc the loads in parallel using workers.
    */
    let system = this.system;
    let zone = system.getZoneAtIndex(jobData.zoneIndex);
    let space = zone.getSpaceAtIndex(jobData.spaceIndex);
    let spaceType = space.getSpaceType();
    let results = await SystemLoadCalculator.calculateSpaceLoads_AsJob(
        this.proj, system, zone, spaceType, jobData, {});
    return results
  }

  async calcSpaceLoadResults() {
    let ctx = this.ctx
    ctx.startSection("Space load results")

    //let spaceWorkerURL = new URL('./workers/SpaceWorker.js', import.meta.url)
    let pool = null;
    if (!IsTestEnv()) {
      pool = new WorkerPool(SpaceWorkerURL, {
        maxWorkers: 10,
        workerOpts: {
          // By default, Vite uses a module worker in dev mode, which can cause your application to fail.
          // Therefore, we need to use a module worker in dev mode and a classic worker in prod mode.
          type: import.meta.env.PROD ? undefined : "module"
        }
      });
    } else {
      console.log("Using MockWorkerPool, for testing");
      pool = new MockWorkerPool();
      pool.registerFunction('calcSpaceLoadResults', async (jobData) => {
        return await this.calcSpaceLoadResults_Serially(jobData);
      })
    }

    let spaceCalculationPromises = []

    // Start progress section
    let spaceProgressItems = []
    this.system.forEachSpace((space) => {
      spaceProgressItems.push({
        name: `Zone ${space.zoneIndex}, Space ${space.spaceIndex} (${space.spaceType.name.value})`
      })
    });
    ctx.startParallelProgressSection('Spaces', spaceProgressItems);

    // Queue all jobs
    let allWorkersSucceeded = true;
    for (let zoneIndex = 0; zoneIndex < this.system.zones.length; zoneIndex++) {
      let zone = this.system.zones[zoneIndex]
      let zoneResult = new ZoneResult(zone, zone.spaces.length)
      this.zoneResults.setZoneResult(zoneIndex, zoneResult)
      for (let spaceIndex = 0; spaceIndex < zone.spaces.length; spaceIndex++) {
        let space = zone.spaces[spaceIndex]
        let spaceType = space.getSpaceType()

        let jobData = {
          systemId: this.system.id,
          zoneIndex: zoneIndex,
          spaceIndex: spaceIndex,
          projectData: deepCopyObject(this.proj.getProjectJson()),
          debugOpts: deepCopyObject(this.debugOpts),
        }
        let thisSpaceIndex = spaceIndex;
        let jobName = `Zone ${zoneIndex}, Space ${spaceIndex} (${spaceType.name.value})`
        let jobOptions = {
          on: (payload) => {
            if (payload.type == 'progress') {
              ctx.setParallelProgress(jobName, payload.progressText);
            }
          }
        };
        let spaceCalculationPromise = pool.exec('calcSpaceLoadResults', [jobData],  jobOptions)
          .then((jobResults) => {
            console.log(`Finished space calculation for Zone ${zoneIndex}, Space ${spaceIndex} (${spaceType.name.value})`);
            let spaceResult = new SpaceResult(space)
            let loadResults = null;
            if (!jobResults.didError) {
              loadResults = Space.Space.readWorkerResultsFromJson(jobResults.loadResults)
              console.log(`Read load results for Zone ${zoneIndex}, Space ${spaceIndex} (${spaceType.name.value}): `, loadResults);
            }
            spaceResult.setLoadResults(loadResults)
            spaceResult.setCalcLog(jobResults.calcLog)
            zoneResult.setSpaceResult(thisSpaceIndex, spaceResult)
            ctx.log(`Done (${jobResults.didError ? 'Error' : 'Success' }) - Zone ${zoneIndex}, Space ${spaceIndex} (${spaceType.name.value})`);
            if (jobResults.didError) {
              allWorkersSucceeded = false;
            }
          }).catch((err) => {
            console.error(`Exception in worker for Zone ${zoneIndex} Space ${spaceIndex} (${spaceType.name.value}): `, err);
            let newErrorMsg = `Exception in worker for Zone ${zoneIndex}, Space ${spaceIndex} (${spaceType.name.value}): ${err.message}`
            ctx.log(`Exception - Zone ${zoneIndex}, Space ${spaceIndex} (${spaceType.name.value})`);
            throw new Error(newErrorMsg);
          })
        spaceCalculationPromises.push(spaceCalculationPromise)
      }
    }

    // Wait for all jobs to finish.
    // Note: we let all workers finish, even if some error, so that we can get their logs.
    // We throw at the end if any worker errored.
    try {
      ctx.log("Waiting for all space calculations to finish...")
      await Promise.all(spaceCalculationPromises);
    } finally {
      // Close the pool
      await pool.terminate();
    }
    ctx.log("Done all")
    ctx.endProgressSection();

    // Log results
    this.system.forEachSpace((space) => {
      ctx.startLocalSection(`Zone ${space.zoneIndex}, Space ${space.spaceIndex} (${space.spaceType.name.value})`)

      let spaceResult = this.zoneResults.getZoneResult(space.zoneIndex).getSpaceResult(space.spaceIndex);

      let calcLog = spaceResult.getCalcLog();
      ctx.pushFullStructuredLog(calcLog)

      let loadResults = spaceResult.getLoadResults();
      console.log("Load results: ", loadResults);
      if (loadResults) {
        ctx.logValue('heating, q_sensible', loadResults.heating.q_sensible)
        ctx.logValue('heating, q_latent', loadResults.heating.q_latent)
        ctx.logValue('heating, q_total', loadResults.heating.q_total)
        ctx.logLoadMatrix('cooling, q_sensible', loadResults.cooling.q_sensible)
        ctx.logLoadMatrix('cooling, q_latent', loadResults.cooling.q_latent)
        ctx.logLoadMatrix('cooling, q_total', loadResults.cooling.q_total)
        ctx.logLoadMatrix('plenum_loads', loadResults.plenum_loads)
      }

      ctx.endSection()
    });

    // Calculate zone totals
    this.zoneResults.calculateZoneTotals(ctx)

    // Throw if any worker errored
    if (!allWorkersSucceeded) {
      throw new Error("Some Space calculations failed, aborting")
    }

    ctx.endSection()
  }

  calcSystemOccupancySchedule() {
    let ctx = this.ctx
    ctx.startSection("System occupancy schedule")
    let systemOccupancySchedule = makeVector(24).fill(0.0)
    for (let hr = 0; hr < 24; hr++) {
      systemOccupancySchedule[hr] = this.system.getFracOccupied(hr);
    }
    ctx.logValue('systemOccupancySchedule', systemOccupancySchedule)
    ctx.endSection()
    return systemOccupancySchedule;
  }

  async calcAllOutputs() {
    let ctx = this.ctx
    ctx.startLocalSection("Calc outputs")
    ctx.startProgressSection('System loads', {
      'Space loads': {},
      'Space ventilations': {},
      'System cooling': {},
      'System heating': {},
    })

    ctx.setProgress("Space loads")
    await this.calcSpaceLoadResults()

    ctx.systemOccupancySchedule = this.calcSystemOccupancySchedule()

    ctx.setProgress("Space ventilations")
    ctx.spaceVentilations = this.calcSpaceVentilations()

    ctx.T_supply_cool = this.system.getCoolingSupplyTemp()
    ctx.T_supply_heat = this.system.getHeatingSupplyTemp()
    ctx.Q_supply_values = this.calc_Q_supply()
    ctx.q_plenum_matrix = this.calc_q_plenum_matrix()
    ctx.V_ot_values = this.calc_V_ot_values()

    ctx.setProgress("System cooling")
    ctx.system_cooling = await this.calc_q_cooling_system()
    ctx.setProgress("System heating")
    ctx.system_heating = await this.calc_q_heating_system()

    let res = {
      zoneResults: this.zoneResults,
      Q_supply_values: ctx.Q_supply_values,
      V_ot_values: ctx.V_ot_values,
      system_cooling: ctx.system_cooling,
      system_heating: ctx.system_heating,
    }

    // Long wait so the progress UI can update
    await ctx.longWait(2);

    ctx.endProgressSection()

    ctx.endSection()
    return res;
  }

  /*
  This is static b/c it is reused by the SpaceWorkers
  */
  static setupCalcContext(ctx, proj, system) {
    ctx.startLocalSection("Basic")

    // Tables cache
    let tablesCache = new DataCache();
    MaterialDataTableRegistry.registerCommercialTables(tablesCache);
    ctx.tablesCache = tablesCache;

    // Building and env:
    let locationData = proj.buildingAndEnv.getLocationData();
    console.log("Location data: ", locationData);
    // Put the location data in 'toplevelData' for compatibility with residential calculations
    ctx.toplevelData = {
      locationData: locationData.getOutputs(),
      dayOfMonth: 21,
    }
    ctx.buildingAndEnv = proj.buildingAndEnv.getOutputs();

    // TODO - is air_density_0 correct?
    ctx.air_density_0 = 0.074
    ctx.altitude  = ctx.toplevelData.locationData.elevation;
    ctx.P_loc = ctx.call(psy.calcP_loc, ctx.altitude)
    ctx.P_atm = psy.P_std
    ctx.air_density = ctx.eval('air_density_0 * P_loc / P_atm', {}, 'air_density')

    // Design temps:
    ctx.designTemps = system.designTempInputs.getDesignTemps(ctx);

    // TODO - are these accurate?
    ctx.summerIndoorRH = 0.50;
    ctx.winterIndoorRH = 0.30;

    ctx.endSection()
  }

  makeSystemInputSummary() {
    let ctx = this.ctx;

    let weatherData = ctx.toplevelData.locationData;

    let environment = {
      name: "Environment",
      type: "OutputValueList",
      outputValues: [
        OutputValue.createSimple("Location", FieldType.String, weatherData.locationName),
        OutputValue.createSimple("Latitude", FieldType.Angle, weatherData.latitude, {min: -90, max: 90}),
        OutputValue.createSimple("Longitude", FieldType.Angle, weatherData.longitude, {min: -180, max: 180}),
        OutputValue.createSimple("Elevation", FieldType.Length, weatherData.elevation, {min: -1e9}),
      ]
    };

    let designHeatingTempStr = ctx.designTemps.getHeatingDesignTempStr();
    let designHeatingTempValueStr = ctx.designTemps.getHeatingDesignTempValueStr();
    let designCoolingTempStr = ctx.designTemps.getCoolingDesignTempStr();
    let designTemps = {
      name: "Design Temperatures",
      type: "OutputValueList",
      outputValues: [
        OutputValue.createSimple("Design Heating Temp", FieldType.String, `${designHeatingTempStr}, ${designHeatingTempValueStr}`),
        OutputValue.createSimple("Design Cooling Temp", FieldType.String, `${designCoolingTempStr}`),
      ]
    }

    let designCoolingTemps = {
      name: "Design Cooling Temperatures (by month)",
      type: "Table",
      columns: [
        {name: "Month"},
        {name: `${designCoolingTempStr}`},
        {name: `${designCoolingTempStr} MCWB`},
      ],
      data: [],
    };
    for (let mo = 0; mo < 12; mo++) {
      let dryBulbTemp = ctx.designTemps.getCoolingDryBulbOutForMonth(mo);
      let wetBulbTemp = ctx.designTemps.getCoolingWetBulbOutForMonth(mo);
      let zoneData = [
        OutputValue.createSimple("Month", FieldType.String, `${getShortMonthName(mo)}`),
        OutputValue.createSimple("Dry Bulb", FieldType.Temperature, dryBulbTemp),
        OutputValue.createSimple("Wet Bulb", FieldType.Temperature, wetBulbTemp),
      ];
      designCoolingTemps.data.push({rowData: zoneData});
    }

    let inputs = {
      name: "Inputs",
      type: "OutputValueList",
      outputValues: [
        OutputValue.createFromField(this.system.estimatedTotalOccupancy, {name: 'System Occupancy'}),
        OutputValue.createFromField(this.system.demandControlledVentilation.get('use'), {name: 'Demand Controlled Ventilation'}),
        OutputValue.createFromField(this.system.economizer.get('use'), {name: 'Economizer'}),
        OutputValue.createFromField(this.system.heatRecovery.recoveryType),
      ],
    };
    let recoveryType = this.system.heatRecovery.recoveryType.value;
    if (recoveryType == RecoveryType.HRV) {
      let hrvgroup = this.system.heatRecovery.hrvGroup;
      let fields = [
        hrvgroup.get('summerEfficiency'),
        hrvgroup.get('winterEfficiency'),
      ];
      for (let field of fields) {
        inputs.outputValues.push(OutputValue.createFromField(field));
      }
    } else if (recoveryType == RecoveryType.ERV) {
      let ervGroup = [
        this.system.heatRecovery.ervGroup.get('summerTotalEfficiency'),
        this.system.heatRecovery.ervGroup.get('summerSensibleEfficiency'),
        this.system.heatRecovery.ervGroup.get('winterTotalEfficiency'),
        this.system.heatRecovery.ervGroup.get('winterSensibleEfficiency'),
      ];
      for (let field of ervGroup) {
        inputs.outputValues.push(OutputValue.createFromField(field));
      }
    } else if (recoveryType == RecoveryType.None) {
      // No additional fields
    } else {
      throw new Error("Unsupported recovery type: " + recoveryType)
    }

    let zoneList = {
      name: "Zone List",
      type: "Table",
      columns: [
        {name: "Zone Name"},
        {name: "Summer Indoor Temp"},
        {name: "Winter Indoor Temp"},
        {name: "Humidity Setpoint"},
      ],
      data: [],
    };
    this.system.forEachZone((zone) => {
      let zoneData = [
        zone.zone.name.value,
        OutputValue.createFromField(zone.zone.summerIndoorTemp),
        OutputValue.createFromField(zone.zone.winterIndoorTemp),
        OutputValue.createFromField(zone.zone.humiditySetpoint),
      ];
      zoneList.data.push({rowData: zoneData});
    });

    let spaceList = {
      name: "Space List",
      type: "Table",
      columns: [
        {name: "Space Name"},
        {name: "Quantity"},
      ],
      data: [],
    };
    this.system.forEachZone((zone) => {
      spaceList.data.push(zone.zone.name.value);
      for (let space of zone.zone.spaces) {
        let spaceData = [
          space.getSpaceType().name.value,
          OutputValue.createFromField(space.quantity),
        ];
        spaceList.data.push({rowData: spaceData});
      }
    });

    let summary = [
      environment,
      designTemps,
      designCoolingTemps,
      inputs,
      zoneList,
      spaceList,
    ];
    return summary;
  }

  getSpacesUsedInSystem() {
    let spaces = [];
    let spaceTypesVisited = {};
    this.system.forEachSpace((space) => {
      let spaceType = space.spaceType;
      if (spaceType.id in spaceTypesVisited) {
        // Already covered
        return;
      }
      spaceTypesVisited[spaceType.id] = true;
      spaces.push(space);
    });
    return spaces;
  }

  makeSpaceInputSummary() {
    let spaces = {
      name: "Spaces",
      type: "WideTable",
      columns: [
        {name: "Space Name"},
        {name: `Floor Area`},
        {name: "Occupancy"},
        {name: "V<sub>bz</sub>"},
        {name: "V<sub>oz</sub> (Cooling)"},
        {name: "V<sub>oz</sub> (Heating)"},
        {name: `Wall Area`},
        {name: `Roof Area`},
        {name: `Window Area`},
        {name: `Door Area`},
        {name: `Skylight Area`},
        {name: `# Appliances`},
        {name: `Winter Infiltration`},
        {name: `Miscellaneous Load`},
      ],
      data: [],
    };
    let spacesUsed = this.getSpacesUsedInSystem();
    for (const space of spacesUsed) {
      let spaceType = space.spaceType;
      let spaceVentilations = this.ctx.spaceVentilations.getValuesForSpaceType(spaceType);
      let spaceData = [
        spaceType.name.value,
        OutputValue.createFromField(spaceType.floorArea),
        OutputValue.createSimple("Occupancy", FieldType.Count, spaceType.getNumOccupants()),
        OutputValue.createSimple("V<sub>bz</sub>", FieldType.AirFlow, spaceVentilations.V_bz),
        OutputValue.createSimple("V<sub>oz</sub> (Cooling)", FieldType.AirFlow, spaceVentilations.V_oz_cooling),
        OutputValue.createSimple("V<sub>oz</sub> (Heating)", FieldType.AirFlow, spaceVentilations.V_oz_heating),
        OutputValue.createSimple("Wall Area", FieldType.Area, spaceType.getTotalWallArea(), {allowMin: true}),
        OutputValue.createSimple("Roof Area", FieldType.Area, spaceType.getTotalRoofArea(), {allowMin: true}),
        OutputValue.createSimple("Window Area", FieldType.Area, spaceType.getTotalWindowArea(), {allowMin: true}),
        OutputValue.createSimple("Door Area", FieldType.Area, spaceType.getTotalDoorArea(), {allowMin: true}),
        OutputValue.createSimple("Skylight Area", FieldType.Area, spaceType.getTotalSkylightArea(), {allowMin: true}),
        OutputValue.createSimple("# Appliances", FieldType.Count, spaceType.getNumAppliances()),
        OutputValue.createSimple("Winter Infiltration", FieldType.AirFlow, spaceType.getWinterInfiltration()),
        OutputValue.createSimple("Miscellaneous Load", FieldType.Load, spaceType.getMiscLoad()),
      ];
      spaces.data.push({rowData: spaceData});
    }

    let summary = [
      spaces,
    ]
    return summary;
  }

  makeOutputSummary(outputs, opts) {
    opts = valOr(opts, {});

    let general = {
      name: "General",
      type: "OutputValueList",
      outputValues: [
        OutputValue.createSimple("Project Name", FieldType.String, this.proj.getName()),
        OutputValue.createSimple("Calculated on", FieldType.String, this.ctx.startDateStr),
      ]
    };

    let systemType = {
      name: "System",
      type: "OutputValueList",
      outputValues: [
        OutputValue.createFromField(this.system.systemType),
      ]
    };

    let peakCoolingInfo = findMaxLoadInMatrix(outputs.system_cooling.q_cooling_total);
    let peakCoolingTime = peakCoolingInfo.time;
    let peakCoolingTimeStr = getMonthHourString(peakCoolingTime[0], peakCoolingTime[1]);
    let peakCoolingValue = peakCoolingInfo.value;
    let sensibleLoadAtPeak = outputs.system_cooling.q_cooling_sens.get(peakCoolingTime);
    let latentLoadAtPeak = outputs.system_cooling.q_cooling_lat.get(peakCoolingTime);
    let sensibleRatioAtPeak = sensibleLoadAtPeak / peakCoolingValue;

    let totalSensibleLoads = math.add(outputs.system_cooling.q_cooling_sens, outputs.system_heating.q_heating_sens);
    let peakSensibleLoadInfo = findMaxLoadInMatrix(totalSensibleLoads);
    let timeOfPeakSensibleLoadStr = getMonthHourString(peakSensibleLoadInfo.time[0], peakSensibleLoadInfo.time[1]);

    let peakLatentLoadInfo = findMaxLoadInMatrix(outputs.system_cooling.q_cooling_lat);
    let timeOfPeakLatentLoadStr = getMonthHourString(peakLatentLoadInfo.time[0], peakLatentLoadInfo.time[1]);

    let coilLoadSummary = {
      name: "Coil Load Summary",
      type: "OutputValueList",
      outputValues: [
        OutputValue.createSimple("Total Heating Load", FieldType.LoadMBH,
          Units.convertValue(outputs.system_heating.q_heating_sens, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Total Cooling Load", FieldType.LoadMBH,
          Units.convertValue(peakCoolingValue, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Sensible Load at Peak", FieldType.LoadMBH,
          Units.convertValue(sensibleLoadAtPeak, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Latent Load at Peak", FieldType.LoadMBH,
          Units.convertValue(latentLoadAtPeak, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Sensible Heat Ratio at Peak", FieldType.Ratio, sensibleRatioAtPeak),
        OutputValue.createSimple("Time of Peak Cooling Load", FieldType.String, peakCoolingTimeStr),
        OutputValue.createSimple("Peak Sensible Load", FieldType.LoadMBH,
          Units.convertValue(peakSensibleLoadInfo.value, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Time of Peak Sensible Load", FieldType.String, timeOfPeakSensibleLoadStr),
        OutputValue.createSimple("Peak Latent Load", FieldType.LoadMBH,
          Units.convertValue(peakLatentLoadInfo.value, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Time of Peak Latent Load", FieldType.String, timeOfPeakLatentLoadStr),
      ],
    };

    let totalHeatingAirflow = outputs.Q_supply_values.getSystemSupplyHeating();
    let peakCoolingAirflowInfo = outputs.Q_supply_values.findPeakSystemSupplyCooling();
    let peakCoolingAirflow = peakCoolingAirflowInfo.value;
    let airflowAndTemp = {
      name: "Airflow and Temp",
      type: "OutputValueList",
      outputValues: [
        OutputValue.createSimple("Total Heating Airflow", FieldType.AirFlow, totalHeatingAirflow),
        OutputValue.createSimple("Total Cooling Airflow", FieldType.AirFlow, peakCoolingAirflow),
        OutputValue.createSimple("Cooling supply temp", FieldType.Temperature,
          this.system.getCoolingSupplyTemp()),
        OutputValue.createSimple("Heating supply temp", FieldType.Temperature,
          this.system.getHeatingSupplyTemp()),
      ],
    };

    let maxOutdoorAirPercentValues = []
    if (this.system.usingSeparateAirflowsForHeatingCooling()) {
      maxOutdoorAirPercentValues = [
        OutputValue.createSimple("Maximum Outdoor Air % (Cooling)", FieldType.Percent,
          outputs.V_ot_values.getMaxOutdoorAirPercentCooling()),
        OutputValue.createSimple("Maximum Outdoor Air % (Heating)", FieldType.Percent,
          outputs.V_ot_values.getMaxOutdoorAirPercentHeating()),
      ]
    } else {
      maxOutdoorAirPercentValues = [
        OutputValue.createSimple("Maximum Outdoor Air %", FieldType.Percent,
          outputs.V_ot_values.getMaxOutdoorAirPercentCooling()),
      ]
    }
    // Note: we either calculate V_ot (and have these details), or the user gives some system override
    // for it.
    let outdoorAirDetails = []
    if (!this.system.overridesSystemVentilationIntake()) {
      let V_ou_cooling = outputs.V_ot_values.get_V_ou_cooling();
      let D_cooling = outputs.V_ot_values.get_D_cooling();
      let E_v_cooling = outputs.V_ot_values.get_E_v_cooling();
      outdoorAirDetails = [
        OutputValue.createSimple("V<sub>ou</sub>", FieldType.AirFlow, V_ou_cooling),
        OutputValue.createSimple("Diversity", FieldType.Ratio, D_cooling),
        OutputValue.createSimple("Ventilation Efficiency (E<sub>v</sub>)", FieldType.Ratio, E_v_cooling),
      ]
    }
    let V_ot_cooling_max = outputs.V_ot_values.get_V_ot_cooling_max();
    let outdoorAir = {
      name: "Outdoor Air",
      type: "OutputValueList",
      outputValues: [
        ...maxOutdoorAirPercentValues,
        ...outdoorAirDetails,
        OutputValue.createSimple("Maximum Outdoor Air Required (V<sub>ot</sub>)", FieldType.AirFlow, V_ot_cooling_max),
      ],
    };

    let zoneLoadBreakdown = {
      name: "Zone Load Breakdown",
      type: "Table",
      columns: [
        {name: "Zone"},
        {name: "Peak Cooling Load"},
        {name: "Time of Peak Cooling Load"},
        {name: "Cooling Load at System Cooling Peak"},
        {name: "Heating Load"},
        {name: "Zone Cooling Airflow"},
        {name: "Zone Heating Airflow"},
        //{name: "V<sub>pz,min</sub> (Cooling)"},
        //{name: "V<sub>pz,min</sub> (Heating)"},
      ],
      data: [],
    };
    this.system.forEachZone((zone) => {
      let zoneResult = this.zoneResults.getZoneResult(zone.zoneIndex);
      let zoneTotals = zoneResult.getZoneTotals();
      let zonePeakCoolingInfo = findMaxLoadInMatrix(zoneTotals.cooling.q_total);
      let zonePeakCoolingLoad = zonePeakCoolingInfo.value;
      let timeOfZonePeakCoolingLoad = getMonthHourString(
        zonePeakCoolingInfo.time[0], zonePeakCoolingInfo.time[1]);
      let zoneCoolingAtSystemCoolingPeak = zoneTotals.cooling.q_total.get(peakCoolingTime);
      let zoneHeatingLoad = zoneTotals.heating.q_total;
      let zoneCoolingAirflow = outputs.Q_supply_values.getZoneSupplyCoolingPeak(zone.zoneIndex);
      let zoneHeatingAirflow = outputs.Q_supply_values.getZoneSupplyHeating(zone.zoneIndex);

      let zoneData = [
        zone.zone.name.value,
        OutputValue.createSimple("Peak Cooling Load", FieldType.LoadMBH,
          Units.convertValue(zonePeakCoolingLoad, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Time of Peak Cooling Load", FieldType.String,
          timeOfZonePeakCoolingLoad),
        OutputValue.createSimple("Cooling Load at System Cooling Peak", FieldType.LoadMBH,
          Units.convertValue(zoneCoolingAtSystemCoolingPeak, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Heating Load", FieldType.LoadMBH,
          Units.convertValue(zoneHeatingLoad, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Zone Cooling Airflow", FieldType.AirFlow,
          zoneCoolingAirflow),
        OutputValue.createSimple("Zone Heating Airflow", FieldType.AirFlow,
          zoneHeatingAirflow),
        //OutputValue.createSimple("V<sub>pz,min</sub> (Cooling)", FieldType.AirFlow, 0),
        //OutputValue.createSimple("V<sub>pz,min</sub> (Heating)", FieldType.AirFlow, 0),
      ];
      zoneLoadBreakdown.data.push({rowData: zoneData});
    });

    let res = []
    if (valOr(opts.includeGeneralInfo, false)) {
      res.push(general);
    }
    res.push(systemType);
    res.push(coilLoadSummary);
    res.push(airflowAndTemp);
    res.push(outdoorAir);
    res.push(zoneLoadBreakdown);
    return res;
  }

  makeDetailedOutputBreakdown(outputs) { 
    let zoneResults = outputs.zoneResults;

    let zoneSections = []
    this.system.forEachZone((zone) => {
      let section = {
        name: `Zone ${zone.zoneIndex}`,
        type: "WideTable",
        columns: [
          {name: "Space"},
          {name: "Quantity"},
          {name: "Peak Cooling Load"},
          {name: "Sensible Load at Peak"},
          {name: "Latent Load at Peak"},
          {name: "Time of Peak Load"},
          {name: "Peak Heating Load"},
          {name: "Maximum Airflow (Cooling)"},
          {name: "Maximum Airflow (Heating)"},
          //{name: "V<sub>pz</sub>"},
        ],
        data: [
        ],
      }
      for (let spaceIndex = 0; spaceIndex < zone.zone.spaces.length; ++spaceIndex) {
        let zoneSpace = zone.zone.spaces[spaceIndex];
        let spaceType = zoneSpace.getSpaceType();
        let spaceLoadResults = zoneResults.getSpaceResult(zone.zoneIndex, spaceIndex).getLoadResults();

        let maxCoolingInfo = findMaxLoadInMatrix(spaceLoadResults.cooling.q_total);
        let peakCoolingLoad = maxCoolingInfo.value;
        let timeOfPeakCoolingLoad = getMonthHourString(maxCoolingInfo.time[0], maxCoolingInfo.time[1]);
        let sensibleLoadAtPeak = spaceLoadResults.cooling.q_sensible.get(maxCoolingInfo.time);
        let latentLoadAtPeak = spaceLoadResults.cooling.q_latent.get(maxCoolingInfo.time);
        let peakHeatingLoad = spaceLoadResults.heating.q_total;

        let maxAirflowCooling = outputs.Q_supply_values.getSpaceSupplyCoolingPeak(zone.zoneIndex, spaceIndex);
        let maxAirflowHeating = outputs.Q_supply_values.getSpaceSupplyHeating(zone.zoneIndex, spaceIndex);

        let spaceData = [
          spaceType.name.value,
          OutputValue.createFromField(zoneSpace.quantity),
          OutputValue.createSimple("Peak Cooling Load", FieldType.LoadMBH,
            Units.convertValue(peakCoolingLoad, Units.Load, Units.LoadMBH)),
          OutputValue.createSimple("Sensible Load at Peak", FieldType.LoadMBH,
            Units.convertValue(sensibleLoadAtPeak, Units.Load, Units.LoadMBH)),
          OutputValue.createSimple("Latent Load at Peak", FieldType.LoadMBH,
            Units.convertValue(latentLoadAtPeak, Units.Load, Units.LoadMBH)),
          OutputValue.createSimple("Time of Peak Load", FieldType.String, timeOfPeakCoolingLoad),
          OutputValue.createSimple("Peak Heating Load", FieldType.LoadMBH,
            Units.convertValue(peakHeatingLoad, Units.Load, Units.LoadMBH)),
          OutputValue.createSimple("Maximum Airflow (Cooling)", FieldType.AirFlow, maxAirflowCooling),
          OutputValue.createSimple("Maximum Airflow (Heating)", FieldType.AirFlow, maxAirflowHeating),
          // TODO - implement this
          //OutputValue.createSimple("V<sub>pz</sub>", FieldType.AirFlow, 0),
        ];
        section.data.push({rowData: spaceData});
      }
      zoneSections.push(section);
    });

    let spaceLoadsCoolingBreakdown = {
      name: "Space Load Cooling Breakdown at Space Peak",
      type: "WideTable",
      autosizeColumns: false,
      columns: [
        {name: "Space"},
        {name: "Time of Peak"},
        {name: "Walls (Sensible)"},
        {name: "Roofs (Sensible)"},
        {name: "Windows (Sensible)"},
        {name: "Skylights (Sensible)"},
        {name: "Doors (Sensible)"},
        {name: "Floors (Sensible)"},
        {name: "Partitions (Sensible)"},
        {name: "Appliances (Sensible)"},
        {name: "Lighting (Sensible)"},
        {name: "Motors (Sensible)"},
        {name: "People (Sensible)"},
        {name: "Miscellaneous (Sensible)"},
        {name: "Infiltration (Sensible)"},
        {name: "Appliances (Latent)"},
        {name: "People (Latent)"},
        {name: "Miscellaneous (Latent)"},
        {name: "Infiltration (Latent)"},
      ],
      data: []
    }
    
    let spacesUsed = this.getSpacesUsedInSystem();
    for (const space of spacesUsed) {
      let spaceResults = zoneResults.getZoneResult(space.zoneIndex).getSpaceResult(space.spaceIndex);
      console.log(`Space results (Zone ${space.zoneIndex}, Space ${space.spaceIndex})`, spaceResults);
      let spaceLoadResults = spaceResults.loadResults;
      let coolingResults = spaceLoadResults.cooling;
      let sensibleLoads = coolingResults.SensibleLoads;
      let latentLoads = coolingResults.LatentLoads;
      let peakInfo = findMaxLoadInMatrix(spaceLoadResults.cooling.q_total);
      let peakTime = peakInfo.time;
      let timeOfPeakString = getMonthHourString(peakTime[0], peakTime[1]);
      let spaceData = [
        space.spaceType.name.value,
        OutputValue.createSimple("Time of Peak", FieldType.String, timeOfPeakString),
        OutputValue.createSimple("Walls (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.wallLoads.SensibleLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Roofs (Sensible)", FieldType.LoadMBH, 
          Units.convertValue(sensibleLoads.roofLoads.SensibleLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Windows (Sensible)", FieldType.LoadMBH, 
          Units.convertValue(sensibleLoads.windowLoads.SensibleLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Skylights (Sensible)", FieldType.LoadMBH, 
          Units.convertValue(sensibleLoads.skylightLoads.SensibleLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Doors (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.doorLoads.SensibleLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Floors (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.floorLoads.SensibleLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Partitions (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.partitionLoads.SensibleLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Appliances (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.applianceLoads.SensibleLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Lighting (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.lightingLoads.SensibleLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Motors (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.motorLoads.SensibleLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("People (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.peopleLoads.SensibleLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Miscellaneous (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.miscLoads.SensibleLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Infiltration (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.infiltrationLoads.SensibleLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Appliances (Latent)", FieldType.LoadMBH,
          Units.convertValue(latentLoads.applianceLoads.LatentLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("People (Latent)", FieldType.LoadMBH,
          Units.convertValue(latentLoads.peopleLoads.LatentLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Miscellaneous (Latent)", FieldType.LoadMBH,
          Units.convertValue(latentLoads.miscLoads.LatentLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Infiltration (Latent)", FieldType.LoadMBH,
          Units.convertValue(latentLoads.infiltrationLoads.LatentLoadsMatrix.get(peakTime),
            Units.Load, Units.LoadMBH)),
      ];
      spaceLoadsCoolingBreakdown.data.push({rowData: spaceData});
    }

    let spaceLoadsHeatingBreakdown = {
      name: "Space Load Heating Breakdown",
      type: "WideTable",
      columns: [
        {name: "Space"},
        {name: "Walls (Sensible)"},
        {name: "Roofs (Sensible)"},
        {name: "Windows (Sensible)"},
        {name: "Skylights (Sensible)"},
        {name: "Doors (Sensible)"},
        {name: "Floors (Sensible)"},
        {name: "Partitions (Sensible)"},
        {name: "Infiltration (Sensible)"},
        {name: "Infiltration (Latent)"},
      ],
      data: [],
    }
    for (const space of spacesUsed) {
      let spaceResults = zoneResults.getZoneResult(space.zoneIndex).getSpaceResult(space.spaceIndex);
      //console.log(`Space results (Zone ${space.zoneIndex}, Space ${space.spaceIndex})`, spaceResults);
      let heatingResults = spaceResults.loadResults.heating;
      let sensibleLoads = heatingResults.SensibleLoads;
      let latentLoads = heatingResults.LatentLoads;
      let spaceData = [
        space.spaceType.name.value,
        OutputValue.createSimple("Walls (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.wallLoads.SensibleLoadValue, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Roofs (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.roofLoads.SensibleLoadValue, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Windows (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.windowLoads.SensibleLoadValue, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Skylights (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.skylightLoads.SensibleLoadValue, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Doors (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.doorLoads.SensibleLoadValue, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Floors (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.floorLoads.SensibleLoadValue, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Partitions (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.partitionLoads.SensibleLoadValue, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Infiltration (Sensible)", FieldType.LoadMBH,
          Units.convertValue(sensibleLoads.infiltrationLoads.SensibleLoadValue, Units.Load, Units.LoadMBH)),
        OutputValue.createSimple("Infiltration (Latent)", FieldType.LoadMBH,
          Units.convertValue(latentLoads.infiltrationLoads.LatentLoadValue, Units.Load, Units.LoadMBH)),
      ];
      spaceLoadsHeatingBreakdown.data.push({rowData: spaceData});
    }

    return [
      ...zoneSections,
      spaceLoadsCoolingBreakdown,
      spaceLoadsHeatingBreakdown,
    ]
  }

  async calcLoads() {
    let ctx = this.ctx
    SystemLoadCalculator.setupCalcContext(ctx, this.proj, this.system)

    let outputs = await this.calcAllOutputs()

    let systemInputSummary = this.makeSystemInputSummary()
    let spaceInputSummary = this.makeSpaceInputSummary()
    let outputSummary = this.makeOutputSummary(outputs)
    let detailedOutputBreakdown = this.makeDetailedOutputBreakdown(outputs);

    return {
      systemInputSummary: systemInputSummary,
      spaceInputSummary: spaceInputSummary,
      outputSummary: outputSummary,
      detailedOutputBreakdown: detailedOutputBreakdown,
    }
  }
}