<script setup>
import { ref, computed, watch, } from 'vue'
import { gApp, } from '../State.js'
import { System, SystemType, } from '../BuildingComponents/System.js'
import { Zone, ZoneSpace, } from '../BuildingComponents/Zone.js'
import { formatNum, removeElem, generateItemName, isDupName, } from '../SharedUtils.js'
import { Units, getLabel } from '../Common/Units.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import ConfirmModal from './ConfirmModal.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import HelpButton from './HelpButton.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import InputObject from './InputObject.vue'

import ComponentErrorsList from './utils/ComponentErrorsList.vue'
import WarningText from './utils/WarningText.vue'
import EditButton from './utils/EditButton.vue'
import DeleteButton from './utils/DeleteButton.vue'
import BackButton from './utils/BackButton.vue'

const props = defineProps({
})

let proj = gApp.proj();
let selectedSystem = ref(null);
let deleteModal = ref(null);

let systems = computed(() => {
  return proj.systems;
})

function selectSystem(system) {
  selectedSystem.value = system;
}

function deleteSystem(system) {
  deleteModal.value.showModal("Delete", `Delete ${system.name.value}?`, () => {
    removeElem(systems.value, system);
  });
}

function addSystem() {
  systems.value.push(System.create(generateItemName('System', proj.systems, 'TYPE_NAME-CTR'), true))
  selectSystem(systems.value[systems.value.length - 1]);
}

function addZone(system) {
  system.createZone();
}

</script>

<template>
  <div class="Container">
    <HelpSection id="SystemsView">
    </HelpSection>
    <div v-if="selectedSystem">
      <div class="mb-m">
        <BackButton @click="selectedSystem=null" />
        <div class="Flex">
          <h1 class="">{{ selectedSystem.name.value || 'Untitled' }}</h1>
          <!-- <button class="LinkBtn">Rename</button> -->
        </div>
      </div>
      <div class="mb-xl">
        <p class="mute-header Container">Info</p>
        <p class="help-text w-80">
          You are editing {{ selectedSystem.name.value }}. A system has some number of Zones, each with Spaces. Create new Spaces from the <b>Spaces</b> tab.
          When you are ready to calculate System loads, go to the <b>Overview</b> tab.
        </p>
      </div>
      <ComponentErrorsList :component="selectedSystem" />
      <SimpleCollapse title="General" class="Section Emphasis">
        <p class="help-text mb-m">Provide general info for the System.</p>
        <div class="mb-m">
          <FieldInput v-model="selectedSystem.name" class="MarginBotXS" />
          <WarnText v-if="isDupName(selectedSystem.name.value, systems)">There is already a system with that name. Please choose another.</WarnText>
        </div>
        <div class="mb-m">
          <p class="mute-header">Occupancy</p>
          <FieldInput v-model="selectedSystem.estimatedTotalOccupancy" />
        </div>
        <!--
        <div class="mb-m">
          <p class="mute-header">Winter Humidity</p>
          <FieldList :obj="selectedSystem" :fieldNames="selectedSystem.winterHumidityFields" />
        </div>
        -->
      </SimpleCollapse>
      <SimpleCollapse title="Zones" class="Section Emphasis">
        <p class="help-text mb-m">Describe the System's zones.</p>
        <div class="mb-m">
          <SimpleCollapse v-if="selectedSystem.zones.length > 0" v-for="(zone, i) in selectedSystem.zones" :title="zone.name.value"
            class="MarginBotXS">
            <div class="MarginBotS">
              <div class="mb-m">
                <FieldInput v-model="zone.name" />
              </div>
              <div class="mb-l">
                <div class="mb-xs">
                  <p class="mute-header">Spaces</p>
                  <p class="help-text">Add your Zone's Spaces here.</p>
                </div>
                <table v-if="zone.spaces.length > 0" class="SimpleTable MinorTable w-90 mb-xs">
                  <tr>
                    <th>Type</th>
                    <th>Quantity</th>
                    <th></th>
                  </tr>
                  <tr v-for="space in zone.spaces">
                    <td><FieldInput v-model="space.spaceType" :compact="true"/></td>
                    <td><FieldInput v-model="space.quantity" :compact="true"/></td>
                    <td><DeleteButton @click="removeElem(zone.spaces, space)" /></td>
                  </tr>
                </table>
                <div v-else>
                  <WarningText class="mb-s">You must add at least one Space to the Zone.</WarningText>
                </div>
                <button @click="zone.createSpace()" class="small-upper CreateBtn AddBtn">Add Space</button>
              </div>
              <p class="mute-header">Temps</p>
              <FieldList :labelWidth="300" :obj="zone" :fieldNames="zone.tempFields" class="" />
            </div>
            <DeleteButton name="Delete Zone" @click="removeElem(selectedSystem.zones, zone)" />
          </SimpleCollapse>
          <div v-else>
            <WarningText class="mb-s">You must add at least one Zone to the System.</WarningText>
          </div>
        </div>
        <button @click="addZone(selectedSystem)" class="MarginBotXS CreateBtn">Add Zone</button>
      </SimpleCollapse>
      <SimpleCollapse title="Ventilation" class="Section Emphasis">
        <p class="help-text mb-m">Describe the System's ventilation.</p>
        <div class="mb-m">
          <p class="mute-header">System Ventilation Intake</p>
          <div class="mb-s">
            <FieldInput v-model="selectedSystem.overrideSystemVentilationIntake" />
            <FieldInput v-model="selectedSystem.minSystemVentilationIntake" />
            <FieldInput v-model="selectedSystem.maxSystemVentilationIntake" />
          </div>
        </div>
        <div class="mb-m">
          <p class="mute-header">Ventilation Modes</p>
          <div class="mb-s">
            <FieldInput v-model="selectedSystem.changeVentilationForModes" />
          </div>
        </div>
        <div class="mb-m">
          <p class="mute-header">Demand Controlled Ventilation</p>
          <FieldGroup v-model="selectedSystem.demandControlledVentilation" />
        </div>
        <div class="mb-m">
          <p class="mute-header">Heat Recovery</p>
          <div class="mb-m">
            <FieldInput v-model="selectedSystem.heatRecovery.recoveryType" />
          </div>
          <FieldGroup class="mb-m" v-model="selectedSystem.heatRecovery.exhaustAir" />
          <FieldGroup v-model="selectedSystem.heatRecovery.hrvGroup" />
          <FieldGroup v-model="selectedSystem.heatRecovery.ervGroup" />
        </div>
        <div class="mb-m">
          <p class="mute-header">Economizer</p>
          <FieldGroup v-model="selectedSystem.economizer" />
        </div>
      </SimpleCollapse>
      <SimpleCollapse title="Air Handler Fans" class="Section Emphasis">
        <p class="help-text mb-m">Describe the System's fans.</p>
        <ComponentErrorsList :component="selectedSystem.systemFans" />
        <div>
          <div class="mb-m">
            <p class="mute-header">Supply Fan</p>
            <div class="mb-s">
              <FieldInput :labelWidth="300" v-model="selectedSystem.systemFans.useSupplyFan" />
            </div>
            <FieldList v-if="selectedSystem.systemFans.usingSupplyFan()" :labelWidth="300" :obj="selectedSystem.systemFans.supplyFan" :fieldNames="selectedSystem.systemFans.supplyFan.fields" />
          </div>
          <div class="mb-m">
            <p class="mute-header">Return Fan</p>
            <div class="mb-s">
              <FieldInput :labelWidth="300" v-model="selectedSystem.systemFans.useReturnFan" />
            </div>
            <FieldList v-if="selectedSystem.systemFans.usingReturnFan()" :labelWidth="300" :obj="selectedSystem.systemFans.returnFan" :fieldNames="selectedSystem.systemFans.returnFan.fields" />
          </div>
        </div>
      </SimpleCollapse>
      <SimpleCollapse title="System Details" class="Section Emphasis">
          <p class="help-text mb-m">Provide system details.</p>
          <div class="mb-l">
            <p class="mute-header">System Type</p>
            <FieldList :labelWidth="300" :obj="selectedSystem" :fieldNames="selectedSystem.detailFields" />
          </div>
          <div>
            <p class="mute-header">{{ selectedSystem.getSystemName() }} System Details</p>
            <p class="help-text mb-s">Provide details specific to the {{selectedSystem.getSystemName()}} system.</p>
            <div v-if="selectedSystem.systemType.value == SystemType.CAV">
              <InputObject :labelWidth="300" v-model="selectedSystem.cavSystemInputs" />
            </div>
            <div v-if="selectedSystem.systemType.value == SystemType.VAV">
              <InputObject :labelWidth="300" v-model="selectedSystem.vavSystemInputs" />
            </div>
          </div>
      </SimpleCollapse>
    </div>
    <div v-else>
      <h1 class="PageH1">Systems</h1>
      <div class="mb-l">
        <p class="mute-header Container">Info</p>
        <p class="help-text w-80">
          Add your building's Systems here. Each System has a list of Zones, which contain Spaces. Heating and cooling loads will be calculated for each System.
        </p>
      </div>
      <button @click="addSystem()" class="MarginBotXS CreateBtn">Add System</button>
      <table class="SimpleTable SystemsTable" v-if="systems.length">
        <colgroup>
          <col style="width: 300px;">
          <col>
          <!-- other columns -->
        </colgroup>
        <tr>
          <th>Name</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="system in systems" class="WallSection MarginBotL">
          <td>{{ system.name.value }}</td>
          <td>
            <EditButton @click="selectSystem(system)" />
          </td>
          <td>
            <DeleteButton @click="deleteSystem(system)" />
          </td>
        </tr>
      </table>
    </div>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<style scoped>
.Result {
  color: blue;
}

.Section {
  margin-bottom: var(--space-xs);
}

</style>
