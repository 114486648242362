<script setup>
import { ref } from 'vue'
import { gApp, } from '../Globals.js'
import { ExteriorShadingType } from '../Components/ExteriorShadingType.js'
import { formatNum, removeElem, generateItemName, isDupName, } from '../SharedUtils.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import ConfirmModal from './ConfirmModal.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'

import DeleteButton from './utils/DeleteButton.vue'
import EditButton from './utils/EditButton.vue'
import BackButton from './utils/BackButton.vue'

import { GetSpacesTabName } from '../Common/UIStrings.js'

let proj = gApp.proj();
let shadings = proj.exteriorShadingTypes;
let shading = ref(null);
let deleteModal = ref(null);

function selectShading(newShading) {
  shading.value = newShading;
}

function deleteShading(someShading) {
  deleteModal.value.showModal("Delete", `Delete shading type ${someShading.name.value}?`, () => {
    removeElem(shadings, someShading);
  });
}

function addShading() {
  shadings.push(ExteriorShadingType.create(generateItemName('ExShading', shadings), true))
  selectShading(shadings[shadings.length - 1]);
}

</script>

<template>
  <div>
    <HelpSection id="ExteriorShadingsView">
      <h4 class="mb-s">Exterior Shading Types</h4>
      <p class="mb-s">
        Use this page to build different exterior shading types for use in the Build House tab.
      </p>
      <p>
        External shadings affect solar heat gains on windows and doors. They will affect the estimate of how much glass is exposed to solar rays at any given hour. 
        <br><br>
        For doors that are not entirely glass, the percentage of the door that is shaded, based on its dimensions and the external shading dimensions, will be applied to the glass portion of the door for solar heat gain calculations. If the shading is unknown or is questionable, it is recommended to not include external shading when only a small portion of the door is glass.
        <br><br>
          <b>Depth</b> refers to the distance that the shading device sticks out from the building in the direction perpendicular to the wall.
        <br><br>
        <img src="../assets/external-shading.png" class="HelpImg">
      </p>
    </HelpSection>
    <div v-if="shading">

      <div class="mb-m">
        <BackButton @click="shading=null" />
        <h1>{{ shading.name.value || 'Untitled' }}</h1>
      </div>
      <FieldInput v-model="shading.name" />
      <WarnText v-if="isDupName(shading.name.value, shadings)">There is already a shading with that name. Please choose another.</WarnText>
      <InputGroup title="Horizontal Fin">
        <p class="help-text mb-xs">Set values to 0 if no horizontal fin.</p>
        <FieldList :obj="shading" :fieldNames="shading.horizontalFinFields" :labelWidth="275" />
      </InputGroup>
      <InputGroup title="Left Fin">
        <p class="help-text mb-xs">Set values to 0 if no left fin.</p>
        <FieldList :obj="shading" :fieldNames="shading.leftFinFields" :labelWidth="275" />
      </InputGroup>
      <InputGroup title="Right Fin">
        <p class="help-text mb-xs">Set values to 0 if no right fin.</p>
        <FieldList :obj="shading" :fieldNames="shading.rightFinFields" :labelWidth="275" />
      </InputGroup>
    </div>
    <div v-else>
      <h1 class="PageH1">Exterior Shadings</h1>
      <div class="mb-l Container">
        <p class="mute-header">Info</p>
        <p class="help-text w-80">
          Add an <b>Exterior Shading Type</b> for each different type of exterior shading used in your project. 
          These will be referenced from the Walls section of the {{ GetSpacesTabName() }} tab, where you can apply
          them to windows.
        </p>
      </div>
      <button @click="addShading" class="MarginBotXS CreateBtn">Add shading type</button>
      <table class="SimpleTable" v-if="shadings.length">
        <tr>
          <th>Name</th>
          <th>Horizontal Depth</th>
          <th>Horizontal Dist. from parallel edge</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="(shading, index) in shadings" class="WindowSection MarginBotL">
          <td>{{ shading.name.value }}</td>
          <td>{{ shading.horizontalFinDepth.getValueStr() }}</td>
          <td>{{ shading.horizontalFinDist.getValueStr() }}</td>
          <td><EditButton @click="selectShading(shading)" /></td>
          <td><DeleteButton @click="deleteShading(shading)" /></td>
        </tr>
      </table>
    </div>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<style scoped>
</style>
