import { Field } from './Field.js';
import { makeEnum } from '../Base.js';
import { valOr, GraphNode, DFSHelper, applyEllipse, } from '../SharedUtils.js';

export class FieldWithVariableUnits extends Field {
  constructor(data) {
    super(data);

    this.unitOptions = data.unitOptions;
    this.serFields = [
      ...this.serFields,
      'units',
    ];
  }
}

export class MultiTieredSelect extends Field {
  constructor(data) {
    data.type = 'MultiTieredSelect';
    data.value = valOr(data.value, []);
    super(data);
    this.allowEmpty = valOr(data.allowEmpty, false);
    this.emptyText = valOr(data.emptyText, 'None');

    this.optionsMap = data.optionsMap;
  }

  postReadFromJson(data) {
    // This is required to fixup some bad data from older versions
    if (typeof this.value === 'string') {
      this.value = []
    }
  }

  getOtherObjErrors() {
    let errors = []
    if (!this.allowEmpty && this.value.length === 0) {
      errors.push('Must choose a value.');
    } else if (!this.hasValidPath()){
      errors.push('Invalid item selected. Please change.');
    }
    return errors;
  }

  isEmpty() {
    return this.value.length === 0;
  }

  getShortValue() {
    if (!this.value.length) {
      return this.emptyText;
    }
    let labels = this.getLabels();
    return applyEllipse(labels[labels.length - 1], 35);
  }

  getLabels() {
    if (!this.hasValidPath()) {
      return []
    }
    let labels = []
    let curDict = this.optionsMap;
    for (let i = 0; i < this.value.length; i++) {
      let part = this.value[i]
      let enumType = curDict.type || curDict;
      labels.push(enumType._labels[part]);
      if (i + 1 < this.value.length) {
        curDict = curDict.children[part];
      }
    }
    return labels;
  }

  getLastLabel() {
    return this.getLabels()[this.getLabels().length - 1];
  }

  lookupPath(path) {
    if (path.length === 0) {
      return {
        isLeaf: false,
        value: this.optionsMap,
      };
    }
    let curObj = this.optionsMap;
    let isLeaf = false;
    for (let i = 0; i < path.length; i++) {
      let part = path[i];
      if ('children' in curObj) {
        if (!(part in curObj.children)) {
          return null;
        }
        curObj = curObj.children[part];
        //console.log(`Found part ${part} - `, curObj);
      } else {
        // Leaf node, curObj is an enum
        if (i == path.length - 1) {
          curObj = curObj[part];
          isLeaf = true;
          //console.log(`Found leaf part ${part} - `, curObj);
        } else {
          // We hit a leaf too early
          return null;
        }
      }
    }
    return {
      isLeaf: isLeaf,
      value: curObj,
    }
  }

  hasValidPath() {
    if (this.value.length === 0) {
      return this.allowEmpty;
    }
    let pathValue = this.lookupPath(this.value);
    return pathValue !== null && pathValue.isLeaf;
  }


  getOptionsForPath(path) {
    //console.log('Looking up path:', path)
    let pathValue = this.lookupPath(path);
    if (pathValue === null) {
      throw new Error(`Invalid path: ${path.join('/')} (for ${this.name})`);
    }
    if (pathValue.isLeaf) {
      //console.log("Got leaf path: ", pathValue.value);
      return null;
    }
    //console.log(`Got options for path: ${path.join('/')}`, pathValue.value);
    return pathValue.value;
  }

  isLeafPath(path) {
    let pathValue = this.lookupPath(path);
    return pathValue !== null && pathValue.isLeaf;
  }

  /*
  Helper for making an options map from table data
  */
  static makeOptionsMapFromTableRows(rowsData, colNames) {
    // Create a list of paths
    let paths = []
    for (const row of rowsData) {
      let path = []
      for (const colName of colNames) {
        path.push(row[colName])
      }
      paths.push(path)
    }
    //console.log("Paths: ", paths);

    let optionsTree = GraphNode.makeTreeFromPaths(paths);
    //console.log("Options tree: ", optionsTree);

    // Make the options map by DFS traversal of the tree
    let dfsHelper = new DFSHelper();
    let optionsMap = dfsHelper.doDFS(optionsTree, (node, resultsMap) => {
      //console.log("Visiting: ", node.id);
      if (node.isLeaf()) {
        return;
      }

      let result = null
      let isLeafEnum = node.children[0].isLeaf();
      if (isLeafEnum) {
        // This node is a leaf enum (children are entries)
        let enumData = {}
        for (const childNode of node.children) {
          enumData[childNode.id] = childNode.data.label;
        }
        result = makeEnum(enumData);
      } else {
        // This node is a parent enum (children are enums)
        let parentEnumData = {}
        let childMap = {}
        for (const childNode of node.children) {
          parentEnumData[childNode.id] = childNode.data.label;
          childMap[childNode.id] = resultsMap[childNode.id];
        }
        result = {
          type: makeEnum(parentEnumData),
          children: childMap,
        }
      }
      return result;
    });
    //console.log("Options map: ", optionsMap);

    return optionsMap;
  }
}