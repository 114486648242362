<script setup>
import { ref } from 'vue'
import {
  gApp,
} from '../Globals.js'
import { SkylightType, SkylightStyle, SkylightInputType, } from '../Components/SkylightType.js'
import { formatNum, removeElem, generateItemName, isDupName, } from '../SharedUtils.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import ConfirmModal from './ConfirmModal.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import ComponentErrorsList from './utils/ComponentErrorsList.vue'

import EditButton from './utils/EditButton.vue'
import DeleteButton from './utils/DeleteButton.vue'
import BackButton from './utils/BackButton.vue'
import { GetTypeHelpString, } from '../Common/UIStrings.js'

let proj = gApp.proj();
let skylights = proj.skylightTypes;
let selectedSkylight = ref(null);
let deleteModal = ref(null);

function selectSkylight(skylight) {
  selectedSkylight.value = skylight;
}

function deleteSkylight(skylight) {
  deleteModal.value.showModal('Delete', `Delete skylight type ${skylight.name.value}?`, () => {
    removeElem(skylights, skylight)
  })
}

function addSkylight() {
  skylights.push(SkylightType.create(generateItemName('Skylight', skylights), true))
  selectSkylight(skylights[skylights.length - 1]);
}

</script>

<template>
  <div>
    <HelpSection id="SkylightsView">
      <h4 class="mb-s">Skylight Types</h4>
      <p class="mb-s">
        Use this page to build different skylight types for use in the Build House tab.
      </p>
      <p class="mb-s">
        The <b>curb height</b> input refers to the height dimension shown in the image below:
        <br>
        <img src="../assets/skylight-frame.png" class="HelpImg">
      </p>
      If this height is negligible (less than 1 inch) then it should be ignored.
      <br><br>
        If <b>Manual Entry</b> is selected for the skylight type, the <b>U-value</b> and <b>SHGC</b> and must be entered for the entire fenestration product (i.e. including the frame). SHGC entry must be the direct beam SHGC value, as reported by the National Fenestration Rating Council (NFRC). Since the NFRC is a North American standard, users outside North America will need to convert from their regional standard to NFRC ratings for SHGC to get accurate results. 
      <br><br>
      With the SHGC input for direct beam, SHGC values for other angles will be estimated based on the window type.
      <br><br>
        For building a skylight product, the following image shows what a typical <b>domed skylight</b> looks like. If your skylight is flat, it is not considered a domed skylight.
      <br><br>
      <img src="../assets/domed-skylight.png" class="HelpImg">
      <br>
      The image below shows what a typical <b>tubular daylighting device (TDD)</b> looks like. If your device does not resemble the device below, then a TDD should not be selected as the skylight type.
      <br>
      <img src="../assets/tubular-skylight.png" class="HelpImg">
      <br>
      Use the available dropdown menus to select the best-fitting description of your skylight. 
      <br><br>
      For TDD type skylights, if the approximate location of the roof insulation is unknown, a conservative estimate would be to select “Below Roof”. Note that locating the insulation directly above the ceiling will improve both heating and cooling loads. Also note that only single-pane glazing on the diffuser will result in poor performance for both heating and cooling loads.
      <br><br>
      For ordinary skylights, for selecting the panes that the low-e coatings are applied to, refer to the diagram below:
      <br>
      <img src="../assets/win-panes.png" class="HelpImg">
      For three panes, the pattern continues, where surface 6 would be the surface immediately adjacent to the indoors.
      <br><br>
      <b>Frame Width</b> is the thickness of the frame as seen from the top view on the outside of the building; it is not related to the thickness of the roof that the skylight is installed in.
      <br>
      <img src="../assets/win-frame.png" class="HelpImg">
    </HelpSection>
    <div v-if="selectedSkylight">
      <div class="mb-m">
        <BackButton @click="selectedSkylight=null" />
        <h1>{{ selectedSkylight.name.value || 'Untitled' }}</h1>
      </div>
      <ComponentErrorsList :component="selectedSkylight" />
      <FieldInput v-model="selectedSkylight.name" />
      <WarnText v-if="isDupName(selectedSkylight.name.value, skylights)">There is already a skylight with that name. Please choose another.</WarnText>
      <InputGroup title="General">
        <FieldInput v-model="selectedSkylight.unusualShape" />
        <FieldInput v-model="selectedSkylight.height" />
        <FieldInput v-model="selectedSkylight.width" />
        <FieldInput v-model="selectedSkylight.unusualShapeArea" />
        <FieldInput v-model="selectedSkylight.unusualShapePerimeter" />
        <FieldInput v-model="selectedSkylight.curbHeight" />
        <FieldInput v-model="selectedSkylight.style" />
        <FieldInput v-model="selectedSkylight.installationSealing" />
      </InputGroup>
      <InputGroup title="U-Value & SHGC">
        <FieldInput v-model="selectedSkylight.inputType" />
        <div class="mt-m">
          <div v-if="selectedSkylight.inputType.value == SkylightInputType.Manual">
            <FieldList :obj="selectedSkylight" :fieldNames="selectedSkylight.manualInputFields" />
          </div>
          <div v-else class="Builder">
            <div class="mb-s">
              <p class="mute-header">Build skylight ({{SkylightStyle._labels[selectedSkylight.style.value]}})</p>
              <p class="help-text">The U-Value and SHGC will be calculated from the given inputs.</p>
            </div>
            <div v-if="selectedSkylight.style.value == SkylightStyle.DomedSkylight">
              <FieldGroup v-model="selectedSkylight.domedSkylightInputs" />
            </div>
            <div v-else-if="selectedSkylight.style.value == SkylightStyle.TubularDaylightingDevice">
              <FieldGroup v-model="selectedSkylight.tubularSkylightInputs" />
            </div>
            <div v-else>
              <FieldList :obj="selectedSkylight" :fieldNames="selectedSkylight.builderFields" />
            </div>
          </div>
        </div>
      </InputGroup>
      <InputGroup title="Results">
        <FieldInput v-model="selectedSkylight.outputUValue"/>
        <FieldInput v-model="selectedSkylight.outputShgc"/>
      </InputGroup>
    </div>
    <div v-else>
      <!-- <h1 class="PageH1">Skylight Types<i class="bi-brightness-high-fill MRXS"></i></h1> -->
      <h1 class="PageH1">Skylight Types</h1>
      <div class="mb-l Container">
        <p class="mute-header">Info</p>
        <p class="help-text w-80">
          Add a <b>Skylight Type</b> for each different type of skylight used in your project.
          {{ GetTypeHelpString('Roofs') }}
        </p>
      </div>
      <button @click="addSkylight" class="MarginBotXS CreateBtn">Add skylight type</button>
      <table class="SimpleTable" v-if="skylights.length">
        <tr>
          <th>Name</th>
          <th>Style</th>
          <th>Height</th>
          <th>Width</th>
          <th>U-Value</th>
          <th>SHGC (0 Deg)</th>
          <th>SHGC (Diff.)</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="(skylight, index) in skylights" class="WindowSection MarginBotL">
          <td>{{ skylight.name.value }}</td>
          <td>{{ SkylightStyle._labels[skylight.style.value] }}</td>
          <td>{{ skylight.height.getValueStr() }}</td>
          <td>{{ skylight.width.getValueStr() }}</td>
          <td>{{ skylight.outputUValue.getValueStr() }}</td>
          <td>{{ formatNum(skylight.computeShgc().Deg0) }}</td>
          <td>{{ formatNum(skylight.computeShgc().Diffuse) }}</td>
          <td><EditButton @click="selectSkylight(skylight)" /></td>
          <td><DeleteButton @click="deleteSkylight(skylight)" /></td>
        </tr>
      </table>
    </div>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<style scoped>

</style>
