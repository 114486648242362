<script setup>
import { ref, onMounted, reactive, computed, watchEffect } from 'vue'
import CalcLogItemV2 from './CalcLogItemV2.vue';
import { prettyJson } from '../SharedUtils';

const props = defineProps({
  ctx: Object
})

let logDict = ref(null);
let isLoading = ref(false);

async function loadLog() {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;
  logDict.value = await props.ctx.getStructuredLogDict();
  isLoading.value = false;
}

</script>

<template>
  <div class="LogText">
    <div v-if="!logDict">
      <div v-if="isLoading">Loading log... (approx time: 20s)</div>
      <button v-else @click="loadLog" class="CreateBtn">Load calculation log</button>
    </div>
    <CalcLogItemV2 v-else :item="logDict" />
  </div>
</template>

<style scoped>
.LogText {
  white-space: pre;
  font-family: monospace;
  font-size: 16px;
  line-height: 1.25;
}
</style>
