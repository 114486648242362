import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, PleaseContactStr,
  IntervalTimer,
} from '../Base.js'
import { InputComponent } from '../Common/InputComponent.js'
import { WeatherDataParser } from './WeatherDataParser.js'

import { 
  getMonthName,
} from '../SharedUtils.js'
import { IsTestEnv } from '../Globals.js'
export { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
} from '../Common/Field.js'

export class LocationData extends InputComponent {
  init() {
    this.locPath = null;
    this.locData = null;

    this.timezone = new Field({
      name: 'Time zone',
      type: FieldType.Count,
      min: -16,
    })
    this.latitude = new Field({
      name: 'Latitude',
      type: FieldType.Angle,
      min: -90,
      max: 90,
    })
    this.longitude = new Field({
      name: 'Longitude',
      type: FieldType.Angle,
      min: -180,
      max: 180,
    })
    this.elevation = new Field({
      name: 'Elevation',
      type: FieldType.Length,
      min: -1e10,
    })
    this.stdPressure = new Field({
      name: 'Pressure',
      type: FieldType.Pressure,
    })
    this.heating99p6PerDryBulb = new Field({
      name: 'Heating - 99.6% DB',
      type: FieldType.Temperature,
    })
    this.heating99PerDryBulb = new Field({
      name: 'Heating - 99% DB',
      type: FieldType.Temperature,
    })
    this.cooling0p4PerDryBulb = new Field({
      name: 'Cooling - 0.4% DB',
      type: FieldType.Temperature,
    })
    this.cooling0p4PerDryBulbMCWB = new Field({
      name: 'Cooling - 0.4% DB MCWB',
      type: FieldType.Temperature,
    })
    this.cooling1PerDryBulb = new Field({
      name: 'Cooling - 1% DB',
      type: FieldType.Temperature,
    })
    this.cooling1PerDryBulbMCWB = new Field({
      name: 'Cooling - 1% DB MCWB',
      type: FieldType.Temperature,
    })
    this.cooling2PerDryBulb = new Field({
      name: 'Cooling - 2% DB',
      type: FieldType.Temperature,
    })
    this.cooling2PerDryBulbMCWB = new Field({
      name: 'Cooling - 2% DB MCWB',
      type: FieldType.Temperature,
    })
    this.cooling0p4PerWetBulb = new Field({
      name: 'Cooling - 0.4% WB',
      type: FieldType.Temperature,
    })
    this.cooling0p4PerWetBulbMCDB = new Field({
      name: 'Cooling - 0.4% WB MCDB',
      type: FieldType.Temperature,
    })
    this.avgAnnualTemp = new Field({
      name: 'Average Annual Dry Bulb',
      type: FieldType.Temperature,
    })
    this.meanDailyDryBulbRange = new Field({
      name: 'Mean Daily Dry Bulb Range',
      type: FieldType.Temperature,
    })
    this.Tau_b = new Field({
      name: 'Solar Irradiance (Tau b)',
      type: FieldType.Count,
    })
    this.Tau_d = new Field({
      name: 'Solar Irradiance (Tau d)',
      type: FieldType.Count,
    })
    this.k_soil = new Field({
      name: 'Soil Conductivity',
      type: FieldType.SoilConductivity,
      // This is the standard value
      value: 0.8,
    })

    this.fields = [
      'timezone',
      'latitude',
      'longitude',
      'elevation',
      'stdPressure',

      'heating99p6PerDryBulb',
      'heating99PerDryBulb',
      'cooling0p4PerDryBulb',
      'cooling0p4PerDryBulbMCWB',
      'cooling1PerDryBulb',
      'cooling1PerDryBulbMCWB',
      'cooling2PerDryBulb',
      'cooling2PerDryBulbMCWB',
      'cooling0p4PerWetBulb',
      'cooling0p4PerWetBulbMCDB',

      'avgAnnualTemp',
      'meanDailyDryBulbRange',
      'Tau_b',
      'Tau_d',
      'k_soil',
    ]
    for (const fieldName of this.fields) {
      // Add aux data to track defaults
      let field = this[fieldName];
      field.data = {
        defaultValue: field.value,
        useDefault: true,
      }
    }

    this.updater.addWatchEffect('loc-data', () => {
      if (!this.locData) {
        return;
      }
      let hottestMonth = this.locData.Cooling['Hottest Month'];
      let monthName = getMonthName(hottestMonth - 1);

      this.timezone.data.defaultValue = this.locData['Location']['Time Zone']
      this.latitude.data.defaultValue = this.locData['Location']['Latitude']
      this.longitude.data.defaultValue = this.locData['Location']['Longitude']
      this.elevation.data.defaultValue = this.locData['Location']['Elevation']
      this.stdPressure.data.defaultValue = this.locData['Location']['Std. Pres']
      
      this.heating99p6PerDryBulb.data.defaultValue = this.locData['Heating']['99.6 Dry Bulb']
      this.heating99PerDryBulb.data.defaultValue = this.locData['Heating']['99% Dry Bulb']

      this.cooling0p4PerDryBulb.data.defaultValue = this.locData['Cooling']['0.4% Dry Bulb']
      this.cooling0p4PerDryBulbMCWB.data.defaultValue = this.locData['Cooling']['0.4% Dry Bulb MCWB']
      this.cooling1PerDryBulb.data.defaultValue = this.locData['Cooling']['1% Dry Bulb']
      this.cooling1PerDryBulbMCWB.data.defaultValue = this.locData['Cooling']['1% Dry Bulb MCWB']
      this.cooling2PerDryBulb.data.defaultValue = this.locData['Cooling']['2% Dry Bulb']
      this.cooling2PerDryBulbMCWB.data.defaultValue = this.locData['Cooling']['2% Dry Bulb MCWB']
      this.cooling0p4PerWetBulb.data.defaultValue = this.locData['Cooling']['0.4% Wet Bulb']
      this.cooling0p4PerWetBulbMCDB.data.defaultValue = this.locData['Cooling']['0.4% Wet Bulb MCDB']

      this.avgAnnualTemp.data.defaultValue = this.locData.Temperatures['Average Dry Bulb']['Annual'];
      this.meanDailyDryBulbRange.data.defaultValue = this.locData.MeanDailyTempRange['Mean Dry Bulb Range'][monthName];
      this.Tau_b.data.defaultValue = this.locData.SolarIrradiance['Tau,b'][monthName];
      this.Tau_d.data.defaultValue = this.locData.SolarIrradiance['Tau,d'][monthName];
    })

    // Note: we serialize the locData so that it is ready on load (and also transfers
    // to workers to run space calculations). However, we re-read it on project read,
    // in case it has changed.
    this.serFields = [
      'locPath',
      'locData',
      ...this.fields,
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Location Info',
    }
  }

  isLocationSet() {
    return this.locData !== null;
  }

  async setLocation(locPath) {
    if (!locPath) {
      return;
    }
    try {
      console.log("Reloading location data!: ", locPath);
      this.locPath = locPath
      await this.reloadData();
      this.unsetErrorWithId('location-data-load-error');
    } catch (err) {
      this.setErrorWithId('location-data-load-error',
        `The location data for ${locPath.path.join('/')} failed to load. There may be a problem with the data. Error: ${err.message}`);
      throw err;
    }
  }

  async reloadData() {
    if (!this.locPath) {
      this.locData = null;
      return;
    }
    console.log("Reloading weather data CSV. LocPath: ", this.locPath);
    let filePath = 'WeatherData/' + this.locPath.path.join("/") + ".csv"
    console.log("FilePath: " + filePath)
    let res = await fetch(filePath);
    let text = await res.text();
    this.locData = WeatherDataParser.parseWeatherDataCSV(text);
  }

  getObjErrors() {
    let errors = [];
    this.updater.addErrors(errors);
    if (!this.isLocationSet()) {
      errors.push('Please set a location.');
    }
    return errors;
  }

  getOutputs() {
    if (!this.isLocationSet()) {
      return null;
    }
    let hottestMonth = this.locData.Cooling['Hottest Month'];
    let outputs = {
      locationName: this.locPath.fullName,
      hottestMonth,
      dryBulbDesignTempsByMonth: this.locData.dryBulbDesignTempsByMonth,
      wetBulbDesignTempsByMonth: this.locData.wetBulbDesignTempsByMonth,
      dryBulbRangeByMonth: this.locData.dryBulbRangeByMonth,
      wetBulbRangeByMonth: this.locData.wetBulbRangeByMonth,
      tauBValuesByMonth: this.locData.tauBValuesByMonth,
      tauDValuesByMonth: this.locData.tauDValuesByMonth,
    }
    for (const fieldName of this.fields) {
      let field = this[fieldName];
      outputs[fieldName] = field.data.useDefault ? field.data.defaultValue : field.value;
    }
    return outputs;
  }
};
setupClass(LocationData)