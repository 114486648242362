import * as ser from '../Common/SerUtil.js'
import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, PleaseContactStr,
  IntervalTimer,
} from '../Base.js'
import { WindowsData, } from '../MaterialData/Windows/WindowsData.js' 
import { CalcContext } from '../Common/CalcContext.js'

import { Project, ProjectTypes, } from '../Project.js'

export { Units } from '../Common/Units.js'

import {
  FieldType,
  kFieldTypesData,
  FieldInputType,
  Field,
  FieldGroup,
  ProjectUnits,
} from '../Common/Field.js'
export * from '../Common/Field.js'

import { DoorType } from './DoorType.js'
import { ExteriorShadingType } from './ExteriorShadingType.js'
import { InteriorShadingType } from './InteriorShadingType.js'
import { WindowType } from './WindowType.js'
import { WallType, RoofType } from './WallType.js'
import { SkylightType } from './SkylightType.js'
import { BufferSpaceType } from './BufferSpaceType.js'
import { HouseDesignTempInputs } from './HouseDesignTemps.js'
import { HouseToplevelData } from './HouseToplevelData.js'
import { HouseMiscDetails } from './HouseMiscDetails.js'
import { HouseInternalsData } from './HouseInternalsData.js'
import { Wall } from './Wall.js'
import { Roof } from './Roof.js'
import { Partition } from './Partition.js'
import { Floor } from './Floor.js'
import { HouseVentilationInfiltration } from './HouseVentilationInfiltration.js'

import { gApp, DebugOn } from '../Globals.js'

export let ResidentialResultsState = makeEnum({
  Waiting: 'Waiting',
  Ready: 'Ready',
})

export class ResidentialResults {
  constructor(results, projectErrors) {
    // Of the form {inputs, outputs}
    this.results = results;
    this.projectErrors = projectErrors;
    this.calcContext = null;

    // Note: we don't store this in the project, but it gets serialized for
    // unit tests
    this.serFields = [
      ser.genericObjectField('results', [Field]),
      'projectErrors',
    ]
  }

  getCalcContext() {
    return this.calcContext;
  }

  setCalcContext(ctx) {
    this.calcContext = ctx;
  }

  getInputs() {
    return this.results.inputs;
  }

  getOutputs() {
    return this.results.outputs;
  }

  getError() {
    return this.results.error;
  }

  getProjectErrors() {
    return this.projectErrors;
  }
}


export class ResidentialProject extends Project {    
  init(id) {
    super.init(id);
  }

  setup() {
    /*
    Note: we do the init code in 'setup' because we must init and set gApp.proj() for 
    */
    this.type = ProjectTypes.Residential;
    this.toplevelData = HouseToplevelData.create();
    this.designTempInputs = HouseDesignTempInputs.create();
    this.ventilationInfiltration = HouseVentilationInfiltration.create();
    this.internals = HouseInternalsData.create();
    this.miscDetails = HouseMiscDetails.create();
    this.floors = [];
    this.walls = [];
    this.roofs = [];
    this.partitions = [];

    this.wallTypes = [];
    this.roofTypes = [];
    this.windowTypes = [];
    this.interiorShadingTypes = [];
    this.exteriorShadingTypes = [];
    this.doorTypes = [];
    this.skylightTypes = [];
    this.bufferSpaceTypes = [];

    this.windowsData = new WindowsData();

    this.results = null;
    this.resultsState = ResidentialResultsState.Waiting;

    // We automatically recalculate results every second
    this.updateResultsTimer = new IntervalTimer(() => {
      this.updateResults();
    }, 1, {onlyWhenVisible: true})
  }

  getResults() {
    return this.results;
  }

  getResultsState() {
    return this.resultsState;
  }

  resultsReady() {
    return this.resultsState === ResidentialResultsState.Ready;
  }

  get serFields() {
    return [
      ...super.serFields,
      'toplevelData',
      'designTempInputs',
      'ventilationInfiltration',
      'internals',
      ser.arrayField('floors', () => { return Floor.create(); }),
      'miscDetails',
      ser.arrayField('walls', () => { return Wall.create(); }),
      ser.arrayField('roofs', () => { return Roof.create(); }),
      ser.arrayField('partitions', () => { return Partition.create(); }),
      ser.arrayField('wallTypes', () => { return WallType.create(); }),
      ser.arrayField('roofTypes', () => { return RoofType.create(); }),
      ser.arrayField('windowTypes', () => { return WindowType.create(); }),
      ser.arrayField('interiorShadingTypes', () => { return InteriorShadingType.create(); }),
      ser.arrayField('exteriorShadingTypes', () => { return ExteriorShadingType.create(); }),
      ser.arrayField('doorTypes', () => { return DoorType.create(); }),
      ser.arrayField('skylightTypes', () => { return SkylightType.create(); }),
      ser.arrayField('bufferSpaceTypes', () => { return BufferSpaceType.create(); }),
    ]
  }

  async onSetupNewProject() {
    console.log("Setting up new Residential project...");

    // Set the default value to Winnipeg MB, Canada
    // Do this here b/c we only want to do on project create
    await this.toplevelData.locationData.setLocation({
      fullName: 'Winnipeg, MB. Canada',
      name: 'Winnipeg',
      path: ['Canada', 'MB', 'Winnipeg'],
    });
  }

  async onPostReadFromJson() {
    console.log("Post read from JSON Residential project...");
    console.log("Reloading location data...");
    await this.toplevelData.reloadLocationData();
  }

  async onCloseProject() {
    console.log("Closing Residential project...");
    this.updateResultsTimer.stop();
  }

  getBaseRoute() {
    return `/house/${this.id}`;
  }

  getLocationData() {
    return this.toplevelData.locationData;
  }

  getProjectUnits() {
    return ProjectUnits.Imperial;
  }

  updateResults() {
    console.log("Updating load results")
    this.updateProjectErrors();
    let errors = this.getProjectErrors();
    if (errors) {
      this.results = new ResidentialResults(null, errors);
      this.resultsState = ResidentialResultsState.Ready;
      return;
    }
    let resultsObj = this._calcResults();
    this.results = new ResidentialResults({
      inputs: resultsObj.inputs,
      outputs: resultsObj.outputs,
      error: resultsObj.error,
    }, null);
    this.results.setCalcContext(resultsObj.calcContext);
    this.resultsState = ResidentialResultsState.Ready;
  }

  _calcRoofOutputs(ctx) {
    ctx.startSection("Roofs");
    
    let sumRoofHeating = 0;
    let sumRoofCooling = 0;
    let sumSkylightHeating = 0;
    let sumSkylightCooling = 0;
    ctx.roofDetails = [];
    for (let i = 0; i < this.roofs.length; ++i) {
      let roof = this.roofs[i];
      ctx.startSection(`Roof${i + 1}`);
      let outputs = roof.calcOutputs(ctx);
      ctx.endSection();
      ctx.roofDetails.push(outputs);

      sumRoofHeating += outputs.q_heating;
      sumRoofCooling += outputs.q_cooling;
      sumSkylightHeating += outputs.skylights.q_heating;
      sumSkylightCooling += outputs.skylights.q_cooling;
    }

    ctx.res.roofs = {
      cooling: {
        sensible: sumRoofCooling,
        latent: null,
      },
      heating: {
        sensible: sumRoofHeating,
        latent: null,
      },
    };
    ctx.res.skylights = {
      cooling: {
        sensible: sumSkylightCooling,
        latent: null,
      },
      heating: {
        sensible: sumSkylightHeating,
        latent: null,
      },
    };

    ctx.endSection();
  }

  _calcWallOutputs(ctx) {
    ctx.startSection("Walls");
    
    let sumWallHeating = 0;
    let sumWallCooling = 0;
    let sumWindowHeating = 0;
    let sumWindowCooling = 0;
    let sumDoorHeating = 0;
    let sumDoorCooling = 0;
    ctx.wallDetails = [];
    for (let i = 0; i < this.walls.length; ++i) {
      let wall = this.walls[i];
      ctx.startSection(`Wall${i + 1}`);
      let outputs = wall.calcOutputs(ctx);
      ctx.endSection();
      ctx.wallDetails.push(outputs);

      sumWallHeating += outputs.q_heating;
      sumWallCooling += outputs.q_cooling;
      sumWindowHeating += outputs.windows.q_heating;
      sumWindowCooling += outputs.windows.q_cooling;
      sumDoorHeating += outputs.doors.q_heating;
      sumDoorCooling += outputs.doors.q_cooling;
    }

    ctx.res.walls = {
      cooling: {
        sensible: sumWallCooling,
        latent: null,
      },
      heating: {
        sensible: sumWallHeating,
        latent: null,
      },
    };
    ctx.res.windows = {
      cooling: {
        sensible: sumWindowCooling,
        latent: null,
      },
      heating: {
        sensible: sumWindowHeating,
        latent: null,
      },
    };
    ctx.res.doors = {
      cooling: {
        sensible: sumDoorCooling,
        latent: null,
      },
      heating: {
        sensible: sumDoorHeating,
        latent: null,
      },
    };

    ctx.endSection();
  }

  _calcPartitionOutputs(ctx) {
    ctx.startSection("Partitions");
    
    let sumHeating = 0;
    let sumCooling = 0;
    for (let i = 0; i < this.partitions.length; ++i) {
      let partition = this.partitions[i];
      ctx.startSection(`Partition${i + 1}`);
      let outputs = partition.calcOutputs(ctx);
      ctx.endSection();

      sumHeating += outputs.q_heating;
      sumCooling += outputs.q_cooling;
    }

    ctx.res.partitions = {
      cooling: {
        sensible: sumCooling,
        latent: null,
      },
      heating: {
        sensible: sumHeating,
        latent: null,
      },
    };

    ctx.endSection();
  }

  _calcFloorOutputs(ctx) {
    ctx.startSection("Floors");

    let sumHeating = 0;
    let sumCooling = 0;
    for (let i = 0; i < this.floors.length; ++i) {
      let floor = this.floors[i];
      ctx.startSection(`Floor${i + 1}`)
      let res = this.floors[i].calcOutputs(ctx);
      sumHeating += res.q_heating;
      sumCooling += res.q_cooling;
      ctx.endSection();
    }

    ctx.res.floors = {
      cooling: {
        sensible: sumCooling,
        latent: null,
      },
      heating: {
        sensible: sumHeating,
        latent: null,
      }
    };

    ctx.endSection();
  }

  static _calcLoadTotals(ctx, resName, sectionsDict) {
    ctx.pushMsg(`${resName} = Sum of...`)
    ctx.items = sectionsDict;
    let totals = {
      cooling: {sensible: 0, latent: 0},
      heating: {sensible: 0, latent: 0},
    }
    for (const key in sectionsDict) {
      let item = sectionsDict[key];
      totals.cooling.sensible += item.cooling.sensible || 0;
      totals.cooling.latent += item.cooling.latent || 0;
      totals.heating.sensible += item.heating.sensible || 0;
      totals.heating.latent += item.heating.latent || 0;
    }
    ctx[resName] = totals;
    return totals;
  }

  _calcTotalEnvelopeOutputs(ctx) {
    ctx.startSection("Total Envelope Outputs")
    ctx.res.totalEnvelope = ResidentialProject._calcLoadTotals(ctx, 'totalEnvelope', {
      'walls': ctx.res.walls,
      'roofs': ctx.res.roofs,
      'partitions': ctx.res.partitions,
      'floors': ctx.res.floors,
      'windows': ctx.res.windows,
      'skylights': ctx.res.skylights,
      'doors': ctx.res.doors,
    });
    ctx.endSection();
  }

  _calcTotalSpaceLoad(ctx) {
    ctx.startSection("Total Space Load")
    ctx.res.totalSpaceLoad = ResidentialProject._calcLoadTotals(ctx, 'totalSpaceLoad', {
      'totalEnvelope': ctx.res.totalEnvelope,
      'ventilationInfiltration': ctx.res.ventilationInfiltration,
      'internals': ctx.res.internals.total,
    })
    ctx.endSection()
  }

  _calcTotalCoilLoad(ctx) {
    ctx.startSection("Total Coil Load");
    ctx.res.totalCoilLoad = ResidentialProject._calcLoadTotals(ctx, 'totalCoilLoad', {
      'totalSpaceLoad': ctx.res.totalSpaceLoad,
      'distributionLoads': ctx.res.distributionLoads,
    })
    ctx.endSection();
  }

  _runCalculations() {
    let ctx = CalcContext.create();
    let error = null;
    ctx.res = {};
    try {
      this.toplevelData.calcOutputs(ctx);
      this.designTempInputs.calcOutputs(ctx);
      this.ventilationInfiltration.calcOutputs(ctx);
      this._calcRoofOutputs(ctx);
      this._calcWallOutputs(ctx);
      this._calcPartitionOutputs(ctx);
      this._calcFloorOutputs(ctx);
      this.internals.calcOutputs(ctx);
      this._calcTotalEnvelopeOutputs(ctx);
      this._calcTotalSpaceLoad(ctx);
      this.miscDetails.calcOutputs(ctx);
      this._calcTotalCoilLoad(ctx);
    } catch (err) {
      // console.log("Error while calculating outputs: ", err);
      ctx.logFatalError(err);
      error = err;
    }
    return {ctx, error};
  }

  _calcResults() {
    let results = {
      inputs: null,
      outputs: null,
      calcContext: null,
      error: null,
    };
    try {
      let toplevelData = this.toplevelData;
      let locationData = toplevelData.locationData;
      let miscDetails = this.miscDetails;
      let outdoorAir = this.ventilationInfiltration;
      let internals = this.internals;

      let makeLocationDataOutput = (field) => {
        return Field.makeOutput(field.name, field.type, field.data.useDefault ? field.data.defaultValue : field.value,
          {
            min: field.min,
            max: field.max,
          });
      };

      results.inputs = {
        basics: {
          floorSpace: toplevelData.totalFloorArea,
          averageCeilingHeight: toplevelData.averageCeilingHeight,
          buildingVolume: toplevelData.totalBuildingVolume,
          numberBedrooms: toplevelData.numberBedrooms,
          numberStoreys: toplevelData.numberStoreys,
          indoorSummerTemp: toplevelData.indoorSummerTemp,
          indoorSummerHumidity: toplevelData.indoorSummerHumidity,
          indoorWinterTemp: toplevelData.indoorWinterTemp,
          indoorWinterHumidity: toplevelData.indoorWinterHumidity,
        },
        environment: {
          heating99p6PerDryBulb: makeLocationDataOutput(locationData.heating99p6PerDryBulb),
          cooling0p4PerDryBulb: makeLocationDataOutput(locationData.cooling0p4PerDryBulb),
          cooling0p4PerWetBulb: makeLocationDataOutput(locationData.cooling0p4PerWetBulb),
          latitude: makeLocationDataOutput(locationData.latitude),
          longitude: makeLocationDataOutput(locationData.longitude),
          elevation: makeLocationDataOutput(locationData.elevation),
        },
        envelope: {
          totalWallArea: Field.makeOutput("Total Wall Area", FieldType.Area, this._calcTotalWallArea(), {allowMin: true}),
          totalRoofArea: Field.makeOutput("Total Roof Area", FieldType.Area, this._calcTotalRoofArea(), {allowMin: true}),
          totalWindowArea: Field.makeOutput("Total Window Area", FieldType.Area, this._calcTotalWindowArea(), {allowMin: true}),
          totalDoorArea: Field.makeOutput("Total Door Area", FieldType.Area, this._calcTotalDoorArea(), {allowMin: true}),
          totalPartitionArea: Field.makeOutput("Total Partition Area", FieldType.Area, this._calcTotalPartitionArea(), {allowMin: true}),
        },
        outdoorAir: {
          totalVentilation: outdoorAir.totalVentilation,
          // TODO - these fields should be calculated if using the automatic method
          summerInfiltration: outdoorAir.infiltrationManual.getField('summerInfiltration'),
          winterInfiltration: outdoorAir.infiltrationManual.getField('winterInfiltration'),
          continuousExhaust: outdoorAir.continuousExhaust,
          recoveryFlow: outdoorAir.heatEnergyRecovery,
          recoveryType: outdoorAir.recoveryType,
        },
        internals: {
          useStdMethod: internals.useStdMethod,
          additionalOccupants: internals.additionalOccupants,
          additionalSensibleLoads: internals.additionalSensibleLoads,
          additionalLatentLoads: internals.additionalLatentLoads,
        },
        system: {
          ductRunLocation: miscDetails.ductRunLocation,
          typicalLeakageRateEntry: miscDetails.typicalLeakageRateEntry,
          typicalLeakageRate: miscDetails.typicalLeakageRate,
          ductInsulation: miscDetails.ductInsulation,
          systemType: miscDetails.systemType,
        },
      };

      let {ctx, error} = this._runCalculations();
      results.calcContext = ctx;
      if (error) {
        throw error;
      }

      let res = ctx.res;
      // console.log("Results: ", prettyJson(res));
      results.outputs = {
        ['Envelope Loads']: {
          Walls: res.walls,
          Roofs: res.roofs,
          Partitions: res.partitions,
          Floors: res.floors,
          Windows: res.windows,
          Skylights: res.skylights,
          Doors: res.doors,
          Total: res.totalEnvelope,
        },
        ['Outdoor Air Loads']: {
          ['Ventilation & Infiltration']: res.ventilationInfiltration,
        },
        ['Internal Loads']: {
          People: res.internals.people,
          Other: res.internals.other,
          Total: res.internals.total,
        },
        ['Space Load']: {
          ['Total Space Load']: res.totalSpaceLoad,
        },
        ['Other Loads']: {
          Distribution: res.distributionLoads,
        },
        ['Coil Load']: {
          ['Total Coil Load']: res.totalCoilLoad,
        }
      };
    } catch (err) {
      console.error(`Error during output calc:\n${err}\n${err.stack}`);
      results.error = err;
      gApp.reportError(err);
    }

    return results;
  }

  _calcTotalWallArea() {
    let area = 0;
    for (const wall of this.walls) {
      area += wall.area.value;
    }
    return area;
  }

  _calcTotalRoofArea() {
    let area = 0;
    for (const roof of this.roofs) {
      area += roof.area.value;
    }
    return area;
  }

  _calcTotalWindowArea() {
    let area = 0;
    for (const wall of this.walls) {
      for (const wallWindow of wall.windows) {
        area += wallWindow.getWindowType().getArea() * wallWindow.quantity.value;
      }
    }
    return area;
  }

  _calcTotalDoorArea() {
    let area = 0;
    for (const wall of this.walls) {
      for (const wallDoor of wall.doors) {
        area += wallDoor.getDoorType().getArea() * wallDoor.quantity.value;
      }
    }
    return area;
  }

  _calcTotalPartitionArea() {
    let area = 0;
    for (const partition of this.partitions) {
      area += partition.size.value;
    }
    return area;
  }
};
setupClass(ResidentialProject)