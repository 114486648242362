import { runTransaction,  } from "firebase/firestore";
import { collection, query, where, or, doc, getDoc,
  getDocs, setDoc, addDoc, updateDoc, deleteDoc, } from "firebase/firestore";

import { gApp, DebugOn } from '../Globals.js'

export class ProjectLock {
  static async acquireLock(projId) {
    // Note: caller should do ownership check before this
    console.log(`Acquiring lock ${projId}`);
    await setDoc(doc(gApp.db, 'locks', projId), {
      userEmail: gApp.getUser().email,
      time: Date.now(),
    });
  }

  static async forceAcquireLock(projId) {
    // Note: acquireLock is already force-acquire for now
    await this.acquireLock(projId);
  }

  /*
  Returns the current lock owner.
  */
  static async updateOwnership(projId) {
    // console.log("Updating lock ownership...");
    let docRef = doc(gApp.db, 'locks', projId);
    let userEmail = gApp.getUser().email;
    return await runTransaction(gApp.db, async (transaction) => {
      const lockDoc = await transaction.get(docRef);
      if (!lockDoc.exists()) {
        // Unexpected, but let's just acquire it
        console.log("Lock not found. Acquiring lock");
        transaction.set(docRef, {
          userEmail: userEmail,
          time: Date.now(),
        })
        return userEmail;
      }
      if (lockDoc.data().userEmail !== userEmail) {
        console.log("We no longer have the lock. Possibly stolen.");
        return lockDoc.data().userEmail;
      }

      // We still have the lock. Update the timestamp
      // console.log("Updating held lock");
      transaction.set(docRef, {
        userEmail: userEmail,
        time: Date.now(),
      })
      return userEmail
    })
  }

  static async tryReleaseLock(projId) {
    // This only releases if we have the lock
    console.log(`Releasing lock ${projId}...`)
    let docRef = doc(gApp.db, 'locks', projId);
    await runTransaction(gApp.db, async (transaction) => {
      const lockDoc = await transaction.get(docRef);
      if (!lockDoc.exists()) {
        console.log("Already released");
        return;
      }
      if (lockDoc.data().userEmail !== gApp.getUser().email) {
        console.log("No longer owned by us. Not releasing.");
        return;
      }
      console.log(`Deleting lock ${projId}`)
      transaction.delete(docRef);
    });
  }

  static async _getLockData(projId) {
    let docSnap = await getDoc(doc(gApp.db, 'locks', projId));
    if (!docSnap.exists()) {
      return null;
    }
    return docSnap.data();
  }

  static async getLockOwner(projId) {
    // We consider a lock 'valid' if it exists and it is not expired (old timestamp)
    let lockData = await this._getLockData(projId);
    let curTime = Date.now();
    if (!lockData || (curTime - lockData.time) > 5000) {
      return null;
    }
    return lockData.userEmail;
  }

  // Does the local user have the proj lock?
  static async hasLock(projId) {
    let owner = await this.getLockOwner(projId);
    return owner && owner == gApp.getUser().email;
  }

  static async canOpen(projId) {
    let owner = await this.getLockOwner(projId);
    let canOpen = !owner || owner == gApp.getUser().email;
    console.log(`Can open proj ${projId}? ${canOpen} (owner: ${owner})`);
    return canOpen;
  }
};