<script setup>
import { gApp } from '../Globals.js'
import { ref, onUnmounted } from 'vue'
import BasicModal from './utils/BasicModal.vue'
import CloseButton from './utils/CloseButton.vue'

let showProfileInfo = ref(false);

let sendFeedbackModal = ref(null);
let feedbackText = ref("");

async function signOutUser() {
  showProfileInfo.value = false;
  await gApp.signOutUser();
}

let feedbackTemplate = `I wish HeatWise had these features:


I hit these bugs / problems:


Other feedback:

`

function openSendFeedback() {
  feedbackText.value = feedbackTemplate;
  sendFeedbackModal.value.showModal();
}

function sendFeedback() {
  gApp.sendDeveloperFeedback(feedbackText.value);
  feedbackText.value = "";
  gApp.toast('Feedback sent!');
}

onUnmounted(() => {
})

</script>

<template>
  <div class="App">
    <div class="Header Flex AlignCenter">
      <img class="AppLogo mr-xs cursor-ptr" src="../assets/cpu-fan-cooling-icon.png" @click="gApp.router.push('/')"/>
      <h3 class="AppTitle cursor-ptr" @click="gApp.router.push('/')">HeatWise</h3>
      <p class="CurProjTitle Bold cursor-ptr" @click="gApp.router.push(gApp.proj().getBaseRoute())" v-if="gApp.proj()"><i class="bi-box pc mr-xs"></i>{{gApp.proj().getUiName()}}</p>
      <div class="TopBtns Flex GapXS AlignBaseline">
        <!-- <button v-if="gApp.proj()" @click="gApp.saveAll()">Save Project</button> -->
        <template v-if="gApp.authState.value == 'SignedIn'">
          <!-- <i class="bi-person-circle"></i> -->
          <!-- <p class="f-s MRXS">{{gApp.user.value.email}}</p> -->
          <button class="CreateBtn FeedbackBtn mr-xxs" @click="openSendFeedback"><i class="bi-send mr-xxs"></i>Send Feedback</button>
          <div class="pos-rel">
            <div class="ProfileBtn text-btn f-s MRXS upper hover-grow" :class="{grown: showProfileInfo}" @click="showProfileInfo = !showProfileInfo">
              <p>{{gApp.user.value.email[0]}}</p>
            </div>
            <div v-if="showProfileInfo" class="ProfileDropdown pos-abs right-0 pane mt-xs shad-m">
              <CloseButton @click="showProfileInfo=false" class="float-r"/>
              <p class="mute-header">Profile</p>
              <p class="mb-m">{{gApp.user.value.email}}</p>
              <button v-if="gApp.authState.value == 'SignedIn'" class="LinkBtn" @click="signOutUser">Sign Out</button>
            </div>
          </div>
          <!-- <button v-if="gApp.authState.value == 'SignedIn'" class="LinkBtn SignOutBtn w-text f-xs" @click="signOutUser">Sign Out</button> -->
        </template>
      </div>
    </div>
    <div class="BodyContainer">
      <router-view></router-view>
    </div>
    <BasicModal ref="sendFeedbackModal" title="Send Feedback" doneText="Send" @onDone="sendFeedback">
      <p class="mute-header">Feedback</p>
      <p class="help-text mb-s">Fill in this form to send feedback to the developers.</p>
      <textarea v-model="feedbackText" class="mb-s w-100 FeedbackArea"/>
    </BasicModal>
  </div>
</template>

<style scoped>
.App {
  /* max-width: 1200px; */
  width: 100%;
  /* padding: var(--space-m); */
  background-color: var(--main-bg);
}

.Header {
  height: var(--header-height);
  background-color: var(--header-color);
  /* padding: var(--space-s) var(--space-xxl); */
  /* padding: var(--space-s) var(--space-m); */
  padding: 0 var(--space-m);
  /* border-bottom: 1px solid var(--grey-80); */
  border-bottom: 1px solid var(--grey-95);
  color: var(--w-text);
  width: 100%;
  position: fixed;
  z-index: 2;
}

.CurProjTitle {
  /* position: fixed; */
  /* justify-content: center; */
  /* margin-left: var(--sidebar-w); */
  float: left;
  margin-left: calc(var(--sidebar-w) - 110px);
  /* margin: 0 auto; */
}

.TopBtns {
  /* float: right; */
  margin-left: auto;
}

.AppLogo {
  /* height: 32px; */
  height: 28px;
  filter: brightness(0) invert(1);
}

.AppTitle {
  color: var(--title-color);
  font-size: var(--f-m);
}

.BodyContainer {
  padding-top: var(--header-height);
  position: relative;
  z-index: 1;
}

.ProfileBtn {
  border-radius: var(--br-s);
  height: 34px;
  width: 34px;
  /* background-color: var(--delete-red); */
  /* background-color: var(--pc); */
  background-color: var(--pc);

  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 100%; */
  border-radius: var(--br-l);
  text-align: center;

  font-size: var(--f-m);
  font-weight: 600;
}

.ProfileDropdown {
  color: var(--main-text);
  z-index: 1000;
  border-radius: var(--br-l);
  min-width: 360px;
}

.FeedbackBtn {
  padding: var(--xs);
  font-size: var(--f-xxs);
  text-transform: none;
  border-radius: var(--br-l);
}

.FeedbackArea {
  resize: vertical;
  height: 200px;
  font-size: var(--f-xs);
}

</style>
