
import { makeEnum, makeEnumWithDataAndLabels } from "../Base";
import { lookupData } from "../Base";

/*
Typical computer types:
100% laptop, docking station
50% laptop, docking station, 50% desktop
100% desktop
*/
export let TypicalComputerTypes = makeEnum({
  OneHundredPercentLaptopDockingStation: '100% laptop, docking station',
  FiftyPercentLaptopDockingStationFiftyPercentDesktop: '50% laptop, docking station, 50% desktop',
  OneHundredPercentDesktop: '100% desktop',
})

/*
Office Load Factors Data:
100% laptop, docking station
  Light	0.34
	Medium	0.46
	Medium, two screens	0.69
	Full-on, two screens	1.14
50% laptop, docking station, 50% desktop
  Light	0.44
	Medium	0.59
100% desktop
  Light	0.54
	Medium	0.72
	Medium, two screens	0.84
	Medium, three screens	0.96
	Heavy, two screens	1.02
	Heavy, three screens	1.16
	Full-on, two screens	1.33
	Full-on, three screens	1.53
*/
export let OfficeLoadIntensities = makeEnum({
  Light: 'Light',
  Medium: 'Medium',
  MediumTwoScreens: 'Medium, two screens',
  MediumThreeScreens: 'Medium, three screens',
  HeavyTwoScreens: 'Heavy, two screens',
  HeavyThreeScreens: 'Heavy, three screens',
  FullOnTwoScreens: 'Full-on, two screens',
  FullOnThreeScreens: 'Full-on, three screens',
})

export let OfficeLoadFactors = {
  [TypicalComputerTypes.OneHundredPercentLaptopDockingStation]: {
    Light: 1.1611,
    Medium: 1.5709,
    MediumTwoScreens: 2.35635,
    FullOnTwoScreens: 3.8931,
  },
  [TypicalComputerTypes.FiftyPercentLaptopDockingStationFiftyPercentDesktop]: {
    Light: 1.5026,
    Medium: 2.01485,
  },
  [TypicalComputerTypes.OneHundredPercentDesktop]: {
    Light: 1.8441,
    Medium: 2.4588,
    MediumTwoScreens: 2.8686,
    MediumThreeScreens: 3.2784, 
    HeavyTwoScreens: 3.4833,
    HeavyThreeScreens: 3.9614,
    FullOnTwoScreens: 4.54195,
    FullOnThreeScreens: 5.22495,
  },
}

export function getApplianceDataForOfficeLoads(ctx, computerType, loadIntensity, floorArea) {
  let loadFactor = lookupData(OfficeLoadFactors, [computerType, loadIntensity])
  let S_app = ctx.eval('Load*A_floor', {
    Load: loadFactor,
    A_floor: floorArea,
  }, 'S_app');
  return {
    S_app: S_app,
    L_app: S_app,
    F_rad: 0.2,
    F_conv: 0.8,
  }
}