export const DailyFractionalDBRange = {
  0: 0.82,
  1: 0.88,
  2: 0.92,
  3: 0.95,
  4: 0.98,
  5: 1,
  6: 0.98,
  7: 0.91,
  8: 0.74,
  9: 0.55,
  10: 0.38,
  11: 0.23,
  12: 0.13,
  13: 0.05,
  14: 0,
  15: 0,
  16: 0.06,
  17: 0.14,
  18: 0.24,
  19: 0.39,
  20: 0.5,
  21: 0.59,
  22: 0.68,
  23: 0.75
};