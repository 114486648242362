import * as ser from '../Common/SerUtil.js'
import { makeEnum, makeOptions,
  setupClass, lookupData, 
  interpolateInMap,
} from '../Base.js'
import { toRads, toDegs, } from '../Common/Math.js'

import { FFsVals } from '../MaterialData/Windows/WindowsData.js' 
import * as calc from './ResidentialCalculations.js'
import * as cool from './CoolingCalculations.js'

import { prettyJson, valOr,
} from '../SharedUtils.js'
export { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
  FieldGroup,
} from '../Common/Field.js'

import {
  kDirectionChoices, YesNo,
  makeYesNoField, makeNoYesField,
 } from '../Components/Common.js'

import { gApp, } from '../Globals.js'

export class RoofSkylight {
  init() {
    this.skylightType = Field.makeTypeSelect('Type', gApp.proj().skylightTypes, null, {
      errorWhenEmpty: 'You must create a Skylight Type.',
    })

    this.quantity = new Field({
      name: 'Quantity',
      type: FieldType.Count,
      defaultValue: 1,
      allowMin: false,
    })

    this.serFields = [
      'skylightType',
      'quantity',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Skylight',
    }
  }

  getSkylightType() {
    return this.skylightType.lookupValue();
  }
}
setupClass(RoofSkylight)

export class Roof {
  init() {
    this.roofType = Field.makeTypeSelect('Type', gApp.proj().roofTypes, null, {
      errorWhenEmpty: `You must create a Roof Type.`
    })
    this.area = new Field({
      name: 'Area',
      type: FieldType.Area,
      requiresInput: true,
    })
    this.slope = new Field({
      name: 'Slope',
      type: FieldType.Percent,
    })
    this.direction = new Field({
      name: 'Direction',
      type: FieldType.Select,
      choices: kDirectionChoices,
    })
    /*
    this.direction.makeUpdater((field) => {
      field.isNA = this.slope.value != 0;
    })
    */
    this.adjacentToAttic = makeNoYesField('Adjacent to Attic');
    this.skylights = [];

    // Only relevant for commercial buildings:
    this.hasCeilingPlenum = makeYesNoField('Has ceiling plenum?');
    this.portionOfRoofLoadToPlenum = new Field({
      name: 'Portion of roof load to plenum',
      type: FieldType.Percent,
    })
    this.portionOfRoofLoadToPlenum.setVisibility(() => {
      return this.hasCeilingPlenum.value == YesNo.Yes;
    })

    this.expandedInUi = false;

    this.serFields = [
      'roofType',
      'area',
      'slope',
      'direction',
      'adjacentToAttic',
      ser.arrayField('skylights', () => { return RoofSkylight.create(); }),
      'hasCeilingPlenum',
      'portionOfRoofLoadToPlenum',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Roof',
    }
  }

  isRoof() {
    return true
  }

  getRoofType() {
    return this.roofType.lookupValue();
  }

  // For compatibility with `Wall` type
  // A RoofType is a WallType
  getWallType() {
    return this.roofType.lookupValue();
  }

  getArea() {
    return this.area.value;
  }

  getStrictlyRoofArea() {
    // Roof area = total area - skylight area
    return this.area.value - this.getSkylightArea();
  }

  getSkylightArea() {
    let totalSkylightArea = 0;
    for (const skylight of this.skylights) {
      totalSkylightArea += skylight.getSkylightType().getArea() * skylight.quantity.value;
    }
    return totalSkylightArea;
  }

  // For compatibility with `Wall` type
  getStrictlyWallArea() {
    return this.getStrictlyRoofArea()
  }

  getTiltAngleDegs() {
    return toDegs(Math.atan(this.slope.value / 100.0));
  }

  getPlenumLoadFraction() {
    return this.hasCeilingPlenum.value == YesNo.Yes ?
      this.portionOfRoofLoadToPlenum.value / 100.0 : 0;
  }

  getRValue() {
    return this.roofType.lookupValue().getRValue();
  }

  getNumSkylights() {
    let num = 0;
    for (const skylight of this.skylights) {
      num += skylight.quantity.value;
    }
    return num;
  }

  _calcLoads(ctx, isHeating) {
    ctx.startSection(`${isHeating ? 'Heating' : 'Cooling'}`);

    let weatherData = ctx.toplevelData.locationData;

    ctx.t_i = isHeating ? ctx.toplevelData.indoorWinterTemp
      : ctx.toplevelData.indoorSummerTemp;
    ctx.t_o = isHeating? ctx.designTemps.heating : ctx.designTemps.cooling;
    /*
    if (!isHeating) {
      ctx.t_wb_F = weatherData.cooling0p4PerDryBulbMCWB;
    }
    */

    // Calc opaque area
    ctx.A = this.area.value;
    for (let i = 0; i < this.skylights.length; ++i) {
      let skylight = this.skylights[i];
      ctx.A = ctx.eval('A - A_skylight*N_skylight', {
        A_skylight: skylight.getSkylightType().getArea(),
        N_skylight: skylight.quantity.value,
      }, 'A');
    }

    let roofType = this.roofType.lookupValue();
    ctx.R = roofType.getRValue();
    ctx.alpha = roofType.getAbsorptance();
    ctx.U = ctx.eval('1.0 / R', {}, 'U');

    let adjToAttic = this.adjacentToAttic.value == YesNo.Yes;
    let surfaceType = adjToAttic ? 'RoofAdjToAttic' : 'RoofNoAttic';
    ctx.q_roof = calc.calcOpaqueQ(ctx, ctx.A, ctx.U, ctx.t_i, ctx.t_o,
      ctx.alpha, isHeating, surfaceType, weatherData);

    // Skylights:
    let q_skylights = 0;
    for (let i = 0; i < this.skylights.length; ++i) {
      ctx.startLocalSection(`SL${i + 1}`)
      let roofSl = this.skylights[i];
      let sl = roofSl.getSkylightType();
      let uValueData = sl.computeUValue();

      ctx.pushMsg('\nOpaque part (curb):');
      // Note: we approximate here and assume the U-value of the curb is the same as the
      // given U-Value/glass U-value (even though a manually given U-value is for the glass+curb)
      if (sl.getCurbArea() > 0) {
        ctx.A_opq = ctx.eval('N*A_curb',
          {N: roofSl.quantity.value, A_curb: sl.getCurbArea()}, 'A_opq');
        // Note: alpha is not used for Walls/Doors/curbs, just set to null
        // Note: we try to use the uValueFrame, which is available when the skylight uses the
        // WindowBuilder. Fall back to regular u-value if not available.
        let uValueCurb = valOr(uValueData.uValueFrame, uValueData.uValue);
        let absorptance = null;
        ctx.q_opq = calc.calcOpaqueQ(ctx, ctx.A_opq,
          uValueCurb, ctx.t_i, ctx.t_o, absorptance,
          isHeating, 'WallOrDoorNotMostlyShaded', weatherData);
      } else {
        ctx.A_curb = 0;
        ctx.q_opq = 0;
      }

      ctx.pushMsg('\nGlass part:')
      ctx.A_sl = ctx.eval('A*N', {A: sl.getArea(),
        N: roofSl.quantity.value}, 'A_sl');
      if (isHeating) {
        ctx.HF = ctx.eval('U_sl*(t_i - t_o)', {
          U_sl: uValueData.uValue}, 'HF');
        ctx.q_glass = ctx.eval('A_sl*HF', {}, 'q_glass')
      } else {
        let DR = weatherData.meanDailyDryBulbRange;
        let E_t = cool.calc_E_t_skylight(ctx, weatherData.latitude);
        let PXI = ctx.eval('T_x*E_t', {T_x: 1,
          E_t: E_t}, 'PXI');
        let SHGC_0 = sl.computeShgc().Deg0;
        let IAC = 1;
        let FF_s = lookupData(FFsVals, ['Horizontal', 'Single-family']);
        ctx.CF = ctx.call(cool.calc_CF_full,
          uValueData.uValue, ctx.t_o, ctx.t_i,
          DR, PXI, SHGC_0, IAC, FF_s);
        ctx.q_glass = ctx.eval('A_sl*CF', {}, 'q_glass')
      }
      ctx.q_sl = ctx.eval('q_glass + q_opq', {}, 'q_sl');
      q_skylights += ctx.q_sl;
      ctx.endSection();
    }

    let res = {
      q: ctx.q_roof,
      q_skylights: q_skylights,
    }
    ctx.endSection();

    return res;
  }

  calcOutputs(ctx) {
    let heatingRes = this._calcLoads(ctx, true);
    let coolingRes = this._calcLoads(ctx, false);

    let outputs = {
      q_heating: heatingRes.q,
      q_cooling: coolingRes.q,
      skylights: {
        q_heating: heatingRes.q_skylights,
        q_cooling: coolingRes.q_skylights,
      }
    }
    return outputs;
  }
}
setupClass(Roof)