import { MaterialDataTable } from './MaterialDataTable.js';
import { CsvHelper } from './CsvHelper.js';
import { Appliances_Data } from './Tables/Appliances_Data.js'
import { OfficeEquipment_Data } from './Tables/OfficeEquipment_Data.js'
import { colStrToIndex } from './CsvHelper.js';
import { makeVector } from '../Common/Math.js';
import { lookupData, makeEnum, } from '../Base.js';
import { logIf, prettyJson, } from '../SharedUtils.js';
import * as math from 'mathjs'

/**
 * ApplianceDataTable
 * 
 * Helper class for handling appliance data.
 * Note - since this is required during input, we use as a singleton (instead of registering with a context).
 */
export class ApplianceDataTable {
  static _instance = null;

  static getInstance() {
    if (!this._instance) {
      this._instance = new ApplianceDataTable();
    }
    return this._instance;
  }

  constructor() {
    this.appliancesCsv = new CsvHelper(Appliances_Data);
    this.officeCsv = new CsvHelper(OfficeEquipment_Data);

    this._parseApplianceData();
  }

  _parseApplianceData() {
    let tableInfo = {
      requiredCols: [
        'id', 'Appliance', 'S_app', 'L_app', 'D', 'F_conv', 'F_rad'
      ],
      colInfo: {
        'id': { type: 'String' },
        'Appliance': { type: 'String' },
        'S_app': { type: 'Number' },
        'L_app': { type: 'Number' },
        'D': { type: 'Number' },
        'F_conv': { type: 'Number' },
        'F_rad': { type: 'Number' },
      },
    }
    this.CookingAppliances_HoodedElectric =
      this.appliancesCsv.parseSimpleTable('Cooking Appliances - Hooded, electric', tableInfo);
    this.CookingAppliances_HoodedGas = 
      this.appliancesCsv.parseSimpleTable('Cooking Appliances - Hooded, gas', tableInfo);
    this.CookingAppliances_HoodedSolidFuel = 
      this.appliancesCsv.parseSimpleTable('Cooking Appliances - Hooded, solid fuel', tableInfo);
    this.CookingAppliances_Unhooded =
      this.appliancesCsv.parseSimpleTable('Cooking Appliances - Unhooded', tableInfo);
    this.DishwashingAppliances =
      this.appliancesCsv.parseSimpleTable('Dishwashing Appliances', tableInfo);
    this.LaboratoryEquipment = 
      this.appliancesCsv.parseSimpleTable('Laboratory Equipment', tableInfo);
    this.MedicalEquipment =
      this.appliancesCsv.parseSimpleTable('Medical Equipment', tableInfo);
    this.MiscMachines =
      this.appliancesCsv.parseSimpleTable('Miscellaneous Machines', tableInfo);
    
    // Office equipment
    this.DesktopComputers =
      this.officeCsv.parseSimpleTable('Desktop Computers', tableInfo);
    this.LaptopComputers =
      this.officeCsv.parseSimpleTable('Laptop Computers', tableInfo);
    this.LaptopWithDockingStation =
      this.officeCsv.parseSimpleTable('Laptop With Docking Station', tableInfo);
    this.Monitors =
      this.officeCsv.parseSimpleTable('Monitors', tableInfo);
    this.Printers =
      this.officeCsv.parseSimpleTable('Printers', tableInfo);
    this.TabletPCs =
      this.officeCsv.parseSimpleTable('Tablet PC', tableInfo);
    
    this.appliancesMap = {
      CookingAppliances_HoodedElectric: {
        name: 'Cooking Appliances - Hooded, electric',
        data: this.CookingAppliances_HoodedElectric,
      },
      CookingAppliances_HoodedGas: {
        name: 'Cooking Appliances - Hooded, gas',
        data: this.CookingAppliances_HoodedGas,
      },
      CookingAppliances_HoodedSolidFuel: {
        name: 'Cooking Appliances - Hooded, solid fuel',
        data: this.CookingAppliances_HoodedSolidFuel,
      },
      CookingAppliances_Unhooded: {
        name: 'Cooking Appliances - Unhooded',
        data: this.CookingAppliances_Unhooded,
      },
      DishwashingAppliances: {
        name: 'Dishwashing Appliances',
        data: this.DishwashingAppliances,
      },
      LaboratoryEquipment: {
        name: 'Laboratory Equipment',
        data: this.LaboratoryEquipment,
      },
      MedicalEquipment: {
        name: 'Medical Equipment',
        data: this.MedicalEquipment,
      },
      MiscMachines: {
        name: 'Miscellaneous Machines',
        data: this.MiscMachines,
      },
      OfficeDesktopComputers: {
        name: 'Office - Desktop Computers',
        data: this.DesktopComputers,
      },
      OfficeLaptopComputers: {
        name: 'Office - Laptop Computers',
        data: this.LaptopComputers,
      },
      OfficeLaptopWithDockingStation: {
        name: 'Office - Laptop With Docking Station',
        data: this.LaptopWithDockingStation,
      },
      OfficeMonitors: {
        name: 'Office - Monitors',
        data: this.Monitors,
      },
      OfficePrinters: {
        name: 'Office - Printers',
        data: this.Printers,
      },
      OfficeTabletPCs: {
        name: 'Office - Tablet PCs',
        data: this.TabletPCs,
      },
    }
    
    // Setup appliances enum
    let categoryEnumData = {}
    for (const key in this.appliancesMap) {
      categoryEnumData[key] = this.appliancesMap[key].name
    }
    let categoryEnum = makeEnum(categoryEnumData)
    this.applianceCategoryEnum = categoryEnum;

    this.applianceOptionsMap = {
      type: categoryEnum,
      children: {},
    }
    for (const key in this.appliancesMap) {
      this.applianceOptionsMap.children[key] = this._makeEnumFromTable(this.appliancesMap[key].data)
    }
  }

  _makeEnumFromTable(tableData) {
    let enumData = {}
    for (const row of tableData.rows) {
      enumData[row.id] = row.Appliance
    }
    return makeEnum(enumData)
  }

  /*
  This is used by the multi-tier select input
  */
  getApplianceOptionsMap() {
    return this.applianceOptionsMap;
  }

  _lookupApplianceData(applianceTable, applianceId) {
    for (const row of applianceTable.rows) {
      if (row.id === applianceId) {
        return row;
      }
    }
    throw new Error(`Could not find appliance with id: ${applianceId}`);
  }

  getApplianceData(appliancePath) {
    if (appliancePath.length !== 2) {
      throw new Error(`Unexpected appliancePath: ${appliancePath.join('/')}`);
    }
    let categoryId = appliancePath[0];
    let applianceId = appliancePath[1];
    let categoryData = lookupData(this.appliancesMap, [categoryId]);
    let applianceData = this._lookupApplianceData(categoryData.data, applianceId);

    return {
      S_app: applianceData.S_app,
      L_app: applianceData.L_app,
      D: applianceData.D,
      F_rad: applianceData.F_rad,
      // Note: F_conv is not always (1.0 - F_rad). So get from the applianceData
      F_conv: applianceData.F_conv,
    }
  }
};
