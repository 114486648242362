import { prettyJson, clearArray, valOr, getElemNames,
  addElem, removeElem, elemIn, extendArray } from '../SharedUtils.js'
import { lookupData } from '../Base.js'

import Papa from 'papaparse';
import * as XLSX from 'xlsx';

function removeHtmlSubscripts(str) {
  // Replace html subscripts like "V<sub>oz</sub>" => "V_oz"
  return str.replace(/<sub>/g, '_').replace(/<\/sub>/g, '');
}

class CSVConverter {
  constructor() {
  }

  convertSummaryPage(summaryData) {
    let summaryDataJson = [];
    for (const section of summaryData) {
      let sectionJson = null;
      if (section.type == 'FieldList') {
        sectionJson = this._convertFieldListSectionToJson(section);
      } else if (section.type == 'OutputValueList') {
        sectionJson = this._convertOutputValueListSectionToJson(section);
      } else if (section.type == 'Table' || section.type == 'WideTable') {
        sectionJson = this._convertTableSectionToJson(section);
      } else {
        throw new Error(`Unexpected summaryData type: ${section.type}`);
      }
      extendArray(summaryDataJson, sectionJson);
      summaryDataJson.push([]);
    }
    return summaryDataJson;
  }

  _convertOutputValueListSectionToJson(section) {
    let sectionJson = [];
    sectionJson.push([section.name]);
    for (const field of section.outputValues) {
      let fieldJson = [
        removeHtmlSubscripts(field.name),
        field.getValueStr(),
        field.getUnitsLabel(),
      ];
      if (field.getErrorMsg()) {
        fieldJson.push(`Error: ${field.getErrorMsg()}`);
      }
      sectionJson.push(fieldJson);
    }
    return sectionJson
  }

  _convertFieldListSectionToJson(section) {
    let sectionJson = [];
    sectionJson.push([section.name]);
    for (const field of section.fields) {
      let fieldJson = [removeHtmlSubscripts(field.name), field.getValueStr(), field.getUnitsLabel()];
      sectionJson.push(fieldJson);
    }
    return sectionJson
  }

  _convertTableSectionToJson(section) {
    let sectionJson = [];
    sectionJson.push([section.name]);

    let headerRow = [];
    for (let i = 0; i < section.columns.length; ++i) {
      let col = section.columns[i];
      let colName = col.name;

      // Add the units, if relevant 
      let firstRealRowIndex = this._getFirstRealRowIndex(section.data);
      if (firstRealRowIndex !== null) {
        let colEntry = section.data[firstRealRowIndex].rowData[i];
        if (typeof colEntry == 'object' && colEntry.getUnitsLabel()) {
          colName += ` (${colEntry.getUnitsLabel()})`;
        }
      }
      headerRow.push(removeHtmlSubscripts(colName));
    }
    sectionJson.push(headerRow);

    for (const rowEntry of section.data) {
      let rowCsv = [];
      if (typeof rowEntry === 'string') {
        // Handle sub-section dividers
        sectionJson.push([rowEntry]);
        continue;
      }
      for (const item of rowEntry.rowData) {
        if (typeof item == 'string') {
          rowCsv.push(item);
        } else {
          // Field
          rowCsv.push(item.getValueStr());
        }
      }
      sectionJson.push(rowCsv);
    }

    return sectionJson;
  }

  _getFirstRealRowIndex(data) {
    for (let i = 0; i < data.length; ++i) {
      if (typeof data[i] !== 'string') {
        return i;
      }
    }
    return null;
  }
}

/*
*/
export class SpreadsheetExporterExcel {
  constructor(filename) {
    this.filename = filename;
    this.workbook = XLSX.utils.book_new();
  }

  addCustomPage(pageName, pageData) {
    let worksheet = XLSX.utils.json_to_sheet(pageData);
    XLSX.utils.book_append_sheet(this.workbook, worksheet, pageName);
  }

  addSummaryDataPage(pageName, summaryData) {
    // Note: summaryData is an array of "sections"
    let csvConverter = new CSVConverter();
    let summaryDataCSV = csvConverter.convertSummaryPage(summaryData);
    // aoa is "array of arrays"
    let worksheet = XLSX.utils.aoa_to_sheet(summaryDataCSV);
    if (this._shouldAutosizeColumns(summaryData)) {
      this._autosizeColumns(worksheet, summaryDataCSV);
    }
    XLSX.utils.book_append_sheet(this.workbook, worksheet, pageName);
  }

  export() {
    XLSX.writeFile(this.workbook, this.filename);
  }

  _shouldAutosizeColumns(summaryData) {
    // If any of the sections have autosizeColumns set to false, then don't autosize
    // Otherwise, autosize
    for (const section of summaryData) {
      if (section.autosizeColumns === false) {
        return false;
      }
    }
    return true;
  }

  _autosizeColumns(worksheet, csvData) {
    let colWidths = [];
    for (let rowIndex = 0; rowIndex < csvData.length; ++rowIndex) {
      for (let colIndex = 0; colIndex < csvData[rowIndex].length; ++colIndex) {
        let cellData = csvData[rowIndex][colIndex];
        let cellString = cellData ? cellData.toString() : "";
        let cellWidth = cellString.length;
        if (colIndex < colWidths.length) {
          colWidths[colIndex] = Math.max(colWidths[colIndex], cellWidth);
        } else {
          colWidths.push(cellWidth);
        }
      }
    }
    worksheet['!cols'] = colWidths.map(width => ({ width: width }));
  }
};

/*
TODO - unused
*/
export class SpreadsheetExporterCSV {
  constructor(filename) {
    this.filename = filename;
    this.csvData = [];
  }

  addSummaryDataPage(summaryData) {
    let summaryDataJson = [];
    for (const section of summaryData) {
      let sectionJson = null;
      if (section.type == 'FieldList') {
        sectionJson = this._convertFieldListSectionToJson(section);
      } else if (section.type == 'Table' || section.type == 'WideTable') {
        sectionJson = this._convertTableSectionToJson(section);
      } else {
        throw new Error(`Unexpected summaryData type: ${summaryData.type}`);
      }
      extendArray(summaryDataJson, sectionJson);
      sect
    }
    extendArray(this.csvData, summaryDataJson);
  }

  _convertFieldListSectionToJson(section) {
    let sectionJson = [];
    sectionJson.push([section.name]);
    return sectionJson
  }

  _convertTableSectionToJson(section) {
    let sectionJson = [];
    sectionJson.push([section.name]);
    return sectionJson;
  }

  export() {
    // TODO
  }
};