import { makeEnum } from '../Base.js'

export let SystemHeatingDesignTemps = makeEnum({
  Temp99p6: '99.6% Dry Bulb',
  Temp99p0: '99% Dry Bulb',
})

export let SystemCoolingDesignTemps = makeEnum({
  Temp0p4: '0.4% Dry Bulb',
  Temp2p0: '2% Dry Bulb',
  Temp5p0: '5% Dry Bulb',
  Temp10p0: '10% Dry Bulb',
})