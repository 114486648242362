import { makeEnum, setupClass, lookupData, } from '../Base.js'
import { Field } from '../Common/Field.js'

export let HeatingDesignTemps = makeEnum({
  Temp99p6: '99.6% Dry Bulb',
  Temp99p0: '99% Dry Bulb',
})

export let CoolingDesignTemps = makeEnum({
  Temp0p4: '0.4% Dry Bulb',
  Temp1p0: '1% Dry Bulb',
  Temp2p0: '2% Dry Bulb',
})


/*
Used to toggle the House design temperatures used to calculate the outputs
*/
export class HouseDesignTempInputs {
  init() {
    this.heatingDesignTemp = Field.makeSelect('Heating Design Temp.', HeatingDesignTemps)
    this.coolingDesignTemp = Field.makeSelect('Cooling Design Temp.', CoolingDesignTemps)

    this.serFields = [
      'heatingDesignTemp',
      'coolingDesignTemp',
    ]
  }

  calcOutputs(ctx) {
    ctx.startLocalSection('Design Temps');

    let weatherData = ctx.toplevelData.locationData;

    let heatingTempMap = {
      [HeatingDesignTemps.Temp99p6]: {dryBulb: weatherData.heating99p6PerDryBulb},
      [HeatingDesignTemps.Temp99p0]: {dryBulb: weatherData.heating99PerDryBulb},
    }
    let heatingTemps = lookupData(heatingTempMap, [this.heatingDesignTemp.value])

    let coolingTempMap = {
      [CoolingDesignTemps.Temp0p4]: {
        dryBulb: weatherData.cooling0p4PerDryBulb,
        mcwb: weatherData.cooling0p4PerDryBulbMCWB,
      },
      [CoolingDesignTemps.Temp1p0]: {
        dryBulb: weatherData.cooling1PerDryBulb,
        mcwb: weatherData.cooling1PerDryBulbMCWB,
      },
      [CoolingDesignTemps.Temp2p0]: {
        dryBulb: weatherData.cooling2PerDryBulb,
        mcwb: weatherData.cooling2PerDryBulbMCWB,
      }
    }
    let coolingTemps = lookupData(coolingTempMap, [this.coolingDesignTemp.value])

    ctx.designTemps = {
      heating: heatingTemps.dryBulb,
      cooling: coolingTemps.dryBulb,
      coolingMCWB: coolingTemps.mcwb,
    }
    ctx.endSection();
  }
}
setupClass(HouseDesignTempInputs)