import { 
  setupClass,
} from '../Base.js'

export { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
  ProjectUnits,
} from '../Common/Field.js'

import { LocationData } from './LocationData.js'


export class HouseToplevelData {
  init() {
    this.locationData = LocationData.create()
    this.projectUnits = Field.makeSelect('Project Units', ProjectUnits)

    this.numberBedrooms = new Field({
      name: 'Number Bedrooms',
      type: FieldType.Count,
      defaultValue: 2,
      min: 0,
      allowMin: true,
    });
    this.numberStoreys = new Field({
      name: 'Number Storeys',
      type: FieldType.Count,
      defaultValue: 1,
      min: 0,
      allowMin: true,
    });
    this.totalFloorArea = new Field({
      name: 'Total Floor Area',
      type: FieldType.Area,
      defaultValue: 1000,
    });
    this.averageCeilingHeight = new Field({
      name: 'Average Ceiling Height',
      type: FieldType.Length,
      defaultValue: 9,
    });
    this.totalBuildingVolume = new Field({
      name: 'Total Building Volume',
      type: FieldType.Volume,
      defaultValue: 9000,
    });
    this.indoorWinterTemp = new Field({
      name: 'Indoor Winter Temperature',
      type: FieldType.Temp,
      defaultValue: 70,
    });
    this.indoorWinterHumidity = new Field({
      name: 'Indoor Winter Humidity',
      type: FieldType.Percent,
      defaultValue: 30,
    })
    this.indoorSummerTemp = new Field({
      name: 'Indoor Summer Temperature',
      type: FieldType.Temp,
      defaultValue: 75,
    });
    this.indoorSummerHumidity = new Field({
      name: 'Indoor Summer Humidity',
      type: FieldType.Percent,
      defaultValue: 50,
    })

    this.basicFields = [
      'numberBedrooms',
      'numberStoreys',
      'totalFloorArea',
      'averageCeilingHeight',
      'totalBuildingVolume',
    ]
    this.tempFields = [
      'indoorWinterTemp',
      'indoorWinterHumidity',
      'indoorSummerTemp',
      'indoorSummerHumidity',
    ]

    this.fields = [
      ...this.basicFields,
      ...this.tempFields,
    ];

    this.serFields = [
      'locationData',
      'projectUnits',
      ...this.fields,
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'General Info',
    }
  }

  async reloadLocationData() {
    await this.locationData.reloadData();
  }

  /*
  getInputPage() {
    return {
      label: `House - General Info`,
      path: `build/general-info`
    }
  }
  */

  calcOutputs(ctx) {
    ctx.startLocalSection('ToplevelData');
    let data = {
      locationData: this.locationData.getOutputs(),
    };
    for (const fieldName of this.fields) {
      if (!(fieldName in data)) {
        data[fieldName] = this[fieldName].value;
      }
    }
    ctx.toplevelData = data;
    ctx.endSection();
  }
}
setupClass(HouseToplevelData)