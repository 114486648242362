import { makeEnumWithDataAndLabels } from '../Base.js'
import { DataTable } from './DataTable.js'

export let LightingPower = {
  Atrium20: {
    label: 'Atrium <20ft in height',
    value: 0.39
  },
  Atrium2040: {
    label: 'Atrium ≥20ft and ≤40ft in height',
    value: 0.48
  },
  Atrium40: {
    label: 'Atrium >40ft in height',
    value: 0.6
  },
  AudienceSeatingAuditorium: {
    label: 'Audience seating area; auditorium',
    value: 0.61
  },
  AudienceSeatingGymnasium: {
    label: 'Audience seating area; gymnasium',
    value: 0.23
  },
  AudienceSeatingMovieTheater: {
    label: 'Audience seating area; movie theater',
    value: 0.27
  },
  AudienceSeatingPenitentiary: {
    label: 'Audience seating area; penitentiary',
    value: 0.67
  },
  AudienceSeatingPerformingArtsTheater: {
    label: 'Audience seating area; performing arts theater',
    value: 1.16
  },
  AudienceSeatingReligiousBuilding: {
    label: 'Audience seating area; religious building',
    value: 0.72
  },
  AudienceSeatingSportsArena: {
    label: 'Audience seating area; sports arena',
    value: 0.33
  },
  AudienceSeatingOther: {
    label: 'All other audience seating areas',
    value: 0.23
  },
  BankingActivityArea: {
    label: 'Banking Activity Area',
    value: 0.61
  },
  ClassroomPenitentiary: {
    label: 'Classroom/Lecture Hall/Training Room; penitentiary',
    value: 0.89
  },
  ClassroomOther: {
    label: 'All other classrooms/lecture halls/training rooms',
    value: 0.71
  },
  ConferenceRoom: {
    label: 'Conference/Meeting/Multipurpose Room',
    value: 0.97
  },
  ConfinementCells: {
    label: 'Confinement Cells',
    value: 0.7
  },
  CopyPrintRoom: {
    label: 'Copy/Print Room',
    value: 0.31
  },
  CorridorVisuallyImpaired: {
    label: 'Corridor; Facility for visually impaired (and not used primarily by staff)',
    value: 0.71
  },
  CorridorHospital: {
    label: 'Corridor; hospital',
    value: 0.71
  },
  CorridorOther: {
    label: 'All other corridors',
    value: 0.41
  },
  Courtroom: {
    label: 'Courtroom',
    value: 1.2
  },
  ComputerRoom: {
    label: 'Computer Room',
    value: 0.94
  },
  DiningPenitentiary: {
    label: 'Dining area; penitentiary',
    value: 0.42
  },
  DiningVisuallyImpaired: {
    label: 'Dining area; facility for visually impaired (and not used primarily by staff)',
    value: 1.27
  },
  BarLounge: {
    label: 'Bar/lounge or leisure dining',
    value: 0.86
  },
  Cafeteria: {
    label: 'Cafeteria or fast food dining',
    value: 0.4
  },
  FamilyDining: {
    label: 'Family dining',
    value: 0.6
  },
  DiningOther: {
    label: 'All other dining areas',
    value: 0.43
  },
  ElectricalMechanicalRoom: {
    label: 'Electrical/Mechanical Room',
    value: 0.43
  },
  EmergencyVehicleGarage: {
    label: 'Emergency Vehicle Garage',
    value: 0.52
  },
  FoodPreparationArea: {
    label: 'Food Preparation Area',
    value: 1.09
  },
  GuestRoom: {
    label: 'Guest Room',
    value: 0.41
  },
  LaboratoryClassroom: {
    label: 'Laboratory; in or as classroom',
    value: 1.11
  },
  LaboratoryOther: {
    label: 'All other laboratories',
    value: 1.33
  },
  LaundryWashingArea: {
    label: 'Laundry/Washing Area',
    value: 0.53
  },
  LoadingDockInterior: {
    label: 'Loading Dock, Interior',
    value: 0.88
  },
  LobbyVisuallyImpaired: {
    label: 'Lobby; facility for the visually impaired (and not used primarily by staff)',
    value: 1.69
  },
  LobbyElevator: {
    label: 'Lobby; elevator',
    value: 0.65
  },
  LobbyHotel: {
    label: 'Lobby; hotel',
    value: 0.51
  },
  LobbyMovieTheater: {
    label: 'Lobby; movie theater',
    value: 0.23
  },
  LobbyPerformingArtsTheater: {
    label: 'Lobby; performing arts theater',
    value: 1.25
  },
  LobbyOther: {
    label: 'All other lobbies',
    value: 0.84
  },
  LockerRoom: {
    label: 'Locker Room',
    value: 0.52
  },
  LoungeHealthcare: {
    label: 'Lounge/breakroom; health care facility',
    value: 0.42
  },
  LoungeOther: {
    label: 'All other lounges/breakrooms',
    value: 0.59
  },
  OfficeEnclosed250: {
    label: 'Office; enclosed and ≤250 ft²',
    value: 0.74
  },
  OfficeEnclosed250Plus: {
    label: 'Office; enclosed and >250 ft²',
    value: 0.66
  },
  OfficeOpenPlan: {
    label: 'Office; open plan',
    value: 0.61
  },
  ParkingAreaInterior: {
    label: 'Parking Area, Interior',
    value: 0.15
  },
  PharmacyArea: {
    label: 'Pharmacy Area',
    value: 1.66
  },
  RestroomVisuallyImpaired: {
    label: 'Restroom; facility for the visually impaired (and not used primarily by staff)',
    value: 1.26
  },
  RestroomOther: {
    label: 'All other restrooms',
    value: 0.63
  },
  SalesArea: {
    label: 'Sales Area',
    value: 1.05
  },
  SeatingAreaGeneral: {
    label: 'Seating Area, General',
    value: 0.23
  },
  Stairwell: {
    label: 'Stairwell',
    value: 0.49
  },
  StorageRoom50: {
    label: 'Storage room <50 ft²',
    value: 0.51
  },
  StorageRoom50Plus: {
    label: 'Storage room ≥50 ft²',
    value: 0.38
  },
  VehicularMaintenanceArea: {
    label: 'Vehicular Maintenance Area',
    value: 0.6
  },
  Workshop: {
    label: 'Workshop',
    value: 1.26
  },
  ChapelVisuallyImpaired: {
    label: 'Chapel; facility for visually impaired (not used primarily by staff)',
    value: 0.7
  },
  RecreationRoomVisuallyImpaired: {
    label: 'Recreation room/common living room; facility for visually impaired (not used primarily by staff)',
    value: 1.77
  },
  ConventionCenterExhibitSpace: {
    label: 'Convention Center, Exhibit Space',
    value: 0.61
  },
  DormitoryLivingQuarters: {
    label: 'Dormitory/Living Quarters',
    value: 0.5
  },
  FireStationSleepingQuarters: {
    label: 'Fire Station, Sleeping Quarters',
    value: 0.23
  },
  FitnessCenterExerciseArea: {
    label: 'Fitness center; exercise area',
    value: 0.9
  },
  FitnessCenterPlayingArea: {
    label: 'Fitness center; playing area',
    value: 0.85
  },
  HealthcareExamRoom: {
    label: 'Health care facility; exam/treatment room',
    value: 1.4
  },
  HealthcareImagingRoom: {
    label: 'Health care facility; imaging room',
    value: 0.94
  },
  HealthcareMedicalSupplyRoom: {
    label: 'Health care facility; medical supply room',
    value: 0.62
  },
  HealthcareNursery: {
    label: 'Health care facility; nursery',
    value: 0.92
  },
  HealthcareNursesStation: {
    label: 'Health care facility; nurses’ station',
    value: 1.17
  },
  HealthcareOperatingRoom: {
    label: 'Health care facility; operating room',
    value: 2.26
  },
  HealthcarePatientRoom: {
    label: 'Health care facility; patient room',
    value: 0.68
  },
  HealthcarePhysicalTherapyRoom: {
    label: 'Health care facility; physical therapy room',
    value: 0.91
  },
  HealthcareRecoveryRoom: {
    label: 'Health care facility; recovery room',
    value: 1.25
  },
  LibraryReadingArea: {
    label: 'Library; reading area',
    value: 0.96
  },
  LibraryStacks: {
    label: 'Library; stacks',
    value: 1.18
  },
  ManufacturingDetailed: {
    label: 'Manufacturing facility; detailed manufacturing area',
    value: 0.8
  },
  ManufacturingEquipmentRoom: {
    label: 'Manufacturing facility; equipment room',
    value: 0.76
  },
  ManufacturingExtraHighBay: {
    label: 'Manufacturing facility; extra-high-bay area (>50 ft floor-to-ceiling height)',
    value: 1.42
  },
  ManufacturingHighBay: {
    label: 'Manufacturing facility; high-bay area (25 to 50 ft floor-to-ceiling height)',
    value: 1.24
  },
  ManufacturingLowBay: {
    label: 'Manufacturing facility; low bay area (<25 ft floor-to-ceiling height)',
    value: 0.86
  },
  MuseumExhibitionArea: {
    label: 'Museum; general exhibition area',
    value: 0.31
  },
  MuseumRestorationRoom: {
    label: 'Museum; restoration room',
    value: 1.1
  },
  PerformingArtsDressingRoom: {
    label: 'Performing arts theater; dressing room',
    value: 0.41
  },
  PostOfficeSortingArea: {
    label: 'Post office; sorting area',
    value: 0.76
  },
  ReligiousFellowshipHall: {
    label: 'Religious fellowship hall',
    value: 0.54
  },
  WorshipPulpitChoir: {
    label: 'Worship/pulpit/choir area',
    value: 0.85
  },
  RetailDressingRoom: {
    label: 'Retail; dressing/fitting room',
    value: 0.51
  },
  MallConcourse: {
    label: 'Mall concourse',
    value: 0.82
  },
  SportsArenaClassI: {
    label: 'Sports arena playing area; Class I facility',
    value: 2.94
  },
  SportsArenaClassII: {
    label: 'Sports arena playing area; Class II facility',
    value: 2.01
  },
  SportsArenaClassIII: {
    label: 'Sports arena playing area; Class III facility',
    value: 1.3
  },
  SportsArenaClassIV: {
    label: 'Sports arena playing area; Class IV facility',
    value: 0.86
  },
  AirportBaggageCarousel: {
    label: 'Airport; baggage/carousel area',
    value: 0.39
  },
  AirportConcourse: {
    label: 'Airport; concourse',
    value: 0.25
  },
  AirportTicketCounter: {
    label: 'Airport; ticket counter',
    value: 0.51
  },
  WarehouseMediumBulky: {
    label: 'Warehouse storage; medium to bulky, palletized items',
    value: 0.33
  },
  WarehouseSmallerHandCarried: {
    label: 'Warehouse storage; smaller, hand-carried items',
    value: 0.69
  }
}

export function GetLightingPowerTable() {
  return DataTable.create([
    {
      name: 'Space Type',
      field: 'label',
    },
    {
      name: 'Lighting Power Density (W/ft²)',
      field: 'value',
    }
  ], LightingPower);
}
