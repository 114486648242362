<script setup>
import { gApp } from '../State.js'
import { ref, onMounted, reactive, computed } from 'vue'
import BasicModal from './utils/BasicModal.vue'
import InputGroup from './InputGroup.vue'
import { ProjectLock } from '../Common/ProjectLock.js';

import ProfileIcon from './utils/ProfileIcon.vue'

let openProjModal = ref(null);

let projToOpen = ref({name: '', lockedBy: ''});

const props = defineProps({
  projects: Array,
})

let kMaxNumSharedUsersShown = 3;

function getTrimmedSharedUsers(proj) {
  let maxUsers = kMaxNumSharedUsersShown;
  return proj.sharedUsers.slice(0, maxUsers);
}

async function tryOpenProj(proj) {
  console.log("Trying to open proj: ", proj);
  let owner = await ProjectLock.getLockOwner(proj.id);
  if (!owner || owner === gApp.getUser().email) {
    console.log("Success - opening");
    gApp.router.push(proj.route)
  } else {
    console.log(`Failed - lock owner is ${owner}`);
    projToOpen.value = proj;
    projToOpen.value.lockedBy = owner;
    openProjModal.value.showModal();
  }
}

async function forceOpenProj(proj) {
  await gApp.stealProjectLock(proj.id);
  gApp.router.push(proj.route)
}

</script>

<template>
  <div class="TableWrapper">
    <div v-if="gApp.isReloadingProjects()">
      <p class="help-text">Loading projects...</p>
    </div>
    <div v-else>
      <p class="help-text" v-if="projects.length == 0">
        No projects here yet.
      </p>
      <table v-else class="NiceTable x-Cols4 ProjectsTable w-100">
        <col style="width:25%">
        <col style="width:10%">
        <col style="width:10%">
        <col style="width:10%">
        <tr>
          <th class="NameRow">Name</th>
          <th>Owner</th>
          <th>Shared With</th>
          <th>Locked By</th>
        </tr>
        <tr v-for="project in projects" :id="project.id">
          <td>
            <button @click="tryOpenProj(project)" class="LinkBtn OpenProjBtn"><i class="bi-box pc mr-xs"></i>{{project.name}}</button>
          </td>
          <td>
            <div class="Flex RowNoWrap">
              <ProfileIcon :username="project.userEmail" class="mr-xs" />
              <p class="">{{project.userEmail || 'Unknown'}}</p>
            </div>
          </td>
          <td>
            <div class="Flex">
              <div v-for="user in getTrimmedSharedUsers(project)">
                <ProfileIcon  :username="user" class="mr-xxs" />
              </div>
              <p v-if="project.sharedUsers.length > kMaxNumSharedUsersShown">(+{{project.sharedUsers.length - kMaxNumSharedUsersShown}} more)</p>
            </div>
          </td>
          <td>
            <div v-if="project.lockedBy" class="Flex NoWrap">
              <!--<ProfileIcon :username="project.lockedBy" class="mr-xs" />-->
              <i class="bi-lock bold-icon mr-xxs"></i>
              <p>{{project.lockedBy}}</p>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <BasicModal ref="openProjModal" title="Project Locked" :showCancel="false">
      <div class="MarginBotM">
        <h4>
          {{projToOpen.lockedBy || 'Someone'}} is currently editing {{projToOpen.name}}.
        </h4>
        <p>
          Only one user can edit a project at a time. Please wait until they are done.
        </p>
      </div>
      <InputGroup title="Open anyways..." class="OpenAnyways">
        <p class="MarginBotS">
          If you believe {{projToOpen.lockedBy || 'someone'}} has left the project open, you can boot them out and open it now.
        </p>
        <button @click="forceOpenProj(projToOpen)" class="LinkBtn">Boot {{projToOpen.lockedBy}}</button>
      </InputGroup>
    </BasicModal>
  </div>
</template>

<style scoped>
.TableWrapper {
  /* width: 80%; */
}

.ProjectsTable {
}

.OpenAnyways {
  
}

.OpenProjBtn {
  text-decoration: none;
  text-transform: none;
  font-weight: 700;
}

.bold-icon {
  font-size: 18px;
}

</style>
