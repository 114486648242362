import * as ser from '../Common/SerUtil.js'
import { makeEnum, makeOptions,
  setupClass, lookupData, 
} from '../Base.js'
import { InputComponent } from '../Common/InputComponent.js'

import { StdAssemblyColMap, FFsVals } from '../MaterialData/Windows/WindowsData.js' 
import { Units } from '../Common/Units.js'
import { IACCalculator } from './IACCalculator.js'

import { prettyJson, valOr,
  addElem, removeElem, elemIn,
  extendMap,
  downloadTextFile,
} from '../SharedUtils.js'
export { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
} from '../Common/Field.js'

import {
  kDirectionChoices, YesNo,
  makeYesNoField, makeNoYesField,
  InstallationSealing,
 } from './Common.js'

 import {
  NumPanes,
  FrameStyle,
  Glazing,
  PanesAppliedTo,
  Tint,
  PaneThickness,
  GapType,
  BugScreen,
 } from './WindowsCommon.js'

 import { SkylightStyle } from './SkylightType.js'

import { gApp, DebugOn } from '../Globals.js'

export class WindowBuilder {
  init(win, opts) {
    opts = valOr(opts, {})
    this.window = win;
    this.isSlidingGlassDoor = valOr(opts.isSlidingGlassDoor, false);

    let builder = this;
    this.numPanes = new Field({
      name: 'Number of Panes',
      type: FieldType.Select,
      choices: [],
    });
    this.numPanes.makeChoicesUpdater(() => {
      if (this.isSlidingGlassDoor) {
        return makeOptions(NumPanes, [NumPanes.N1, NumPanes.N2]);
      }
      if (this.getWinStyle() == WindowStyle.GardenWindow) {
       return makeOptions(NumPanes, [NumPanes.N1, NumPanes.N2]);
      } else {
        return makeOptions(NumPanes,
          [NumPanes.N1, NumPanes.N2, NumPanes.N3, NumPanes.N4]);
      }
    });

    this.frameStyle = new Field({
      name: 'Frame Style',
      type: FieldType.Select,
      choices: [],
    });
    this.frameStyle.makeChoicesUpdater(() => {
      // console.log("Window style: ", win.style.value);
      if (this.isSlidingGlassDoor) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
        ])
      }
      if (this.getWinStyle() == WindowStyle.GardenWindow) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.WoodSlashVinyl,
        ])
      } else if (this.getWinStyle() == WindowStyle.CurtainWall) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
          FrameStyle.StructuralGlazing,
        ])
      } else if (this.getWinStyle() == WindowStyle.Fixed
        || this.getWinStyle() == WindowStyle.Operable) {
        return makeOptions(FrameStyle, [
          FrameStyle.AluminumWithoutThermalBreak,
          FrameStyle.AluminumWithThermalBreak,
          FrameStyle.ReinforcedVinylSlashAluminumCladWood,
          FrameStyle.WoodSlashVinyl,
          FrameStyle.InsulatedFiberglassSlashVinyl,
        ]);
      }
      throw new Error("Unrecognized window style: " + this.getWinStyle());
    })

    this.useStdFrameWidth = makeYesNoField('Use default frame width')
    // TODO - should probs more be a 'isEditable' rather than an output
    this.frameWidth = new Field({
      name: 'Frame Width',
      type: FieldType.SmallLength,
      isOutput: true,
    })
    this.frameWidth.makeUpdater(() => {
      if (this.useStdFrameWidth.value == YesNo.Yes) {
        this.frameWidth.value = lookupData(WindowType._getStdFrameWidthMap(), [
          this.getWinStyle(),
          this.frameStyle.value,
        ])
        this.frameWidth.isOutput = true;
      } else {
        this.frameWidth.isOutput = false;
      }
    })

    this.glazing = new Field({
      name: 'Glazing',
      type: FieldType.Select,
      choices: [],
    });
    this.glazing.makeChoicesUpdater(() => {
      if (this.isSlidingGlassDoor) {
        if (builder.numPanes.value == NumPanes.N1) {
          return makeOptions(Glazing, [Glazing.None])
        } else if (builder.numPanes.value == NumPanes.N2) {
          return makeOptions(Glazing, [Glazing.None, Glazing.E0_2])
        } else {
          throw new Error(`Unexpected numPanes for a sliding glass door: ${builder.numPanes.value}`);
        }
      }
      if (builder.numPanes.value == NumPanes.N1) {
        return makeOptions(Glazing, [Glazing.None])
      } else if (builder.numPanes.value == NumPanes.N2) {
        return makeOptions(Glazing, [
          Glazing.None,
          Glazing.E0_6,
          Glazing.E0_4,
          Glazing.E0_2,
          Glazing.E0_1,
          Glazing.E0_05,
        ])
      } else if (builder.numPanes.value == NumPanes.N3 ||
        builder.numPanes.value == NumPanes.N4) {
        return makeOptions(Glazing, [
          Glazing.None,
          Glazing.E0_2,
          Glazing.E0_1,
          Glazing.E0_05,
        ]);
      }
    })

    this.panesAppliedTo = new Field({
      name: 'Panes Applied To',
      type: FieldType.Select,
      choices: [],
    });
    this.panesAppliedTo.makeChoicesUpdater((field) => {
      if (this.isSlidingGlassDoor) {
        // Note: we don't want to display this input for Sliding glass doors.
        // For SlidingGlassDoor, N = 1 or 2 and for N = 2 we always use PanesAppliedTo = Surface3
        field.visible = false;
      }
      // console.log("Updating panesAppliedTo");
      if (builder.numPanes.value == NumPanes.N1) {
        return [];
      } else if (builder.numPanes.value == NumPanes.N2) {
        if (builder.glazing.value == Glazing.E0_6) {
          return makeOptions(PanesAppliedTo, [PanesAppliedTo.Surface2or3]);
        } else if (builder.glazing.value == Glazing.E0_4 || builder.glazing.value == Glazing.E0_2 ||
          builder.glazing.value == Glazing.E0_1 || builder.glazing.value == Glazing.E0_05) {
          if (this.isSlidingGlassDoor) {
            return makeOptions(PanesAppliedTo, [
              PanesAppliedTo.Surface3,
            ]);
          } else {
            return makeOptions(PanesAppliedTo, [
              PanesAppliedTo.Surface2,
              PanesAppliedTo.Surface3,
            ]);
          }
        } else {
          return [];
        }
      } else if (builder.numPanes.value == NumPanes.N3 || builder.numPanes.value == NumPanes.N4) {
        if (builder.glazing.value == Glazing.None) {
          return [];
        } else if (builder.glazing.value == Glazing.E0_2) {
          return makeOptions(PanesAppliedTo, [
            PanesAppliedTo.Surface2or3,
            PanesAppliedTo.Surface4or5,
            PanesAppliedTo.Surface2or3and4or5,
          ])
        } else if (builder.glazing.value == Glazing.E0_1 || builder.glazing.value == Glazing.E0_05) {
          return makeOptions(PanesAppliedTo, [PanesAppliedTo.Surface2or3and4or5])
        } else {
          return [];
        }
      }
    })

    this.tint = new Field({
      name: 'Tint',
      type: FieldType.Select,
      choices: [],
    });
    this.tint.makeChoicesUpdater(() => {
      if (builder.numPanes.value == NumPanes.N1) {
        return makeOptions(Tint, [
          Tint.Clear,
          Tint.Bronze,
          Tint.Green,
          Tint.Grey,
          Tint.BlueGreen,
          Tint.StainlessSteelOnClear8p,
          Tint.StainlessSteelOnClear14p,
          Tint.StainlessSteelOnGreen14p,
          Tint.StainlessSteelOnClear20p,
          Tint.TitaniumOnClear20p,
          Tint.TitaniumOnClear30p,
        ])
      } else if (builder.numPanes.value == NumPanes.N2) {
        if (elemIn(builder.glazing.value, [Glazing.None, Glazing.E0_6])) {
          return makeOptions(Tint)
        } else if (elemIn(builder.glazing.value, [Glazing.E0_4, Glazing.E0_2, Glazing.E0_1])) {
          if (builder.panesAppliedTo.value == PanesAppliedTo.Surface2) {
            return makeOptions(Tint, [Tint.Clear]);
          } else if (builder.panesAppliedTo.value == PanesAppliedTo.Surface3) {
            return makeOptions(Tint, [
              Tint.Clear,
              Tint.Bronze,
              Tint.Green,
              Tint.Grey,
              Tint.BlueGreen,
              Tint.HighPerfGreen,
            ])
          } else {
            throw new Error("Unexpected PanesAppliedTo in tint updater.");
          }
        } else if (builder.glazing.value == Glazing.E0_05) {
          return makeOptions(Tint, [
            Tint.Clear,
            Tint.Bronze,
            Tint.Green,
            Tint.Grey,
            Tint.BlueGreen,
            Tint.HighPerfGreen,
          ])
        } else {
          throw new Error("Unexpected glazing: " + builder.glazing.value);
        }
      } else {
        if (builder.glazing.value == Glazing.None) {
          return makeOptions(Tint, [
            Tint.Clear,
            Tint.HighPerfGreen,
          ])
        } else {
          return makeOptions(Tint, [
            Tint.Clear,
          ])
        }
      }
    })
    this.paneThickness = new Field({
      name: 'Pane Thickness',
      type: FieldType.Select,
      choices: [],
    });
    this.paneThickness.makeChoicesUpdater(() => {
      if (elemIn(builder.tint.value, [Tint.Clear, Tint.Bronze, Tint.Green, Tint.Grey])) {
        return makeOptions(PaneThickness, [
          PaneThickness.QuarterInch,
          PaneThickness.EighthInch,
        ])
      } else {
        return makeOptions(PaneThickness, [PaneThickness.QuarterInch])
      }
    })
    this.gapType = new Field({
      name: 'Gap Type',
      type: FieldType.Select,
      choices: [],
    });
    this.gapType.makeChoicesUpdater(() => {
      //let name = this.isSlidingGlassDoor ? `SlidingGlassDoor` : `${this.window.name.value}`;
      // console.log(`${name || 'noname'}: Updating gapType. NumPanes: ${builder.numPanes.value}`);
      if (this.isSlidingGlassDoor) {
        if (builder.numPanes.value == NumPanes.N1) {
          return makeOptions(GapType, [
            GapType.None
          ])
        } else {
          return makeOptions(GapType, [
            GapType.QuarterInchAir,
            GapType.HalfInchAir,
            GapType.QuarterInchArgon,
            GapType.HalfInchArgon,
          ])
        }
      }
      if (builder.numPanes.value == NumPanes.N1) {
        return makeOptions(GapType, [
          GapType.None
        ])
      } else if (elemIn(builder.numPanes.value, [NumPanes.N2, NumPanes.N3])) {
        return makeOptions(GapType, [
          GapType.QuarterInchAir,
          GapType.HalfInchAir,
          GapType.QuarterInchArgon,
          GapType.HalfInchArgon,
        ])
      } else {
        return makeOptions(GapType, [
          GapType.QuarterInchAir,
          GapType.HalfInchAir,
          GapType.QuarterInchArgon,
          GapType.HalfInchArgon,
          GapType.QuarterInchKrypton,
        ])
      }
    })

    this.fields = [
      'numPanes',
      'frameStyle',
      'useStdFrameWidth',
      'frameWidth',
      'glazing',
      'panesAppliedTo',
      'tint',
      'paneThickness',
      'gapType',
    ];

    this.serFields = this.fields;
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Window Builder',
    }
  }

  getWinStyle() {
    if (!this.isSlidingGlassDoor) {
      return this.window.style.value;
    } else {
      return WindowStyle.Operable;
    }
  }

  /*
  getValues() {
    return {
      frameWidth: this.frameWidth.getValueInUnits(Units.ft),
      numPanes: this.numPanes.value,
      panesAppliedTo: this.panesAppliedTo.value,
      tint: this.tint.value,
      paneThickness: this.paneThickness.value,
      gapType: this.gapType.value,
      glazing: this.glazing.value,
      windowStyle: this.getWinStyle(),
      frameStyle: this.frameStyle.value,
    }
  }
  */
}
setupClass(WindowBuilder)

export let WindowStyle = makeEnum({
  Fixed: 'Fixed',
  Operable: 'Operable',
  GardenWindow: 'GardenWindow',
  CurtainWall: 'CurtainWall',
});

export let WindowInputType = makeEnum({
  Manual: 'Manual',
  BuildWindow: 'Build Window',
})

export class WindowType extends InputComponent {
  init(name, makeId) {
    this.id = makeId ? gApp.proj().makeId('WindowType') : 0;

    this.name = Field.makeName('Name', name)
    this.height = new Field({
      name: 'Height',
      type: FieldType.Length,
      requiresInput: true,
    })
    this.width = new Field({
      name: 'Width',
      type: FieldType.Length,
      requiresInput: true,
    })

    this.unusualShape = makeNoYesField('Non-rectangular shape', {visible: false})
    this.unusualShapeArea = new Field({
      name: 'Shape Area',
      type: FieldType.Area,
    })
    this.unusualShapePerimeter = new Field({
      name: 'Shape Perimeter',
      type: FieldType.Length,
    })
    this.updater.addWatchEffect('unusual-shape', () => {
      let unusualShape = this.unusualShape.value == YesNo.Yes;
      this.height.visible = !unusualShape;
      this.width.visible = !unusualShape;
      this.unusualShapeArea.visible = unusualShape;
      this.unusualShapePerimeter.visible = unusualShape;
    })

    this.style = Field.makeSelect('Window Style', WindowStyle)
    this.sealing = Field.makeSelect('Installation Sealing', InstallationSealing)
    this.bugScreen = new Field({
      name: 'Bug screen',
      type: FieldType.Select,
      choices: [],
    });
    this.bugScreen.makeChoicesUpdater(() => {
      if (this.style.value == WindowStyle.Operable) {
        return makeOptions(BugScreen, [BugScreen.Interior, BugScreen.Exterior, BugScreen.None])
      } else {
        return makeOptions(BugScreen, [BugScreen.None])
      }
    })

    this.inputType = Field.makeSelect('Input type', WindowInputType, {bold: true})

    this.manualUValue = new Field({
      name: 'U-Value',
      type: FieldType.UValue,
      min: 0,
      allowMin: false,
      requiresInput: true,
    })
    this.manualUValue.setVisibility(() => {
      return this.inputType.value == 'Manual';
    })
    this.manualShgc = new Field({
      name: 'SHGC (Total Window)',
      type: FieldType.Ratio,
      requiresInput: true,
    })
    this.manualShgc.setVisibility(() => {
      return this.inputType.value == 'Manual';
    })

    this.windowBuilder = WindowBuilder.create(this);
    this.updater.setEnabledWhen('winder-builder', this.windowBuilder, () => {
      this.inputType.value == WindowInputType.BuildWindow;
    })

    this.outputUValue = new Field({
      name: 'Output U-Value',
      type: FieldType.UValue,
      isOutput: true,
      allowMin: false,
    })
    this.outputUValue.makeUpdater((field) => {
      let uValue = this.computeUValue();
      field.value = uValue.uValue;
      field.debugOutput = DebugOn() ? prettyJson(uValue) : null;
    })
    this.outputShgc = new Field({
      name: 'Output SHGC',
      type: FieldType.Ratio,
      isOutput: true,
      allowMin: false,
    })
    this.outputShgc.makeUpdater((field) => {
      // console.log("Updating outputShgc");
      let shgc = this.computeShgc();
      field.value = shgc.TotalWindow;
      field.debugOutput = DebugOn() ? prettyJson(shgc) : null;
    })

    this.serFields = [
      'id',
      'name',
      'height',
      'width',
      'unusualShape',
      'unusualShapePerimeter',
      'style',
      'sealing',
      'bugScreen',
      'inputType',
      'manualUValue',
      'manualShgc',
      'windowBuilder',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: `Window`,
    }
  }

  getInputPage() {
    return {
      label: `Windows - ${this.name.value}`,
      // TODO
      path: `windows/${this.id}`,
    };
  }

  getArea() {
    if (this.unusualShape.value == YesNo.No) {
      return this.height.value * this.width.value;
    } else {
      return this.unusualShapeArea.value;
    }
  }

  getPerimeter() {
    if (this.unusualShape.value == YesNo.No) {
      return 2 * this.height.value + 2 * this.width.value;
    } else {
      return this.unusualShapePerimeter.value;
    }
  }

  hasBugScreen() {
    return this.bugScreen.value != BugScreen.None;
  }

  getWindowStyle() {
    return this.style.value;
  }

  getWindowBuilderIfUsing() {
    if (this.inputType.value == WindowInputType.BuildWindow) {
      return this.windowBuilder;
    }
    return null;
  }

  static _getUValuesRow(numPanes, glazing,
    panesAppliedTo, gapType) {
    if (numPanes == NumPanes.N1) {
      return 2;
    } else {
      // Row = startRow + gapIndex
      let startRow = null;
      if (numPanes == NumPanes.N2) {
        let rowIdMap = {
          [Glazing.None]: 5,
          [Glazing.E0_6]: 10,
          [Glazing.E0_4]: 15,
          [Glazing.E0_2]: 20,
          [Glazing.E0_1]: 25,
          [Glazing.E0_05]: 30,
        }
        startRow = lookupData(rowIdMap, [glazing]);
      } else if (numPanes == NumPanes.N3) {
        if (glazing == Glazing.None) {
          startRow = 35;
        } else if (glazing == Glazing.E0_2) {
          let rowIdMap = {
            [PanesAppliedTo.Surface2or3]: 40,
            [PanesAppliedTo.Surface4or5]: 40,
            [PanesAppliedTo.Surface2or3and4or5]: 45,
          };
          startRow = lookupData(rowIdMap, [panesAppliedTo]);
        } else if (glazing == Glazing.E0_1 || glazing == Glazing.E0_05) {
          startRow = 50;
        } else {
          throw new Error("Unexpected glazing: " + glazing);
        }
      } else if (numPanes == NumPanes.N4) {
        startRow = 55;
      } else {
        throw new Error("Unexpected - cannot find U-Value start row");
      }

      let rowGapMap = {
        [GapType.QuarterInchAir]: 1,
        [GapType.HalfInchAir]: 2,
        [GapType.QuarterInchArgon]: 3,
        [GapType.HalfInchArgon]: 4,
        [GapType.QuarterInchKrypton]: 5,
      };
      let gapIndex = lookupData(rowGapMap, [gapType])
      return startRow + gapIndex;
    }
  }

  static computeUValue(inputData) {
    let width = inputData.width; // ft
    let height = inputData.height; // ft
    let frameWidth = inputData.frameWidth; // ft
    let numPanes = inputData.numPanes;
    let glazing = inputData.glazing;
    let panesAppliedTo = inputData.panesAppliedTo;
    let gapType = inputData.gapType;
    // Note: this must be either a WindowStyle of SkylightStyle enum val
    let windowStyle = inputData.windowStyle;
    let frameStyle = inputData.frameStyle;
    let windowsData = inputData.windowsData;
    let isSkylight = valOr(inputData.isSkylight, false);
    let curbHeight = valOr(inputData.curbHeight, 0);

    // TODO - handle unusual shapes
    if (width == 0 || height == 0) {
      console.log("W or H is 0. Returning U-Value=0");
      return {
        uValue: 0,
      }
    }

    let debugVals = {};
    let rowIndex = WindowType._getUValuesRow(numPanes, glazing, panesAppliedTo, gapType);
    let centerUValue = windowsData.uValues.lookupValue(rowIndex, !isSkylight ? 'D' : 'U');
    let edgeUValue = windowsData.uValues.lookupValue(rowIndex, !isSkylight ? 'E' : 'V');
    extendMap(debugVals, {
      rowIndex,
      centerUValue,
      edgeUValue,
    })

    let stdAssemblyCol = lookupData(StdAssemblyColMap, [
      windowStyle,
      frameStyle,
    ]);
    let stdAssemblyUValue = windowsData.uValues.lookupValue(rowIndex, stdAssemblyCol);
    let stdVals = WindowType._getStdValues(windowStyle, frameStyle);
    extendMap(debugVals, {
      stdAssemblyCol,
    });

    let uValueInputs = {
      H: height ,
      W: width,
      Fw: frameWidth,
      Hs: stdVals.Hs,
      Ws: stdVals.Ws,
      Fws: stdVals.Fws,
      C: centerUValue,
      E: edgeUValue,
      Us: stdAssemblyUValue,
      isSkylight,
      curbHeight,
    }
    let uValueOutputs = WindowType._calculateUValue(uValueInputs);
    // Include some intermediate values in the output
    return {
      ...uValueInputs,
      ...uValueOutputs,
      // Main output:
      uValue: uValueOutputs.Uval,
      uValueFrame: uValueOutputs.Uf,
      debugVals,
    }
  }

  static _getStdFrameWidthMap() {
    return {
      [WindowStyle.Operable]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 1.5,
        [FrameStyle.AluminumWithThermalBreak]: 2.1,
        [FrameStyle.ReinforcedVinylSlashAluminumCladWood]: 2.8,
        [FrameStyle.WoodSlashVinyl]: 2.8,
        [FrameStyle.InsulatedFiberglassSlashVinyl]: 3.1,
      },
      [WindowStyle.Fixed]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 1.3,
        [FrameStyle.AluminumWithThermalBreak]: 1.3,
        [FrameStyle.ReinforcedVinylSlashAluminumCladWood]: 1.6,
        [FrameStyle.WoodSlashVinyl]: 1.6,
        [FrameStyle.InsulatedFiberglassSlashVinyl]: 1.8,
      },
      [WindowStyle.GardenWindow]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 1.75,
        [FrameStyle.WoodSlashVinyl]: 1.75,
      },
      [WindowStyle.CurtainWall]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 2.25,
        [FrameStyle.AluminumWithThermalBreak]: 2.25,
        [FrameStyle.StructuralGlazing]: 2.25,
      },
      [SkylightStyle.ManufacturedSkylight]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 0.7,
        [FrameStyle.AluminumWithThermalBreak]: 0.7,
        [FrameStyle.ReinforcedVinylSlashAluminumCladWood]: 0.9,
        [FrameStyle.WoodSlashVinyl]: 0.9,
      },
      [SkylightStyle.SiteAssembledSlopedSlashOverheadGlazing]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 2.25,
        [FrameStyle.AluminumWithThermalBreak]: 2.25,
        [FrameStyle.StructuralGlazing]: 2.5,
      },
      [SkylightStyle.SlopedCurtainWall]: {
        [FrameStyle.AluminumWithoutThermalBreak]: 2.25,
        [FrameStyle.AluminumWithThermalBreak]: 2.25,
        [FrameStyle.StructuralGlazing]: 2.25,
      }
    };
  }

  static _getStdDimensionsMap() {
    return {
      [WindowStyle.Operable]: {Hs: 5, Ws: 4},
      [WindowStyle.Fixed]: {Hs: 5, Ws: 4},
      [WindowStyle.GardenWindow]: {Hs: 5, Ws: 4},
      [WindowStyle.CurtainWall]: {Hs: 6.67, Ws: 6.67},
      [SkylightStyle.ManufacturedSkylight]: {Hs: 4, Ws: 4},
      [SkylightStyle.SiteAssembledSlopedSlashOverheadGlazing]: {Hs: 6.67, Ws: 6.67},
      [SkylightStyle.SlopedCurtainWall]: {Hs: 6.67, Ws: 6.67},
    };
  }

  static _getStdValues(windowStyle, frameStyle) {
    let Fws = lookupData(WindowType._getStdFrameWidthMap(), [
      windowStyle,
      frameStyle,
    ])
    let stdDimensions = lookupData(WindowType._getStdDimensionsMap(), [
      windowStyle,
    ])
    // Return in units of feet
    return {
      Fws: Fws / 12.0,
      Hs: stdDimensions.Hs,
      Ws: stdDimensions.Ws,
    }
  }

  // TODO - unused?
  static _calculateAreaValues(inputs) {
    let W = inputs.W;
    let H = inputs.H;
    let Fw = inputs.Fw;
    let Ew = inputs.Ew;

    let Ac = (H - Fw*2 - Ew*2) * (W - Fw*2 - Ew*2);
    let Ae = (H - Fw*2)*(W - Fw*2) - Ac;
    let A = H * W;
    let Af = A - Ac - Ae;
    return {
      Ac: Ac,
      Ae: Ae,
      A: A,
      Af: Af,
    }
  }

  static _calculateUValue(inputs) {
    /*
    Values used in calculations: (note: named differently!)

    Inputs:
    Fw = Frame width (feet)	** required user input
    C = Center of glass U-value
    E = Edge of glass U-value
    Us = Standard assembly U-value
    H = Height	(ft)	** required user input
    W = Width	(ft)	** required user input
    Hs = Standard window height
    Ws = Standard window width
    Fws  = Standard window frame width

    Other:
    isSkylight
    curbHeight (ft)

    Calc:
    Ew = 2.5 / 12		(constant ; Ew = Edge Width)
    Ac = (H - Fw*2 - Ew*2)*(W - Fw*2 - Ew*2)	(Center area)
    Ae = (H - Fw*2)*(W - Fw*2) - Ac			(Edge area)
    A = H * W						(Area)
    Af = A - Ac - Ae					(Frame area)

    Acs = (Hs - 2*Fws - 2*Ew) * (Ws - 2*Fws - 2*Ew)
    Aes = (Hs - 2*Fws) * (Ws - 2*Fws) - Acs
    As = Hs * Ws
    Afs = As - Acs - Aes

    Uf = (Us*As - C*Acs - E*Aes) / Afs

    Final U-value Calculation:
    Uval = (C*Ac + E*Ae + Uf*Af) / A
    */
    // console.log("Calculating U-Value with inputs: " + prettyJson(inputs));
    let H = inputs.H;
    let W = inputs.W;
    let Fw = inputs.Fw;
    let Hs = inputs.Hs;
    let Ws = inputs.Ws;
    let Fws = inputs.Fws;
    let C = inputs.C;
    let E = inputs.E;
    let Us = inputs.Us;

    if (H == 0 || W == 0) {
      throw new Error("Invalid H or W: 0ft");
    }

    let Ew = 2.5 / 12.0;
    let Ac = (H - Fw*2 - Ew*2) * (W - Fw*2 - Ew*2);
    let Ae = (H - Fw*2)*(W - Fw*2) - Ac;
    let A = H * W;
    let Af = A - Ac - Ae;

    // TODO - account for the unusualShape building types properly
    let Acs = (Hs - 2*Fws - 2*Ew) * (Ws - 2*Fws - 2*Ew);
    let Aes = (Hs - 2*Fws) * (Ws - 2*Fws) - Acs;
    let As = Hs * Ws;
    let Afs = As - Acs - Aes;

    let Uf = (Us*As - C*Acs - E*Aes) / Afs;
    let Uval = (C*Ac + E*Ae + Uf*Af) / A;

    return {
      // Main output
      Uval: Uval,
      // Intermediate outputs
      Ew: Ew,
      Ac: Ac,
      Ae: Ae,
      A: A,
      Af: Af,
      Acs: Acs,
      Aes: Aes,
      As: As,
      Afs: Afs,
      Uf: Uf,
    };
  }

  static _getTotalWindowShgcCol(frameStyle, windowStyle) {
    if (frameStyle == FrameStyle.AluminumWithoutThermalBreak ||
        frameStyle == FrameStyle.AluminumWithThermalBreak) {
      if (windowStyle == WindowStyle.Operable) {
        return 'P';
      } else {
        return 'Q';
      }
    } else {
      if (windowStyle == WindowStyle.Operable) {
        return 'R';
      } else {
        return 'S';
      }
    }
    throw new Error("Could not find Total Window SHGC column.");
  };

  // For use with the manual method
  static _estimateShgcs(shgcTotalWindow, uValue) {
    let multipliers = null;
    if (uValue >= 0.9) {
      multipliers = {
        Deg0: 1, Deg40: 0.98, Deg50: 0.95, Deg60: 0.89, Deg70: 0.77, Deg80: 0.50, Diffuse: 0.90,
      }
    } else if (uValue >= 0.6) {
      multipliers = {
        Deg0: 1, Deg40: 0.96, Deg50: 0.92, Deg60: 0.83, Deg70: 0.67, Deg80: 0.38, Diffuse: 0.86,
      }
    } else if (uValue >= 0.50) {
      multipliers = {
        Deg0: 1, Deg40: 0.96, Deg50: 0.91, Deg60: 0.83, Deg70: 0.65, Deg80: 0.35, Diffuse: 0.86,
      }
    } else {
      multipliers = {
        Deg0: 1, Deg40: 0.96, Deg50: 0.90, Deg60: 0.79, Deg70: 0.57, Deg80: 0.27, Diffuse: 0.84,
      }
    }
    
    // Note: the 'shgc' value is an alias for the TotalWindow value
    let shgcs = {TotalWindow: shgcTotalWindow, shgc: shgcTotalWindow}
    for (const key in multipliers) {
      shgcs[key] = shgcTotalWindow * multipliers[key];
    }
    return shgcs;
  }

  static _estimateSkylightShgcs(shgcTotalWindow) {
    let multipliers = {
      Deg0: 1, Deg40: 0.982, Deg50: 0.939, Deg60: 0.860, Deg70: 0.658, Deg80: 0.368, Diffuse: 0.877,
    }
    // Note: the 'shgc' value is an alias for the TotalWindow value
    let shgcs = {TotalWindow: shgcTotalWindow, shgc: shgcTotalWindow}
    for (const key in multipliers) {
      shgcs[key] = shgcTotalWindow * multipliers[key];
    }
    return shgcs;
  }

  static _getShgcValuesRow(numPanes, panesAppliedTo,
      paneThickness, tint, glazing) {
    let sectionRowMap = {
      [NumPanes.N1]: 1,
      [NumPanes.N2]: 77,
      [NumPanes.N3]: 365,
      [NumPanes.N4]: 365
    }
    let sectionRow = lookupData(sectionRowMap, [numPanes])
    let subSecRow1 = null;
    let subSecRow2 = null;
    let paneAdj = 0;

    if (numPanes == NumPanes.N1) {
      paneAdj = paneThickness == PaneThickness.EighthInch ? -5 : 0;
      subSecRow1 = 5;
      let tintIndexMap = {
        [Tint.Clear]: 0,
        [Tint.Bronze]: 1,
        [Tint.Green]: 2,
        [Tint.Grey]: 3,
        [Tint.BlueGreen]: 4,
        [Tint.StainlessSteelOnClear8p]: 5,
        [Tint.StainlessSteelOnClear14p]: 6,
        [Tint.StainlessSteelOnClear20p]: 7,
        [Tint.StainlessSteelOnGreen14p]: 8,
        [Tint.TitaniumOnClear20p]: 9,
        [Tint.TitaniumOnClear30p]: 10,
      }
      let tintIndex = lookupData(tintIndexMap, [tint])
      subSecRow2 = tintIndex <= 3 ? 10*tintIndex : (30 + 5*(tintIndex - 3));
    } else if (numPanes == NumPanes.N2) {
      paneAdj = paneThickness == PaneThickness.EighthInch ? -6 : 0;
      if (glazing == Glazing.None ||
          glazing == Glazing.E0_6) {
        subSecRow1 = 6;
        let tintIndexMap = {
          [Tint.Clear]: 0,
          [Tint.Bronze]: 1,
          [Tint.Green]: 2,
          [Tint.Grey]: 3,
          [Tint.BlueGreen]: 4,
          [Tint.HighPerfGreen]: 5,
          [Tint.StainlessSteelOnClear8p]: 6,
          [Tint.StainlessSteelOnClear14p]: 7,
          [Tint.StainlessSteelOnClear20p]: 8,
          [Tint.StainlessSteelOnGreen14p]: 9,
          [Tint.TitaniumOnClear20p]: 10,
          [Tint.TitaniumOnClear30p]: 11,
        }
        let tintIndex = lookupData(tintIndexMap, [tint])
        subSecRow2 = tintIndex <= 3 ? 12 * tintIndex : (36 + 6 * (tintIndex - 3));
      } else if (glazing == Glazing.E0_4 || glazing == Glazing.E0_2) {
        subSecRow1 = 97;
        if (panesAppliedTo == PanesAppliedTo.Surface2) {
          subSecRow2 = 6;
        } else if (panesAppliedTo == PanesAppliedTo.Surface3) {
          let tintIndexMap = {
            [Tint.Clear]: 0,
            [Tint.Bronze]: 1,
            [Tint.Green]: 2,
            [Tint.Grey]: 3,
            [Tint.BlueGreen]: 4,
            [Tint.HighPerfGreen]: 5,
          }
          let tintIndex = lookupData(tintIndexMap, [tint])
          subSecRow2 = tintIndex <= 3 ? (19 + 12*tintIndex) : (55 + 6*(tintIndex - 3));
        } else {
          throw new Error(`Unexpected panesAppliedTo: ${panesAppliedTo}`);
        }
      } else if (glazing == Glazing.E0_1) {
        subSecRow1 = 171;
        if (panesAppliedTo == PanesAppliedTo.Surface2) {
          subSecRow2 = 6;
        } else if (panesAppliedTo == PanesAppliedTo.Surface3) {
          let tintIndexMap = {
            [Tint.Clear]: 0,
            [Tint.Bronze]: 1,
            [Tint.Green]: 2,
            [Tint.Grey]: 3,
            [Tint.BlueGreen]: 4,
            [Tint.HighPerfGreen]: 5,
          }
          let tintIndex = lookupData(tintIndexMap, [tint])
          subSecRow2 = tintIndex <= 3 ? (19 + 12*tintIndex) : (55 + 6*(tintIndex - 3));
        } else {
          throw new Error("Unexpected panesAppliedTo");
        }
      } else if (glazing == Glazing.E0_05) {
        subSecRow1 = 251;
        let tintIndexMap = {
          [Tint.Clear]: 0,
          [Tint.Bronze]: 1,
          [Tint.Green]: 2,
          [Tint.Grey]: 3,
          [Tint.BlueGreen]: 4,
          [Tint.HighPerfGreen]: 5,
        }
        let tintIndex = lookupData(tintIndexMap, [tint])
        subSecRow2 = 6*tintIndex
        if (tint != Tint.Clear) {
          paneAdj = 0;
        }
      } else {
        throw new Error(`Unknown glazing : ${glazing}`);
      }
    } else {
      // NumPanes is N3 or N4
      paneAdj = paneThickness == PaneThickness.EighthInch ? -7 : 0;
      if (glazing == Glazing.None ||
        glazing == Glazing.E0_6) {
        subSecRow1 = 0;
        subSecRow2 = tint == Tint.Clear ? 7 : 14;
      } else if (glazing == Glazing.E0_4 || glazing == Glazing.E0_2) {
        if (panesAppliedTo == PanesAppliedTo.Surface2or3) {
          subSecRow1 = 29;
          subSecRow2 = 0;
        } else if (panesAppliedTo == PanesAppliedTo.Surface4or5) {
          subSecRow1 = 44;
          subSecRow2 = 0;
        } else {
          subSecRow1 = 44;
          subSecRow2 = 0;
        }
      } else if (glazing == Glazing.E0_1) {
        subSecRow1 = 59;
        subSecRow2 = 0;
      } else {
        subSecRow1 = 74;
        subSecRow2 = 0;
      }
    }
    if (subSecRow1 == null || !subSecRow2 == null) {
      throw new Error(`subSecRow1 or subSecRow2 is null: ${subSecRow1}, ${subSecRow2}`);
    }
    /*
    console.log(`Row lookup: `, {
      sectionRow: sectionRow,
      subSecRow1: subSecRow1,
      subSecRow2: subSecRow2,
      paneAdj: paneAdj
    });
    */

    let shgcValuesRow = sectionRow + subSecRow1 + subSecRow2 + paneAdj
    return {
      row: shgcValuesRow,
      debugVals: {
        sectionRow,
        subSecRow1,
        subSecRow2,
        paneAdj
      }
    };
  }

  static _lookupShgcs(windowsData, shgcValuesRow, shgcTotalWindowCol) {
    let shgcCols = {
      Deg0: 'H',
      Deg40: 'I',
      Deg50: 'J',
      Deg60: 'K',
      Deg70: 'L',
      Deg80: 'M',
      Diffuse: 'N',
      TotalWindow: shgcTotalWindowCol,
      shgc: shgcTotalWindowCol,
    }
    let shgcs = {};
    for (const key in shgcCols) {
      shgcs[key] = windowsData.shgcValues.lookupValue(shgcValuesRow, shgcCols[key]);
    }
    // Also save the glazingId (used later)
    shgcs.glazingId = windowsData.shgcValues.lookupValue(shgcValuesRow, 'A', String)
    return shgcs;
  }

  static normalizeShgcValues(shgcValues) {
    /**
     * The SHGC values should always have SHGC_TOTAL_WINDOW=SHGC_DEG0, but
     * this is sometimes not the case when looking up from the data.
     * So use this func to normalize the values.
     */
    let keys = [
      'Deg0',
      'Deg40',
      'Deg50',
      'Deg60',
      'Deg70',
      'Deg80',
      'Diffuse',
    ]
    let multipler = shgcValues.TotalWindow / shgcValues.Deg0;
    for (const key of keys) {
      shgcValues[key] *= multipler;
    }
  }

  static computeShgc(inputData) {
    let width = inputData.width; // ft
    let height = inputData.height; // ft
    let frameWidth = inputData.frameWidth; // ft
    let numPanes = inputData.numPanes;
    let glazing = inputData.glazing;
    let panesAppliedTo = inputData.panesAppliedTo;
    let gapType = inputData.gapType;
    let paneThickness = inputData.paneThickness;
    let tint = inputData.tint;
    let windowStyle = inputData.windowStyle;
    let frameStyle = inputData.frameStyle;
    let windowsData = inputData.windowsData;
    let isSkylight = valOr(inputData.isSkylight, false);
    let curbHeight = valOr(inputData.curbHeight, 0);

    if (width == 0 || height == 0) {
      console.log("W or H is 0. Returning SHGC=0");
      return {
        shgc: 0,
      }
    }

    let debugVals = {};
    let shgcValuesRowRes = WindowType._getShgcValuesRow(numPanes, panesAppliedTo, paneThickness, tint, glazing)
    let shgcValuesRow = shgcValuesRowRes.row;
    let shgcTotalCol = WindowType._getTotalWindowShgcCol(frameStyle, windowStyle);
    // console.log(`SHGC row, SHGC_T col: ${shgcValuesRow}, ${shgcTotalCol}`);
    let shgcs = WindowType._lookupShgcs(windowsData, shgcValuesRow, shgcTotalCol);
    // console.log(`SHGC_T: ${shgcs.TotalWindow}, SHGC_0deg: ${shgcs.Deg0}, GlazingId: ${shgcs.glazingId}`);

    extendMap(debugVals, {
      rowInfo: shgcValuesRowRes,
      shgcTotalCol,
    });

    // Calculate SHGC of frame, and use to revise SHGC_Total
    // SHGC_F = SHGC_T * (Hs * Ws) / Afs - SHGC_0deg * (Hs * Ws - Afs) / Afs
    // SHGC_T_NEW = (SHGC_0deg * (Ac + Ae) + SHGC_F * Af) / (Ac  + Ae + Af)
    let uValueOutputs = WindowType.computeUValue({
      width: width,
      height: height,
      frameWidth: frameWidth,
      numPanes: numPanes,
      glazing: glazing,
      panesAppliedTo: panesAppliedTo,
      gapType: gapType,
      windowStyle: windowStyle,
      frameStyle: frameStyle,
      windowsData: windowsData,
      isSkylight,
      curbHeight,
    });
    let SHGC_T = shgcs.TotalWindow;
    let Hs = uValueOutputs.Hs;
    let Ws = uValueOutputs.Ws;
    let Afs = uValueOutputs.Afs;
    let Ac = uValueOutputs.Ac;
    let Ae = uValueOutputs.Ae;
    let Af_flat = uValueOutputs.Af_flat;
    let Af = uValueOutputs.Af;
    let SHGC_0deg = shgcs.Deg0;
    let SHGC_F = SHGC_T * (Hs * Ws) / Afs - SHGC_0deg * (Hs * Ws - Afs) / Afs
    let SHGC_T_NEW = (SHGC_0deg * (Ac + Ae) + SHGC_F * Af) / (Ac  + Ae + Af)
    let multiplier = SHGC_T_NEW / SHGC_0deg;

    debugVals.multiplier = multiplier;
    debugVals.shgcTOrig = SHGC_T;
    debugVals.shgcTNew = SHGC_T_NEW;

    // Update all SHGC values
    shgcs.Deg0 *= multiplier;
    shgcs.Deg40 *= multiplier;
    shgcs.Deg50 *= multiplier;
    shgcs.Deg60 *= multiplier;
    shgcs.Deg70 *= multiplier;
    shgcs.Deg80 *= multiplier;
    shgcs.Diffuse *= multiplier;
    shgcs.TotalWindow = SHGC_T_NEW;
    shgcs.shgc = shgcs.TotalWindow;

    return {
      ...shgcs,
      debugVals
    };
  }

  computeUValue() {
    if (this.inputType.value == WindowInputType.Manual) {
      return {
        uValue: this.manualUValue.value
      };
    } else {
      let builder = this.windowBuilder;
      let inputData = {
        width: this.width.getValueInUnits(Units.ft),
        height: this.height.getValueInUnits(Units.ft),
        frameWidth: builder.frameWidth.getValueInUnits(Units.ft),
        numPanes: builder.numPanes.value,
        glazing: builder.glazing.value,
        panesAppliedTo: builder.panesAppliedTo.value,
        gapType: builder.gapType.value,
        windowStyle: this.style.value,
        frameStyle: builder.frameStyle.value,
        windowsData: gApp.proj().windowsData,
      }
      return WindowType.computeUValue(inputData);
    }
  }

  computeShgc() {
    if (this.inputType.value == WindowInputType.Manual) {
      return WindowType._estimateShgcs(this.manualShgc.value,
        this.manualUValue.value);
    } else {
      let builder = this.windowBuilder;
      let inputData = {
        width: this.width.getValueInUnits(Units.ft),
        height: this.height.getValueInUnits(Units.ft),
        frameWidth: builder.frameWidth.getValueInUnits(Units.ft),
        numPanes: builder.numPanes.value,
        glazing: builder.glazing.value,
        panesAppliedTo: builder.panesAppliedTo.value,
        gapType: builder.gapType.value,
        paneThickness: builder.paneThickness.value,
        tint: builder.tint.value,
        windowStyle: this.style.value,
        frameStyle: builder.frameStyle.value,
        windowsData: gApp.proj().windowsData,
      }
      return WindowType.computeShgc(inputData);
    }
  }

  // See IAC values docs
  // Note: winType may be a WindowType or DoorType (DoorType implements the same interface used for glass doors)
  static computeIAC(winType, inShading, iacValues) {
    let iacCalculator = new IACCalculator(winType, inShading, iacValues);
    return iacCalculator.computeIAC();
  }

  exportAsTestCase() {
    let inputData = ser.writeToJson(this);
    let dataStr = prettyJson({
      input: inputData,
      output: {
        uValue: this.computeUValue(),
        shgc: this.computeShgc(),
      }
    });
    console.log("Exporting test case:\n", dataStr);
    downloadTextFile(dataStr, "WindowTestCase.js");
  }
};
setupClass(WindowType);