import { makeEnum, makeOptions, } from '../Base'
import { Field, FieldType, } from '../Common/Field'

export let kDirectionChoices = [
  'N',
  'NNE',
  'NE',
  'ENE',
  'E',
  'ESE',
  'SE',
  'SSE',
  'S',
  'SSW',
  'SW',
  'WSW',
  'W',
  'WNW',
  'NW',
  'NNW',
];

export let YesNo = makeEnum({
  Yes: 'Yes',
  No: 'No',
})

export function makeYesNoField(fieldName, opts) {
  // Defaults to Yes
  return Field.makeSelect(fieldName, YesNo, opts)
}

export function makeNoYesField(fieldName, otherOpts) {
  // Shows No first in the list (and defaults to No)
  return new Field({
    name: fieldName,
    type: FieldType.Select,
    choices: makeOptions(YesNo, [YesNo.No, YesNo.Yes]),
    ...otherOpts,
  })
}

export let UnknownOrEnter = makeEnum({
  Unknown: 'Unknown',
  Enter: 'Enter',
})

export let Season = makeEnum({
  Winter: 'Winter',
  Summer: 'Summer',
})

export function getSeasonOfMonth(month) {
  // Nov, Dec, Jan, Feb, and Mar are winter months.
  return (month >= 11 || month <= 3) ? Season.Winter : Season.Summer;
}

export let WallOrRoof = makeEnum({
  Wall: 'Wall',
  Roof: 'Roof',
})

export let ManualOrAutomatic = makeEnum({
  Manual: 'Manual',
  Automatic: 'Automatic',
})

export let AutomaticOrManual = makeEnum({
  Automatic: 'Automatic',
  Manual: 'Manual',
})

export let ManualOrSelectFromList = makeEnum({
  Manual: 'Manual',
  SelectFromList: 'Select from List',
})

export let InstallationSealing = makeEnum({
  Tight: 'Tight',
  Average: 'Average',
  Loose: 'Loose',
})