export let Appliances_Data = `Appliances,,,,,,
,,,,,,
"Cooking Appliances - Hooded, electric",,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad
Broiler_underfired_3_ft,Broiler: underfired 3 ft,"36,900",0,0.84,0,0.35
Cheesemelter,Cheesemelter,"12,300",0,0.97,0,0.39
Fryer_kettle,"Fryer, kettle","99,000",0,0.02,0,0.28
Fryer_open_deep-fat_1_vat,"Fryer, open deep-fat, 1-vat","47,800",0,0.06,0,0.36
Fryer_pressure,"Fryer, pressure","46,100",0,0.06,0,0.19
Griddle_double-sided_3_ft_clamshell_down,"Griddle, double-sided 3 ft (clamshell down)","72,400",0,0.1,0,0.2
Griddle_clamshell_up,"Griddle, (Clamshell up)","72,400",0,0.16,0,0.31
Griddle_flat_3_ft,"Griddle, flat 3 ft","58,400",0,0.2,0,0.39
Griddle_small_3_ft,"Griddle, small 3 ft","30,700",0,0.2,0,0.44
Induction_cooktop,Induction cooktop,"71,700",0,0,0,0
Induction_wok,Induction wok,"11,900",0,0,0,0
Oven_combi_combi_mode,"Oven, combi: combi-mode","56,000",0,0.1,0,0.15
Oven_combi_convection_mode,"Oven, combi: convection mode","56,000",0,0.1,0,0.25
Oven_convection_full_sized,"Oven, convection, full-sized","41,300",0,0.16,0,0.22
Oven_convection_half_sized,"Oven, convection, half-sized","18,800",0,0.2,0,0.14
Pasta_cooker,Pasta cooker,"75,100",0,0.11,0,0
Range_top_top_off_oven_on,"Range top, top off/oven on","16,600",0,0.24,0,0.25
Range_top_3_elements_on_oven_off,"Range top, 3 elements on/oven off","51,200",0,0.3,0,0.41
Range_top_6_elements_on_oven_off,"Range top, 6 elements on/oven off","51,200",0,0.65,0,0.42
Range_top_3_elements_on_oven_on,"Range top, 6 elements on/oven on","67,800",0,0.54,0,0.4
Range_hot_top,"Range, hot-top","54,000",0,0.95,0,0.23
Rotisserie,Rotisserie,"37,900",0,0.36,0,0.33
Salamander,Salamander,"23,900",0,0.97,0,0.3
Steam_kettle_large_60_gal_simmer_lid_down,"Steam kettle, large (60 gal) simmer lid down","110,600",0,0.02,0,0.04
Steam_kettle_medium_40_gal_simmer_lid_down,"Steam kettle, medium (40 gal) simmer lid down","73,700",0,0.02,0,0.17
Steamer_compartment_atmospheric,"Steamer, compartment, atmospheric","33,400",0,0.46,0,0.01
Tilting_skillet_braising_pan,Tilting skillet/braising pan,"32,900",0,0.16,0,0
,,,,,,
,,,,,,
"Cooking Appliances - Hooded, gas",,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad
Broiler_batch,"Broiler, batch","95,000",0,0.73,0,0.12
Broiler_chain_conveyor,"Broiler, chain (conveyor)","132,000",0,0.73,0,0.14
Broiler_overfired_upright,"Broiler, overfired (upright)","100,000",0,0.88,0,0.03
Broiler_underfired,"Broiler, underfired","96,000",0,0.77,0,0.12
Fryer_doughnut,"Fryer, doughnut","44,000",0,0.28,0,0.23
Fryer_open_deep_fat_1_vat,"Fryer, open deep-fat, 1 vat","80,000",0,0.06,0,0.23
Fryer_pressure,"Fryer, pressure","80,000",0,0.11,0,0.09
Griddle_double-sided_3_ft_clamshell_down,"Griddle, double sided 3 ft, clamshell down","108,200",0,0.07,0,0.23
Griddle_clamshell_up,"Griddle, clamshell up","108,200",0,0.14,0,0.33
Griddle_flat_3_ft,"Griddle, flat 3 ft","90,000",0,0.23,0,0.18
Oven_combi_combi_mode,"Oven, combi: combi-mode","75,700",0,0.08,0,0.07
Oven_combi_convection_mode,"Oven, combi: convection mode","75,700",0,0.08,0,0.17
Oven_convection_full_sized,"Oven, convection, full-size","44,000",0,0.27,0,0.08
Oven_conveyor_pizz,"Oven, conveyor (pizza)","170,000",0,0.4,0,0.11
Oven_deck,"Oven, deck","105,000",0,0.2,0,0.17
Oven_rack_mini_rotating,"Oven, rack mini-rotating","56,300",0,0.08,0,0.24
Pasta_cooker,Pasta cooker,"80,000",0,0.3,0,0
Range_top_top_off_oven_on,"Range top, top off/oven on","25,000",0,0.3,0,0.27
Range_top_3_burners_on_oven_off,"Range top, 3 burners on/oven off","120,000",0,0.5,0,0.12
Range_top_6_elements_on_oven_off,"Range top, 6 burners on/oven off","120,000",0,1.01,0,0.1
Range_top_3_elements_on_oven_on,"Range top, 6 burners on/oven on","145,000",0,0.85,0,0.11
Range_wok,Range: wok,"99,000",0,0.88,0,0.06
Rethermalizer,Rethermalizer,"90,000",0,0.26,0,0.49
Rice cooker,Rice cooker,"35,000",0,0.01,0,0.6
Salamander,Salamander,"35,000",0,0.95,0,0.16
Steam_kettle_large_60_gal_simmer_lid_down,"Steam kettle, large (60 gal) simmer lid down","145,000",0,0.04,0,0
Steam_kettle_small_10_gal_simmer_lid_down,"Steam kettle, small (10 gal) simmer lid down","52,000",0,0.06,0,0.09
Steam_kettle_medium_40_gal_simmer_lid_down,"Steam kettle, medium (40 gal) simmer lid down","100,000",0,0.04,0,0
Steamer_compartment_atmospheric,Steamer: compartment: atmospheric,"26,000",0,0.32,0,0
Tilting_skillet_braising_pan,Tilting skillet/braising pan,"104,000",0,0.1,0,0.04
,,,,,,
,,,,,,
"Cooking Appliances - Hooded, solid fuel",,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad
Broiler_charcoal,"Broiler, charcoal","42,000",0,1,0,0.15
Broiler_wood (mesquite),"Broiler, wood (mesquite)","49,600",0,1,0,0.14
,,,,,,
,,,,,,
Cooking Appliances - Unhooded,,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad
Cabinet_hot_serving_large_insulated,"Cabinet; hot serving (large), insulated","6,800",0,0.18,0.67,0.33
Cabinet_hot_serving_large_uninsulated,"Cabinet; hot serving (large), uninsulate","6,800",0,0.51,0.8,0.2
Cabinet_proofing_large,Cabinet; proofing (large),"14,914",2486,0.08,0.14,0.86
Cabinet_proofing_small_15_shelf,Cabinet; proofing (small 15-shelf),"3,300",11000,0.27,1,0
Cheesemelter,Cheesemelter,"8,200",0,0.41,0.55,0.45
Coffee_brewing_urn,Coffee brewing urn,"5,417",7583,0.09,0.83,0.17
Drawer_warmers_2_drawer_moist_holding,"Drawer warmers, 2-drawer (moist holding)",0,4100,0.12,1,0
Egg_cooker,Egg cooker,"8,100",0,0.1,0.74,0.26
Espresso_machine,Espresso machine,"8,200",0,0.15,0.67,0.33
Food_warmer_steam_table_2_well_type,Food warmer: steam table (2-well-type),"1,311",3789,0.69,0.91,0.09
Freezer_small,Freezer (small),"2,700",0,0.41,0.55,0.45
Fryer_countertop_open_deep_fat,"Fryer, countertop, open deep fat","15,700",0,0.09,0.53,0.47
Griddle_countertop,"Griddle, countertop","27,300",0,0.22,0.52,0.48
Hot_dog_roller,Hot dog roller,"5,500",0,0.77,0.78,0.22
Hot_plate_single_element,Hot plate; single element,"3,800",0,0.89,0.68,0.32
Hot_food_case_dry_holding,Hot-food case (dry holding),"31,100",0,0.08,0.64,0.36
Hot_food_case_moist_holding,Hot-food case (moist holding),"25,445",5655,0.11,0.73,0.27
Induction_hob_countertop,"Induction hob, countertop","17,100",0,0,1,0
Microwave_oven_commercial,Microwave oven: commercial,"5,800",0,0,1,0
Oven_countertop_conveyorized,Oven; countertop conveyorized,"17,100",0,0.79,0.82,0.18
Panini,Panini,"6,100",0,0.37,0.71,0.29
Popcorn_popper,Popcorn popper,"2,900",0,0.14,0.76,0.24
Rapid_cook_oven_quartz_halogen,Rapid-cook oven (quartz-halogen),"41,000",0,0,1,0
Rapid_cook_oven_microwave_convection,Rapid-cook oven (microwave/convection),"19,400",0,0.2,0.92,0.08
Reach_in_refrigerator,Reach-in refrigerator,"4,800",0,0.25,0.75,0.25
Refrigerated_prep_table,Refrigerated prep table,"2,000",0,0.45,0.33,0.67
Rice_cooker,Rice cooker,"5,300",0,0.05,0.83,0.17
Soup_warmer,Soup warmer,415,2285,0.49,1,0
Steamer_bun,Steamer (bun),"5,100",0,0.13,0.84,0.16
Steamer_countertop,"Steamer, countertop","18,867",9433,0.04,1,0
Toaster_4_slice_pop_up_large_cooking,Toaster; 4-slice pop up (large): cooking,"3,754",2346,0.49,0.93,0.07
Toaster_contact_vertical,Toaster; contact (vertical),"8,900",0,0.29,0.76,0.24
Toaster_large_conveyor,Toaster; large conveyor,"32,800",0,0.31,0.71,0.29
Toaster_small_conveyor,Toaster; small conveyor,"6,000",0,0.98,0.79,0.21
Tortilla_grill,Tortilla grill,"7,500",0,0.47,0.75,0.25
Waffle_iron,Waffle iron,"9,200",0,0.1,0.78,0.22
,,,,,,
,,,,,,
Dishwashing Appliances,,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad
Pre_rinse_spray_valve_small,"Pre-rinse spray valve, small",200,8200,1,1,0
Pre_rinse_spray_valve_medium,"Pre-rinse spray valve, medium",300,"11,700",1,1,0
Pre_rinse_spray_valve_large,"Pre-rinse spray valve, large",1100,"13,800",1,1,0
3_compartment_sink_rinsing,"3-Compartment sink, rinsing",900,4900,1,1,0
Power_wash_sink_rinsing,"Power wash sink, rinsing",2000,"3,100",1,1,0
Scrapper,Scrapper,1200,"11,000",1,1,0
Scrapper_with_trough,Scrapper with trough,2800,"13,900",1,1,0
Under_counter_dishwasher_low_temperature,"Under counter dishwasher, low temperature",2200,4900,1,1,0
Under_counter_dishwasher_high_temperature,"Under counter dishwasher, high temperature",4000,4600,1,1,0
Under_counter_dishwasher_high_temperature_with_heat_recovery_unhooded,"Under counter dishwasher, high temperature with heat recovery, unhooded",2200,1100,1,1,0
Under_counter_dishwasher_low_temperature_dump_and_fill_unhooded,"Upright door type dishwasher, low temperature dump and fill, unhooded",3200,3500,1,1,0
Under_counter_dishwasher_low_temperature_with_tank_unhooded,"Upright door type dishwasher, low temperature with tank, unhooded",3900,13200,1,1,0
Upright_door_type_high_temperature_unhooded,"Upright door type, high temperature, unhooded",8000,21400,1,1,0
Upright_door_type_high_temperature_with_heat_recovery_unhooded,"Upright door type dishwasher, high temperature with heat recovery, unhooded",4800,13000,1,1,0
Pot_and_pan_washer_unhooded,"Pot and pan washer, unhooded",6000,23500,1,1,0
Pot_and_pan_washer_with_heat_recovery_unhooded,"Pot and pan washer with heat recovery, unhooded",5500,19000,1,1,0
44_in_conveyor_dishwasher_unvented,"44 in. Conveyor dishwasher, unvented",10000,59300,1,1,0
66_in_conveyor_dishwasher_unvented,"66 in. Conveyor dishwasher, unvented",16100,45000,1,1,0
Upright_door_type_dishwasher_high_temperature_under_a_3x3_hood_at_300_cfm,"Upright door type dishwasher, high temperature under a 3×3 ft hood at 300 cfm",3500,13000,1,1,0
Upright_door_type_dishwasher_high_temperature_under_a_5x4_hood_at_500_cfm,"Upright door type dishwasher, high temperature under a 5×4 ft hood at 500 cfm",1600,7900,1,1,0
44_in_conveyor_dishwasher_high_temperature_under_a_10_ft_hood_at_1000_cfm,"44 in. Conveyor dishwasher, high temperature under a 10 ft hood at 1000 cfm",1000,20000,1,1,0
Ducted_66_in_conveyor_dishwasher,Ducted 66 in. conveyor dishwasher,10600,6300,1,1,0
Ducter_flight_type_conveyor_dishwasher,Ducted Flight type conveyor dishwasher,13400,8900,1,1,0
Ducted_flight_type_conveyor_dishwasher_with_heat_recovery,Ducted Flight type conveyor dishwasher with heat recovery,12300,3600,1,1,0
Ducted_flight_type_conveyor_dishwasher_with_blow_dryer,Ducted Flight type conveyor dishwasher with blow dryer,21200,15700,1,1,0
,,,,,,
,,,,,,
Laboratory Equipment,,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad
Analytical_balance,Analytical balance,23.884,0,1,0.5,0.5
Centrifuge_small,Centrifuge (small),303.668,0,0.9775280899,0.5,0.5
Centrifuge_medium,Centrifuge (medium),464.032,0,0.9705882353,0.5,0.5
Centrifuge_large,Centrifuge (large),4012.512,0,0.6207482993,0.5,0.5
Electrochemical_analyzer_small,Electrochemical analyzer (small),153.54,0,0.9777777778,0.5,0.5
Electrochemical_analyzer_large,Electrochemical analyzer (large),290.02,0,0.9882352941,0.5,0.5
Flame_photometer,Flame photometer,365.084,0,0.9813084112,0.5,0.5
Fluorescent_microscope_small,Fluorescent microscope (small),491.328,0,0.9930555556,0.5,0.5
Fluorescent_microscope_large,Fluorescent microscope (large),699.46,0,0.8682926829,0.5,0.5
Function_generator,Function generator,98.948,0,1,0.5,0.5
Incubator_small,Incubator (small),1572.932,0,0.978308026,0.5,0.5
Incubator_medium,Incubator (medium),1634.348,0,0.5511482255,0.5,0.5
Incubator_large,Incubator (large),4555.02,0,0.9153558052,0.5,0.5
Orbital_shaker,Orbital shaker,54.592,0,1,0.5,0.5
Oscilloscope_small,Oscilloscope (small),129.656,0,1,0.5,0.5
Oscilloscope_large,Oscilloscope (large),337.788,0,0.9797979798,0.5,0.5
Rotary_evaporator_small,Rotary evaporator (small),252.488,0,0.9864864865,0.5,0.5
Rotary_evaporator_large,Rotary evaporator (large),98.948,0,0.9655172414,0.5,0.5
Spectronics,Spectronics,105.772,0,1,0.5,0.5
Spectrophotometer_large,Spectrophotometer (large),361.672,0,0.9811320755,0.5,0.5
Spectrophotometer_medium,Spectrophotometer (medium),416.264,0,0.9918032787,0.5,0.5
Spectrophotometer_small,Spectrophotometer (small),433.324,0,0.9842519685,0.5,0.5
Spectro_fluorometer,Spectro fluorometer,1381.86,0,0.975308642,0.5,0.5
Thermocycler_large,Thermocycler (large),3292.58,0,0.6642487047,0.5,0.5
Thermocycler_small,Thermocycler (small),794.996,0,0.8497854077,0.5,0.5
Tissue_culture_small,Tissue culture (small),450.384,0,0.3484848485,0.5,0.5
Tissue_culture_large,Tissue culture (large),4019.336,0,0.9728353141,0.5,0.5
,,,,,,
,,,,,,
Medical Equipment,,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad
Anesthesia_system,Anesthesia system,603.924,0,0.9378531073,0.5,0.5
Blanket_warmer,Blanket warmer,1719.648,0,0.4384920635,0.5,0.5
Blood_pressure_meter,Blood pressure meter,112.596,0,0.8787878788,0.5,0.5
Blood_warmer,Blood warmer,696.048,0,0.5588235294,0.5,0.5
ECG_RESP,ECG/RESP,184.248,0,0.9259259259,0.5,0.5
Electrosurgery,Electrosurgery,501.564,0,0.7414965986,0.5,0.5
Endoscope,Endoscope,2064.26,0,0.9851239669,0.5,0.5
Harmonical_scalpel,Harmonical scalpel,204.72,0,0.9833333333,0.5,0.5
Hysteroscopic_pump,Hysteroscopic pump,119.42,0,0.9714285714,0.5,0.5
Laser_sonics,Laser sonics,873.472,0,0.89453125,0.5,0.5
Optical_microscope,Optical microscope,221.78,0,0.9692307692,0.5,0.5
Pulse_oximeter,Pulse oximeter,71.652,0,0.9523809524,0.5,0.5
Stress_treadmill,Stress treadmill,675.576,0,0.8737373737,0.5,0.5
Ultrasound_system,Ultrasound system,3626.956,0,0.987770461,0.5,0.5
Vacuum_suction,Vacuum suction,1149.844,0,0.8961424332,0.5,0.5
X_ray_system_small,X-ray system (small),1023.6,0,0.2733333333,0.5,0.5
X_ray_system_medium,X-ray system (medium),1822.008,0,0.8988764045,0.5,0.5
X_ray_system_large,X-ray system (large),2187.092,0,0.02808112324,0.5,0.5
,,,,,,
,,,,,,
Miscellaneous Machines,,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad
Vending_machine_drinks,Vending machine; drinks,3207.28,0,1,0.5,0.5
Vending_machine_snacks,Vending machine; snacks,184.248,0,1,0.5,0.5
Vending_machine_food,Vending machine; food,1586.58,0,1,0.5,0.5
Thermal_binding_machine,Thermal binding machine,97.242,0,1,0.5,0.5
Projector,Projector,1050.896,0,1,0.5,0.5
Paper_shredder,Paper shredder,904.18,0,1,0.5,0.5
Electric_stapler,Electric stapler,5.118,0,1,0.5,0.5
Speakers,Speakers,51.18,0,1,0.5,0.5
Temperature_controlled_electronics_soldering_station,Temperature-controlled electronics soldering station,54.592,0,1,0.5,0.5
Cell_phone_charger,Cell phone charger,17.06,0,1,0.5,0.5
40V_battery_charger,40 V Battery charger,64.828,0,1,0.5,0.5
AA_battery_charger,AA Battery charger,18.766,0,1,0.5,0.5
Microwave_oven_small,Microwave oven; small,2432.756,0,1,0.5,0.5
Microwave_oven_large,Microwave oven; large,2804.664,0,1,0.5,0.5
Coffee_maker_single_cup,Coffee maker; single cup,1313.62,0,1,0.5,0.5
Coffee_maker_12_cups,Coffee maker; 12 cups,2661.36,0,1,0.5,0.5
Coffee_maker_with_grinder_single_cup,"Coffee maker; with grinder, single cup",1282.912,0,1,0.5,0.5
Coffee_grinder_up_to_12_cups,"Coffee grinder, up to 12 cups",249.076,0,1,0.5,0.5
Tea_kettle,Tea kettle,4094.4,0,1,0.5,0.5
Dorm_fridge,Dorm fridge,194.484,0,1,0.5,0.5
Freezer,Freezer,426.5,0,1,0.5,0.5
Fridge_medium_size,"Fridge, medium size",1395.508,0,1,0.5,0.5
Ice_maker_and_dispenser_20_lb_bin_capacity,"Ice maker and dispenser, 20 lb bin capacity",2245.096,0,1,0.5,0.5
Top_mounted_bottled_water_cooler_small,"Top mounted bottled water cooler, small",388.968,0,1,0.5,0.5
Top_mounted_bottled_water_cooler_large,"Top mounted bottled water cooler, large",1194.2,0,1,0.5,0.5
Cash_register,Cash register,30.708,0,1,0.5,0.5
Touch_screen_computer_15_in_standard_LCD,"Touch screen computer, 15 in. standard LCD",197.896,0,1,0.5,0.5
Self_checkout_machine,Self-checkout machine,51.18,0,1,0.5,0.5`