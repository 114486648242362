<script setup>
import { ref, onMounted, reactive, computed, defineEmits, } from 'vue'

let props = defineProps({
  username: String,
})

function getFirstLetter(str) {
  return str[0];
}

// TODO - make a hover tooltip that shows the full username

</script>

<template>
  <div class="ProfileBtn" :title="username" :data-username="username || '?'">
    <p class="upper white f-xs">{{getFirstLetter(username || '?')}}</p>
  </div>
</template>

<style scoped>
.ProfileBtn {
  height: 26px;
  width: 26px;
  background-color: var(--pc);

  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 100%; */
  border-radius: var(--br-l);

  font-weight: 500;
  text-align: center;

  position: relative;

  cursor: pointer;
}

.ProfileBtn::after {
  content: attr(data-username);
  position: absolute;
  bottom: 105%; /* Position above the element */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: var(--xxs) var(--xs);
  border-radius: 3px;
  font-size: var(--f-s);
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s ease-in-out;
}

.ProfileBtn:hover::after {
  opacity: 1;
  visibility: visible;
}

</style>
