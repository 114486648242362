import { gApp } from '../Globals';

export function GetTypeHelpString(sectionName) {
  return `These types will be referenced from the ${sectionName} section of the ${gApp.proj().isCommercial() ? 'Spaces' : 'Build House'} tab.`
}

export function GetSpaceName() {
  return gApp.proj().isCommercial() ? 'space' : 'house';
}

export function GetSpacesTabName() {
  return gApp.proj().isCommercial() ? 'Spaces' : 'Build House';
}
