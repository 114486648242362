import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, PleaseContactStr,
  IntervalTimer,
} from '../Base.js'
import { UnknownOrEnter } from './Common.js'

export { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
} from '../Common/Field.js'


let DuctRunLocation = makeEnum({
  Attic: 'Attic',
  Basement: 'Basement',
  CrawlSpace: 'Crawl space',
  ConditionedSpace: 'Conditioned space',
  Unknown: 'Unknown',
  DuctsNotUsed: 'Ducts are not used',
})

let SystemType = makeEnum({
  Furnace: 'Furnace',
  HeatPump: 'Heat pump',
})

let DuctLossFactors = {
  [DuctRunLocation.Attic]: {
    [SystemType.Furnace]: {
      [1]: {
        [11]: {[0]: 0.49, [4]: 0.29, [8]: 0.25},
        [5]: {[0]: 0.34, [4]: 0.16, [8]: 0.13},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.41, [4]: 0.26, [8]: 0.24},
        [5]: {[0]: 0.27, [4]: 0.14, [8]: 0.12},
        [0]: {[0]: 0, [8]: 0},
      },
    },
    [SystemType.HeatPump]: {
      [1]: {
        [11]: {[0]: 0.56, [4]: 0.37, [8]: 0.34},
        [5]: {[0]: 0.34, [4]: 0.19, [8]: 0.16},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.49, [4]: 0.35, [8]: 0.33},
        [5]: {[0]: 0.28, [4]: 0.17, [8]: 0.15},
        [0]: {[0]: 0, [8]: 0},
      },
    }
  },
  [DuctRunLocation.Basement]: {
    [SystemType.Furnace]: {
      [1]: {
        [11]: {[0]: 0.28, [4]: 0.18, [8]: 0.16},
        [5]: {[0]: 0.19, [4]: 0.10, [8]: 0.08},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.24, [4]: 0.17, [8]: 0.15},
        [5]: {[0]: 0.16, [4]: 0.09, [8]: 0.08},
        [0]: {[0]: 0, [8]: 0},
      },
    },
    [SystemType.HeatPump]: {
      [1]: {
        [11]: {[0]: 0.23, [4]: 0.17, [8]: 0.16},
        [5]: {[0]: 0.14, [4]: 0.09, [8]: 0.08},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.20, [4]: 0.16, [8]: 0.15},
        [5]: {[0]: 0.12, [4]: 0.08, [8]: 0.07},
        [0]: {[0]: 0, [8]: 0},
      },
    }
  },
  [DuctRunLocation.CrawlSpace]: {
    [SystemType.Furnace]: {
      [1]: {
        [11]: {[0]: 0.49, [4]: 0.29, [8]: 0.25},
        [5]: {[0]: 0.34, [4]: 0.16, [8]: 0.13},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.41, [4]: 0.26, [8]: 0.24},
        [5]: {[0]: 0.27, [4]: 0.14, [8]: 0.12},
        [0]: {[0]: 0, [8]: 0},
      },
    },
    [SystemType.HeatPump]: {
      [1]: {
        [11]: {[0]: 0.56, [4]: 0.37, [8]: 0.34},
        [5]: {[0]: 0.34, [4]: 0.19, [8]: 0.16},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.49, [4]: 0.35, [8]: 0.33},
        [5]: {[0]: 0.28, [4]: 0.17, [8]: 0.15},
        [0]: {[0]: 0, [8]: 0},
      },
    }
  },
};

let DuctGainFactors = {
  [DuctRunLocation.Attic]: {
    [SystemType.Furnace]: {
      [1]: {
        [11]: {[0]: 1.26, [4]: 0.71, [8]: 0.63},
        [5]: {[0]: 0.68, [4]: 0.33, [8]: 0.27},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 1.02, [4]: 0.66, [8]: 0.6},
        [5]: {[0]: 0.53, [4]: 0.29, [8]: 0.25},
        [0]: {[0]: 0, [8]: 0},
      },
    },
    [SystemType.HeatPump]: {
      [1]: {
        [11]: {[0]: 1.26, [4]: 0.71, [8]: 0.63},
        [5]: {[0]: 0.68, [4]: 0.33, [8]: 0.27},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 1.02, [4]: 0.66, [8]: 0.6},
        [5]: {[0]: 0.53, [4]: 0.29, [8]: 0.25},
        [0]: {[0]: 0, [8]: 0},
      },
    },
  },
  [DuctRunLocation.Basement]: {
    [SystemType.Furnace]: {
      [1]: {
        [11]: {[0]: 0.12, [4]: 0.09, [8]: 0.09},
        [5]: {[0]: 0.07, [4]: 0.05, [8]: 0.04},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.11, [4]: 0.09, [8]: 0.09},
        [5]: {[0]: 0.06, [4]: 0.04, [8]: 0.04},
        [0]: {[0]: 0, [8]: 0},
      },
    },
    [SystemType.HeatPump]: {
      [1]: {
        [11]: {[0]: 0.12, [4]: 0.09, [8]: 0.09},
        [5]: {[0]: 0.07, [4]: 0.05, [8]: 0.04},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.11, [4]: 0.09, [8]: 0.09},
        [5]: {[0]: 0.06, [4]: 0.04, [8]: 0.04},
        [0]: {[0]: 0, [8]: 0},
      },
    },
  },
  [DuctRunLocation.CrawlSpace]: {
    [SystemType.Furnace]: {
      [1]: {
        [11]: {[0]: 0.16, [4]: 0.12, [8]: 0.11},
        [5]: {[0]: 0.10, [4]: 0.06, [8]: 0.05},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.14, [4]: 0.12, [8]: 0.11},
        [5]: {[0]: 0.08, [4]: 0.06, [8]: 0.05},
        [0]: {[0]: 0, [8]: 0},
      },
    },
    [SystemType.HeatPump]: {
      [1]: {
        [11]: {[0]: 0.16, [4]: 0.12, [8]: 0.11},
        [5]: {[0]: 0.10, [4]: 0.06, [8]: 0.05},
        [0]: {[0]: 0, [8]: 0},
      },
      [2]: {
        [11]: {[0]: 0.14, [4]: 0.12, [8]: 0.11},
        [5]: {[0]: 0.08, [4]: 0.06, [8]: 0.05},
        [0]: {[0]: 0, [8]: 0},
      },
    },
  },
};

export class HouseMiscDetails {
  init() {
    this.ductRunLocation = new Field({
      name: 'Primary location of duct runs',
      type: FieldType.Select,
      choices: makeOptions(DuctRunLocation),
    })
    this.typicalLeakageRateEntry = new Field({
      name: 'Typical leakage rate',
      type: FieldType.Select,
      choices: makeOptions(UnknownOrEnter),
    })
    this.typicalLeakageRate = new Field({
      name: 'Typical leakage rate',
      type: FieldType.Percent,
      showName: false,
      defaultValue: 8,
    })
    this.typicalLeakageRate.makeUpdater((field) => {
      field.visible = this.typicalLeakageRateEntry.value == UnknownOrEnter.Enter;
    })
    this.ductInsulation = new Field({
      name: 'Duct insulation',
      type: FieldType.Insulation,
      defaultValue: 0,
    })
    this.systemType = new Field({
      name: 'System type',
      type: FieldType.Select,
      choices: makeOptions(SystemType),
    })

    this.serFields = [
      'ductRunLocation',
      'typicalLeakageRateEntry',
      'typicalLeakageRate',
      'ductInsulation',
      'systemType',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'System Details',
    }
  }

  _calcF_dl(ctx, isHeating) {
    ctx.startSection("Calc F_dl");

    let ductFactors = isHeating ? DuctLossFactors : DuctGainFactors;

    if (ctx.ductRunLocation == DuctRunLocation.DuctsNotUsed) {
      ctx.F_dl = 0;
    } else if (ctx.ductRunLocation == DuctRunLocation.ConditionedSpace) {
      ctx.F_dl = 0;
    } else if (ctx.ductRunLocation == DuctRunLocation.Unknown) {
      // When the location is unknown, take the average of
      // the results of all 3 locations.
      let locations = [
        DuctRunLocation.Attic,
        DuctRunLocation.Basement,
        DuctRunLocation.CrawlSpace,
      ]
      let F_dl_arr = [];
      for (let loc of locations) {
        ctx.loc = loc;
        ctx.factors = lookupData(ductFactors, [
          ctx.loc,
          ctx.systemType,
          ctx.N_stories <= 1 ? 1 : 2,
        ]);
        ctx.F_dl = doubleInterpolateInMap(ctx.factors,
          ctx.typicalLeakageRate, ctx.ductInsulation);
        F_dl_arr.push({F_dl: ctx.F_dl});
      }
      ctx.F_dl_sum = ctx.evalSum('F_dl', F_dl_arr, 'F_dl_sum')
      ctx.F_dl = ctx.eval('F_dl_sum / cnt', {cnt: locations.length}, 'F_dl')
    } else {
      ctx.factors = lookupData(ductFactors, [
        ctx.ductRunLocation,
        ctx.systemType,
        ctx.N_stories <= 1 ? 1 : 2,
      ]);
      ctx.F_dl = doubleInterpolateInMap(ctx.factors,
        ctx.typicalLeakageRate, ctx.ductInsulation);
    }
    let F_dl = ctx.F_dl;
    ctx.endSection();
    return F_dl;
  }

  calcOutputs(ctx) {
    ctx.startSection('System Details')

    ctx.ductRunLocation = this.ductRunLocation.value;
    ctx.typicalLeakageRate = this.typicalLeakageRateEntry.value ==
        UnknownOrEnter.Enter ? this.typicalLeakageRate.value : 8.0;
    ctx.ductInsulation = this.ductInsulation.value;
    ctx.systemType = this.systemType.value;
    ctx.N_stories = ctx.toplevelData.numberStoreys;

    let totalSpaceLoad = ctx.res.totalSpaceLoad;
    ctx.startLocalSection('Heating')
    ctx.F_dl_heating = this._calcF_dl(ctx, true);
    let res_heating_sensible = ctx.eval('SpaceLoad * F_dl_heating',
      {SpaceLoad: totalSpaceLoad.heating.sensible},
      'res_cooling_sensible');
    let res_heating_latent = ctx.eval('SpaceLoad * F_dl_heating',
      {SpaceLoad: totalSpaceLoad.heating.latent},
      'res_cooling_latent');
    ctx.endSection();

    ctx.startLocalSection('Cooling')
    ctx.F_dl_cooling = this._calcF_dl(ctx, false);
    let res_cooling_sensible = ctx.eval('SpaceLoad * F_dl_cooling',
      {SpaceLoad: totalSpaceLoad.cooling.sensible},
      'res_cooling_sensible');
    let res_cooling_latent = ctx.eval('SpaceLoad * F_dl_cooling',
      {SpaceLoad: totalSpaceLoad.cooling.latent},
      'res_cooling_latent');
    ctx.endSection();

    ctx.res.distributionLoads = {
      cooling: {
        sensible: res_cooling_sensible,
        latent: res_cooling_latent,
      },
      heating: {
        sensible: res_heating_sensible,
        latent: res_heating_latent,
      },
    };
    
    ctx.endSection();
  }
}
setupClass(HouseMiscDetails)
