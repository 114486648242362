import { ref, reactive } from 'vue'
import * as math from 'mathjs'
import { matrix } from 'mathjs'

export const PI = Math.PI;

// Modify math.js matrix to add some helper functions
export let MatrixPrototype = Object.getPrototypeOf(matrix([1, 2, 3]));
MatrixPrototype.toValString = function() {
  return `[${this.size()[0]}x${this.size()[1]}]`;
};
MatrixPrototype.toDenseString = function() {
  let str = ``;
  for (let i = 0; i < this.size()[0]; ++i) {
    for (let j = 0; j < this.size()[1]; ++j) {
      str += `${this.get([i, j])} `;
    }
    str += '\n';
  }
  return str;
};

// Add SerUtil support
MatrixPrototype.serInfo = {
  type: 'Matrix',
  ctor: () => {
    return matrix();
  },
}
MatrixPrototype.writeToJson = function() {
  return {
    _type: 'Matrix',
    data: this.toArray(),
  }
}
MatrixPrototype.readFromJson = function(jsonObj) {
  // Set to the array values, in place
  this.resize([jsonObj.data.length, jsonObj.data[0].length]);
  for (let i = 0; i < jsonObj.data.length; i++) {
    for (let j = 0; j < jsonObj.data[0].length; j++) {
      this.set([i, j], jsonObj.data[i][j]);
    }
  }
}

export function cos(val) {
  return Math.cos(val)
}

export function sin(val) {
  return Math.sin(val)
}

export function toRads(degs) {
  return 2*PI*degs/360.0;
}

export function toDegs(rads) {
  return 360.0*rads/(2*PI);
}

export function arcsin(val) {
  return Math.asin(val)
}

export function arccos(val) {
  return Math.acos(val)
}

// Result in radians, [-pi/2, pi/2]
export function arctan(val) {
  return Math.atan(val)
}

export function sqrt(val) {
  return Math.sqrt(val);
}

export function ln(val) {
  return Math.log(val);
}

export function log(val) {
  return Math.log10(val);
}

export function exp(val) {
  return Math.exp(val);
}

export class MatrixUtils {
  static getRow(m, rowIndex) {
    return math.row(m, rowIndex).toArray()[0]
  }

  static setRow(m, rowIndex, newRowArr) {
    for (let i = 0; i < newRowArr.length; i++) {
      m.set([rowIndex, i], newRowArr[i]);
    }
  }

  static setCol(m, colIndex, newColArr) {
    for (let i = 0; i < newColArr.length; i++) {
      m.set([i, colIndex], newColArr[i]);
    }
  }

  static sumOfMatrices(matrixArr) {
    let sum = math.zeros(matrixArr[0].size())
    for (let m of matrixArr) {
      sum = math.add(sum, m)
    }
    return sum
  }

  static sumOfMatrixMaxs(matrixArr) {
    let sum = 0
    for (let m of matrixArr) {
      sum += math.max(m)
    }
    return sum
  }

  static maxInMatrix(m) {
    return math.max(m)
  }

  static makeMatrixOfValue(rows, cols, val) {
    let m = math.zeros(rows, cols)
    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        m.set([i, j], val)
      }
    }
    return m
  }

  static writeToJson(m) {
    return m.toArray();
  }

  static readFromJson(json) {
    return matrix(json);
  }
};

export function scalarSum(arr) {
  let sum = 0
  for (let val of arr) {
    sum += val
  }
  return sum
}

export function makeVector(n) {
  return new Array(n).fill(0)
}

export function makeMonthVector(monthIndex) {
  let arr = new Array(12).fill(0)
  arr[monthIndex] = 1
  return arr
}

export function makeHourVector(hourIndex) {
  let arr = new Array(24).fill(0)
  arr[hourIndex] = 1
  return arr
}

// Make a true mod (unlike JS % operator, which handles -ves weird
// because it is a remainder operator, not a mod operator)
export function positiveModulo(n, m) {
  return ((n % m) + m) % m;
}

export function clampAngleZeroToTwoPi(angleRads) {
  return positiveModulo(angleRads, 2.0*PI);
}

export function clampClampNegativePiToPi(angleRads) {
  return positiveModulo(angleRads + PI, 2.0*PI) - PI;
}

export function maxInArray(arr) {
  let max = arr[0]
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      max = arr[i]
    }
  }
  return max
}