import { makeEnum, makeOptions, } from '../Base'

export let NumPanes = makeEnum({
  'N1': '1',
  'N2': '2',
  'N3': '3',
  'N4': '4',
});

export let FrameStyle = makeEnum({
  AluminumWithoutThermalBreak: 'Aluminum without thermal break',
  AluminumWithThermalBreak: 'Aluminum with thermal break',
  WoodSlashVinyl: 'Wood/vinyl',
  StructuralGlazing: 'Structural Glazing',
  ReinforcedVinylSlashAluminumCladWood: 'Reinforced vinyl / aluminum clad wood',
  InsulatedFiberglassSlashVinyl: 'Insulated fiberglass / vinyl',
});

export let Glazing = makeEnum({
  None: 'None',
  E0_6: 'e=0.6',
  E0_4: 'e=0.4',
  E0_2: 'e=0.2',
  E0_1: 'e=0.1',
  E0_05: 'e=0.05',
});

export let PanesAppliedTo = makeEnum({
  Surface2: 'Surface 2',
  Surface3: 'Surface 3',
  Surface2or3: 'Surface 2 or 3',
  Surface4or5: 'Surface 4 or 5',
  Surface2or3and4or5: 'Surface 2 or 3 and 4 or 5',
})

export let Tint = makeEnum({
  Clear: 'Clear',
  Bronze: 'Bronze',
  Green: 'Green',
  Grey: 'Grey',
  BlueGreen: 'Blue-green',
  HighPerfGreen: 'High-performance green',
  StainlessSteelOnClear8p: 'Stainless steel on clear (8%)',
  StainlessSteelOnClear14p: 'Stainless steel on clear (14%)',
  StainlessSteelOnGreen14p: 'Stainless steel on green (14%)',
  StainlessSteelOnClear20p: 'Stainless steel on clear (20%)',
  TitaniumOnClear20p: 'Titanium on clear (20%)',
  TitaniumOnClear30p: 'Titanium on clear (30%)',
});

export let PaneThickness = makeEnum({
  QuarterInch: '1/4"',
  EighthInch: '1/8"',
})

export let GapType = makeEnum({
  None: 'None',
  QuarterInchAir: '1/4" Air',
  HalfInchAir: `1/2" Air`,
  QuarterInchArgon: '1/4" Argon',
  HalfInchArgon: `1/2" Argon`,
  QuarterInchKrypton: '1/4" Krypton',
})

export let BugScreen = makeEnum({
  None: 'None',
  Interior: 'Interior',
  Exterior: 'Exterior',
})
