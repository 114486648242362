<script setup>
import { ref, computed, onMounted, watch, } from 'vue'
import { gApp, DebugOn, } from '../State.js'
import { removeElem } from '../SharedUtils.js'
import FieldInput from './FieldInput.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import HelpButton from './HelpButton.vue'
import HelpSection from './HelpSection.vue'
import ProjectSettingsSection from './ProjectSettingsSection.vue'
import SystemResultsPage from './SystemResultsPage.vue'

let proj = computed(() => {
  return gApp.proj();
})

let selectedSystem = ref(null);

function goToSystemResultsPage(systemIdx) {
  let system = gApp.proj().systems[systemIdx];
  selectedSystem.value = system;
}

function onDoneSystemResults() {
  selectedSystem.value = null;
}

onMounted(() => {
  // gApp.proj().setHelpId('Home')
})

</script>

<template>
  <div class="Container">
    <div v-if="selectedSystem !== null" class="SystemResultsPage">
      <SystemResultsPage :system="selectedSystem" @onDone="onDoneSystemResults" />
    </div>
    <div v-else>
      <div class="mb-s TextAlignCenter">
      <!-- <div class="mb-l"> -->
        <div class="FlexHelp JustifyCenter">
          <h2 class="">Commercial Building Designer</h2>
          <HelpButton id="home" :hasSlot="false"></HelpButton>
        </div>
        <p class="big-help-text mlr-auto MainHelp">
        <!-- <p class="big-help-text MainHelp"> -->
          Welcome to the Commercial Building Designer! Read the <b>Quick Help</b> on the right to get started.
        </p>
        <HelpSection id="home">
          <h4 class="mb-s">Welcome to the Commercial Building Designer!</h4>
          <p class="mb-s">Let's get started.</p>
          <p class=""></p>
          <p class="mb-s">This is the <b>Quick Help</b> pane. Click a <i class="bi-question-circle pc"></i> anywhere to open it.</p>
          <p class="mb-s">This page shows the <b>Load Results</b> for your building. The table will be mostly empty until you give more info
            about your building.
          </p>
          <p>Go to the <router-link :to="{path: `/building/${proj.id}/building-and-env`}"><b>Building and Environment</b></router-link> tab now to get started.</p>
        </HelpSection>
      </div>
      <div class="BorderedSection mb-m">
        <div class="mb-m">
          <h3 class=""><i class="bi-bar-chart-line mr-xs"></i>Results</h3>
          <p class="help-text">View the load results for each system.</p>
        </div>
        <div>
          <div>
            <div v-if="gApp.proj().systems.length > 0">
              <div v-for="(system, i) in gApp.proj().systems" class="SystemResultRow mb-xs">
                <p class="SystemNameTxt"><i class="bi-grid mr-xs"></i>{{system.getName()}}</p>
                <p class="ml-auto mr-m help-text">{{system.getResultsStatusStr()}}</p>
                <button class="CreateBtn ResultsBtn" @click="goToSystemResultsPage(i)">Results</button>
              </div>
            </div>
            <div v-else>
              <p class="">No results yet. Add a system to get started.</p>
            </div>
          </div>
          <!--<button class="CreateBtn mt-xs" @click="">Add System</button>-->
        </div>
      </div>
      <ProjectSettingsSection />
      <div class="BorderedSection mb-m" v-if="gApp.isAdminUser()">
        <h3>[Admin Only] Debug</h3>
        <p class="mb-xs"><i>This section is only visible b/c you are an admin user.</i></p>
        <SimpleCollapse title="IAC Debugger">
          <FieldInput v-model="gApp.proj().debugData.iacDebugger.winType" />
          <FieldInput v-model="gApp.proj().debugData.iacDebugger.inShadingType" />
          <FieldInput v-model="gApp.proj().debugData.iacDebugger.outputIAC" />
        </SimpleCollapse>
      </div>
    </div>
  </div>
</template>

<style scoped>
.Section {
  /* margin-bottom: var(--space-s); */
}

.LastSection {
  margin-bottom: var(--space-xl);
}

.WallsTable td {
  vertical-align: top;
}

.UsersTable {
  border: 1px solid var(--b-20);
  min-width: 600px;
}

.ShareBtn {
  padding: var(--space-xxs);
  /* margin-left: auto; */
}

.SharingRemoveBtn {
  margin-left: auto;
}

.MainHelp {
  width: 520px;
}

.SystemResultRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  padding: var(--xs) var(--s);
  border: 1px solid var(--b-20);
  width: 60%;
}

.SystemNameTxt {
}

.SystemResultRow .ResultsBtn {
  /*margin-left: auto;*/
}

</style>
