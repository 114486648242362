<script setup>
import { ref, computed, watch, } from 'vue'
import { gApp, } from '../Globals.js'
import { WallType, RoofType } from '../Components/WallType.js'
import { formatNum, removeElem, generateItemName, isDupName, } from '../SharedUtils.js'
import { Units, getLabel } from '../Common/Units.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import ConfirmModal from './ConfirmModal.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import HelpButton from './HelpButton.vue'
import PopupRefTable from './PopupRefTable.vue'
import { GetRecommendedRoofWeightsData, GetRecommendedWallWeightsData } from '../Data/RecommendedWallWeights.js'
import ComponentErrorsList from './utils/ComponentErrorsList.vue'
import { GetTypeHelpString } from '../Common/UIStrings.js'

import EditButton from './utils/EditButton.vue'
import DeleteButton from './utils/DeleteButton.vue'
import BackButton from './utils/BackButton.vue'

const props = defineProps({
  isRoof: Boolean
})

let proj = gApp.proj();
let selectedWall = ref(null);
let deleteModal = ref(null);

let wallTypes = computed(() => {
  return props.isRoof ? proj.roofTypes : proj.wallTypes;
})

function selectWall(wall) {
  selectedWall.value = wall;
}

function deleteWall(wall) {
  deleteModal.value.showModal("Delete", `Delete ${props.isRoof ? "roof" : "wall"} type ${wall.name.value}?`, () => {
    removeElem(wallTypes.value, wall);
  });
}

function addWallType() {
  if (props.isRoof) {
    wallTypes.value.push(RoofType.create(generateItemName('Roof', proj.roofTypes), true))
  } else {
    wallTypes.value.push(WallType.create(generateItemName('Wall', proj.wallTypes), true))
  }
  selectWall(wallTypes.value[wallTypes.value.length - 1]);
}

// Note: we call this on route change to prevent re-using state b/w the WallsView for Walls and
// the WallsView for Roofs
function resetComponent() {
  selectedWall.value = null;
}

watch(() => {
  return props.isRoof;
}, (newId) => {
  resetComponent();
});

</script>

<template>
  <div class="Container">
    <HelpSection :id="isRoof ? 'RoofsView' : 'WallsView'">
      <h4 class="mb-s">{{isRoof?'Roof':'Wall'}} Types</h4>
      <p class="mb-m">
        Use this page to build different {{ isRoof ? 'roof' : 'wall' }} types for use in the Build House tab.
      </p>
      <p>
        The <b>R-value</b> of the {{isRoof ? 'roof' : 'wall'}} will impact both the heating and cooling loads. The absorptance value will only affect the cooling load.
        <br><br>
          Consult your local building code for minimum requirements of <b>R-value</b>. For <b>absorptance</b>, if the material and/or colour are unknown, a conservative estimate would be 0.9. Values above 0.9 are unusual and would likely cause unrealistic results.
        <br><br>

        When using the <b>Wall Builder</b>, certain materials have a standard thickness, and a thickness input is not required for those materials. Thermal resistance of convection on both interior and exterior surfaces has already been built into the program and should not be taken into account when building a wall.
        <br><br>

        The order that materials are placed in will not affect heating or cooling loads, only the total R-value and solar absorptance will affect the loads. The total R-value is the sum of R-values of each layer.
        <br><br>

        {{isRoof?'Roofs':'Walls'}} built in this section can be used as {{isRoof?'roof':'wall'}} types {{!isRoof ? 'or partition types' : ''}} in the Build House tab.

      </p>
    </HelpSection>
    <div v-if="selectedWall">
      <div class="mb-m">
        <BackButton @click="selectedWall=null" />
        <div class="Flex">
          <h1 class="">{{ selectedWall.name.value || 'Untitled' }}</h1>
          <!-- <button class="LinkBtn">Rename</button> -->
        </div>
      </div>
      <ComponentErrorsList :component="selectedWall" />
      <FieldInput v-model="selectedWall.name" class="MarginBotXS" />
      <WarnText v-if="isDupName(selectedWall.name.value, wallTypes)">There is already a {{isRoof?'roof':'wall'}} with that name. Please choose another.</WarnText>
      <InputGroup class="MarginBotXS" title="General" v-if="gApp.proj().isCommercial()">
        <FieldInput v-model="selectedWall.type" />
      </InputGroup>
      <InputGroup class="MarginBotXS" title="Thermal Resistance">
        <FieldInput v-model="selectedWall.thermalResistance.entryMethod" />
        <div v-if="selectedWall.thermalResistance.entryMethod.value == 'Manual'">
          <FieldInput v-model="selectedWall.thermalResistance.manualRValue" />
          <div v-if="gApp.proj().isCommercial()">
            <FieldInput v-model="selectedWall.thermalResistance.manualExteriorWallWeight" v-if="gApp.proj().isCommercial()" />
            <div class="mt-s">
              <PopupRefTable :title="!isRoof ? 'Recommended wall weights' : 'Recommended roof weights'" :data="!isRoof ? GetRecommendedWallWeightsData() : GetRecommendedRoofWeightsData()" >
                If you are unsure of the {{isRoof ? 'roof' : 'wall'}} weight, use this table to help you estimate.
              </PopupRefTable>
            </div>
          </div>
        </div>
        <div v-else class="WallBuilder">
          <div class="mt-m mb-s">
            <p class="mute-header">Wall Builder</p>
            <p class="help-text">Add the layers that make up your wall. The R-value {{gApp.proj().isCommercial()? 'and wall weight ' : ''}}will be calculated for you, from material data.</p>
          </div>
          <ComponentErrorsList :component="selectedWall.thermalResistance.wallBuilder" />
          <button @click="selectedWall.thermalResistance.wallBuilder.addLayer()" class="LinkBtn mb-xs">Add Layer</button>
          <table class="SimpleTable">
            <tr>
              <th>Material Category</th>
              <th>Material Type</th>
              <th>Thickness [{{getLabel(Units.inches)}}]</th>
              <th>R-value [{{getLabel(Units.RValue)}}]</th>
              <th></th>
            </tr>
            <tr v-for="(layer, index) in selectedWall.thermalResistance.wallBuilder.layers" class="BuilderLayer">
              <td><FieldInput v-model="layer.category" :compact="true" /></td>
              <td><FieldInput v-model="layer.type" :compact="true" /></td>
              <td><FieldInput v-model="layer.thickness" :compact="true" /></td>
              <td>{{ layer.rValue.getValueStr() }}</td>
              <td><DeleteButton v-if="layer.isEditable" @click="selectedWall.thermalResistance.wallBuilder.deleteLayer(layer)" /></td>
            </tr>
            <tr>
              <td class=""></td>
              <td class=""></td>
              <td class=""></td>
              <td class="Result">{{ formatNum(selectedWall.thermalResistance.wallBuilder.computeRValue()) }}</td>
              <td class=""></td>
            </tr>
          </table>
        </div>
      </InputGroup>
      <InputGroup title="Solar Absorptance">
        <FieldInput v-model="selectedWall.solarAbsorptance.entryMethod" />
        <FieldInput v-model="selectedWall.solarAbsorptance.absorptance" />
        <FieldInput v-model="selectedWall.solarAbsorptance.material" />
      </InputGroup>
      <InputGroup title="Results">
        <FieldInput v-model="selectedWall.thermalResistance.outputRValue" />
        <FieldInput v-if="gApp.proj().isCommercial()" v-model="selectedWall.thermalResistance.outputExteriorWallWeight" />
        <FieldInput v-model="selectedWall.solarAbsorptance.outputAbsorptance" />
      </InputGroup>
    </div>
    <div v-else>
      <h1 class="PageH1">{{isRoof ? "Roof" : "Wall"}} Types</h1>
      <div class="mb-l">
        <p class="mute-header Container">Info</p>
        <p class="help-text w-80">
          Add a <b>{{isRoof?'Roof':'Wall'}} Type</b> for each different type of {{isRoof?'roof':'wall'}} used in your project.
          {{ GetTypeHelpString(isRoof ? 'Roofs' : 'Walls') }}
        </p>
      </div>
      <button @click="addWallType()" class="MarginBotXS CreateBtn">Add {{ isRoof ? "roof" : "wall" }} type</button>
      <table class="SimpleTable" v-if="wallTypes.length">
        <tr>
          <th>Name</th>
          <th>R-value</th>
          <th>Absorptance</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="wall in wallTypes" class="WallSection MarginBotL">
          <td>{{ wall.name.value }}</td>
          <td>{{ wall.thermalResistance.outputRValue.getValueStr() }}</td>
          <td>{{ wall.solarAbsorptance.outputAbsorptance.getValueStr() }}</td>
          <td>
            <EditButton @click="selectWall(wall)"/>
          </td>
          <td>
            <DeleteButton @click="deleteWall(wall)"/>
          </td>
        </tr>
      </table>
    </div>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<style scoped>
.Result {
  color: blue;
}
</style>
