import { ref, reactive } from 'vue'
import { prettyJson, valOr, waitMillis, applyIndent, extendArray, } from '../SharedUtils.js'
import { Units, valToStr, makeValStr } from './Units.js'
import * as math from 'mathjs'
import * as ser from './SerUtil.js'
import { FunctionCache } from './FunctionCache.js'
import { GenericCache } from './GenericCache.js'

export { Units, valToStr, makeValStr, } from './Units.js'; 

let ValToStrOpts = {
  precision: 6,
};

let MsgType = {
  Log: 'Log',
  SetVar: 'SetVar',
  Eval: 'Eval',
  StartCall: 'StartCall',
  EndCall: 'EndCall',
  StartSection: 'StartSection',
  EndSection: 'EndSection',
};

// Function decorator that adds argument and result info (esp.
// the units).
export function describeFunc(name, argsDesc, resDesc, func) {
  func.desc = {
    name: name,
    form: 'args-list',
    args: argsDesc,
    res: resDesc,
  }
  return func;
}

class CompiledExpression {
  constructor(eqStr) {
    this.eqStr = eqStr;
    this.compiledEq = math.compile(eqStr);
    this.varPositions = null;
  }

  evaluate(variables) {
    return this.compiledEq.evaluate(variables);
  }

  subVars(vars) {
    // Return the eq string with variable names replaced with values
    let varPositions = this._findAllVarPositions(this.eqStr);
    let outEq = this.eqStr;
    for (let i = varPositions.length - 1; i >= 0; --i) {
      let varPos = varPositions[i];
      if (!vars.has(varPos.name)) {
        continue;
      }
      let varVal = vars.get(varPos.name);
      // Note: some vars may be funcs, do not replace
      if (typeof varVal == 'string' || typeof varVal == 'number') {
        outEq = outEq.substring(0, varPos.start) + valToStr(varVal, ValToStrOpts) + outEq.substring(varPos.end);
      }
    }
    return outEq;
  }

  _findAllVarPositions(eqStr) {
    if (this.varPositions) {
      // Already calculated
      return this.varPositions;
    }
    //console.log("Calculate var positions for eq: " + eqStr);
    let varsList = []
    let curPart = '';
    let start = -1;

    for (let i = 0; i < eqStr.length; ++i) {
      let char = eqStr[i];
      if (('a' <= char && char <= 'z') || ('A' <= char && char <= 'Z') || char == '_' ||
        (curPart.length > 0 && '0' <= char && char <= '9')) {
        if (curPart.length === 0) {
          start = i;
        }
        curPart += char;
      } else {
        if (curPart.length > 0) {
          varsList.push({start, end: i, name: curPart});
          curPart = ''
        }
      }
    }
    if (curPart.length > 0) {
      varsList.push({start, end: eqStr.length, name: curPart});
    }

    this.varPositions = varsList;
    return varsList;
  }
}

/*
Used to cache compiled equation strings, so that we can avoid recompiling
them every time they are run. Big win in tight loops.
*/
class CompiledExpressionCache extends GenericCache {
  constructor() {
    super();
  }

  _makeKey(eqStr) {
    return eqStr;
  }

  _createEntry(eqStr) {
    //console.log("Creating compiled expression for: " + eqStr);
    let compiledExpr = new CompiledExpression(eqStr);
    return compiledExpr;
  }
};

/*
Must implement get(), set(), keys(), and has() methods
so that it can be passed to mathjs evaluate as a scope.
*/
class VariableMap {
  constructor() {
    this.curContext = {_parent: null, _name: 'Root'};

    // Note: this is currently unused
    this.serFields = [
      'curContext',
    ];
  }

  pushContext(name) {
    let newContext = {_parent: this.curContext, _name: name};
    this.curContext = newContext;
  }

  popContext() {
    if (this.curContext._parent === null) {
      throw new Error("Cannot pop root context");
    }
    let context = this.curContext;
    this.curContext = this.curContext._parent;
    return context;
  }

  get(key) {
    let context = this.curContext;
    while (context) {
      let value = context[key];
      if (value !== undefined) {
        return value;
      }
      context = context._parent;
    }
    return undefined;
  }

  set(key, value) {
    this.curContext[key] = value;
  }

  setMany(obj) {
    for (const key in obj) {
      this.set(key, obj[key]);
    }
  }

  keys() {
    let keys = [];
    let context = this.curContext;
    while (context) {
      for (const key in context) {
        if (key[0] != '_') {
          keys.push(key);
        }
      }
      context = context._parent;
    }
    // Must return a map iterator
    return keys[Symbol.iterator]();
  }

  has(key) {
    return this.get(key) !== undefined;
  }
};

/*
Note: we don't do 'setupClass' here b/c we don't want this class to be reactive. It
is mutated too frequently.
*/
export class CalcContext {
  static create(opts) {
    let ctx = new CalcContext(opts);
    return ctx;
  }

  constructor(opts) {
    opts = valOr(opts, {}); 
    this._log = [];
    this._structuredLog = [];
    this._variableMap = new VariableMap();
    this._compiledExprCache = new CompiledExpressionCache();
    this._callDepth = 0;
    this._sectionStack = []
    this._progressStack = []
    this._progressText = ''
    this._funcCache = new FunctionCache();
    this._recordLog = valOr(opts.recordLog, true);

    // Wait vars:
    // We allow using the 'briefWait' async func during long calcs to
    // keep the UI responsive.
    this._lastWaitTime = Date.now();
    this._waitIntervalMs = 16;
    // When running in a worker, we skip waits b/c we don't have to
    // worry about the UI updating
    this._skipWaits = valOr(opts.skipWaits, false);

    // Optional fields for handling progress update callbacks
    this._lastProgressUpdate = Date.now();
    this._progressUpdateIntervalSecs = valOr(opts.progressUpdateIntervalSecs, null);
    this._progressUpdateFunc = valOr(opts.progressUpdateFunc, null);

    return new Proxy(this, {
      get: (obj, key) => {
        let val = obj._variableMap.get(key);
        if (val !== undefined) {
          return val;
        }
        // If not found, may be a member field
        return obj[key];
      },
      set: (obj, key, value) => {
        if (typeof key === 'string' && key.length > 0 &&
          key[0] == '_') {
          // Ignore our private vars (treat as regular fields on the object)
          return Reflect.set(obj, key, value);
        }
        
        // Record the var
        if (this._recordLog) {
          let valueStr = valToStr(value, ValToStrOpts);
          obj.pushMsg(`${key.padEnd(20)} = ${valueStr}`);
          obj.pushStructuredMsg(MsgType.SetVar, {key, valueStr})
        }

        // Error out early on bad values
        if (value === undefined || (typeof value == 'number' && !Number.isFinite(value))) {
          throw new Error(`Detected an error during calculations: '${key} = ${value}'`);
        }

        // Push the var to the latest context
        obj._variableMap.set(key, value);
        return true;
      }
    })
  }

  writeToJsonForWorker() {
    // When sending the context to a worker, we only care only about the variable map
    return {
      _variableMap: ser.writeToJson(this._variableMap),
    }
  }

  readFromJsonForWorker(json) {
    ser.readFromJson(this._variableMap, json._variableMap);
  }

  get funcCache() {
    return this._funcCache;
  }

  startSection(name, opts) {
    opts = valOr(opts, {});
    // We push to the section stack and also (usually) to the calc context stack.
    // This protects from inner sections overwriting variables in parent sections.
    let pushCalcContext = valOr(opts.pushCalcContext, true);
    this._sectionStack.push({name: name, pushCalcContext});
    if (pushCalcContext) {
      this._variableMap.pushContext(name);
    }

    if (this._recordLog) {
      let fullPath = this._sectionStack.map(elem => {
        return elem.name;
      }).join('/');
      let str = `>> ${fullPath}:`
      // this.pushMsg(`${str}\n${'='.repeat(str.length)}\n`);
      this.pushMsg(`${str}\n`);
      this.pushStructuredMsg(MsgType.StartSection, {name, fullPath});
    }
  }

  startLocalSection(name) {
    /*
    Starts a section that is for name/organization only. Does not 
    */
    this.startSection(name, {pushCalcContext: false});
  }

  endSection(debugInfo) {
    let entry = this._sectionStack.pop();
    if (entry.pushCalcContext) {
      this._variableMap.popContext();
    }

    if (this._recordLog) {
      this.pushMsg("<< DONE\n");
      this.pushStructuredMsg(MsgType.EndSection, {});
      if (debugInfo) {
        //this.pushStructuredMsg(MsgType.Log, {msg: `DONE: ${debugInfo}`});
      }
    }
  }

  cleanupSectionStack() {
    // Pop the stack until at the top level again
    // Call after an error occurs, if must keep logging after that
    while (this._sectionStack.length > 0) {
      this.endSection();
    }
  }

  _startContext(name) {
    if (name && this._recordLog) {
      let str = `>> ${name}`;
      this.pushMsg(`${str}\n`);
    }
    this._variableMap.pushContext(name);
  }

  _endContext() {
    let ctx = this._variableMap.popContext();
    if (ctx._name && this._recordLog) {
      this.pushMsg("<< DONE\n");
    }
  }

  async briefWait() {
    // Pause for a bit to allow the UI to update
    if (this._skipWaits) {
      return;
    }
    let curTime = Date.now();
    if (this._lastWaitTime === null ||
      curTime - this._lastWaitTime > this._waitIntervalMs) {
      await waitMillis(0);
      this._lastWaitTime = curTime;
    }
    //await waitMillis(10);
  }

  async longWait(numSecs) {
    if (this._skipWaits) {
      return;
    }
    // Pause for a bit to allow the UI to update
    await waitMillis(numSecs * 1000);
  }

  _runProgressCallback() {
    // Run the progress func if it's time
    if (this._progressUpdateFunc && this._progressUpdateIntervalSecs) {
      let curTime = Date.now();
      if (curTime - this._lastProgressUpdate > this._progressUpdateIntervalSecs * 1000) {
        this._progressUpdateFunc(this, this.getDetailedProgressText());
        this._lastProgressUpdate = curTime;
      }
    }
  }

  getProgressText() {
    return this._progressText;
  }

  setProgressText(text) {
    this._progressText = text;
  }

  startProgressSection(sectionName, progressItems) {
    // If progress items is an array, convert to a dict
    if (typeof progressItems.length === 'number') {
      let newItems = {};
      for (let i = 0; i < progressItems.length; ++i) {
        let item = progressItems[i];
        newItems[item.name] = {...item};
      }
      progressItems = newItems;
    }
    let progressSection = {
      name: sectionName,
      type: 'sequential',
      items: {},
      curItem: 0,
      numItems: Object.keys(progressItems).length,
    };
    let i = 0;
    for (const itemName in progressItems) {
      progressSection.items[itemName] = {...progressItems[itemName], index: i};
      ++i;
    }
    this._progressStack.push(progressSection);
  }

  startParallelProgressSection(sectionName, progressItems) {
    let progressSection = {
      name: sectionName,
      type: 'parallel',
      items: {},
    }
    for (const item of progressItems) {
      progressSection.items[item.name] = {...item, progressText: 'In progress...'};
    }
    this._progressStack.push(progressSection);
  }

  endProgressSection() {
    this._progressStack.pop();
  }

  setProgress(itemName) {
    let progressSection = this._progressStack[this._progressStack.length - 1];
    if (progressSection.type !== 'sequential') {
      throw new Error(`Cannot set progress for non-sequential section using 'setProgress'.`);
    }
    let item = progressSection.items[itemName];
    if (item === undefined) {
      throw new Error(`Progress item '${itemName}' not found.`);
    }
    progressSection.curItem = item.index;
    this._runProgressCallback();
  }

  setParallelProgress(subSectionName, progressText) {
    let progressSection = this._progressStack[this._progressStack.length - 1];
    if (progressSection.type !== 'parallel') {
      throw new Error(`Cannot set progress for non-parallel section using 'setParallelSectionProgress'.`);
    }
    let item = progressSection.items[subSectionName];
    if (item === undefined) {
      throw new Error(`Progress item '${subSectionName}' not found.`);
    }
    item.progressText = progressText;
    this._runProgressCallback();
  }

  _getDetailedProgressForSection(sectionIndex) {
    let progressSection = this._progressStack[sectionIndex];
    //let str = `${progressSection.name}:\n`;
    let str = '';
    if (progressSection.type === 'sequential') {
      for (const itemName in progressSection.items) {
        let item = progressSection.items[itemName];
        let isDone = item.index < progressSection.curItem;
        let prefix = isDone ? '* ' : '  ';

        let childStr = null;
        if (item.index == progressSection.curItem) {
          if (sectionIndex + 1 < this._progressStack.length) {
            prefix = '* ';
            childStr = this._getDetailedProgressForSection(sectionIndex + 1);
            childStr = applyIndent(childStr.trimEnd(), 2);
          } else {
            prefix = '-> ';
          }
        }
        let isDoneClass = isDone ? 'IsDone' : '';
        let inProgClass = item.index == progressSection.curItem ? 'InProg' : '';
        str += `<span class="${isDoneClass} ${inProgClass}">${prefix}${itemName}</span>\n`;
        if (childStr !== null) {
          str += childStr + '\n';
        }
      }
    } else if (progressSection.type === 'parallel') {
      for (const itemName in progressSection.items) {
        let item = progressSection.items[itemName];
        let isDone = item.progressText === 'Done';
        let prefix = isDone ? '* ' : '  ';
        let isDoneClass = isDone ? 'IsDone' : '';
        let inProgClass = !isDone ? 'InProg' : '';
        str += `<span class="${isDoneClass} ${inProgClass}">${prefix}${itemName}</span>\n`;
        str += applyIndent(item.progressText, 2) + '\n';
      }
    } else {
      throw new Error(`Unknown progress section type: ${progressSection.type}`);
    }
    return str;
  }

  getDetailedProgressText() {
    if (this._progressStack.length == 0) {
      return 'Calculating...';
    }
    let str = this._getDetailedProgressForSection(0);
    return str;
  }

  getProgressPercent() {
    let fracProg = 0;
    let nextMultiplier = 1.0;
    for (let i = 0; i < this._progressStack.length; ++i) {
      let section = this._progressStack[i];
      fracProg += section.curItem / section.numItems * nextMultiplier;
      nextMultiplier /= section.numItems;
    }
    return Math.floor(fracProg * 100.0);
  }

  static makeArgsDesc(argsDesc, argsList) {
    let argKeys = Object.keys(argsDesc);
    if (argKeys.length !== argsList.length) {
      throw new Error(`Expected ${argKeys.length} but` +
        ` got ${argsList.length}.`);
    }
    let str = ``;
    for (let i = 0; i < argKeys.length; ++i) {
      let argKey = argKeys[i];
      str += `${argKey}=${makeValStr(argsList[i], argsDesc[argKey], ValToStrOpts)}`
      if (i < argKeys.length - 1) {
        str += `, `;
      }
    }
    return str;
  }

  pushMsg(msg) {
    let padding = '';
    let strMsg = msg;
    if (this._callDepth > 0) {
      padding = ' '.repeat((this._callDepth)*2);
      strMsg = msg.split("\n").map((line) => (padding + line)).join("\n");
    }
    this._log.push(strMsg);
  }

  pushStructuredMsg(type, value) {
    let newMsg = {type, value}
    this._structuredLog.push(newMsg);
    return newMsg;
  }

  getFullStructuredLog() {
    return this._structuredLog;
  }

  pushFullStructuredLog(otherLog) {
    extendArray(this._structuredLog, otherLog);
  }

  call(func, ...args) {
    // console.log(`func:`, func.desc);
    let res = null;
    if (this._recordLog) {
      if (this._callDepth == 0) {
        this.pushMsg("");
      }
      let argsDesc = CalcContext.makeArgsDesc(func.desc.args, args)
      this.pushMsg(`${func.desc.name}(${argsDesc}):`)
      this._callDepth++;

      this._startContext();
      let startMsg = this.pushStructuredMsg(MsgType.StartCall, {funcDesc: func.desc, args, res: null});
      res = func(this, ...args);
      this._endContext();
      this.pushStructuredMsg(MsgType.EndCall, {funcDesc: func.desc, args, res});
      // Add the `res` to the start msg retroactively, so we can display it early in the logs
      startMsg.value.res = res;

      let retValDesc = makeValStr(res, func.desc.res, ValToStrOpts);
      this.pushMsg(`Out: ${retValDesc}`);
      this._callDepth--;
    } else {
      this._startContext();
      res = func(this, ...args);
      this._endContext();
    }

    return res;
  }

  evalSum(eqStr, arr, resName) {
    try {
      let variables = this._variableMap;

      let msg = ''
      let spacesStr = ' '.repeat(resName.length)
      if (this._recordLog) {
        msg += `${resName} = SUM ${eqStr}\n`
      }

      let sum = 0;
      let compiledExpr = this._compiledExprCache.getOrCreate(eqStr);
      for (let i = 0; i < arr.length; ++i) {
        variables.pushContext();
        variables.setMany(arr[i]);
        let elemRes = compiledExpr.evaluate(variables);
        sum += elemRes;
        if (this._recordLog) {
          msg += `[${i + 1}] = ${compiledExpr.subVars(variables)} = ${valToStr(elemRes, ValToStrOpts)}\n`
        }
        variables.popContext();
      }
      if (this._recordLog) {
        msg += `${spacesStr} = ${sum}`
        this.pushMsg(msg);
        this.pushStructuredMsg(MsgType.Log, {msg});
      }
      return sum;
    } catch (err) {
      if (this._recordLog) {
        let msg = `Error with eq: SUM ${eqStr}\n>> ${err.message}`;
        this.pushMsg(msg);
        this.pushStructuredMsg(MsgType.Log, {msg});
      }
      throw err;
    }
  }

  eval(eqStr, args, resName) {
    // Create an eq context of CalcContext vars plus any extras
    let variables = this._variableMap;
    variables.pushContext();
    for (const argName in args) {
      let argVal = args[argName];
      if (argVal === undefined || argVal === null) {
        throw new Error(`Argument '${argName}' to equation is ${argVal}.`);
      }
      variables.set(argName, argVal);
    }

    let compiledExpr = this._compiledExprCache.getOrCreate(eqStr);
    let msg = ''
    let spacesStr = ' '.repeat(resName.length)
    if (this._recordLog) {
      msg += `${resName} = ${eqStr}\n`
      msg += `${spacesStr} = ${compiledExpr.subVars(variables)}\n`
    }

    let res = null;
    try {
      res = compiledExpr.evaluate(variables);
      if (this._recordLog) {
        msg += `${spacesStr} = ${valToStr(res, ValToStrOpts)}`
        this.pushMsg(msg);
        this.pushStructuredMsg(MsgType.Log, {msg})
      }
    } catch (err) {
      if (this._recordLog) {
        msg += `Error with eq: ${eqStr}\n>> ${err.message}`;
        this.pushMsg(msg);
        this.pushStructuredMsg(MsgType.Log, {msg});
      }
      throw err;
    } finally {
      variables.popContext();
    }
    return res;
  }

  lookupVar(varName) {
    return this._variableMap.get(varName);
  }

  require(varName) {
    if (this.lookupVar(varName) === undefined) {
      throw new Error(`Variable '${varName}' is required but has not been set.`);
    }
  }

  assert(condition, msg) {
    if (!condition) {
      throw new Error(`Assertion failed: ${msg}`);
    }
  }

  log(str) {
    if (this._recordLog) {
      this.pushMsg(str);
      this.pushStructuredMsg(MsgType.Log, {msg: str})
    }
  }

  logFatalError(err) {
    if (this._recordLog) {
      let errMsg = `Fatal error occurred:\n${err}\n\nTrace:\n${err.stack}`;
      this.pushMsg(errMsg);
      this.pushStructuredMsg(MsgType.Log, {msg: errMsg, extraClasses: 'FatalError'});
    }
  }

  logLoadMatrix(name, matrix) {
    if (!this._recordLog) {
      return;
    }
    //this.log(`${name}:\n${matrix.toDenseString()}`);
    let str = `<p>${name}:</p><table class="LoadMatrixTable">`;
    let monthes = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    str += `<tr><td></td>`
    for (let i = 0; i < 24; ++i) {
      let hrStr = `${i}`.padStart(2, '0');
      str += `<td><b>${hrStr}</b></td>`;
    }
    str += `</tr>`;
    for (let i = 0; i < matrix.size()[0]; ++i) {
      str += `<tr>`;
      str += `<td><b>${monthes[i % monthes.length]}</b></td>`;
      for (let j = 0; j < matrix.size()[1]; ++j) {
        str += `<td>${valToStr(matrix.get([i, j]), ValToStrOpts)}</td>`;
      }
      str += `</tr>`;
    }
    str += `</table>`;
    this.log(str);
  }

  logValue(name, value) {
    if (!this._recordLog) {
      return;
    }
    this.log(`${name}: ${valToStr(value, ValToStrOpts)}`);
  }

  getLogStr() {
    if (!this._recordLog) {
      return 'Did not record logs.';
    }
    let logLines = [];
    for (const line of this._log) {
      if (line.startsWith(">>")) {
        if (logLines.length > 0 && !logLines[logLines.length - 1].endsWith('\n')) {
          logLines.push('\n');
        }
        let str = line.substring(3).trim();
        logLines.push(`${str}\n${'='.repeat(str.length)}\n`);
      } else if (line.startsWith("<<")) {
        if (logLines[logLines.length - 1] != '\n') {
          logLines.push("\n");
        }
      } else {
        logLines.push(line);
      }
    }
    return logLines.join("\n");
  }

  getLogDict() {
    let dict = {
      name: 'Calculations',
      log: []
    }
    if (!this._recordLog) {
      return dict;
    }
    let stack = [dict];
    let curStrs = []
    for (const item of this._log) {
      //console.log("Item: " + item.substring(0, 50) + "...");
      let curObj = stack[stack.length - 1];
      //console.log("CurObj name: " + curObj.name);
      if (item.startsWith(">>")) {
        //console.log("START: " + item);
        if (curStrs.length > 0) {
          curObj.log.push(curStrs.join('\n'))
          curStrs = [];
        }
        let newObj = {
          name: item.substring(3),
          log: []
        }
        curObj.log.push(newObj);
        stack.push(newObj);
      } else if (item.startsWith("<<")) {
        //console.log("END");
        if (curStrs.length > 0) {
          curObj.log.push(curStrs.join('\n'));
          curStrs = [];
        }
        stack.pop();
      } else {
        // console.log("PUSH " + item);
        curStrs.push(item);
      }
    }
    return dict;
  }

  getStructuredLog() {
    return this._structuredLog;
  }

  async getStructuredLogDict() {
    // Convert the structuredLog array to a nested dict
    // Note: the log dict may have 500k+ items, so this can be slow. Run waits to allow the UI to update.
    console.log(`Making structured log dict (${this._structuredLog.length} items)...`)
    let startTime = Date.now();
    //console.log("Structured log:\n", this._structuredLog);
    let outDict = {
      name: `<span class="RootHdr">Calculations</span>`,
      log: []
    }
    if (!this._recordLog) {
      return outDict;
    }
    let objStack = [outDict]
    for (let i = 0; i < this._structuredLog.length; ++i) {
      if (i % 1000 == 0 && !this._skipWaits) {
        await waitMillis(10);
      }
      let item = this._structuredLog[i];
      let curObj = objStack[objStack.length - 1];
      switch (item.type) {
        case MsgType.Log: {
          let extraClasses = item.value.extraClasses || '';
          curObj.log.push(`<span class="LogMsg ${extraClasses}">${item.value.msg}</span>`);
          break;
        }
        case MsgType.SetVar: {
          curObj.log.push(`${item.value.key} = ${item.value.valueStr}`);
          break;
        }
        case MsgType.Eval: {
          curObj.log.push(item.value.msg)
          break;
        }
        case MsgType.StartSection: {
          //let str = `>> ${item.value.fullPath}`;
          //curObj.log.push(str);
          let subObj = {name: item.value.name, log: []};
          curObj.log.push(subObj);
          objStack.push(subObj);
          break;
        }
        case MsgType.EndSection: {
          objStack.pop();
          break;
        }
        case MsgType.StartCall: {
          let argsDesc = CalcContext.makeArgsDesc(item.value.funcDesc.args, item.value.args);
          let retValDesc = makeValStr(item.value.res, item.value.funcDesc.res, ValToStrOpts);
          let subObjName = `<span class="FuncName">${item.value.funcDesc.name}</span> ==> <span class="CallRes">${retValDesc}</span>`;
          let subObj = {
            name: subObjName,
            log: [
              `<div class="InputsMsg"><span class="InputsHdr">Inputs</span>: <span class="InputsArgs">${argsDesc}</span></div>`
            ]
          };
          //curObj.log.push(`${item.value.funcDesc.name} with: ${argsDesc}`);
          curObj.log.push(subObj);
          objStack.push(subObj);
          break;
        }
        case MsgType.EndCall: {
          let retValDesc = makeValStr(item.value.res, item.value.funcDesc.res, ValToStrOpts);
          curObj.log.push(`<div class="ResultMsg"><span class="RetValHdr">Result:</span> <span class="RetVal">${retValDesc}</span></div>`);
          objStack.pop();
          break;
        }
      }
    }
    console.log(`Done log dict - took ${Date.now() - startTime} ms.`);
    return outDict;
  }
};

