<script setup>
import { ref, computed, onMounted, watch, } from 'vue'
import { gApp,  } from '../State.js'
import { removeElem } from '../SharedUtils.js'
import LocationData from './LocationData.vue'
import HouseResults from './HouseResults.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import InputGroup from './InputGroup.vue'
import HelpButton from './HelpButton.vue'
import HelpSection from './HelpSection.vue'
import ProjectSettingsSection from './ProjectSettingsSection.vue'


let proj = computed(() => {
  return gApp.proj();
})

onMounted(() => {
  // gApp.proj().setHelpId('Home')
})

</script>

<template>
  <div class="Container">
    <div class="mb-s TextAlignCenter">
    <!-- <div class="mb-l"> -->
      <div class="FlexHelp JustifyCenter">
        <h2 class="">House Builder</h2>
        <HelpButton id="home" :hasSlot="false"></HelpButton>
      </div>
      <p class="big-help-text mlr-auto MainHelp">
      <!-- <p class="big-help-text MainHelp"> -->
        Welcome to the House Builder! Read the <b>Quick Help</b> on the right to get started.
      </p>
      <HelpSection id="home">
        <h4 class="mb-s">Welcome to House Builder!</h4>
        <p class="mb-s">Let's get started.</p>
        <p class=""></p>
        <p class="mb-s">This is the <b>Quick Help</b> pane. Click a <i class="bi-question-circle pc"></i> anywhere to open it.</p>
        <p class="mb-s">This page shows the <b>Load Results</b> for your house. The table will be mostly empty until you give more info
          about your house.
        </p>
        <p>Go to the <router-link :to="{path: `/house/${proj.id}/build`}"><b>Build House</b></router-link> tab now to fill in your house info.</p>
      </HelpSection>
    </div>
    <div class="Section BorderedSection mb-s">
      <div class="mb-s">
        <div class="FlexHelp">
          <h3 class="mr-xs"><i class="bi-globe-americas mr-xs"></i>Location</h3>
          <HelpButton id="Location">
            <h4>Location</h4>
            <p class="mb-s">Select your house's location.</p>
            <p class="mb-s">It is used to obtain the statistical weather data, latitude, longitude, time zone, and more.
              This information will impact the calculations of solar radiation, external wall conduction, and outdoor air temperatures. 
            </p>
            <p class="mb-s">
              All data provided in this section is taken from weather stations at or near the listed locations.
            </p>
            <p>
            You can view and override all data by clicking "Show Data".
            </p>
          </HelpButton>
        </div>
        <p class="help-text">Set your house's location.</p>
      </div>
      <LocationData v-model="proj.toplevelData.locationData" />
    </div>
    <HouseResults class="mb-s" />
    <ProjectSettingsSection />
  </div>
</template>

<style scoped>

.LastSection {
  margin-bottom: var(--space-xl);
}

.WallsTable td {
  vertical-align: top;
}

.UsersTable {
  border: 1px solid var(--b-20);
  min-width: 600px;
}

.ShareBtn {
  padding: var(--space-xxs);
  /* margin-left: auto; */
}

.SharingRemoveBtn {
  margin-left: auto;
}

.MainHelp {
  width: 520px;
}

</style>
