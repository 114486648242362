import { WallsAndRoofsDataCombined } from './WallsAndRoofsDataCombined.js'

import { StrictParseNumber, IsNumberString, AssertThrow } from '../../SharedUtils.js'
import {makeEnumWithDataAndLabels} from '../../Base.js'

import {AbsorptanceData} from './Absorptance Data.js'

import { CsvHelper } from '../CsvHelper.js'
import Papa from 'papaparse';


function parseSimpleTable(rawCsv) {
  let csv = Papa.parse(rawCsv);
  let objs = [];
  let colNames = csv.data[1];
  for (let row = 2; row < csv.data.length; ++row) {
    let rowData = csv.data[row];
    let obj = {};
    for (let col = 0; col < rowData.length; ++col) {
      obj[colNames[col]] = rowData[col];
    }
    objs.push(obj);
  }
  return objs;
}

function IsValidString(str) {
  return typeof str === 'string' && str.length > 0;
}

export class WallMaterial {
  constructor(data) {
    this.id = data.Id;
    AssertThrow(IsValidString(this.id), "Material ID is invalid");
    this.name = data.Material;
    AssertThrow(IsValidString(this.name), "Material name is invalid");

    try {
      if (IsNumberString(data.Thickness)) {
        // The thickness, resistance, and weight are preset
        this.thickness = StrictParseNumber(data.Thickness);
        this.resistance = StrictParseNumber(data.Resistance);
        this.weight = StrictParseNumber(data.Weight);
        //AssertThrow(!IsNumberString(data.Conductivity), "Conductivity should not be set");
        //AssertThrow(!IsNumberString(data.Density), "Density should not be set");
      } else {
        // The thickness, resistance, and weight are user-defined
        this.thickness = null;
        this.conductivity = StrictParseNumber(data.Conductivity);
        this.density = StrictParseNumber(data.Density);
        //AssertThrow(!IsNumberString(data.Resistance), "Resistance should not be set");
        //AssertThrow(!IsNumberString(data.Weight), "Weight should not be set");
      }
    } catch (e) {
      console.error(`Error parsing WallMaterial ${this.name}, with data:`, data);
      throw e;
    }
  }

  requiresUserThickness() {
    return this.thickness === null;
  }

  getPresetThickness() {
    AssertThrow(this.thickness !== null, "Thickness is not preset");
    return this.thickness;
  }

  getPresetResistance() {
    AssertThrow(this.resistance !== null, "Resistance is not preset");
    return this.resistance;
  }

  getPresetWeight() {
    AssertThrow(this.weight !== null, "Weight is not preset");
    return this.weight;
  }

  getResistanceFromThickness(thickness) {
    AssertThrow(this.thickness === null, "Thickness is not user-defined");
    return thickness / this.conductivity;
  }

  getWeightFromThickness(thicknessInches) {
    AssertThrow(this.thickness === null, "Thickness is not user-defined");
    return this.density * (thicknessInches / 12.0);
  }
};

export class WallAndRoofData {
  static _instance = null;

  static instance() {
    if (!this._instance) {
      this._instance = new WallAndRoofData();
    }
    return this._instance;
  }

  constructor() {
    this.Defaults = [
      new WallMaterial({
        'Id': 'Outdoor_Surface_Resistance',
        'Material': 'Outdoor Surface Resistance',
        'Thickness': 0,
        'Conductivity': null,
        'Resistance': 0.25,
        'Density': null,
        'Weight': 0,
      }),
      new WallMaterial({
        'Id': 'Indoor_Layer_Air_Resistance',
        'Material': 'Indoor Layer Air Resistance',
        'Thickness': 0,
        'Conductivity': null,
        'Resistance': 0.68,
        'Density': null,
        'Weight': 0,
      })
    ];
    let csvData = new CsvHelper(WallsAndRoofsDataCombined);
    this.WallMaterials = this._parseMaterialsTable(csvData, 'WallMaterials');
    this.Roofing = this._parseMaterialsTable(csvData, 'Roofing');
    this.BuildingBoardAndSiding = this._parseMaterialsTable(csvData, 'BuildingBoardAndSiding');
    this.InsulatingMaterials = this._parseMaterialsTable(csvData, 'Insulating');
    this.MasonryMaterials = this._parseMaterialsTable(csvData, 'Masonry');
    this.PlasteringMaterials = this._parseMaterialsTable(csvData, 'Plastering');
    this.Woods = this._parseMaterialsTable(csvData, 'Woods');
    this.wallDataMap = {
      'Wall Materials': this.WallMaterials,
      'Roofing': this.Roofing,
      'Building Board and Siding': this.BuildingBoardAndSiding,
      'Insulating Materials': this.InsulatingMaterials,
      'Masonry Materials': this.MasonryMaterials,
      'Plastering Materials': this.PlasteringMaterials,
      'Woods': this.Woods,
      'Defaults': this.Defaults,
    }

    this.AbsorptanceData = parseSimpleTable(AbsorptanceData);
    let materials = {};
    for (const row of this.AbsorptanceData) {
      let name = row['Outer Layer'];
      let absorptance = row['Absorptance'];
      materials[name] = {
        label: `${name} (${absorptance})`,
        value: Number(absorptance),
      }
    }
    this.MaterialAbsorptance = makeEnumWithDataAndLabels(materials);
  }

  _parseMaterialsTable(csvObj, tableName) {
    // TODO - fill in requiredCols and colInfo
    let tableData = csvObj.parseSimpleTable(tableName, {
      requiredCols: [],
      colInfo: {}
    });
    let materials = [];
    for (const row of tableData.rows) {
      let material = new WallMaterial(row);
      materials.push(material);
    }
    return materials;
  }

  getWallLayerCategories() {
    return Object.keys(this.wallDataMap);
  }

  getWallLayerTypes(categoryName) {
    if (!this.wallDataMap[categoryName]) {
      return [];
    }
    let materialNames = [];
    for (const row of this.wallDataMap[categoryName]) {
      // TODO - return ID and Name, as enum?
      materialNames.push(row.name);
    }
    return materialNames;
  }

  lookupWallMaterial(categoryName, materialId) {
    let table = this.wallDataMap[categoryName];
    if (!table) {
      return null;
    }
    return table.find((elem) => {
      // Note: for backwards-compat, we allow lookup by id or name
      return elem.id == materialId || elem.name == materialId;
    });
  }
};

