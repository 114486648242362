import { ref, reactive, } from 'vue'

import { prettyJson, clearArray, valOr, getElemNames,
  addElem, removeElem, elemIn, compareNums, } from './SharedUtils.js'

export let PleaseContactStr = `Please contact support@heatwise-hvac.com for assistance.`

// Given a dict like {A: 'A label', B: 'B label'},
// will produce an object like:
// {A: 'A', B: 'B', _labels: {A: 'A label', B: 'B label'}}
// The code can work entirely with the keys (A, B, ...) and use the labels
// just for the UI.
export function makeEnum(optionsDict) {
  let obj = {
    _labels: {},
    _keys: []
  };
  obj.getLabel = (key) => {
    return obj._labels[key];
  };
  for (const key in optionsDict) {
    obj[key] = key; 
    obj._labels[key] = optionsDict[key];
    obj._keys.push(key)
  }
  return obj;
}

// For making an options array for a Select field, from an enum.
export function makeOptions(enumObj, keys) {
  let enumKeys = keys;
  if (!enumKeys) {
    enumKeys = enumObj._keys;
  }
  return enumKeys.map((key) => {
    return {label: enumObj._labels[key], value: key};
  });
}

// Similar to makeEnum, but for when each enum value has an associated data object which
// isn't just the label.
// Given a dict like {A: dataA, B: dataB},
// will produce an object like:
// {A: 'A', B: 'B', _data: {A: dataA, B: dataB}}
export function makeEnumWithData(optionsDict) {
  let obj = {
    _data: {},
    _keys: [],
  };
  obj.getData = (key) => {
    return obj._data[key];
  };
  obj.getLabel = (key) => {
    return obj._data[key].label;
  };
  for (const key in optionsDict) {
    obj[key] = key; 
    obj._data[key] = optionsDict[key];
    obj._keys.push(key)
  }
  return obj;
}

export function makeEnumWithDataAndLabels(optionsDict) {
  let obj = {
    _labels: {},
    _data: {},
    _keys: []
  };
  obj.getData = (key) => {
    return obj._data[key];
  };
  obj.getLabel = (key) => {
    return obj._labels[key];
  };
  for (const key in optionsDict) {
    obj[key] = key; 
    let label = optionsDict[key].label;
    obj._labels[key] = label;
    obj._data[key] = optionsDict[key];
    obj._keys.push(key)
  }
  return obj;
}

// Helper that generates the 'create' static method for a class
export function setupClass(classType) {
  classType.create = (...args) => {
    let obj = reactive(new classType());
    obj.init(...args);
    return obj;
  }
}

class MatchObj {
  constructor(...args) {
    this.options = [...args]
  }

  checkMatch(val) {
    console.log("Checking for match!")
    return elemIn(val, this.options)
  }
}

// For use with lookupData dicts
export function Matches(...args) {
  return new MatchObj(...args)
}

export function lookupData(dataDict, pathArr) {
  let curDict = dataDict;
  // console.log(`Lookup ${prettyJson(pathArr)} in dict:`, dataDict);
  for (const key of pathArr) {
    let didFind = false;
    for (const dictKey in curDict) {
      // console.log("Object dictKey: ", typeof dictKey);
      if (key == dictKey) {
        curDict = curDict[dictKey];
        didFind = true;
        break;
      } else if (typeof dictKey === 'object' && (dictKey instanceof MatchObj)
        && dictKey.checkMatch(key)) {
        curDict = curDict[dictKey];
        didFind = true;
        break;
      } else if (dictKey == '_ELSE') {
        curDict = curDict[dictKey];
        didFind = true;
        break;
      }
    }
    if (!didFind) {
      throw new Error(`Could not find the path ${pathArr.join('/')} in the data. Failed at key: ${key}. Data:\n${prettyJson(dataDict)}`);
    }
  }
  return curDict;
}

export function lerp(xa, xb, ya, yb, x) {
  if (!(xa < xb)) {
    throw new Error("Invalid xa and xb. Cannot lerp.");
  }
  let t = (x - xa) / (xb - xa);
  return ya + (yb - ya) * t;
}

export function clampedLerp(xa, xb, ya, yb, x) {
  if (x <= xa) {
    return ya;
  }
  if (x >= xb) {
    return yb;
  }
  return lerp(xa, xb, ya, yb, x);
}

export function clamp(val, min, max) {
  return Math.min(Math.max(val, min), max);
}

/*
Given a map like:
Vals {
  [2]: 20,
  [4]: 30,
  [10]: 80,
}
and a val 3, will interp between map values (returning 25, here).
Result is clamped to the lowest and highest values in the map.
*/
export function interpolateInMap(interpMap, val) {
  if (interpMap.length < 2) {
    throw new Error("Bad interpMap");
  }
  let keys = Object.keys(interpMap).map((key) => Number(key)).sort(compareNums);
  let minKey = keys[0];
  if (val <= minKey) {
    return interpMap[minKey];
  }
  let maxKey = keys[keys.length - 1];
  if (val >= maxKey) {
    return interpMap[maxKey];
  }
  for (let i = 1; i < keys.length; ++i) {
    let xa = keys[i - 1];
    let xb = keys[i];
    if (xa <= val && val <= xb) {
      return lerp(xa, xb, interpMap[xa], interpMap[xb], val);
    }
  }
  throw new Error("Unexpected. Could not find interp interval");
}

/*
Given a map like:
Vals {
  [2]: {[5]: 10, [10]: 20},
  [4]: {[5]: 30, [10]: 40},
  [10]: {[5]: 50, [10]: 60},
}
and two values, interpolates along both dimensions in the map.
*/
export function doubleInterpolateInMap(interpMap, valA, valB) {
  if (interpMap.length < 2) {
    throw new Error("Bad interpMap");
  }
  let keys = Object.keys(interpMap).map((key) => Number(key)).sort(compareNums);
  let minKey = keys[0];
  if (valA <= minKey) {
    return interpolateInMap(interpMap[minKey], valB);
  }
  let maxKey = keys[keys.length - 1];
  if (valA >= maxKey) {
    return interpolateInMap(interpMap[maxKey], valB);
  }
  for (let i = 1; i < keys.length; ++i) {
    let xa = keys[i - 1];
    let xb = keys[i];
    if (xa <= valA && valA <= xb) {
      let y1 = interpolateInMap(interpMap[xa], valB);
      let y2 = interpolateInMap(interpMap[xb], valB);
      return lerp(xa, xb, y1, y2, valA);
    }
  }
  throw new Error("Unexpected. Could not find interp interval");
}

// Helps track a unique id ctr for different keys/categories
export class IdsMap {
  constructor() {
    this.ids = {}

    this.serFields = [
      'ids',
    ]
  }

  makeId(key) {
    if (!(key in this.ids)) {
      this.ids[key] = 0;
    }
    return ++this.ids[key];
  }
};

export class IntervalTimer {
  constructor(targetFunc, intervalSecs, opts) {
    this.targetFunc = targetFunc;
    this.intervalSecs = intervalSecs;
    this.timer = null;

    opts = valOr(opts, {});
    this.onlyWhenVisible = valOr(opts.onlyWhenVisible, false);

    this._reset();
  }

  start() {
    this._reset();
  }

  stop() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  runNow() {
    this.targetFunc();
    this._reset();
  }

  _reset() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.timer = setInterval(() => {
      if (this.onlyWhenVisible && document.visibilityState !== 'visible') {
        return;
      }
      this.targetFunc();
    }, this.intervalSecs * 1000)
  }
}

