import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, PleaseContactStr,
  IntervalTimer,
} from '../Base.js'
import * as solar from './SolarCalculations.js'

import { Field, FieldType, FieldGroup, SelectOrManualInput, } from '../Common/Field.js'
import { CalcContext } from '../Common/CalcContext.js'

import { InputComponent } from '../Common/InputComponent.js'
import { Watcher } from '../Common/Watcher.js'

import { kDirectionChoices } from './Common.js'

export class SolarCalculator extends InputComponent{
  init() {
    this.updater.setWatcher(Watcher.globalInstance());    

    this.timeZone = new Field({
      name: 'Time zone',
      type: FieldType.Count,
      min: -16,
    })
    this.localStdTime = new Field({
      name: 'Local standard time',
      type: FieldType.Decimal,
      min: 0,
      max: 24,
    })
    this.latitude = new Field({
      name: 'Latitude',
      type: FieldType.Angle,
      min: -90,
      max: 90,
    })
    this.longitude = new Field({
      name: 'Longitude',
      type: FieldType.Angle,
      min: -180,
      max: 180,
    })
    this.dayOfYear = new Field({
      name: 'Day of year',
      type: FieldType.Count,
      min: 0,
      max: 364,
    })
    this.tau_b = new Field({
      name: 'Optical depth',
      type: FieldType.Decimal,
    })
    this.tau_d = new Field({
      name: 'Diffuse optical depth',
      type: FieldType.Decimal,
    })
    this.surfaceDir = new Field({
      name: 'Direction',
      type: FieldType.Select,
      choices: kDirectionChoices,
      defaultValue: 'N',
    })
    this.surfaceTiltAngle = new Field({
      name: 'Tilt angle',
      type: FieldType.Angle,
    })
    this.groundReflectance = new Field({
      name: 'Ground reflectance',
      type: FieldType.Ratio,
    })

    this.inputFields = [
      'timeZone',
      'localStdTime',
      'latitude',
      'longitude',
      'dayOfYear',
      'tau_b',
      'tau_d',
      'surfaceDir',
      'surfaceTiltAngle',
      'groundReflectance',
    ]

    this.outputSolarIrradiance = new Field({
      name: 'Solar Irradiance',
      type: FieldType.Decimal,
      isOutput: true,
    })

    this.outputFields = [
      'outputSolarIrradiance',
    ]

    this.calcContext = CalcContext.create();
    this.calcError = null;

    this.updater.addWatchEffect('updater-output', () => {
      let res = this.calcOutput();
      this.outputSolarIrradiance.value = res;
    })
  }

  calcOutput() {
    this.calcContext = CalcContext.create();
    let ctx = this.calcContext;

    let res = null;
    this.calcError = null;
    try {
      let E_t_data = ctx.call(solar.calc_E_t,
        this.timeZone.value,
        this.localStdTime.value,
        this.dayOfYear.value,
        this.latitude.value, this.longitude.value, 
        this.tau_b.value, this.tau_d.value, this.surfaceDir.value,
        this.surfaceTiltAngle.value, this.groundReflectance.value);
      res = E_t_data.E_t;
    } catch (e) {
      console.log(e);
      ctx.logFatalError(e);
      this.calcError = e;
    }
    return res;
  }
}
setupClass(SolarCalculator)