<script setup>
import { ref, onMounted, computed } from 'vue'

const props = defineProps({
  value: [Object, String, Number],
  // May be [String] or [{label, value}, ...]
  options: Array,
  style: {
    default: "select"
  },
  errorWhenEmpty: {
    default: null,
  }
});
const emit = defineEmits(['change'])

function getLabel(obj) {
  if (typeof obj == 'object') {
    return obj['label'];
  } else {
    return obj;
  }
}

function getValue(obj) {
  if (typeof obj == 'object') {
    return obj['value'];
  } else {
    return obj;
  }
}

let isValidOption = computed(() => {
  for (const option of props.options) {
    if (props.value == getValue(option)) {
      return true;
    }
  }
  return false;
});

function onChange(changeEvt) {
  let chosenValue = changeEvt.target.value;
  //console.log("NewVal: "+chosenValue);
  emit('change', chosenValue);
}

function onRadioChange(newVal) {
  emit('change', newVal);
}

function getRadioClasses(isSelected) {
  if (isSelected) {
    return {'bi-check-square':  true};
  } else {
    return {'bi-square': true};
  }
}

</script>

<template>
  <div>
    <div class="BasicSelector" v-if="style == 'select'">
      <select v-if="options.length > 0" :value="value" @change="onChange" class="Select" :class="{IsInvalid: !isValidOption}">
        <!-- <option disabled value="">Please Select</option> -->
        <!-- <option disabled value="">Current: {{curValue}}</option> -->
        <option v-if="!isValidOption" disabled :value="value">
          Invalid Value
        </option>
        <option v-for="option in options" :key="getValue(option)" :value="getValue(option)">
          {{ getLabel(option) }}
        </option>
      </select>
      <template v-else>
        <p v-if="!errorWhenEmpty">
          NA
        </p>
        <div v-else>
          <p><i class="bi-exclamation-circle delete-red mr-xxs"></i>Empty</p>
          <!-- Do not display the error msg here b/c we already display the msg in FieldInput.vue -->
          <!--<p class="help-txt EmptyError">{{ errorWhenEmpty }}</p>-->
        </div>
      </template>
    </div>
    <div v-else>
      <div v-if="options.length > 0" class="RadioOptions">
        <div v-for="option in options" :key="getValue(option)" class="RadioItem Flex">
          <button @click="onRadioChange(getValue(option))" :value="getValue(option)" class="LinkBtn RadioBtn" :class="{Selected: value == getValue(option)}">
            <i :class="getRadioClasses(value == getValue(option))"></i>
          </button>
          {{ getLabel(option) }}
        </div>
      </div>
      <p v-else>NA</p>
    </div>
  </div>
</template>

<style scoped>
.Select {
  width: 100%;
}

.Select.IsInvalid {
  border: 2px solid red;
}

.RadioOptions {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  gap: var(--s);
}

.RadioItem {
}

.RadioBtn {
  margin-right: var(--xxs);
}

.RadioBtn.Selected {
  /* color: red; */
}

.EmptyError {
  font-size: var(--f-xxs);
}
</style>
