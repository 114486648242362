import { WallsViewMarkdown } from './WallsViewMarkdown';
import { RoofsViewMarkdown } from './RoofsViewMarkdown';
import { WindowsViewMarkdown } from './WindowsViewMarkdown';
import { DoorsViewMarkdown } from './DoorsViewMarkdown';
import { SkylightsViewMarkdown } from './SkylightsViewMarkdown';
import { InternalShadingsViewMarkdown } from './InternalShadingsViewMarkdown';
import { ExternalShadingsViewMarkdown } from './ExternalShadingsViewMarkdown';
import { BufferSpacesViewMarkdown } from './BufferSpacesViewMarkdown';
import { SchedulesViewMarkdown } from './SchedulesViewMarkdown';
import { OverviewPageMarkdown } from './OverviewPageMarkdown';
import { BuildingAndEnvMarkdown } from './BuildingAndEnvMarkdown';
import { SystemsMarkdown } from './SystemsMarkdown';
import { SpacesViewMarkdown } from './SpacesViewMarkdown';

export let CommercialHelpTable = {
  "WallsView": WallsViewMarkdown,
  "RoofsView": RoofsViewMarkdown,
  "WindowsView": WindowsViewMarkdown,
  "DoorsView": DoorsViewMarkdown,
  "SkylightsView": SkylightsViewMarkdown,
  "InteriorShadingsView": InternalShadingsViewMarkdown,
  "ExteriorShadingsView": ExternalShadingsViewMarkdown,
  "BufferSpacesView": BufferSpacesViewMarkdown,
  "SchedulesView": SchedulesViewMarkdown,
  "home": OverviewPageMarkdown,
  "BuildingAndEnv": BuildingAndEnvMarkdown,
  "SystemsView": SystemsMarkdown,
  "SpacesView": SpacesViewMarkdown,
}