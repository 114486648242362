import { 
  setupClass, 
} from '../Base.js'

import { Season,  } from '../Components/Common.js'

import { CalcPsychrometrics, PsyCalcMethod, adjustForAltitude, } from '../Components/Psychrometrics.js'
import { scalarSum } from '../Common/Math.js'

export class SpaceHeatingCalculator {
  constructor(space, ctx) {
    this.space = space
    this.ctx = ctx
  }

  calcWallLoads() {
    let ctx = this.ctx
    ctx.startSection("Wall loads")
    let wallLoads = []
    for (let i = 0; i < this.space.walls.length; i++) {
      let wall = this.space.walls[i];
      ctx.log(`Calculating q_sens_wall for wall: ${i + 1} (type ${wall.getWallType().name.value})`)
      //let q_sens_wall = wall.calculateSensibleLoad(ctx);
      ctx.q_sens_wall = ctx.eval('1.0/R*A*(t_i - t_o)', {
        R: wall.getRValue(),
        A: wall.getStrictlyWallArea(),
      }, 'q_sens_wall');
      wallLoads.push({q_sens_wall: ctx.q_sens_wall});
    }
    ctx.log("Summing wall sensible loads...")
    ctx.q_sens_walls = ctx.evalSum('q_sens_wall', wallLoads, 'q_sens_walls')

    let res = {
      SensibleLoadValue: ctx.q_sens_walls,
    }

    ctx.endSection();
    return res;
  }

  calcWindowLoads() {
    let ctx = this.ctx;
    ctx.startSection("Window loads")
    let windowLoads = []
    for (let wallIndex = 0; wallIndex < this.space.walls.length; wallIndex++) {
      let wall = this.space.walls[wallIndex];
      ctx.log(`Summing windows on wall ${wallIndex + 1} (type ${wall.getWallType().name.value})`)
      for (let winIndex = 0; winIndex < wall.windows.length; winIndex++) {
        let window = wall.windows[winIndex];
        let windowType = window.getWindowType();
        ctx.log(`Window ${winIndex + 1} (type ${windowType.name.value}) on wall ${wallIndex + 1}`)
        ctx.q_sens_window = ctx.eval('n*U*A*(t_i - t_o)', {
          n: window.quantity.value,
          U: windowType.computeUValue().uValue,
          A: windowType.getArea(),
        }, 'q_sens_window');
        windowLoads.push({q_sens_window: ctx.q_sens_window});
      }
    }
    ctx.log("Summing window sensible loads...")
    ctx.q_sens_windows = ctx.evalSum('q_sens_window', windowLoads, 'q_sens_windows')

    let res = {
      SensibleLoadValue: ctx.q_sens_windows,
    }

    ctx.endSection();
    return res;
  }

  calcDoorLoads() {
    let ctx = this.ctx;
    ctx.startSection("Door loads")
    let doorLoads = []
    for (let wallIndex = 0; wallIndex < this.space.walls.length; wallIndex++) {
      let wall = this.space.walls[wallIndex];
      ctx.log(`Summing doors on wall ${wallIndex + 1} (type ${wall.getWallType().name.value})`)
      for (let doorIndex = 0; doorIndex < wall.doors.length; doorIndex++) {
        let door = wall.doors[doorIndex];
        let doorType = door.getDoorType();
        ctx.log(`Door ${doorIndex + 1} (type ${doorType.name.value}) on wall ${wallIndex + 1}`)
        let uValue = doorType.computeUValue();
        // TODO - are the U-values here correct?
        ctx.q_sens_door = ctx.eval('n*((U_glass*A_glass + U_opaq*A_opaq)*(t_i - t_o))', {
          n: door.quantity.value,
          U_glass: uValue.uValueGlass,
          A_glass: doorType.getGlassArea(),
          U_opaq: uValue.uValueDoor,
          A_opaq: doorType.getOpaqueArea(),
        }, 'q_sens_door');
        doorLoads.push({q_sens_door: ctx.q_sens_door});
      }
    }

    ctx.log("Summing door sensible loads...")
    ctx.q_sens_doors = ctx.evalSum('q_sens_door', doorLoads, 'q_sens_doors')

    let res = {
      SensibleLoadValue: ctx.q_sens_doors,
    }

    ctx.endSection();
    return res;
  }

  calcRoofLoads() {
    let ctx = this.ctx;
    ctx.startSection("Roof loads")
    let roofLoads = []
    for (let i = 0; i < this.space.roofs.length; i++) {
      let roof = this.space.roofs[i];
      let roofType = roof.getRoofType();
      ctx.log(`Calculating q_sens_roof for roof: ${i + 1} (type ${roofType.name.value})`)
      //let q_sens_roof = roof.calculateSensibleLoad(ctx);
      ctx.q_sens_roof = ctx.eval('1.0/R*A*(t_i - t_o)', {
        R: roof.getRValue(),
        A: roof.getStrictlyRoofArea(),
      }, 'q_sens_roof');
      roofLoads.push({q_sens_roof: ctx.q_sens_roof});
    }
    ctx.log("Summing roof sensible loads...")
    ctx.q_sens_roofs = ctx.evalSum('q_sens_roof', roofLoads, 'q_sens_roofs')

    let res = {
      SensibleLoadValue: ctx.q_sens_roofs,
    }

    ctx.endSection();
    return res;
  }

  calcSkylightLoads() {
    let ctx = this.ctx;
    ctx.startSection("Skylight loads")
    let skylightLoads = []
    for (let i = 0; i < this.space.roofs.length; i++) {
      let roof = this.space.roofs[i];
      let roofType = roof.getRoofType();
      ctx.log(`Summing skylights on roof ${i + 1} (type ${roofType.name.value})`)
      for (let skylightIndex = 0; skylightIndex < roof.skylights.length; skylightIndex++) {
        let skylight = roof.skylights[skylightIndex];
        let skylightType = skylight.getSkylightType();
        ctx.log(`Skylight ${skylightIndex + 1} (type ${skylightType.name.value}) on roof ${i + 1}`)
        ctx.q_sens_skylight = ctx.eval('n*U*A*(t_i - t_o)', {
          n: skylight.quantity.value,
          U: skylightType.computeUValue().uValue,
          A: skylightType.getArea(),
        }, 'q_sens_skylight');
        skylightLoads.push({q_sens_skylight: ctx.q_sens_skylight});
      }
    }
    ctx.log("Summing skylight sensible loads...")
    ctx.q_sens_skylights = ctx.evalSum('q_sens_skylight', skylightLoads, 'q_sens_skylights')

    let res = {
      SensibleLoadValue: ctx.q_sens_skylights,
    }

    ctx.endSection();
    return res;
  }

  calcFloorLoads() {
    let ctx = this.ctx;
    ctx.startSection("Floor loads")
    let floorLoads = []
    for (let i = 0; i < this.space.floors.length; i++) {
      ctx.startLocalSection(`Floor ${i + 1}`)
      let floor = this.space.floors[i];
      ctx.q_sens_floor = floor._calcLoads(ctx, true, {t_i: ctx.t_i, t_o: ctx.t_o});
      floorLoads.push({q_sens_floor: ctx.q_sens_floor});
      ctx.endSection()
    }
    ctx.log("Summing floor sensible loads...")
    ctx.q_sens_floors = ctx.evalSum('q_sens_floor', floorLoads, 'q_sens_floors')

    let res = {
      SensibleLoadValue: ctx.q_sens_floors,
    }

    ctx.endSection();
    return res;
  }

  calcPartitionLoads() {
    let ctx = this.ctx;
    ctx.startSection("Partition loads")
    let partitionLoads = []
    for (let i = 0; i < this.space.partitions.length; i++) {
      ctx.startLocalSection(`Partition ${i + 1}`)
      let partition = this.space.partitions[i];
      ctx.q_sens_partition = partition._calcLoads(ctx, true, {t_i: ctx.t_i, t_o: ctx.t_o});
      partitionLoads.push({q_sens_partition: ctx.q_sens_partition});
      ctx.endSection()
    }
    ctx.log("Summing partition sensible loads...")
    ctx.q_sens_partitions = ctx.evalSum('q_sens_partition', partitionLoads, 'q_sens_partitions')

    let res = {
      SensibleLoadValue: ctx.q_sens_partitions,
    }

    ctx.endSection();
    return res;
  }

  calcInfiltrationSensibleLoads() { 
    let ctx = this.ctx;
    ctx.startSection("Infiltration sensible load")
    ctx.Q_inf = this.space.calcInfiltrationFlowRate(ctx, Season.Winter);
    ctx.C_s = ctx.call(adjustForAltitude, 1.08, ctx.P_loc);
    ctx.q_sens_inf = ctx.eval('Q_inf*C_s*(t_i - t_o)', {
    }, 'q_sens_inf');

    let res = {
      SensibleLoadValue: ctx.q_sens_inf,
    }

    ctx.endSection();
    return res;
  }

  calcInfiltrationLatentLoads() {
    let ctx = this.ctx;
    ctx.startSection("Infiltration latent load")
    ctx.Q_inf = this.space.calcInfiltrationFlowRate(ctx, Season.Winter);

    ctx.C_l = ctx.call(adjustForAltitude, 4840, ctx.P_loc);
    ctx.W_out = ctx.call(CalcPsychrometrics, ctx.t_o, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.50
      }
    ).W;
    ctx.W_in = ctx.call(CalcPsychrometrics, ctx.t_i, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: ctx.winterIndoorRH
      }
    ).W;
    ctx.q_lat_inf = ctx.eval('Q_inf*C_l*(W_in - W_out)', {
    }, 'q_lat_inf');

    let res = {
      LatentLoadValue: ctx.q_lat_inf,
    }

    ctx.endSection();
    return res;
  }

  calcOutputs() {
    let ctx = this.ctx;
    ctx.startSection('Space Heating')

    ctx.log("Setting t_i to the winter indoor temp")
    ctx.t_i = ctx.eval('t_i_winter', {}, 't_i');
    ctx.log("Setting t_o to the heating dry bulb design temp")
    ctx.t_o = ctx.designTemps.getHeatingDryBulbOut();

    let wallLoads = this.calcWallLoads();
    let windowLoads = this.calcWindowLoads();
    let doorLoads = this.calcDoorLoads();
    let roofLoads = this.calcRoofLoads();
    let skylightLoads = this.calcSkylightLoads();
    let floorLoads = this.calcFloorLoads();
    let partitionLoads = this.calcPartitionLoads();
    let infiltrationSensibleLoads = this.calcInfiltrationSensibleLoads();
    let infiltrationLatentLoads = this.calcInfiltrationLatentLoads();

    let totalSensibleLoad = scalarSum([
      wallLoads.SensibleLoadValue,
      windowLoads.SensibleLoadValue,
      doorLoads.SensibleLoadValue,
      roofLoads.SensibleLoadValue,
      skylightLoads.SensibleLoadValue,
      floorLoads.SensibleLoadValue,
      partitionLoads.SensibleLoadValue,
      infiltrationSensibleLoads.SensibleLoadValue,
    ]);
    let totalLatentLoad = scalarSum([
      infiltrationLatentLoads.LatentLoadValue,
    ]);
    ctx.logValue('Total sensible load', totalSensibleLoad)
    ctx.logValue('Total latent load', totalLatentLoad)

    let SensibleLoads = {
      totalLoad: totalSensibleLoad,

      wallLoads: wallLoads,
      windowLoads: windowLoads,
      doorLoads: doorLoads,
      roofLoads: roofLoads,
      skylightLoads: skylightLoads,
      floorLoads: floorLoads,
      partitionLoads: partitionLoads,
      infiltrationLoads: infiltrationSensibleLoads,
    }
    let LatentLoads = {
      totalLoad: totalLatentLoad,

      infiltrationLoads: infiltrationLatentLoads,
    }

    let res = {
      q_sensible: totalSensibleLoad,
      q_latent: totalLatentLoad,
      q_total: totalSensibleLoad + totalLatentLoad,

      SensibleLoads: SensibleLoads,
      LatentLoads: LatentLoads,
    }
    ctx.endSection()
    return res
  }
}