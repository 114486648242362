import { MaterialDataTable } from './MaterialDataTable.js';
import { TimeSeriesDataTable } from './TimeSeriesDataTable.js';

import { TimeSeries_Data } from './Tables/TimeSeries_Data.js';
import { Luminaires_Data } from './Tables/Luminaires_Data.js';
import { ActivityLevels_Data } from './Tables/ActivityLevels_Data.js';

import { StrictParseNumber } from '../SharedUtils.js';

export let TimeSeriesData_Table = {
  name: 'TimeSeriesData',
  class: TimeSeriesDataTable,
  data: TimeSeries_Data,
  funcs: {
  }
}

export let LuminairesData_Table = {
  name: 'LuminairesData',
  class: MaterialDataTable,
  data: Luminaires_Data,
  headerRows: 1,
  funcs: {
    getLuminaireData: (csv, luminaireType) => {
      let res = {}
      res.F_space = csv.lookupValue(luminaireType, 'C', StrictParseNumber)
      res.F_rad = csv.lookupValue(luminaireType, 'F', StrictParseNumber)
      return res
    },
  }
}

export let ActivityLevels_Table = {
  name: 'ActivityLevels',
  class: MaterialDataTable,
  data: ActivityLevels_Data,
  headerRows: 1,
  funcs: {
    getActivityLevelData: (csv, activityLevel) => {
      let res = {}
      res.S_P = csv.lookupValue(activityLevel, 'E', StrictParseNumber)
      res.F_rad = csv.lookupValue(activityLevel, 'G', StrictParseNumber)
      res.Latent_Heat = csv.lookupValue(activityLevel, 'F', StrictParseNumber)
      return res
    },
  }
}

export function registerCommercialTables(dataCache) {
  let tables = [
    TimeSeriesData_Table,
    LuminairesData_Table,
    ActivityLevels_Table,
  ]
  for (const tableDesc of tables) {
    console.log(`Registering table: ${tableDesc.name}`)
    dataCache.registerTable(tableDesc.name, tableDesc.class.getLoader(tableDesc))
  }
}