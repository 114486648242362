<script setup>
import { ref, onMounted, reactive, computed, watchEffect, } from 'vue'
import { YesNo } from '../Components/Common.js'
import FieldInput from './FieldInput.vue'
import { Field, FieldType } from '../Common/Field.js'

import BasicModal from './utils/BasicModal.vue'

const props = defineProps({
  modelValue: Object
})

let modal = ref(null);

let textInput = reactive(new Field({
  name: props.modelValue.name,
  type: FieldType.String,
  isOutput: true,
  value: ''
}))

watchEffect(() => {
  let scheduleText = "None";
  let schedule = props.modelValue.schedule.lookupValue()
  if (schedule) {
    scheduleText = schedule.name.value;
  }
  let diversityFactorText = '';
  if (props.modelValue.useDiversityFactor.value == YesNo.Yes) {
    diversityFactorText = `(D = ${props.modelValue.diversityFactor.getNumberValueStrWithUnits()})`;
  }
  textInput.value = [scheduleText, diversityFactorText].join(' ');

  let anyErrors = props.modelValue.getAnyErrors();
  if (anyErrors.length > 0) {
    textInput.setEntryErrorMsg(anyErrors[0]);
  } else {
    textInput.setEntryErrorMsg(null);
  }
})

</script>

<template>
  <div class="">
    <div class="Flex GapXXS">
      <FieldInput class="ScheduleTextOutput mr-xs" v-model="textInput" />
      <button class="LinkBtn" @click="modal.showModal()">
        Change
      </button>
    </div>
    <BasicModal ref="modal" title="Schedule">
      <div class="mb-s">
        <FieldInput v-model="modelValue.schedule" />
      </div>
      <div v-if="modelValue.enableDiversityFactor">
        <p class="mute-header">Diversity Factor</p>
        <FieldInput v-model="modelValue.useDiversityFactor" />
        <FieldInput v-model="modelValue.diversityFactor" />
      </div>
    </BasicModal> 
  </div>
</template>

<style scoped>
.ScheduleInput {
}

/* We don't want this to be blue */
.ScheduleTextOutput:deep(.OutputStringParent) {
  color: var(--main-text);
}

.ScheduleTextOutput:deep(.OutputHasEntryError) {
  color: #f39c12;
}

</style>
