
/**
 * Helper to make caches.
 */
export class GenericCache {
  constructor() {
    this.cache = new Map()
  }

  _makeKey(args) {
    // Note: can override in subclass if needed
    return JSON.stringify(args)
  }

  _createEntry(args) {
    throw new Error("Must implement in subclass")
  }

  get(args) {
    let entry = this.cache.get(this._makeKey(args))
    return entry !== undefined ? entry : null;
  }

  getOrCreate(args) {
    let key = this._makeKey(args)
    let entry = this.cache.get(key)
    if (entry === undefined) {
      entry = this._createEntry(args)
      this.cache.set(key, entry)
    }
    return entry;
  }

  set(args, result) {
    this.cache.set(this._makeKey(args), result)
  }
}
