export let SpacesViewMarkdown = `# Spaces
Build spaces to add to your system. Each space can be added to as many systems as you’d like, and you can add a quantity to each space in case the same space is repeated several times within the system. A space is considered an area that is enclosed and separated from other areas in the building, and that has a heating or cooling load associated with it that will contribute to the overall system sizing.

Sometimes it is beneficial to split large areas into multiple spaces, even if they are not separated by walls, if air will be delivered to different parts of the large area from different terminal units and controlled with different thermostats.

Areas that will not be conditioned for heating or cooling do not need to be added since their heating and cooling loads will not contribute to the central AHU sizing.
## General
Each space must have a name. This name will allow you to select it when adding it to a system. It is important to be as accurate as possible with the floor area since it can potentially impact many calculations further on. The ceiling height is not as crucial, but may affect calculations based on later inputs.

The space type and category are useful for selecting the proper ventilation rates and occupant density. If these values will be entered manually, then these inputs can be skipped.

The construction weight, interior/exterior, glass, and carpet inputs will all have an impact on the radiant time series (RTS) calculations, which will play a role in all calculations for the space. Click the Construction Weights Reference pop-up for descriptions of typical light, medium, and heavy weight construction materials.
## Ventilation
Ventilation can be entered manually or calculated based on space type, floor area, and occupancy. If entering ventilation manually, the minimum ventilation requirement is the ventilation corresponding for when occupancy is 0. This input will only make a difference if demand control ventilation (DCV) is used in the system. If it is not used, this input can be ignored.

A separate input may be provided for zone ventilation effectiveness (E<sub>z</sub>) for heating versus cooling. These separate values will only make a difference if heating and cooling modes are allowed to have separate ventilation flows (from the system inputs). If not, then the system will select the option resulting in a higher ventilation for both heating and cooling modes. For a list of E<sub>z</sub> values for different supply/return strategies, click the Ventilation Effectiveness Reference pop-up.
## Infiltration
Infiltration may be added based on different criteria from the dropdown menus. If Only During Occupied Hours is selected for the infiltration hours, the infiltration will only go to 0 when the entire system occupancy goes to 0, which means that it may be non-zero even if the space occupancy is 0 for any given hour.
## Walls
When entering the wall area, include the entire wall area - do not subtract window or door areas from the wall area. When you add windows or doors to your wall - the wall area will automatically subtract the areas of those windows and doors. 

Wall types are created in the Walls section in the left-hand menu. Once wall types are created, they will show up in the available list of walls to use to build your space.

The direction to select is the direction that the wall is facing. That is, if you were standing with your back to the wall, then the direction that you would be looking toward.
### Windows and Doors
For adding windows and doors, the options from the dropdown menus come from the window and door types created under the Windows section and Doors section from the left-hand menu. The same goes for interior and exterior shading. See each respective section for more detail.

A schedule may be added to an internal shading device. This can help adjust for the fact that blinds and curtains will not be fully closed all the time. Some roller shades can be put on automatic schedules so that the exact schedule can be known. It may be valuable to test out different shading types in these calculations to see the impact of internal shading on cooling loads.
### Plenum
If the room will have a dropped ceiling, and the wall being edited has a portion that is adjacent to that dropped ceiling, then select Yes for the ceiling plenum. A way to estimate the portion of the wall that goes to the ceiling plenum is by using the fraction of wall height adjacent to the dropped ceiling. For example, if the wall is 10’ tall and the ceiling is at 8’, then 2' out of the 10’ (20%) is adjacent to the ceiling space, and 20% should be entered as the portion going to the wall plenum.
![Ceiling_Diagram](Ceiling_Diagram.png)
For more detailed information on this topic, see our blog post at https://www.heatwise-hvac.com/blog/plenum-load-impacts.
## Roofs
When entering the roof area, include the entire roof area - do not subtract any skylight area from the roof area. When you add skylights to your roof, the roof area will automatically subtract the areas of those skylights. Roofs are considered as any sloped surface where the slope is between 0 degrees (horizontal) and 45 degrees. Any surface with a greater angle should be considered a wall.

Roof types are created in the Roofs section in the left-hand menu. Once roof types are created, they will show up in the available list of roofs to use to build your space.
![Roof_Slope](Roof_Slope.png)
When entering the roof slope, enter the % value as a number, not as a percentage or decimal. For example, if the slope is 5%, enter 5 and not 0.05.
### Skylights
For adding skylights, the options from the dropdown menu come from the skylights created under the Skylights section from the left-hand menu. Skylights cannot have exterior or interior shading added to them. The direction to select is the direction that the roof is facing if it is sloped. That is, if you were to raise the roof in the direction it is sloped toward so that it was vertical and stand with your back to it, then it is the direction that you would be looking toward.
### Plenum
If the room will have a dropped ceiling, and the roof being edited is above that dropped ceiling, then select Yes for the ceiling plenum. This should be done even if the ceiling is not used as a return air (or supply air) plenum. It is difficult to estimate how much of the roof load will go into the plenum; with no information, a rough estimate can be made at around 30%. If the ceiling has thermal insulation separating the ceiling cavity from the space, then this number could be increased. If it is a very narrow ceiling space with little or no insulation, then it can be reduced. For more detailed information on this topic, see our blog post at https://www.heatwise-hvac.com/blog/plenum-load-impacts.
## Partitions
Partitions are walls that separate the conditioned space from an unconditioned or partially conditioned space where the temperature is likely to be warmer than the outdoors but colder than the space in the winter, and cooler than the outdoors but hotter than the space in the summer.

In order to use a partition you must create a buffer space in the Buffer Spaces tab in the left-hand menu. 

**Example:** A wall separating the space from a parking garage is a partition. A parking garage is typically heated in the winter to prevent freezing, but not to a comfortable temperature, and is typically not cooled. The only exception would be if the garage is heated and cooled to the same temperatures as the conditioned space at all times. In that case, you would not consider the wall separating the garage from the space as a partition.

Wall types are created in the Walls section and buffer spaces are created in the Buffer Spaces section of the left-hand menu. See each section for more detail.

The wall size is limited to the total wall area separating the partition from the buffer space.
## Floors
Floors can be continuously added, just like walls and roofs. There are several different floor types that can be added, each with a unique way of calculating heat loss/gain. Where the area of the floor is required, enter the area only of that floor type. Usually it will be one floor type for an entire space and the area will be equal to the space floor area, but multiple floors may be added for unique situations.

### Slab on Grade
All heat transfer for a slab on grade floor is through the exposed perimeter. The heat transfer along the perimeter is impacted by both the outer wall construction and insulation at the footing. If these variables are unknown, then the most conservative values can be used, which would be to select Poured Concrete Wall and Uninsulated from the dropdown options.

### Slab Below Grade
A slab below grade is a basement; this option should be used if the space being built is a basement, not if the space is above a basement. The basement wall heat loss will be considered as part of the floor load in the overall load breakdown summary. For the basement wall area, only include the portion of the walls that are below grade. For example, if a basement has an 8 ft ceiling and 100 ft perimeter, but only 6 ft of the 8 ft height is below ground, then treat the basement wall as having an area of 6 x 100  = 600 ft<sup>2</sup>.

If the basement floor or walls are just bare concrete, then leave the R-value of Floor Insulation and R-value of Wall Insulation as 0. The bare concrete R-value is already built into the calculations.

The Amplitude of Ground Surface Temperature Variation can be calculated automatically by the software, or it can be manually entered based on the image shown below: 
![Ground_Temp_Amp](Ground_Temperature_Amplitude.png)
Each blue line represents a constant value of surface temperature amplitude, and the value to use as an input must be estimated based on which blue lines it lies between.

The picture above shows a red dot at the approximate location of Atlanta, GA. The location is partway between the 18<sup>o</sup>F isotherms and the 22<sup>o</sup>F isotherm. Since it is slightly closer to the 18<sup>o</sup>F line, the ground temperature variance amplitude can be estimated as 19<sup>o</sup>F.

The average ground temperature is typically estimated as the average annual temperature of the location from the weather data. If the value is known to be different than this, select Manual Entry and enter the value.
### Floor Above Crawl Space or Unconditioned Space
Crawl spaces and unconditioned spaces are similar to basements, but usually have minimal area and are not heated or cooled to the same temperature as the conditioned space above. This option should be used when the crawl space is below the conditioned space being built, not when the space is the crawl space, as with the basement. Crawlspaces typically do not add much to the cooling load, but will increase the heating load.
### Floor Above Conditioned Space
Where a floor is above a conditioned space below, it is assumed that there is no heat transfer at all. If there will likely be heat transfer, then use the Floor Above Crawl Space or Unconditioned Space option. 
### Floor Raised Off Ground
This option should be used only when the floor is not touching the ground and is not above another indoor space. This method will treat the floor like a wall that is entirely shaded.
## Internals
Internal loads refer to people, machines, electrical equipment, appliances, and anything else that will generate heat or moisture from within the space. For any internal load, a schedule may be added to specify when the load will be at full capacity, part capacity, or not contributing at all to internal gains. For many of the internal loads a diversity factor can be added as well - it should be noted that the diversity factor compounds with the schedule, and in many cases it is best to only use one or the other. 

For example, if the schedule for occupancy at some hour is at 50%, then the space calculations will apply 50% of the maximum capacity to the space for that hour. If a diversity factor of 50% is also added, then the total load from occupants will be 50% x 50% = 25% of the maximum occupant load. In some cases it may be beneficial to use a binary schedule with a diversity factor. For example, if it is known that a commercial stovetop is not in use at night, and then during the day it is used intermittently, then an on/off schedule can be used which switches between 0% and 100% from night to day, and then a diversity factor of 50% could be added if it is estimated that it is used only 50% of the time, switching on and off regularly. A diversity factor is applied to all hours calculated in the simulation.

Schedules are important to use and using an "always on" schedule will result in inaccurate results. This is because the radiant time series (RTS) calculation procedure involves a time delay, meaning that for most internal loads, a portion of the load is absorbed by the building materials and then released slowly over the next hours. This means that keeping the lights on overnight may increase the cooling load during the day.
### People
For large spaces with an unspecified number of people, it may be wise to use an estimated occupant density for the space. Values of estimated density by space type can be viewed by clicking Occupant Density Reference. Otherwise, simply enter the maximum number of people in the space at any given time. This value will be used to calculated ventilation, if ventilation is in automatic mode.

A schedule can be used when it is expected that the number of people in the space will fluctuate in a predictable pattern. For example, in a grocery store it is reasonable to expect that more people will be inside in the afternoon than in the morning. For example, if at some hour the schedule value is equal to 50%, then 50% of the value entered as the occupancy will be used as the number of people in the space at that hour.

Activity levels influence the heat given off by the people. This will impact both sensible and latent loads. The only time when using both a schedule and diversity factor would make sense is in a setting like a mall, when you don’t know how many people there will be, but you know that the mall is closed overnight, so you can apply a schedule flipping from 0% to 100% from closed to open time, and then use a diversity factor to bring the occupant density to a more realistic value during the open hours. Total ventilation required will still be calculated on the occupancy number entered.
### Lighting
Lighting power densities (LPDs) for various space types can be viewed by clicking Lighting Power Reference. Selecting the type of light and luminaire type can help increase the accuracy of results; but if you don’t know what they are, just select the Unknown options and HeatWise will make estimates for you.

Since lights are not always on, it is recommended that you apply a schedule to your lighting. Schedules can be created in the Schedules section on the menu to the left. Lighting schedules do not need to be a simple on/off schedule, but instead you can even include times where only some of the lights are on.

A diversity factor can be applied as well for situations where the lights are turning on and off frequently, and assuming they’re on for hours at a time would be inaccurate. 
### Motors
Add as many unique motors to the space as you would like by clicking Add Motor. Adding motors is recommended where the motor is not part of an appliance and where it is used regularly. If the motor is on a machine where all energy stays in the room (e.g. portable propeller fan), then it may make more sense to add it as a miscellaneous load, since motor efficiency will not play a role (100% of electrical energy is converted to heat that stays within the room).

Click Motor Efficiency Reference to view a table of recommended motor efficiencies based on the motor characteristics. A schedule may be useful if it is known that the motor is not running during certain portions of the day. 

A diversity factor should be applied if it is known that the motor cycles regularly. For example, if there are two pumps in a room with large motors, and they are in a duty/standby configuration, it is known that the pumps won’t both be operating at the same time, so a diversity factor of 50% could be applied to each pump.
### Appliances
HeatWise has a wide selection of appliances to choose from to add to your space. If you choose not to select an appliance from the available list and instead enter the heat rejection manually, you must be careful about the portions of heat that are sensible versus latent, and of the sensible heat, how much is radiant versus convective. This information is contained in the appliances from the list when you select, but if you are entering manually and you do not know the values, you can use the below information as a guide:
- If there is no water involved (such as computers or other electric machines/devices), latent heat gain = 0
- If the equipment has some kind of exhaust hood, such as hooded cooking equipment, then set latent heat gain = 0 and convective sensible portion = 0. This is because all convective sensible heat gain is extracted by the hood. 
- For unhooded appliances:
	- Office equipment with no fan: approximately 30% of the sensible power output is radiant.
	- Office equipment with a fan: approximately 10% of the sensible power output is radiant.
	- Other appliances: highly variable, but generally 30% of the sensible output can be assumed as radiant. For appliances where air velocity in the room is higher, this percentage should be lower. Where appliances operate at a very high temperature, this percentage should be higher.

An office load factor should be used when it is known that there will be many computers in the room and it is not worth counting and inputting them all. In this case, using the load factor may be more accurate. When using an office load factor, it is recommended to use a schedule, but not to use a diversity factor, however the option is still there in case it is necessary.

Where individual appliances are added, a schedule is important where it is known that the appliance will be off during certain hours. A schedule can already incorporate a diversity factor if entries of less than 100% are used at certain hours. A diversity factor will simply multiply each hour of the schedule by the same number. For example, if a schedule is applied so that one hour is at 100% and another is at 80%, and a diversity factor of 75% is used, then the 100% hour will become 75%, and the 80% hour will become 60% of the total power output. For this reason, it is recommended to use either a schedule or a diversity factor, but not both.
## Miscellaneous Loads
For other loads that do not conform to lights, people, motors, or appliances, or simply for more flexibility, you can add miscellaneous loads. The sensible and latent loads are often known, but the radiative portion is rarely known. If absolutely no information is known about the radiant portion, an arbitrary guess of around 25% of the sensible load can be used. For high-temperature items this number should be increased, and where air velocity is high or a fan is used, this number should be lower. If a hood is used to extract heat, then all convective sensible heat is generally removed and the radiant portion will be 100% of what is remaining.`