<script setup>
import { ref } from 'vue'
import { gApp, } from '../Globals.js'
import { Partition } from '../Components/Partition.js'
import { removeElem } from '../SharedUtils.js'
import FieldInput from './FieldInput.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import HelpButton from './HelpButton.vue'

import EditButton from './utils/EditButton.vue'
import DeleteButton from './utils/DeleteButton.vue'
import { GetSpaceName } from '../Common/UIStrings.js'

const props = defineProps({
  space: [Object]
})

let proj = gApp.proj();

</script>

<template>
  <SimpleCollapse class="Section Emphasis" title="Partitions" @onOpen="gApp.proj().setHelpId('Partitions')">
    <div>
      <HelpButton class="float-r" id="Partitions">
        <h4 class="mb-s">Partitions</h4>
        <p class="mb-s">
          Partitions are walls that separate the conditioned home space from an unconditioned space where the temperature is likely to be warmer than the outdoors but colder than the house in the winter, and cooler than the outdoors but hotter than the house in the summer.
          <br><br>
            <b>Example:</b> A floor separating the house from the basement is NOT a partition, even if the basement is not conditioned, because the main area of the house is generally open to the basement. 
          <br><br>
            <b>Example:</b> A wall separating the house from an attached garage is a partition. An attached garage is typically heated in the winter to prevent freezing, but not to a comfortable temperature, and is typically not cooled. The only exception would be if the garage is heated and cooled to the same temperatures as the house at all times. In that case, you would not consider the wall separating the garage from the house as a partition.
          <br><br>
            <b>Wall types</b> are created in the Walls section and <b>buffer spaces</b> are created in the Buffer Spaces section of the left-hand menu. See each section for more detail.
          <br><br>
            The wall <b>size</b> is limited to the total wall area separating the partition from the buffer space.
        </p>
      </HelpButton>
      <div class="mb-m">
        <p class="mute-header">Info</p>
        <p class="help-text">
          If your {{GetSpaceName()}} has partitions, add them here. Each partition references a <b>Wall Type</b> from the <b>Walls</b> tab and a <b>Buffer Space Type</b>
          from the <b>Buffer Spaces</b> tab.
        </p>
      </div>
      <table class="SimpleTable MarginBotXS w-90" v-if="space.partitions.length">
        <tr>
          <th>Wall Type</th>
          <th>Size</th>
          <th>Associated Buffer Space</th>
          <th></th>
        </tr>
        <tr v-for="partition in space.partitions" class="MarginBotS">
          <td><FieldInput v-model="partition.wallType" :compact="true" /></td>
          <td><FieldInput v-model="partition.size" :compact="true" /></td>
          <td><FieldInput v-model="partition.bufferSpaceType" :compact="true" /></td>
          <td><DeleteButton @click="removeElem(space.partitions, partition)" /></td>
        </tr>
      </table>
      <button @click="space.partitions.push(Partition.create())" class="MarginBotXS CreateBtn">Add Partition</button>
    </div>
  </SimpleCollapse>
</template>

<style scoped>
.Section {
  margin-bottom: var(--space-xs);
}

.WallsTable td {
  vertical-align: top;
}

.RowDetails {
  border-left: 2px solid var(--pc-dark);
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.RowDetailsRow:hover {
  background-color: inherit;
}

.AddBtn {
  margin-top: var(--xs);
}

.CloseDetailsBtn {
  float: right;
}
</style>