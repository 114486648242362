
export let OfficeEquipment_Data = `Appliances,,,,,,,
,,,,,,,
Desktop Computers,,,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad,Load Factor
4_GB_RAM,4 GB RAM,174.165,0,0.75,0.9,0.1,0
8_GB_RAM,8 GB RAM,256.125,0,0.75,0.9,0.1,0
16_GB_RAM,16 GB RAM,293.69,0,0.75,0.9,0.1,0
32_GB_RAM,32 GB RAM,303.935,0,0.75,0.9,0.1,0
,,,,,,,
,,,,,,,
Laptop Computers,,,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad,Load Factor
4_GB_RAM,4 GB RAM,201.485,0,0.7,0.75,0.25,0
8_GB_RAM_or_larger,8 GB RAM or larger,157.09,0,0.7,0.75,0.25,0
,,,,,,,
,,,,,,,
Laptop With Docking Station,,,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad,Load Factor
4_GB_RAM,4 GB RAM,174.165,0,0.7,0.75,0.25,0
8_GB_RAM,8 GB RAM,143.43,0,0.7,0.75,0.25,0
16_GB_RAM,16 GB RAM,218.56,0,0.7,0.75,0.25,0
32_GB_RAM,32 GB RAM,290.275,0,0.7,0.75,0.25,0
,,,,,,,
,,,,,,,
Load Factors,,,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad,Load Factor
100_laptop_with_docking_station_light,"100% laptop with docking station, light",0,0,1,0.8,0.2,1.1611
100_laptop_with_docking_station_medium,"100% laptop with docking station, medium",0,0,1,0.8,0.2,1.5709
100_laptop_with_docking_station_medium_two_screens,"100% laptop with docking station, medium, two screens",0,0,1,0.8,0.2,2.35635
100_laptop_with_docking_station_medium_two_screens_full_on,"100% laptop with docking station, medium, two screens, full on",0,0,1,0.8,0.2,3.8931
50_laptop_docking_station_50_desktop_light,"50% laptop with docking station, 50% desktop, light",0,0,1,0.8,0.2,1.5026
50_laptop_docking_station_50_desktop_medium,"50% laptop with docking station, 50% desktop, medium",0,0,1,0.8,0.2,2.01485
100_desktop_light,"100% desktop, light",0,0,1,0.8,0.2,1.8441
100_desktop_medium,"100% desktop, medium",0,0,1,0.8,0.2,2.4588
100_desktop_medium_two_screens,"100% desktop, medium, two screens",0,0,1,0.8,0.2,2.8686
100_desktop_medium_three_screens,"100% desktop, medium, three screens",0,0,1,0.8,0.2,3.2784
100_desktop_heavy_two_screens,"100% desktop, heavy, two screens",0,0,1,0.8,0.2,3.4833
100_desktop_heavy_three_screens,"100% desktop, heavy, three screens",0,0,1,0.8,0.2,3.9614
100_desktop_full_on_two_screens,"100% desktop, full on, two screens",0,0,1,0.8,0.2,4.54195
100_desktop_full_on_three_screens,"100% desktop, full on, three screens",0,0,1,0.8,0.2,5.22495
,,,,,,,
,,,,,,,
Monitors,,,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad,Load Factor
Large,Large,170.75,0,0.7,0.6,0.4,0
Medium,Medium,88.79,0,0.7,0.6,0.4,0
Small,Small,78.545,0,0.7,0.6,0.4,0
,,,,,,,
,,,,,,,
Printers,,,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad,Load Factor
Large_multifunction_multiuser_floor_unit_small,"Large multifunction, multiuser, floor unit, small",1844.1,0,0.45,0.7,0.3,0
Large_multifunction_multiuser_floor_unit_medium,"Large multifunction, multiuser, floor unit, medium",1034.745,0,0.45,0.7,0.3,0
Large_multifunction_multiuser_floor_unit_large,"Large multifunction, multiuser, floor unit, large",1478.695,0,0.45,0.7,0.3,0
Desktop_small_office_type,"Desktop, small-office type",191.24,0,0.45,0.7,0.3,0
Monochrome_desktop_large,"Monochrome, desktop, large",758.13,0,0.45,0.7,0.3,0
Monochrome_desktop_medium,"Monochrome, desktop, medium",208.315,0,0.45,0.7,0.3,0
Color_printer_desktop,"Color printer, desktop",409.8,0,0.45,0.7,0.3,0
Laser_printer_desktop_small,"Laser printer, desktop, small",303.935,0,0.45,0.7,0.3,0
Laser_printer_desktop_medium,"Laser printer, desktop, medium",228.805,0,0.45,0.7,0.3,0
Laser_printer_desktop_large,"Laser printer, desktop, large",221.975,0,0.45,0.7,0.3,0
Plotter_large,"Plotter, large",1949.965,0,0.45,0.7,0.3,0
Plotter_small,"Plotter, small",590.795,0,0.45,0.7,0.3,0
Fax_machine_medium,"Fax machine, medium",314.18,0,0.45,0.7,0.3,0
Fax_machine_small,"Fax machine, small",157.09,0,0.45,0.7,0.3,0
,,,,,,,
,,,,,,,
Tablet PC,,,,,,,
id,Appliance,S_app,L_app,D,F_conv,F_rad,Load Factor
4_GB_RAM,4 GB RAM,143.43,0,0.75,0.7,0.3,0
8_GB_RAM,8 GB RAM,105.865,0,0.75,0.7,0.3,0
16_GB_RAM,16 GB RAM,136.6,0,0.75,0.7,0.3,0`