import { SpaceTypes_Data } from './Tables/SpaceTypes_Data.js';
import { CsvHelper } from './CsvHelper.js';

import { makeEnum, makeEnumWithData, lookupData, } from '../Base.js'
import { DataTable } from '../Data/DataTable.js'
import { Units, getLabel } from '../Common/Units.js'

import { MultiTieredSelect } from '../Common/FieldUtils.js'


export class SpaceTypesDataTable {
  static _instance = null;

  static getInstance() {
    if (!this._instance) {
      this._instance = new SpaceTypesDataTable();
    }
    return this._instance;
  }

  constructor() {
    this.spaceTypesCsv = new CsvHelper(SpaceTypes_Data);

    this._parseData();
  }

  _parseData() {
    let tableInfo = {
      requiredCols: [
        'Category',
        'Space Type',
        'cfm/person',
        'cfm/ft^2',
        'occupant density',
      ],
      colInfo: {
        'Category': { type: 'IdAndLabel' },
        'Space Type': { type: 'IdAndLabel' },
        'cfm/person': { type: 'Number' },
        'cfm/ft^2': { type: 'Number' },
        'occupant density': { type: 'Number' },
      },
    }
    this.spaceTypesData = this.spaceTypesCsv.parseSimpleTable(null, tableInfo);
    //console.log("Parsed space types data: ", this.spaceTypesData);

    this.dataLookupTable = {}
    for (const row of this.spaceTypesData.rows) {
      let categoryId = row['Category'].id;
      let typeId = row['Space Type'].id;
      this.dataLookupTable[`${categoryId}/${typeId}`] = row;
    }
    //console.log("Created lookup table: ", this.dataLookupTable);

    this.optionsMap = MultiTieredSelect.makeOptionsMapFromTableRows(
      this.spaceTypesData.rows,
      ['Category', 'Space Type']
    );
    //console.log("Created options map: ", this.optionsMap);
  }

  getOptionsMap() {
    return this.optionsMap;
  }

  getDataForSpaceType(spaceTypeValue) {
    let spaceTypeKey = spaceTypeValue.join('/');
    return lookupData(this.dataLookupTable, [spaceTypeKey]);
  }

  getOccupancyTableData() {
    let data = {}
    for (const row of this.spaceTypesData.rows) {
      let id = `${row['Category'].id}/${row['Space Type'].id}`;
      let label = `${row['Category'].label} / ${row['Space Type'].label}`;
      data[id] = {
        label: label,
        value: row['occupant density'],
      }
    }
    return DataTable.create([
      {name: 'Space Type', field: 'label'},
      {name: `Occupant Density (${getLabel(Units.PeoplePer1000ft2)})`, field: 'value'},
    ], data);
  }
}
