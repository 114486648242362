<script setup>
import { ref, onMounted, reactive, computed, defineEmits, } from 'vue'

const emit = defineEmits(['click'])
</script>

<template>
  <button class="LinkBtn CloseX" @click="emit('click')">&#x2715;</button>
</template>

<style scoped>
</style>
