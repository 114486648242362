<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import SimpleCollapse from './SimpleCollapse.vue';
import DataTable from './DataTable.vue';
import BasicModal from './utils/BasicModal.vue';

const props = defineProps({
  title: String,
  data: Object
})

let showData = ref(false);

let modal = ref(null);

</script>

<template>
  <div class="PopupRefTable">
    <button class="PopupBtn MinorTextBtn mb-xs" @click="modal.showModal()">
      <i class="bi-file-earmark"></i>
      {{title}}
    </button>
  </div>
  <BasicModal ref="modal" :title="title" class="PopupRefModal">
    <p class="help-text mb-s">
      <i class="bi-info-circle mr-xxs"></i>
      <slot>Viewing reference data</slot>
    </p>
    <div>
      <DataTable :data="data" />
    </div>
  </BasicModal>
</template>

<style scoped>

.PopupBtn {
  /*border-bottom: 1px solid black;*/
}

.PopupRefTable {
}

.PopupRefModal {
  width: 800px;
}

.Content {
}

</style>
