import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, PleaseContactStr,
  IntervalTimer,
} from '../Base.js'
import * as psy from './Psychrometrics.js'

import { Field, FieldType, FieldGroup, SelectOrManualInput, } from '../Common/Field.js'
import { CalcContext } from '../Common/CalcContext.js'

import { InputComponent } from '../Common/InputComponent.js'
import { Updater } from '../Common/Updater.js'
import { Watcher } from '../Common/Watcher.js'


/*
From Psychrometrics.js:
export let PsyCalcMethod = {
  CalcWithWetBulbTemp: 'CalcWithWetBulbTemp',
  CalcWithRelativeHumidity: 'CalcWithRelativeHumidity',
  CalcWithHumidityRatio: 'CalcWithHumidityRatio',
  CalcWithDewpointTemp: 'CalcWithDewpointTemp',
  CalcWithSpecificVolume: 'CalcWithSpecificVolume',
  CalcWithEnthalpy: 'CalcWithEnthalpy',
};
*/

let CalculationMethod = makeEnum({
  CalcWithWetBulbTemp: 'CalcWithWetBulbTemp',
  CalcWithRelativeHumidity: 'CalcWithRelativeHumidity',
  CalcWithHumidityRatio: 'CalcWithHumidityRatio',
  CalcWithDewpointTemp: 'CalcWithDewpointTemp',
  CalcWithSpecificVolume: 'CalcWithSpecificVolume',
  CalcWithEnthalpy: 'CalcWithEnthalpy',
})

export class PsychrometricsCalculator extends InputComponent {
  init() {
    // We can't use the project watcher here, because the calculator is at
    // the global level.
    this.updater.setWatcher(Watcher.globalInstance());

    this.dryBulbTemp = new Field({
      name: 'Dry Bulb Temperature',
      type: FieldType.Temperature,
      watcher: this.watcher,
    })
    this.altitude = new Field({
      name: 'Altitude',
      type: FieldType.Length,
      watcher: this.watcher,
    })
    this.calculationMethod = Field.makeSelect('Calculation method', CalculationMethod)

    this.wetBulbTemp = new Field({
      name: 'Wet Bulb Temperature',
      type: FieldType.Temperature,
      watcher: this.watcher,
    })
    this.wetBulbTemp.setVisibility(() => {
      return this.calculationMethod.value == CalculationMethod.CalcWithWetBulbTemp;
    })
    this.relativeHumidity = new Field({
      name: 'Relative Humidity',
      type: FieldType.Percent,
      watcher: this.watcher,
    })
    this.relativeHumidity.setVisibility(() => {
      return this.calculationMethod.value == CalculationMethod.CalcWithRelativeHumidity;
    })
    this.humidityRatio = new Field({
      name: 'Humidity Ratio',
      type: FieldType.HumidityRatio,
      watcher: this.watcher,
    })
    this.humidityRatio.setVisibility(() => {
      return this.calculationMethod.value == CalculationMethod.CalcWithHumidityRatio;
    });
    this.dewpointTemp = new Field({
      name: 'Dewpoint Temperature',
      type: FieldType.Temperature,
      watcher: this.watcher,
    })
    this.dewpointTemp.setVisibility(() => {
      return this.calculationMethod.value == CalculationMethod.CalcWithDewpointTemp;
    });
    this.specificVolume = new Field({
      name: 'Specific Volume',
      type: FieldType.SpecificVolume,
      watcher: this.watcher,
    })
    this.specificVolume.setVisibility(() => {
      return this.calculationMethod.value == CalculationMethod.CalcWithSpecificVolume;
    })
    this.enthalpy = new Field({
      name: 'Enthalpy',
      type: FieldType.Enthalpy,
      watcher: this.watcher,
    })
    this.enthalpy.setVisibility(() => {
      return this.calculationMethod.value == CalculationMethod.CalcWithEnthalpy;
    });
    this.inputFields = [
      'wetBulbTemp',
      'relativeHumidity',
      'humidityRatio',
      'dewpointTemp',
      'specificVolume',
      'enthalpy',
    ]

    this.outputWetBulbTemp = new Field({
      name: 'Wet Bulb Temperature',
      type: FieldType.Temperature,
      isOutput: true,
    })
    this.outputRelativeHumidity = new Field({
      name: 'Relative Humidity',
      type: FieldType.Percent,
      isOutput: true,
    })
    this.outputHumidityRatio = new Field({
      name: 'Humidity Ratio',
      type: FieldType.HumidityRatio,
      numDecimals: 4,
      isOutput: true,
    })
    this.outputDewpointTemp = new Field({
      name: 'Dewpoint Temperature',
      type: FieldType.Temperature,
      isOutput: true,
    })
    this.outputSpecificVolume = new Field({
      name: 'Specific Volume',
      type: FieldType.SpecificVolume,
      isOutput: true,
    })
    this.outputEnthalpy = new Field({
      name: 'Enthalpy',
      type: FieldType.Enthalpy,
      isOutput: true,
    })
    this.outputFields = [
      'outputWetBulbTemp',
      'outputRelativeHumidity',
      'outputHumidityRatio',
      'outputDewpointTemp',
      'outputSpecificVolume',
      'outputEnthalpy',
    ]

    this.calcContext = CalcContext.create();
    this.calcError = null;

    this.updater.addWatchEffect('update-output', () => {
      let res = this.calcOutput();
      this.outputWetBulbTemp.value = res.t_wb_F;
      this.outputRelativeHumidity.value = res.RH * 100;
      this.outputHumidityRatio.value = res.W;
      this.outputDewpointTemp.value = res.t_dp_F;
      this.outputSpecificVolume.value = res.v;
      this.outputEnthalpy.value = res.h;
    })
  }

  calcOutput() {
    this.calcContext = CalcContext.create();
    let ctx = this.calcContext;

    let inputValues = {
      t_wb_F: this.wetBulbTemp.value,
      RH: this.relativeHumidity.value / 100,
      W: this.humidityRatio.value,
      t_dp_F: this.dewpointTemp.value,
      v: this.specificVolume.value,
      h: this.enthalpy.value,
    }
    let res = null;
    this.calcError = null;
    try {
       res = ctx.call(psy.CalcPsychrometrics, this.dryBulbTemp.value, this.altitude.value,
      this.calculationMethod.value, inputValues);
    } catch (e) {
      console.log(e);
      ctx.logFatalError(e);
      this.calcError = e;
    }
    return res;
  }
}
setupClass(PsychrometricsCalculator)