import { prettyJson, valOr,
  extendArray, isObject,
  valueOrFuncRes,
  AssertThrow,
} from '../SharedUtils.js'

/*
Helper class that traverses an object hierarchy and collects all errors.
*/
export class ErrorCollector {
  constructor(parentObj, opts) {
    this.parentObj = parentObj;
    if (!this._implementsTraversal(parentObj)) {
      throw new Error(`ErrorCollector: parentObj must implement traversal: ${parentObj}`);
    }
    this.opts = valOr(opts, {});
  }

  _implementsTraversal(obj) {
    return isObject(obj) && obj.childObjs;
  }

  _getChildErrors(parentObj, errors, visited, data) {
    let ignoreSet = valOr(this.opts.ignoreSet, new Set());
    if (ignoreSet.has(parentObj)) {
      data.curDebugObj.result = "Ignored";
      return;
    }
    if (visited.has(parentObj)) {
      data.curDebugObj.result = "Already visited";
      return;
    }
    visited.add(parentObj);
    data.curDebugObj.name = data.curPath;

    // Add errors from this object:
    if (parentObj.getInputPage) {
      //console.log(`Setting page: ${parentObj.getInputPage()}`)
      data.page = parentObj.getInputPage();
    }
    if (parentObj.getObjErrors) {
      let parentErrors = parentObj.getObjErrors().map((elem) => {
        let errorMsg = null;
        if (typeof elem === 'string') {
          errorMsg = elem;
        } else {
          errorMsg = elem.msg;
        }
        return {page: data.page, path: data.curPath, msg: errorMsg};
      });
      data.curDebugObj.errors = parentErrors;
      extendArray(errors, parentErrors);
    }

    // Add errors from child objects:
    if (parentObj.childObjs == '$none') {
      return;
    } else if (parentObj.childObjs == '$auto') {
      for (const key of Object.keys(parentObj)) {
        let childObj = parentObj[key];
        if (this._implementsTraversal(childObj) && childObj.enabled !== false) {
          let childName = childObj.objInfo ? valueOrFuncRes(childObj.objInfo._name) : key;
          let newPath = childName ? `${data.curPath ? data.curPath + ' / ' : ''}${childName}` : data.curPath;
          let newDebugObj = {};
          data.curDebugObj[key] = newDebugObj;
          this._getChildErrors(childObj, errors, visited, {
            ...data,
            curPath: newPath,
            curDebugObj: newDebugObj
          });
        } else if (Array.isArray(childObj)) {
          // Look in arrays for objects that have 'childObjs'
          data.curDebugObj[key] = [];
          for (let i = 0; i < childObj.length; ++i) {
            let elem = childObj[i];
            if (this._implementsTraversal(elem) && elem.enabled !== false) {
              // let newPath = `${curPath}/${key}#${i + 1}`
              let childName = elem.objInfo ? valueOrFuncRes(elem.objInfo._name) : key;
              let hasUniqueName = elem.objInfo && elem.objInfo._uniqueName;
              let uniqueNameLabel = !hasUniqueName ? ` #${i + 1}` : '';
              let newPath = childName ? `${data.curPath ? data.curPath + ' / ' : ''}${childName}${uniqueNameLabel}` : data.curPath;
              let newDebugObj = {};
              data.curDebugObj[key].push(newDebugObj);
              this._getChildErrors(elem, errors, visited, {
                ...data,
                curPath: newPath,
                curDebugObj: newDebugObj,
              });
            }
          }
        }
      }
    } else {
      throw new Error(`Unexpected value for 'childObjs': '${parentObj.childObjs}'`)
    }
  }

  _groupErrorsByPage(errors) {
    let pageDict = {};
    for (const err of errors) {
      let page = err.page;
      if (!page) {
        page = {label: `Project - ${err.path}`, path: err.path};
      }
      if (!pageDict[page.path]) {
        pageDict[page.path] = {
          page: page,
          errors: [],
        };
      }
      pageDict[page.path].errors.push(err);
    }

    let pageErrors = [];
    for (const key in pageDict) {
      let elem = pageDict[key];
      //let errMsg = `Found ${elem.errors.length} error(s) here.`;
      let firstError = elem.errors[0];
      let firstErrorParts = firstError.path.split(' / ');
      let abbrevErrorPath = null;
      if (firstErrorParts.length > 1) {
        abbrevErrorPath = firstErrorParts.slice(1).join(' / ');
      } else {
        abbrevErrorPath = firstError.path;
      }
      let errMsg = `${abbrevErrorPath}: ${firstError.msg}`;
      if (elem.errors.length > 1) {
        errMsg += ` <span class="AndMore">(+${elem.errors.length - 1} more errors)</span>`;
      }
      let error = {
        page: elem.page,
        path: elem.page.path,
        msg: errMsg,
      }
      pageErrors.push(error);
    }
    return pageErrors;
  }

  getErrors() {
    let errors = [];
    let debugDict = {};
    let data = {
      curPath: '',
      page: null,
      debugDict: debugDict,
      curDebugObj: debugDict,
    }
    this._getChildErrors(this.parentObj, errors, new Set(), data);
    //console.log(`DebugDict: `, prettyJson(debugDict));
    let errorsByPage = this._groupErrorsByPage(errors);
    return errorsByPage;
  }
};