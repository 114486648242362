<script setup>
import { ref, onMounted, reactive, computed, watch } from 'vue'
import { YesNo, UnknownOrEnter } from '../Components/Common.js'
import FieldInput from './FieldInput.vue'

const props = defineProps({
  results: {
    type: Object
  }
});

let inputs = computed(() => {
  return props.results.getInputs();
});

</script>

<template>
  <div class="InnerInputsSummary">
    <div class="InputSection">
      <h4>Basic House Characteristics</h4>
      <FieldInput :readonly="true" v-model="inputs.basics.floorSpace" />
      <FieldInput :readonly="true" v-model="inputs.basics.averageCeilingHeight" />
      <FieldInput :readonly="true" v-model="inputs.basics.buildingVolume" />
      <FieldInput :readonly="true" v-model="inputs.basics.numberBedrooms" />
      <FieldInput :readonly="true" v-model="inputs.basics.numberStoreys" />
      <FieldInput :readonly="true" v-model="inputs.basics.indoorSummerTemp" />
      <FieldInput :readonly="true" v-model="inputs.basics.indoorSummerHumidity" />
      <FieldInput :readonly="true" v-model="inputs.basics.indoorWinterTemp" />
      <FieldInput :readonly="true" v-model="inputs.basics.indoorWinterHumidity" />
    </div>
    <div class="InputSection">
      <h4>Environment Details</h4>
      <FieldInput :readonly="true" v-model="inputs.environment.heating99p6PerDryBulb" />
      <FieldInput :readonly="true" v-model="inputs.environment.cooling0p4PerDryBulb" />
      <FieldInput :readonly="true" v-model="inputs.environment.cooling0p4PerWetBulb" />
      <FieldInput :readonly="true" v-model="inputs.environment.latitude" />
      <FieldInput :readonly="true" v-model="inputs.environment.longitude" />
      <FieldInput :readonly="true" v-model="inputs.environment.elevation" />
    </div>
    <div class="InputSection">
      <h4>Envelope Details</h4>
      <FieldInput :readonly="true" v-model="inputs.envelope.totalWallArea" />
      <FieldInput :readonly="true" v-model="inputs.envelope.totalRoofArea" />
      <FieldInput :readonly="true" v-model="inputs.envelope.totalWindowArea" />
      <FieldInput :readonly="true" v-model="inputs.envelope.totalDoorArea" />
      <FieldInput :readonly="true" v-model="inputs.envelope.totalPartitionArea" />
    </div>
    <div class="InputSection">
      <h4>Outdoor Air Details</h4>
      <FieldInput :readonly="true" v-model="inputs.outdoorAir.totalVentilation" />
      <FieldInput :readonly="true" v-model="inputs.outdoorAir.summerInfiltration" />
      <FieldInput :readonly="true" v-model="inputs.outdoorAir.winterInfiltration" />
      <FieldInput :readonly="true" v-model="inputs.outdoorAir.continuousExhaust" />
      <!-- <FieldInput :readonly="true" v-model="inputs.outdoorAir.recoveryFlow" /> -->
      <FieldInput :readonly="true" v-model="inputs.outdoorAir.recoveryType" />
    </div>
    <div class="InputSection">
      <h4>Internal Details</h4>
      <FieldInput :readonly="true" v-model="inputs.internals.useStdMethod" />
      <template v-if="inputs.internals.useStdMethod.value == YesNo.No">
        <FieldInput :readonly="true" v-model="inputs.internals.additionalOccupants" />
        <FieldInput :readonly="true" v-model="inputs.internals.additionalSensibleLoads" />
        <FieldInput :readonly="true" v-model="inputs.internals.additionalLatentLoads" />
      </template>
    </div>
    <div class="InputSection">
      <h4>System Details</h4>
      <FieldInput :readonly="true" v-model="inputs.system.ductRunLocation" />
      <template v-if="inputs.system.typicalLeakageRateEntry.value == UnknownOrEnter.Enter">
        <FieldInput :readonly="true" v-model="inputs.system.typicalLeakageRate" :forceShowName="true" />
      </template>
      <template v-else>
        <FieldInput :readonly="true" v-model="inputs.system.typicalLeakageRateEntry" />
      </template>
      <FieldInput :readonly="true" v-model="inputs.system.ductInsulation" />
      <FieldInput :readonly="true" v-model="inputs.system.systemType" />
    </div>
  </div>
</template>

<style scoped>
.InputSection {
  margin-bottom: var(--space-xs);
}
</style>
