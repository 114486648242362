export let SpaceTypes_Data = `Category,Space Type,cfm/person,L/s / person,cfm/ft^2,L/s/m^2,occupant density,air class
$AnimalFacilities Animal Facilities,$AnimalExamRoom Animal Exam Room,10,5,0.12,0.6,20,2
,$AnimalImagingMriCtPet Animal Imaging (MRI/CT/PET),10,5,0.18,0.9,20,3
,$AnimalOperatingRooms Animal Operating Rooms,10,5,0.18,0.9,20,3
,$AnimalPostoperativeRecoveryRoom Animal Postoperative Recovery Room,10,5,0.18,0.9,20,3
,$AnimalPreparationRoom Animal Preparation Room,10,5,0.18,0.9,20,3
,$AnimalSurgeryScrub Animal Surgery Scrub,10,5,0.18,0.9,20,3
,$LargeAnimalHoldingRoom Large Animal Holding Room,10,5,0.18,0.9,20,3
,$Necropsy Necropsy,10,5,0.18,0.9,20,3
,$SmallAnimalCageRoomStaticCages Small Animal Cage Room (static cages),10,5,0.18,0.9,20,3
,$SmallAnimalCageRoomVentilatedCages Small Animal Cage Room (ventilated cages),10,5,0.18,0.9,20,3
$CorrectionalFacilities Correctional Facilities,$BookingWaiting Booking/waiting,7.5,3.8,0.06,0.3,50,2
,$Cell Cell,5,2.5,0.12,0.6,25,2
,$Dayroom Dayroom,5,2.5,0.06,0.3,30,1
,$GuardStation Guard Station,5,2.5,0.06,0.3,15,1
$EducationalFacilities Educational Facilities,$ArtClassroom Art Classroom,10,5,0.18,0.9,20,2
,$ClassroomAge5To8 Classroom (age 5 to 8),10,5,0.12,0.6,25,1
,$ClassroomAge9 Classroom (age 9+),10,5,0.12,0.6,35,1
,$ComputerLab Computer Lab,10,5,0.12,0.6,25,1
,$DaycareSickroom Daycare sickroom,10,5,0.18,0.9,25,3
,$DaycareThroughAge4 Daycare (through age 4),10,5,0.18,0.9,25,2
,$LectureClassroom Lecture classroom,7.5,3.8,0.06,0.3,65,1
,$LectureHallFixedSeats Lecture Hall (fixed seats),7.5,3.8,0.06,0.3,150,1
,$Libraries Libraries,5,2.5,0.12,0.6,10,1
,$MediaCenter Media Center,10,5,0.12,0.6,25,1
,$MultiuseAssembly Multiuse Assembly,7.5,3.8,0.06,0.3,100,1
,$MusicTheaterDance Music/theater/dance,10,5,0.06,0.3,35,1
,$ScienceLaboratories Science Laboratories,10,5,0.18,0.9,25,2
,$UniverstiyCollegeLaboratories Universtiy/college laboratories,10,5,0.18,0.9,25,2
,$WoodMetalShop Wood/metal shop,10,5,0.18,0.9,20,2
$FoodAndBeverageService Food and Beverage Service,"$BarCocktailLounge Bar, cocktail lounge",7.5,3.8,0.18,0.9,100,2
,$CafeteriaFastFoodDining Cafeteria/fast-food dining,7.5,3.8,0.18,0.9,100,2
,$KitchenCooking Kitchen (cooking),7.5,3.8,0.12,0.6,20,2
,$Restaurant Restaurant,7.5,3.8,0.18,0.9,70,2
$General General,$Breakrooms Breakrooms,5,2.5,0.06,0.3,25,1
,$CoffeeStations Coffee Stations,5,2.5,0.06,0.3,20,1
,$ConferenceMeeting Conference/meeting,5,2.5,0.06,0.3,50,1
,$Corridors Corridors,0,0,0.06,0.3,0,1
,$OccupiableStorageForLiquidsOrGels Occupiable storage for liquids or gels,5,2.5,0.12,0.6,2,2
"$HotelsAndOther Hotels, Motels, Resorts, Dormitories",$BarracksSleepingAreas Barracks sleeping areas,5,2.5,0.06,0.3,20,1
,$BedroomLivingRoom Bedroom/living room,5,2.5,0.06,0.3,10,1
,"$LaundryRoomsCentral Laundry Rooms, central",5,2.5,0.12,0.6,10,2
,$LaundryRoomWithinDwellingUnits Laundry Room within dwelling units,5,2.5,0.12,0.6,10,1
,$LobbiesPrefunction Lobbies/prefunction,7.5,3.8,0.06,0.3,30,1
,$MultipurposeAssembly Multipurpose assembly,5,2.5,0.06,0.3,120,1
$MiscSpaces Miscellaneous Spaces,$BanksOfBankLobbies Banks of Bank lobbies,7.5,3.8,0.06,0.3,15,1
,$BankVaultsSafeDeposit Bank vaults/safe deposit,5,2.5,0.06,0.3,5,2
,$ComputerNotPrinting Computer (not printing),5,2.5,0.06,0.3,4,1
,"$FreezerAndRefrigeratedSpaces50f10c Freezer and refrigerated spaces (<50F, 10C)",10,5,0,0,0,2
,$ManufacturingWhereHazardousMaterialsAreNotUsed Manufacturing where hazardous materials are not used,10,5,0.18,0.9,7,2
,$ManufacturingWhereHazardousMeterialsAreUsed Manufacturing where hazardous materials are used (excluding heavy industrial and chemical processes),10,5,0.18,0.9,7,3
,$PharmacyPrepArea Pharmacy (prep. Area),5,2.5,0.18,0.9,10,2
,$PhotoStudios Photo studios,5,2.5,0.12,0.6,10,1
,$ShippingReceiving Shipping/receiving,10,5,0.12,0.6,2,2
,"$SortingPackingLightAssembly Sorting, packing, light assembly",7.5,3.8,0.12,0.6,7,2
,$TelephoneClosets Telephone closets,0,0,0,0,0,1
,$TransportationWaiting Transportation waiting,7.5,3.8,0.06,0.3,100,1
,$Warehouses Warehouses,10,5,0.06,0.3,0,2
$OfficeBuildings Office Buildings,$Breakrooms Breakrooms,5,2.5,0.12,0.6,50,1
,$MainEntryLobbies Main entry lobbies,5,2.5,0.06,0.3,10,1
,$OccupiableStorageRoomsForDryMaterials Occupiable storage rooms for dry materials,5,2.5,0.06,0.3,2,1
,$OfficeSpace Office space,5,2.5,0.06,0.3,5,1
,$ReceptionAreas Reception areas,5,2.5,0.06,0.3,30,1
,$TelephoneDataEntry Telephone/data entry,5,2.5,0.06,0.3,60,1
$PublicAssemblySpaces Public Assembly Spaces,$AuditoriumSeatingAreas Auditorium seating areas,5,2.5,0.06,0.3,150,1
,$Courtrooms Courtrooms,5,2.5,0.06,0.3,70,1
,$LegislativeChambers Legislative chambers,5,2.5,0.06,0.3,50,1
,$Libraries Libraries,5,2.5,0.12,0.6,10,1
,$Lobbies Lobbies,5,2.5,0.06,0.3,150,1
,$MuseumsChildrenS Museums (children's),7.5,3.8,0.12,0.6,40,1
,$MuseumsGalleries Museums/galleries,7.5,3.8,0.06,0.3,40,1
,$PlacesOfReligiousWorship Places of Religious Worship,5,2.5,0.06,0.3,120,1
$Residential Residential,$CommonCorridors Common Corridors,0,0,0.06,0.3,0,1
$Retail Retail,$Sales Sales,7.5,3.8,0.12,0.6,15,2
,$Barbershop Barbershop,7.5,3.8,0.06,0.3,25,2
,$BeautyAndNailSalons Beauty and nail salons,20,10,0.12,0.6,25,2
,$CoinOperatedLaundry Coin-operated laundry,7.5,3.8,0.12,0.6,20,2
,$MallCommonAreas Mall common areas,7.5,3.8,0.06,0.3,40,1
,$PetShops Pet shops,7.5,3.8,0.18,0.9,10,2
,$Supermarket Supermarket,7.5,3.8,0.06,0.3,8,1
$SportsAndEntertainment Sports and Entertainment,$BowlingAlley Bowling Alley,10,5,0.12,0.6,40,1
,$DiscoDanceFloors Disco/dance floors,20,10,0.06,0.3,100,2
,$GamblingCasinos Gambling Casinos,7.5,3.8,0.18,0.9,120,1
,$GameArcades Game Arcades,7.5,3.8,0.18,0.9,20,1
,"$GymSportsArenaPlayArea Gym, sports arena (play area)",20,10,0.18,0.9,7,2
,$HealthClubAerobicsRoom Health club/aerobics room,20,10,0.06,0.3,40,2
,$HealthClubWeightRooms Health club/weight rooms,20,10,0.06,0.3,10,2
,$SpectatorAreas Spectator areas,7.5,3.8,0.06,0.3,150,1
,"$StagesStudios stages, studios",10,5,0.06,0.3,70,1
,$SwimmingPoolAndDeck Swimming (pool and deck),0,0,0.48,2.4,0,2`