<script setup>

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  options: {
    type: Array,
    required: true
  }
})
const emit = defineEmits(['update:modelValue'])

function selectType(type) {
    emit('update:modelValue', type);
}

</script>

<template>
<div class="RadioSelector">
    <p v-for="option in options" :key="option.value" class="Option mb-xxs cursor-ptr f-m" @click="selectType(option.value)">
        <i :class="{'bi-square-fill': modelValue === option.value, 'bi-square': modelValue !== option.value}" class="Box pc f-m mr-xs"></i>
        {{ option.label }}
    </p>
</div>
</template>
  
<style scoped>
.RadioSelector {
/* Add your styles here */
}

.Option:hover {
}
</style>