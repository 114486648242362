<script setup>
import { ref } from 'vue'
import { gApp,
} from '../State.js'
import { Roof, RoofSkylight, } from '../Components/Roof.js'
import { removeElem } from '../SharedUtils.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import LocationData from './LocationData.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import HelpButton from './HelpButton.vue'

import EditButton from './utils/EditButton.vue'
import DeleteButton from './utils/DeleteButton.vue'
import { GetSpaceName } from '../Common/UIStrings.js'

const props = defineProps({
  space: [Object]
})

let proj = gApp.proj();

</script>

<template>
  <SimpleCollapse class="Section Emphasis" title="Roofs" @onOpen="gApp.proj().setHelpId('Roofs')">
    <div>
      <HelpButton class="float-r" id="Roofs">
        <h4 class="mb-s">Roofs</h4>
        <p class="mb-s">
          When entering the roof <b>area</b>, include the entire roof area. That is, do not subtract skylight areas from the roof area. When you add skylights to your roof, the roof area will automatically subtract the areas of those skylights. 
          <br><br>
            Roofs are considered as any sloped surface where the <b>slope</b> is between 0 degrees (horizontal) and 45 degrees. Any surface with a greater angle should be considered a wall.
          <br><br>
            <b>Roof types</b> are created in the Roofs section in the left-hand menu. Once roof types are created, they will become available to select in the dropdown menu when building your house.
          <br><br>
            When entering the roof <b>slope</b>, enter the % value as a number, not as a percentage or decimal. For example, if the slope is 5%, enter 5 and not 0.05.
            <img src="../assets/roof-help.png" class="HelpImg">
          <br><br>
            For adding <b>skylights</b>, the options from the dropdown menu come from the skylights created under the Skylights section from the left-hand menu. Skylights cannot have exterior or interior shading added to them. 
        </p>
      </HelpButton>
      <div class="mb-m">
        <p class="mute-header">Info</p>
        <p class="help-text">
          Add your {{GetSpaceName()}}'s roofs here. Each roof references a <b>Roof Type</b> from the <b>Roofs</b> tab.
        </p>
      </div>
      <WarnText v-if="!proj.roofTypes.length" type="warn" class="mb-xs">Before adding roofs, go to the <router-link to="roofs">Roofs</router-link> tab to setup some Roof Types.</WarnText>
      <table class="SimpleTable MarginBotXS w-90" v-if="space.roofs.length">
        <tr>
          <th>Type</th>
          <th>Area</th>
          <th>Slope</th>
          <th>Direction</th>
          <th v-if="gApp.proj().isResidential()">Adj. to Attic</th>
          <th>Skylights</th>
          <th></th>
          <th></th>
        </tr>
        <template v-for="(roof, index) in space.roofs">
          <tr class="MarginBotS">
            <td><FieldInput v-model="roof.roofType" :compact="true" /></td>
            <td><FieldInput v-model="roof.area" :compact="true" /></td>
            <td><FieldInput v-model="roof.slope" :compact="true" /></td>
            <td>
              <FieldInput v-if="roof.slope.value > 0" v-model="roof.direction" :compact="true" />
              <p v-else>N/A</p>
            </td>
            <td v-if="gApp.proj().isResidential()"><FieldInput v-model="roof.adjacentToAttic" :compact="true" /></td>
            <td>
              <p>{{roof.getNumSkylights()}} Skylight(s)</p>
            </td>
            <td><EditButton @click="roof.expandedInUi = !roof.expandedInUi" /></td>
            <td><DeleteButton @click="removeElem(space.roofs, roof)" /></td>
          </tr>
          <tr v-if="roof.expandedInUi" class="mb-s RowDetailsRow">
            <td colspan="100%" class="RowDetails">
              <button class="LinkBtn upper CloseDetailsBtn" @click="roof.expandedInUi = false">Close</button>
              <div v-if="gApp.proj().isCommercial()" class="mt-xs mb-m">
                <p class="mute-title">Ceiling Plenum</p>
                <FieldInput v-model="roof.hasCeilingPlenum" />
                <FieldInput v-model="roof.portionOfRoofLoadToPlenum" />
              </div>
              <div class="mb-m">
                <p class="mute-title">Skylights</p>
                <p class="help-txt mb-xs">Add any skylights to the roof.</p>
                <WarnText v-if="!proj.skylightTypes.length" type="warn" class="mb-xs">Before adding skylights, go to the <router-link to="skylights">Skylights</router-link> tab to setup some Skylight Types.</WarnText>
                <table class="SimpleTable MinorTable w-60" v-if="roof.skylights.length">
                  <tr>
                    <th>Type</th>
                    <th>Quantity</th>
                    <th></th>
                  </tr>
                  <tr v-for="skylight in roof.skylights">
                    <td><FieldInput v-model="skylight.skylightType" :compact="true"/></td>
                    <td><FieldInput v-model="skylight.quantity" :compact="true"/></td>
                    <td><DeleteButton @click="removeElem(roof.skylights, skylight)" /></td>
                  </tr>
                </table>
                <button @click="roof.skylights.push(RoofSkylight.create())" class="small-upper AddBtn">Add Skylight</button>
              </div>
            </td>
          </tr>
        </template>
      </table>
      <button @click="space.roofs.push(Roof.create())" class="MarginBotS CreateBtn">Add Roof</button>
    </div>
  </SimpleCollapse>
</template>

<style scoped>
.Section {
  margin-bottom: var(--space-xs);
}

.WallsTable td {
  vertical-align: top;
}

.RowDetails {
  border-left: 2px solid var(--pc-dark);
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.RowDetailsRow:hover {
  background-color: inherit;
}

.AddBtn {
  margin-top: var(--xs);
}

.CloseDetailsBtn {
  float: right;
}
</style>