export let RoofsViewMarkdown = `# Roofs
The R-value of the roof will impact both the heating and cooling loads. The absorptance value will only affect the cooling load.

Roof R-values or roof construction will come from the architect’s design. For absorptance, if the material and/or colour are unknown, a conservative estimate would be 0.9. Roofs that are a clean, white colour can be expected to have an absorptance of approximately 0.3. Values below 0.3 are quite rare unless the roof is highly reflective.

Certain materials have a standard thickness, and a thickness input is not required for those materials. Thermal resistance of convection on both interior and exterior surfaces has already been built into the program and should not be taken into account when building a roof.

The order that materials are placed in will not affect heating or cooling loads, only the total R-value and solar absorptance will affect the loads. The total R-value is the sum of R-values of each layer.

Even if the R-value of the roof is known from architectural information, it may still be useful to build the roof by material, because that will give important information on the thermal mass of the roof. Thermal mass information is usually not as accessible as R-value, but is very important when looking at the Conduction Time Series (CTS) of cooling loads. The roof type input is important even when building the roof by material as it will help refine the thermal mass calculations. 

If you decide to enter roof information manually, click Recommended Roof Weights for a list of typical mass values by roof type. 

Roofs built in this section can be used as roof types when creating a space.
`