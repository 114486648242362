import * as ser from '../Common/SerUtil.js'
import { makeEnum, makeOptions,
  setupClass, lookupData, 
} from '../Base.js'
import { InputComponent } from '../Common/InputComponent.js'

import { WallAndRoofData } from '../MaterialData/WallsAndRoofs/WallAndRoofData.js'

import { prettyJson, valOr,
  addElem, removeElem, elemIn,
  extendMap,
  downloadTextFile,
} from '../SharedUtils.js'
export { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
  FieldGroup,
} from '../Common/Field.js'

import { gApp, DebugOn } from '../Globals.js'

class WallLayer {
  init(wallBuilder, optIsEditable, opts) {
    this.wallBuilder = wallBuilder;
    this.isEditable = valOr(optIsEditable, true)
    this.opts = valOr(opts, {});
    this.isRoof = valOr(this.opts.isRoof, false);

    this.materialInfo = null;

    this.category = new Field({
      name: 'Category',
      type: FieldType.Select,
      choicesFunc: () => {
        let cats = WallAndRoofData.instance().getWallLayerCategories();
        removeElem(cats, 'Defaults');
        if (this.isRoof) {
          removeElem(cats, 'Wall Materials');
        } else {
          removeElem(cats, 'Roofing');
        }
        return cats;
      },
      hiddenChoices: ['Defaults'],
    }) 

    this.type = new Field({
      name: 'Type',
      type: FieldType.Select,
      choices: [],
    })
    this.type.makeChoicesUpdater(() => {
      return WallAndRoofData.instance().getWallLayerTypes(this.category.value);
    })

    let updater = this.wallBuilder.getUpdater();
    updater.addWatchEffect('layer-is-editable', () => {
      this.category.isOutput = !this.isEditable;
      this.type.isOutput = !this.isEditable;
    })
    updater.addWatchEffect('layer-material-info', () => {
      this.materialInfo = WallAndRoofData.instance().lookupWallMaterial(
        this.category.value, this.type.value);
    })

    this.thickness = new Field({
      name: 'Thickness',
      type: FieldType.SmallLength,
      min: 0,
      allowMin: true,
    })
    this.thickness.makeUpdater((field) => {
      if (!this.materialInfo) {
        field.isOutput = true;
        field.value = 0.0;
        return;
      }
      if (this.materialInfo.requiresUserThickness()) {
        field.isOutput = false;
      } else {
        field.isOutput = true;
        field.value = this.materialInfo.getPresetThickness();
      }
    })

    this.rValue = new Field({
      name: 'R-value',
      type: FieldType.RValue,
      isOutput: true,
      min: 0,
      allowMin: true,
    })
    this.rValue.makeUpdater((field) => {
      field.value = this.getRValue();
    })

    this.serFields = [
      'category',
      'type',
      'thickness',
      'rValue',
      'isEditable',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      '_name': 'Wall Layer',
    }
  }

  static makeFirstLayer(wallBuilder) {
    let layer = WallLayer.create(wallBuilder, false, this.opts);
    layer.category.value = "Defaults";
    layer.type.value = "Outdoor Surface Resistance";
    return layer;
  }

  static makeLastLayer(wallBuilder) {
    let layer = WallLayer.create(wallBuilder, false, this.opts);
    layer.category.value = "Defaults";
    layer.type.value = "Indoor Layer Air Resistance";
    return layer;
  }

  getRValue() {
    if (!this.materialInfo) {
      return 0;
    }
    if (this.materialInfo.requiresUserThickness()) {
      return this.materialInfo.getResistanceFromThickness(this.thickness.value);
    } else {
      return this.materialInfo.getPresetResistance();
    }
  }

  getThickness() {
    return this.thickness.value;
  }

  // Get the wall weight per area in lb/ft^2
  getWallWeight() {
    if (!this.materialInfo) {
      return 0
    }
    if (this.materialInfo.requiresUserThickness()) {
      return this.materialInfo.getWeightFromThickness(this.thickness.value);
    } else {
      return this.materialInfo.getPresetWeight();
    }
  }
};
setupClass(WallLayer)

class WallBuilder extends InputComponent {
  init(wallThermalResistance, opts) {
    this.wallThermalResistance = wallThermalResistance;
    this.opts = valOr(opts, {});

    let firstLayer = WallLayer.makeFirstLayer(this);
    let lastLayer = WallLayer.makeLastLayer(this);
    this.layers = [
      firstLayer,
      lastLayer,
    ];

    this.serFields = [
      ser.arrayField('layers', () => {
        return WallLayer.create(this, true, this.opts);
      }),
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      '_name': 'Wall Builder',
      'layers': 'Layer',
    }
  }

  getUpdater() {
    return this.updater;
  }

  addLayer() {
    let newLayer = WallLayer.create(this, true, this.opts);
    addElem(this.layers, newLayer, this.layers.length - 1);
    return newLayer;
  }

  deleteLayer(layer) {
    removeElem(this.layers, layer);
  }

  computeRValue() {
    let rValue = 0;
    for (const layer of this.layers) {
      rValue += layer.getRValue();
    }
    return rValue;
  }

  computeExteriorWallWeight() {
    let wallWeight = 0
    for (const layer of this.layers) {
      wallWeight += layer.getWallWeight();
    }
    return wallWeight;
  }
};
setupClass(WallBuilder)

export let ThermalResistanceEntryMethod = makeEnum({
  Manual: 'Manual',
  BuildWall: 'Build wall',
})

class WallThermalResistance {
  init(wallType, opts) {
    this.opts = valOr(opts, {});
    let isRoof = valOr(this.opts.isRoof, false);

    this.wallType = wallType;

    this.entryMethod = new Field({
      name: 'Entry method',
      type: FieldType.Select,
      choices: [
        {label: 'Manual', value: ThermalResistanceEntryMethod.Manual},
        {label: `Build ${this.opts.isRoof ? 'Roof' : 'Wall'}`, value: ThermalResistanceEntryMethod.BuildWall},
      ],
      bold: true,
    })
    this.manualRValue = new Field({
      name: 'R-value',
      type: FieldType.RValue,
      requiresInput: true,
    })
    this.manualRValue.setVisibility(() => {
      return this.entryMethod.value == ThermalResistanceEntryMethod.Manual;
    })
    this.manualExteriorWallWeight = new Field({
      name: isRoof ? 'Roof Weight' : 'Exterior Wall Weight',
      type: FieldType.WeightPerArea,
      min: 0,
      allowMin: false,
      requiresInput: true,
    })
    this.manualExteriorWallWeight.setVisibility(() => {
      return gApp.proj().isCommercial() &&
        this.entryMethod.value == ThermalResistanceEntryMethod.Manual;
    })

    this.wallBuilder = WallBuilder.create(this, this.opts);
    this.wallType.updater.setEnabledWhen('wall-builder-enabled', this.wallBuilder, () => {
      return this.entryMethod.value == ThermalResistanceEntryMethod.BuildWall;
    })

    this.outputRValue = new Field({
      name: 'R-value',
      type: FieldType.RValue,
      isOutput: true,
    })
    this.outputRValue.makeUpdater((field) => {
      field.value = this.getRValue();
    })
    this.outputExteriorWallWeight = new Field({
      name: 'Exterior Wall Weight',
      type: FieldType.WeightPerArea,
      isOutput: true,
    })
    this.outputExteriorWallWeight.makeUpdater((field) => {
      field.visible = gApp.proj().isCommercial();
      field.value = this.getWallWeight();
    })

    this.serFields = [
      'entryMethod',
      'manualRValue',
      'manualExteriorWallWeight',
      'wallBuilder',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      //_name: 'Thermal Resistance',
    }
  }

  getRValue() {
    if (this.entryMethod.value == ThermalResistanceEntryMethod.Manual) {
      return this.manualRValue.value;
    } else {
      return this.wallBuilder.computeRValue();
    }
  }

  getWallWeight() {
    if (this.entryMethod.value == ThermalResistanceEntryMethod.Manual) {
      return this.manualExteriorWallWeight.value;
    } else {
      return this.wallBuilder.computeExteriorWallWeight();
    }
  }
}
setupClass(WallThermalResistance)

export let AbsorptanceEntryMethod = makeEnum({
  Manual: 'Manual',
  SelectByMaterial: 'Select by material',
})

class WallSolarAbsorptance {
  init(opts) {
    this.entryMethod = new Field({
      name: 'Entry method',
      type: FieldType.Select,
      choices: makeOptions(AbsorptanceEntryMethod),
      bold: true,
    })
    this.absorptance = new Field({
      name: 'Absorptance',
      type: FieldType.Ratio,
      min: 0,
      allowMin: false,
      requiresInput: true,
    })
    this.absorptance.setVisibility(() => {
      return this.entryMethod.value == AbsorptanceEntryMethod.Manual;
    })
    
    let materialsEnum = WallAndRoofData.instance().MaterialAbsorptance;
    this.material = new Field({
      name: 'Material',
      type: FieldType.Select,
      choices: makeOptions(materialsEnum),
    })
    this.material.setVisibility(() => {
      return this.entryMethod.value == AbsorptanceEntryMethod.SelectByMaterial;
    })

    this.outputAbsorptance = new Field({
      name: 'Absorptance',
      type: FieldType.Ratio,
      isOutput: true,
      allowMin: false,
    })
    this.outputAbsorptance.makeUpdater((field) => {
      field.value = this.getAbsorptance();
    })

    this.serFields = [
      'entryMethod',
      'absorptance',
      'material',
      'outputAbsorptance',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      //'_name': 'Solar Absorptance',
    }
  }

  getAbsorptance() {
    if (this.entryMethod.value == AbsorptanceEntryMethod.Manual) {
      return this.absorptance.value;
    } else {
      let materialsEnum = WallAndRoofData.instance().MaterialAbsorptance;
      return lookupData(materialsEnum._data, [this.material.value]).value;
    }
  }
}
setupClass(WallSolarAbsorptance)

/*
WallTypeOptions:
Curtain wall
Stud wall
EIFS
Brick wall
Concrete block wall
Precast / Cast-in-place block wall
Unknown
*/
export let WallTypeOption = makeEnum({
  CurtainWall: 'Curtain wall',
  StudWall: 'Stud wall',
  EIFS: 'EIFS',
  BrickWall: 'Brick wall',
  ConcreteBlockWall: 'Concrete block wall',
  PrecastCastInPlaceBlockWall: 'Precast / Cast-in-place block wall',
  Unknown: 'Unknown',
});

/*
Roof type options:
Sloped frame roof
Wood deck roof
Metal deck roof
Concrete roof
Unknown
*/
export let RoofTypeOption = makeEnum({
  SlopedFrameRoof: 'Sloped frame roof',
  WoodDeckRoof: 'Wood deck roof',
  MetalDeckRoof: 'Metal deck roof',
  ConcreteRoof: 'Concrete roof',
  Unknown: 'Unknown',
})

export class WallType extends InputComponent {
  init(name, makeId, opts) {
    this.id = makeId ? gApp.proj().makeId('WallType') : 0;
    this.opts = valOr(opts, {});
    
    this.isRoof = valOr(this.opts.isRoof, false);
    this.name = Field.makeName(`${this.isRoof ? 'Roof' : 'Wall'} Name`, name)

    this.type = Field.makeSelect('Type', !this.isRoof ? WallTypeOption : RoofTypeOption)
    this.thermalResistance = WallThermalResistance.create(this, this.opts);
    this.solarAbsorptance = WallSolarAbsorptance.create(this.opts);

    this.serFields = [
      'id',
      'name',
      'type',
      'thermalResistance',
      'solarAbsorptance',
    ];

    this.childObjs = '$auto'
    this.objInfo = {
      _uniqueName: true,
      _name: () => {
        return this.name.value;
      },
    }
  }

  getInputPage() {
    return {
      label: `${this.isRoof ? 'Roofs' : 'Walls'} - ${this.name.value}`,
      // TODO
      path: `${this.isRoof ? 'roofs' : 'walls'}/${this.id}`,
    };
  }

  getRValue() {
    return this.thermalResistance.getRValue();
  }

  getAbsorptance() {
    return this.solarAbsorptance.getAbsorptance();
  }

  getWallWeight() {
    return this.thermalResistance.getWallWeight();
  }
};
setupClass(WallType)

export class RoofType extends WallType {
  init(name, makeId) {
    super.init(name, makeId, {isRoof: true});
  }
};
setupClass(RoofType)
