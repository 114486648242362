import { PleaseContactStr } from "../Base";
import { valOr } from '../SharedUtils';
import { gApp } from '../Globals'


/*
Helper class to store an object's watchEffect updaters that aren't associated
to a given field.
*/
export class Updater {
  constructor() {
    this.watcher = null;

    this.errorsDict = {}
    this.errorHandlerCtr = 1;
  }

  _getWatcher() {
    return this.watcher || gApp.proj();
  }

  setWatcher(watcher) {
    // Usually the gApp.proj() watcher is used, but sometimes user may want to set to the global
    // one, like for the global calculators
    this.watcher = watcher;
  }

  // For manually adding errors
  setErrorWithId(id, errorMsg) {
    this.errorsDict[id] = errorMsg;
  }

  unsetErrorWithId(id) {
    delete this.errorsDict[id];
  }

  addWatchEffect(name, effectFunc, opts) {
    if (typeof name !== 'string') {
      throw new Error('Updater.addWatchEffect() misuse: name must be a string');
    }
    let errorHandlerId = this.errorHandlerCtr++;
    this._getWatcher().addWatchEffect(() => {
      try {
        effectFunc();
        delete this.errorsDict[errorHandlerId];
      } catch (err) {
        console.error(`Error during update ${name}:\n${err}\n${err.stack}`);
        this.errorsDict[errorHandlerId] = `Internal error during update (${name}): ${err.message}`
        if (gApp) {
          gApp.reportError(err);
        }
      }
    }, opts);
  }

  getErrors() {
    let errors = [];
    for (const key in this.errorsDict) {
      errors.push(this.errorsDict[key]);
    }
    return errors;
  }

  addErrors(errorsArr) {
    for (const key in this.errorsDict) {
      errorsArr.push(this.errorsDict[key]);
    }
  }

  setEnabledWhen(name, obj, func) {
    this.addWatchEffect(name, () => {
      obj.enabled = func();
    });
  }

  setFieldsEnabled(name, obj, fieldNames, func) {
    this.addWatchEffect(name, () => {
      let enabled = func();
      for (const field of fieldNames) {
        obj[field].enabled = enabled;
      }
    })
  }

  setMapItemEnabled(name, objMap, func) {
    this.addWatchEffect(name, () => {
      let selectedKey = func();
      for (const key in objMap) {
        objMap[key].enabled = selectedKey == key;
      }
    })
  }
};