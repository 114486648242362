<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import { makeOptions } from '../Base.js'
import { replaceArray } from '../SharedUtils.js'
import FieldInput from './FieldInput.vue'
import BasicModal from './utils/BasicModal.vue'

const props = defineProps({
  field: Object,
})

let modal = ref(null);
let errorText = ref("");
let curPath = reactive([]);

function open() {
  replaceArray(curPath, props.field.value);
  modal.value.showModal();
}

function makeColData(options) {
  let enumType = options.type || options;
  let isLeaf = !options.children || options.children.length == 0;

  let optionsArr = makeOptions(enumType)
  for (let option of optionsArr) {
    option.isLeaf = isLeaf;
  }
  return optionsArr;
}

let colData = computed(() => {
  let cols = [];
  cols.push(makeColData(props.field.optionsMap));

  let pathSoFar = [];
  for (let i = 0; i < curPath.length; ++i) {
    let part = curPath[i];
    pathSoFar.push(part);
    let curOptions = props.field.getOptionsForPath(pathSoFar);
    if (curOptions === null) {
      break;
    }
    cols.push(makeColData(curOptions));
  }
  //console.log("Cols: ", cols);
  return cols;
})

function onClickPathElem(colIndex, itemValue) {
  curPath.length = colIndex + 1;
  curPath[colIndex] = itemValue;
}

function onSubmit() {
  if (!props.field.isLeafPath(curPath)) {
    errorText.value = "Please select an appliance type within the current category.";
    return;
  }
  props.field.value = [...curPath];
  errorText.value = "";
  modal.value.closeModal();
}

let allowSubmit = computed(() => {
  return props.field.isLeafPath(curPath);
})

function getItemIcon(entry) {
  if (entry.isLeaf) {
    return "bi-box";
  } else {
    return "bi-folder";
  }
}

function resetToDefault() {
  props.field.value = [];
  errorText.value = "";
  modal.value.closeModal();
}

defineExpose({
  open
})

</script>

<template>
  <BasicModal ref="modal" class="MultiTierModal" :title="field.name" doneText="Choose" @onDone="onSubmit" :doneButtonEnabled="allowSubmit">
    <p v-if="errorText" class="input-error-text">{{ errorText }}</p>
    <div v-if="field.allowEmpty" class="mb-s">
      <button class="NormalTextBtn" @click="resetToDefault">Reset value to default: {{ field.emptyText }}</button>
    </div>
    <p>Select a new value:</p>
    <div class="ColTable MarginBotXXS">
      <div v-for="(col,i) of colData" class="Col">
        <button v-for="entry in col" class="ItemBtn LinkBtn MarginBotXXS" :class="{IsSelected: (i < curPath.length && curPath[i] === entry.value)}"
          @click="onClickPathElem(i, entry.value)">
          <i :class="getItemIcon(entry)" class="mr-xs"></i>{{ entry.label }}
        </button>
      </div>
    </div>
  </BasicModal>
</template>

<style scoped>
.MultiTierModal {
  width: 800px;
}

.ColTable {
  /* padding: var(--space-xs); */
  /* border: 1px solid var(--b-20); */
  display: flex;
  flex-flow: row nowrap;
  min-height: 320px;
  max-height: 500px;
}

.Col {
  padding: var(--space-s);
  border: 1px solid var(--b-20);
  min-width: 200px;
  overflow-y: scroll;
}

.Col + .Col {
  border-left: none;
}

.ItemBtn {
  text-transform: none;
  text-align: left;
}

.ItemBtn.IsSelected {
  color: var(--red);
  /*color: var(--pc);*/
}

</style>
