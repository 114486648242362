import workerpool from 'workerpool';

/*
Wrapper around workerpool API, so that we can mock it.
*/
export class WorkerPool {
  constructor(url, options) {
    this.pool = workerpool.pool(url, options);
  }

  async exec(funcName, argsList, options) {
    return this.pool.exec(funcName, argsList, options);
  }

  async terminate() {
    await this.pool.terminate();
  }
}

/*
Mock pool for testing
*/
export class MockWorkerPool {
  constructor() {
    this.registeredFunctions = {};
  }

  async terminate() {
  }

  registerFunction(funcName, func) {
    this.registeredFunctions[funcName] = func;
  }

  async exec(funcName, argsList, options) {
    if (!(funcName in this.registeredFunctions)) {
      throw new Error("Function not registered: " + funcName);
    }
    return this.registeredFunctions[funcName](...argsList);
  }
}