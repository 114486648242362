<script setup>
import { ref } from 'vue'
import { gApp, } from '../State.js'
import { removeElem } from '../SharedUtils.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import HelpButton from './HelpButton.vue'
import PopupRefTable from './PopupRefTable.vue'
import ScheduleInput from './ScheduleInput.vue'

import DeleteButton from './utils/DeleteButton.vue'

const props = defineProps({
  space: [Object]
})

let proj = gApp.proj();

</script>

<template>
  <SimpleCollapse class="Section Emphasis" title="Internals" @onOpen="gApp.proj().setHelpId('SpaceInternals')">
    <HelpButton class="float-r" id="SpaceInternals">
      <h4 class="mb-s">Floors</h4>
      <p class="mb-s">
        There are several different <b>floor types</b> that can be added, each with a unique way of calculating heat loss/gain.
        <br><br>
        For any floor type, there may be input options where the correct description is unavailable or unknown. One way forward is to pick the best-suited option and see what kind of impact the floor ends up having on the overall load. You can then change some inputs and rerun the calculations to see what kind of impact it will have. 
        <br><br>
          For <b>basements</b>, the basement wall heat loss will be considered as part of the floor load in the overall load breakdown summary. For the basement <b>wall area</b>, only include the portion of the walls that are below grade.
          For example, if a basement has an 8 ft ceiling and 100 ft perimeter, but only 6 ft of the 8 ft height is below ground, then treat the basement wall as having an area of 6 x 100 = 600 ft&sup2;.
          The <b>Amplitude of Ground Surface Temperature Variance</b> can be calculated automatically by the software, or it can be manually entered based on the image shown below: 
        <br><br>
          <img src="../assets/ground-temp.png" class="HelpImg">
        <br><br>
          Each blue line is an isotherm (that is, each blue line is a line of constant temperature), and the value to use as an input must be estimated based on which blue lines it lies between.
        <br><br>
        The picture above shows a red dot at the approximate location of Atlanta, GA. The location is partway between the 18F isotherms and the 22F isotherm. Since it is slightly closer to the 18F lines, the ground temperature variance amplitude can be estimated as 19F.
        <br><br>
          A <b>crawl space</b> should be treated similar to a buffer space.
          If the temperature in the crawl space is unknown, you may want to build a buffer space in the Buffer Spaces page,
          and use the output temperatures for winter and summer as your crawl space temperatures.
          If the crawl space is in direct contact with the earth, then a wall can be added with R-value 0.1 and adjacent temperature of <i>T<sub>avg</sub> + T<sub>amp</sub></i> for summer and <i>T<sub>avg</sub> - T<sub>amp</sub></i> for winter, where <i>T<sub>avg</sub></i> is found in the location data and <i>T<sub>amp</sub></i> is found in the figure above.
        <br><br>
        If the house has multiple floor types for different areas of the house, you can continuously add floor types to cover all areas.
        <br><br>
      </p>
    </HelpButton>
    <div class="MarginBotS">
      <div class="mb-m">
        <p class="mute-header">Info</p>
        <p class="help-text">
          Specify the space's internals.
        </p>
      </div>
      <InputGroup title="People">
        <p class="help-text mb-s">Describe the space's occupancy.</p>
        <div class="">
          <FieldInput v-model="space.internals.people.occupancyEntryType" />
          <FieldInput v-model="space.internals.people.occupancyCount" />
          <FieldInput v-model="space.internals.people.occupancyDensity" />
        </div>
        <FieldList :obj="space.internals.people" :fieldNames="space.internals.people.miscFields" />
        <ScheduleInput v-model="space.internals.people.scheduleInput" />
        <div class="mt-s">
          <PopupRefTable title="Occupancy density reference" :data="space.internals.people.occupancyTableData">
            If you are unsure of the occupancy density of your Space, use this table to help you estimate.
          </PopupRefTable>
        </div>
      </InputGroup>
      <InputGroup title="Lighting">
        <p class="help-text mb-s">Describe the space's lighting.</p>
        <FieldInput v-model="space.internals.lighting.power" />
        <FieldInput v-model="space.internals.lighting.type" />
        <FieldInput v-model="space.internals.lighting.luminaireType" />
        <ScheduleInput v-model="space.internals.lighting.scheduleInput" />
        <div class="mt-s">
          <PopupRefTable title="Lighting power reference" :data="space.internals.lighting.powerData">
            If you are unsure of the lighting power, use this table to help you estimate.
          </PopupRefTable>
        </div>
      </InputGroup>
      <InputGroup title="Motors">
        <p class="help-text mb-s">Add any motors in the space.</p>
        <div>
          <SimpleCollapse v-for="(motor, i) in space.internals.motors.motors" :title="'Motor #' + (i + 1)"
            class="MarginBotXS">
            <div class="mb-s">
              <FieldInput v-model="motor.power" />
              <FieldInput v-model="motor.efficiency" />
              <FieldInput v-model="motor.quantity" />
              <ScheduleInput v-model="motor.scheduleInput" />
              <div class="mt-s">
                <PopupRefTable title="Motor efficiency reference" :data="motor.efficiencyData" >
                  If you are unsure of the motor efficiency, use this table to help you estimate.
                </PopupRefTable>  
              </div>
            </div>
            <DeleteButton name="Delete Motor" @click="removeElem(space.internals.motors.motors, motor)"></DeleteButton>
          </SimpleCollapse>
        </div>
        <button @click="space.internals.motors.addMotor()" class="MarginBotXS CreateBtn">Add Motor</button>
      </InputGroup>
      <InputGroup title="Appliances">
        <p class="help-text mb-s">Add any appliances in the space.</p>
        <div>
          <SimpleCollapse v-for="(appliance, i) in space.internals.appliances.appliances" :title="'Appliance #' + (i + 1)"
            class="MarginBotXS">
            <div class="MarginBotS">
              <FieldInput v-model="appliance.entryType" />
              <FieldInput v-model="appliance.applianceType" />
              <FieldInput v-model="appliance.computerTypes" />
              <FieldInput v-model="appliance.officeLoadsIntensity" />
              <FieldGroup v-model="appliance.manualGroup" />
              <FieldInput v-model="appliance.quantity" />
              <div class="mb-m">
                <ScheduleInput v-model="appliance.scheduleInput"/>
              </div>
              <FieldList :obj="appliance" :fieldNames="appliance.outputFields"/>
            </div>
            <DeleteButton name="Delete Appliance" @click="removeElem(space.internals.appliances.appliances, appliance)"></DeleteButton>
          </SimpleCollapse>
        </div>
        <button @click="space.internals.appliances.addAppliance()" class="MarginBotXS CreateBtn">Add Appliance</button>
      </InputGroup>
      <InputGroup title="Misc Loads">
        <p class="help-text mb-s">Add any misc loads in the space.</p>
        <div class="mb-s">
          <FieldList :obj="space.internals.miscLoads" :fieldNames="space.internals.miscLoads.sensibleLoadsFields" />
        </div>
        <div class="mb-s">
          <FieldList :obj="space.internals.miscLoads" :fieldNames="space.internals.miscLoads.latentLoadsFields" />
        </div>
        <div class="mb-s">
          <ScheduleInput v-model="space.internals.miscLoads.scheduleInput" />
        </div>
      </InputGroup>
    </div>
  </SimpleCollapse>
</template>

<style scoped>
.Section {
  margin-bottom: var(--space-xs);
}

.WallsTable td {
  vertical-align: top;
}

.RowDetails {
  border-left: 2px solid var(--pc-dark);
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.RowDetailsRow:hover {
  background-color: inherit;
}

.AddBtn {
  margin-top: var(--xs);
}

.CloseDetailsBtn {
  float: right;
}
</style>