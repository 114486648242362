export let SkylightsViewMarkdown = `# Skylights
Skylights can be added to roofs when building a space. If a surface is sloped at an angle less than 45 degrees from vertical, then it should be considered a wall, and only windows and doors may be added to walls. 

For skylights, the length and width inputs refer to the dimensions including the frame, and the curb height input refers to the distance that the skylight protrudes from the roof exterior surface - see the images below for clarity.
![Skylight_Pic](Skylights.png)
If this height is negligible (less than 1 inch) then it should be ignored.

If Manual Entry is selected for the skylight data entry, the U-value and solar heat gain coefficient (SHGC) must be entered for the entire fenestration product (i.e. including the frame). SHGC is the portion of solar radiation entering the space versus solar radiation hitting the skylight. For example, an SHGC value of 0.9 would indicate that 90% of solar radiation hitting the skylight is entering the space, with the other 10% being reflected away. SHGC is a function of solar angle, and manufacturers publish the SHGC as test at direct beam conditions. If manual entry is selected, enter the value as reported by the manufacturer - the SHGC for non-direct angles will then be estimated based on the direct beam value provided.

For building a skylight product, the following image shows what a typical domed skylight looks like. If your skylight is flat, it is not considered a domed skylight.
![Dome](Domed_Skylight.png)
The image below shows what a typical tubular daylighting device (TDD) looks like. If your device does not resemble the device below, then a TDD should not be selected as the skylight type. 
![TDD](TDD.png)
Use the available dropdown menus to select the best-fitting description of your skylight. 

For TDD type skylights, if the approximate location of the roof insulation is unknown, a conservative estimate would be to select “Below Roof”. Note that locating the insulation directly above the ceiling will help reduce both heating and cooling loads. Also note that only single-pane glazing on the diffuser will result in poor performance for both heating and cooling loads.

For ordinary skylights, for selecting the panes that the low-e coatings are applied to, refer to the diagram below:
![Panes](Pane_Numbering.png)
For three panes, the pattern continues, where surface 6 would be the surface immediately adjacent to the indoors.

Frame thickness is the thickness of the frame as seen from the top view on the outside of the building; it is not related to the thickness of the roof that the skylight is installed in.
![Frame](Frame_Dimensions.png)
`