export let WallsAndRoofsDataCombined = `WallsAndRoofsCombined,,,,,,,,M,t,W
,,,,,,,,Birch,1,3.666666667
,,,,,,,,"Vegetable fiber board, nail-based sheathing",5,10.41666667
Relevant info (not to be used):,,,,,,,,Sand/cement/lime plaster,0.4,3
Id,Material,Thickness,Conductivity,Resistance,Density,Weight,,,,
Outdoor_surface_resistance,Outdoor surface resistance,0,,0.25,,0,,,,
Indoor_vertical_surface_resistance,Indoor vertical surface resistance,0,,0.68,,0,,,,
Indoor_horizontal_surface_resistance,Indoor horizontal surface resistance,0,,0.92,,0,,Asbestos/cement shingles,0.375,3.75
,,,,,,,,Built-up roofing,0.375,2.19
,,,,,,,,Slate 1/2 in.,0.5,5
Woods,,,,,,,,,,28.02333333
Id,Material,Thickness,Conductivity,Resistance,Density,Weight,,,,
Oak,Oak,,1.8,,44,,,,,
Birch,Birch,,1.19,,44,,,,,
Maple,Maple,,1.14,,42,,,,,
Ash,Ash,,1.1,,40,,,,,
Southern_pine,Southern pine,,1.06,,38.5,,,,,
Southern_yellow_pine,Southern yellow pine,,1.11,,31,,,,,
Eastern_white_pine,Eastern white pine,,0.895,,25,,,,,
Douglas_fir,Douglas fir/larch,,0.985,,35,,,,,
Southern_cypress,Southern cypress,,0.91,,31.5,,,,,
Hem_spruce,"Hem/fir, spruce/pine/fir",,0.82,,27.5,,,,,
Spruce,Spruce,,0.795,,25,,,,,
Western_red_cedar,Western red cedar,,0.845,,22,,,,,
West_coast_woods,"West coast woods, cedars",,0.79,,26.5,,,,,
Eastern_white_cedar,Eastern white cedar,,0.855,,23,,,,,
California_redwood,California redwood,,0.78,,26,,,,,
Pine_oven_dried,Pine (oven-dried),,0.64,,23,,,,,
Spruce_oven_dried,Spruce (oven-dried),,0.69,,25,,,,,
,,,,,,,,,,
,,,,,,,,,,
,,,,,,,,,,
WallMaterials,,,,,,,,,,
Id,Material,Thickness,Conductivity,Resistance,Density,Weight,,,,
2__wall_air_gap,"2"" wall air gap",1.5,,0.87,,0.1,,,,
4__wall_air_gap,"4"" wall air gap",3.5,,0.85,,0.2,,,,
6__wall_air_gap,"6"" wall air gap",5.5,,0.86,,0.3,,,,
Ceiling_air_gap,Ceiling air gap,5.5,,0.91,,0.3,,,,
EIFS_finish,EIFS finish,0.375,,0.08,,3.63,,,,
1_in__stucco,1 in. stucco,1,,0.2,,9.67,,,,
Metal_surface,Metal surface,0.03,,0.0001,,1.22,,,,
Opaque_spandrel_glass,Opaque spandrel glass,0.25,,0.04,,3.29,,,,
1_in__stone,1 in. stone,1,,0.05,,13.33,,,,
Wood_siding,Wood siding,0.5,,0.81,,1.54,,,,
Asphalt_shingles,Asphalt shingles,0.125,,0.44,,0.73,,,,
Built_up_roofing,Built-up roofing,0.375,,0.33,,2.19,,,,
Slate_or_tile,Slate or tile,0.5,,0.05,,5,,,,
Wood_shingles,Wood shingles,0.25,,0.94,,0.77,,,,
Acoustic_tile,Acoustic tile,0.75,,1.79,,1.44,,,,
Carpet,Carpet,0.375,,0.68,,0.63,,,,
Terrazzo,Terrazzo,1,,0.08,,13.33,,,,
Gypsum_Board,Gypsum Board,,1.11,,50,,,,,
Plywood,Plywood,,0.74,,34,,,,,
Fiberboard_sheathing,Fiberboard sheathing,,0.47,,25,,,,,
Insulation_board,Insulation board,,0.2,,2.5,,,,,
Batt_insulation,Batt insulation,,0.32,,0.49,,,,,
4_in__brick,4 in. brick,4,,0.65,,40,,,,
6_in__LW_concrete_block,6 in. LW concrete block,6,,1.77,,16,,,,
8_in__LW_concrete_block,8 in. LW concrete block,8,,2.55,,19.33,,,,
12_in__LW_concrete_block,12 in. LW concrete block,12,,2.45,,32,,,,
8_in__concrete_block,8 in. concrete block,8,,1.04,,33.33,,,,
12_in__concrete_block,12 in. concrete block,12,,1.23,,50,,,,
6_in__LW_concrete_block__filled_,6 in. LW concrete block (filled),6,,3,,16,,,,
8_in__LW_concrete_block__filled_,8 in. LW concrete block (filled),8,,4.6,,19.33,,,,
12_in__LW_concrete_block__filled_,12 in. LW concrete block (filled),12,,5.8,,32,,,,
8_in__concrete_block__filled_,8 in. concrete block (filled),8,,1.65,,33.33,,,,
Lightweight_concrete,Lightweight concrete,,3.7,,80,,,,,
Heavyweight_concrete,Heavyweight concrete,,13.5,,140,,,,,
2_in__LW_concrete_roof_ballast,2 in. LW concrete roof ballast,2,,1.54,,6.7,,,,
,,,,,,,,,,
Roofing,,,,,,,,,,
Id,Material,Thickness,Conductivity,Resistance,Density,Weight,,,,
Asbestos_cement_shingles,Asbestos/cement shingles,0.375,,0.21,120,3.75,,,,
Asphalt_roll_roofing,Asphalt roll roofing,0.375,,0.15,70,2.1875,,,,
Asphalt_shingles,Asphalt shingles,0.375,,0.44,70,2.1875,,,,
Built_up_roofing,Built-up roofing,0.375,,0.33,70,2.1875,,,,
Reed_Thatch,Reed Thatch,,0.62,,17,0,,,,
Roofing_felt,Roofing felt,,8.32,,141,0,,,,
Slate_1_2_in_,Slate 1/2 in.,0.5,,0.05,120,5,,,,
Straw_thatch,Straw thatch,,0.49,,15,0,,,,
Wood_shingles__plain_and_plastic_film_faced,"Wood shingles, plain and plastic-film-faced",0.5,,0.94,50,2.083333333,,,,
,,,,,,,,,,
,,,,,,,,,,
Plastering,,,,,,,,,,
Id,Material,Thickness,Conductivity,Resistance,Density,Weight,,,,
Cement_plaster__sand_aggregate,"Cement plaster, sand aggregate",,5,,116,,,,,
Sand_aggregate,Sand aggregate,,4.8,,116,,,,,
Gypsum_plaster,Gypsum plaster,,2.9,,70,,,,,
Perlite_aggregate,Perlite aggregate,,1.5,,45,,,,,
Vermiculite_aggregate__low_density,"Vermiculite aggregate, low density",,1.35,,30,,,,,
Vermiculite_aggregate__high_density,"Vermiculite aggregate, high density",,1.9,,45,,,,,
Perlite_plaster__low_density,"Perlite plaster, low density",,0.55,,25,,,,,
Perlite_plaster__high_density,"Perlite plaster, high density",,1.32,,38,,,,,
Pulpboard_or_paper_plaster,Pulpboard or paper plaster,,0.48,,38,,,,,
Sand_cement_plaster,Sand/cement plaster,,4.4,,98,,,,,
Sand_cement_lime_plaster,Sand/cement/lime plaster,,3.33,,90,,,,,
Sand_gypsum__3_1__plaster,Sand/gypsum (3:1) plaster,,4.5,,97,,,,,
,,,,,,,,,,
,,,,,,,,,,
Masonry,,,,,,,,,,
Id,Material,Thickness,Conductivity,Resistance,Density,Weight,,,,
Brick__fired_clay__high_density,"Brick, fired clay, high density",,6.8,,125,,,,,
Brick__fired_clay__low_density,"Brick, fired clay, low density",,4.4,,95,,,,,
Clay_tile__hollow,"Clay tile, hollow",,4.2,,50,,,,,
Lightweight_brick,Lightweight brick,,1.45,,50,,,,,
Limestone_aggregate,Limestone aggregate,,3.5,,138,,,,,
Normal_weight_aggregate,Normal-weight aggregate,,7.5,,131,,,,,
Medium_weight_aggregate,Medium-weight aggregate,,2.6,,105,,,,,
Expanded_shale__clay__slate_or_slag__pumice,"Expanded shale, clay, slate or slag, pumice",,1.7,,86,,,,,
Stone__lime__or_sand,"Stone, lime, or sand",,72,,180,,,,,
Quartzitic_and_sandstone__high_density,"Quartzitic and sandstone, high density",,43,,160,,,,,
Quartzitic_and_sandstone__medium_density,"Quartzitic and sandstone, medium density",,24,,140,,,,,
Quartzitic_and_sandstone__low_density,"Quartzitic and sandstone, low density",,13,,120,,,,,
Calcitic__dolomitic__limestone__marble__and_granite__high_density,"Calcitic, dolomitic, limestone, marble, and granite, high density",,21,,155,,,,,
Calcitic__dolomitic__limestone__marble__and_granite__low_density,"Calcitic, dolomitic, limestone, marble, and granite, low density",,12.8,,125,,,,,
Limestone,Limestone,,5,,150,,,,,
Sand_and_gravel_or_stone_aggregate_concrete,Sand and gravel or stone aggregate concrete,,15,,150,,,,,
Lightweight_aggregate_or_limestone_concrete,Lightweight aggregate or limestone concrete,,7.75,,120,,,,,
Gypsum_fiber_concrete,Gypsum/fiber concrete,,1.66,,51,,,,,
Cement_lime__mortar__and_stucco,"Cement/lime, mortar, and stucco",,7,,100,,,,,
Perlite__vermiculite__and_polystyrene_beads,"Perlite, vermiculite, and polystyrene beads",,1.3,,50,,,,,
Foam_concrete__high_density,"Foam concrete, high density",,3.75,,93,,,,,
Foam_concrete__low_density,"Foam concrete, low density",,1.4,,40,,,,,
Aerated_concrete,Aerated concrete,,1.4,,38.5,,,,,
Polystyrene_concrete,Polystyrene concrete,,2.54,,33,,,,,
Polymer_concrete,Polymer concrete,,9.3,,130,,,,,
Polymer_cement,Polymer cement,,5.39,,117,,,,,
Slag_concrete__low_density,"Slag concrete, low density",,1.9,,70,,,,,
Slag_concrete__high_density,"Slag concrete, high density",,5.8,,113,,,,,
,,,,,,,,,,
,,,,,,,,,,
Insulating,,,,,,,,,,
Id,Material,Thickness,Conductivity,Resistance,Density,Weight,,,,
Glass_fiber_batt__low_density,"Glass-fiber batt, low density",,0.3,,0.65,,,,,
Glass_fiber_batt__high_density,"Glass-fiber batt, high density",,0.23,,1.4,,,,,
Rock_and_slag_wool_batt,Rock and slag wool batt,,0.25,,2.4,,,,,
Mineral_wool__felted,"Mineral wool, felted",,0.26,,4,,,,,
Cellular_glass_board,Cellular glass board,,0.29,,7.5,,,,,
Cement_fiber_slabs__shredded_wood_with_Portland_cement_binder,"Cement fiber slabs, shredded wood with Portland cement binder",,0.54,,24,,,,,
Glass_fiber_board,Glass fiber board,,0.22,,4,,,,,
Expanded_rubber__rigid_,Expanded rubber (rigid),,0.2,,4,,,,,
Extruded_polystyrene,Extruded polystyrene,,0.2,,2.2,,,,,
Expanded_polystyrene,Expanded polystyrene,,0.25,,1.4,,,,,
Mineral_fiberboard__wet_felted,"Mineral fiberboard, wet felted",,0.26,,10,,,,,
Rock_wool_board___floors_walls_,"Rock wool board, (floors/walls)",,0.24,,6,,,,,
Rock_wool_board___roofing_,"Rock wool board, (roofing)",,0.28,,10.5,,,,,
Acoustical_tile,Acoustical tile,,0.365,,22,,,,,
Perlite_board,Perlite board,,0.36,,9,,,,,
Polyisocyanurate,Polyisocyanurate,,0.16,,1.95,,,,,
Phenolic_foam_board_with_facers,Phenolic foam board with facers,,0.15,,0.195,,,,,
Cellulose_fiber, Cellulose fiber,,0.3,,2,,,,,
Perlite__expanded,"Perlite, expanded",,0.34,,6,,,,,
Glass_fiber__attic," Glass fiber, attic",,0.36,,0.5,,,,,
Glass_fiber__closed_attic_or_wall_cavities," Glass fiber, closed attic or wall cavities",,0.255,,2.05,,,,,
Rock_and_slag_wool__attics," Rock and slag wool, attics",,0.33,,1.6,,,,,
Rock_and_slag_wool___closed_attic_or_wall_cavities,"Rock and slag wool,  closed attic or wall cavities",,0.28,,4,,,,,
Vermiculite__exfoliated,"Vermiculite, exfoliated",,0.455,,6,,,,,
Cellulose__sprayed_into_open_wall_cavities,"Cellulose, sprayed into open wall cavities",,0.275,,2.2,,,,,
Glass_fiber__sprayed_into_open_wall_or_attic_cavities,"Glass fiber, sprayed into open wall or attic cavities",,0.26,,1.7,,,,,
Polyurethane_foam__open_cell,"Polyurethane foam, open cell",,0.275,,0.55,,,,,
Polyurethane_foam__closed_cell,"Polyurethane foam, closed cell",,0.17,,2.55,,,,,
,,,,,,,,,,
,,,,,,,,,,
BuildingBoardAndSiding,,,,,,,,,,
Id,Material,Thickness,Conductivity,Resistance,Density,Weight,,,,
Asbestos_cement_board,Asbestos/cement board,,4,,120,,,,,
Cement_board,Cement board,,1.7,,71,,,,,
Fiber_cement_board__high_density,"Fiber/cement board, high density",,1.5,,75,,,,,
Fiber_cement_board__low_density,"Fiber/cement board, low density",,0.75,,36,,,,,
Gypsum_or_plaster_board,Gypsum or plaster board,,1.1,,40,,,,,
Oriented_strand_board__OSB_,Oriented strand board (OSB),0.4375,,0.62,41,1.494791667,,,,
Plywood__douglas_fir_,Plywood (douglas fir),,0.63,,29,,,,,
Plywood_wood_panels,Plywood/wood panels,,0.69,,28,,,,,
Vegetable_fiber_board__sheathing__regular_density,"Vegetable fiber board, sheathing, regular density",,0.378787879,,18,,,,,
Vegetable_fiber_board__nail_based_sheathing,"Vegetable fiber board, nail-based sheathing",,0.47,,25,,,,,
Vegetable_fiber_board__shingle_backer,"Vegetable fiber board, shingle backer",,0.39893617,,18,,,,,
Vegetable_fiber_board__sound_deadening_board,"Vegetable fiber board, sound-deadening board",,0.37037037,,15,,,,,
Vegetable_fiber_board__laminated_paperboard,"Vegetable fiber board, laminated paperboard",,0.5,,30,,,,,
Hardboard,Hardboard,,0.82,,52,,,,,
Particleboard__low_density,"Particleboard, low density",,0.83,,44,,,,,
Particleboard__high_density,"Particleboard, high density",,1.06,,56,,,,,
Waferboard,Waferboard,,0.63,,37,,,,,
Shingles__Asbestos_cement,"Shingles, Asbestos/cement",0.3125,,0.21,120,3.125,,,,
Shingles__Wood,"Shingles, Wood",0.3125,,1.15,120,3.125,,,,
Siding__Asbestos_cement,"Siding, Asbestos/cement",0.25,,0.21,120,2.5,,,,
Siding__Asphalt,"Siding, Asphalt",0.25,,0.18,120,2.5,,,,
Hardboard_siding,Hardboard siding,0.4375,,0.15,120,4.375,,,,
Siding__Wood,"Siding, Wood",0.5,,0.81,120,5,,,,
Siding__Aluminum__steel__or_vinyl__over_sheathing__hollow_backed,"Siding, Aluminum, steel, or vinyl, over sheathing, hollow-backed",0.375,,0.62,120,3.75,,,,
Siding__Aluminum__steel__or_vinyl__over_sheathing__insulating_board_backed,"Siding, Aluminum, steel, or vinyl, over sheathing, insulating-board-backed",0.375,,1.82,120,3.75,,,,
Siding__Aluminum__steel__or_vinyl__over_sheathing__foil_backed,"Siding, Aluminum, steel, or vinyl, over sheathing, foil-backed",0.375,,2.96,120,3.75,,,,
Siding__Aluminum__steel__or_vinyl__over_sheathing__insulated_vinyl_siding,"Siding, Aluminum, steel, or vinyl, over sheathing, insulated vinyl siding",0.75,,2.35,120,7.5,,,,`