import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, PleaseContactStr,
  IntervalTimer,
} from '../Base.js'
import * as ser from '../Common/SerUtil.js'
import { InputComponent } from '../Common/InputComponent.js'

import { Field, FieldType, } from '../Common/Field.js'

import { gApp, DebugOn } from '../Globals.js'

export class ZoneSpace {
  init() {
    this.spaceType = Field.makeTypeSelect('Type', gApp.proj().spaces, null, {
      errorWhenEmpty: `You must create a Space`,
    });
    this.quantity = new Field({
      name: 'Quantity',
      type: FieldType.Count,
      defaultValue: 1,
      allowMin: false,
    })

    this.serFields = [
      'spaceType',
      'quantity',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _name: "Space",
    }
  }

  getSpaceType() {
    return this.spaceType.lookupValue();
  }

  getSpaceName() {
    return this.spaceType.lookupValue().getName();
  }

  getQuantity() {
    return this.quantity.value;
  }

  isOccupied(hr) {
    return this.spaceType.lookupValue().isOccupied(hr);
  }
}
setupClass(ZoneSpace)

export class Zone extends InputComponent {
  init(name) {
    this.name = Field.makeName(`Zone Name`, name)

    this.spaces = []

    this.winterIndoorTemp = new Field({
      name: 'Winter indoor temp',
      type: FieldType.Temp,
      defaultValue: 70,
    });
    this.summerIndoorTemp = new Field({
      name: 'Summer indoor temp',
      type: FieldType.Temp,
      defaultValue: 75,
    });

    this.humiditySetpoint = new Field({
      name: 'Zone Humidity',
      type: FieldType.Percent,
      defaultValue: 50,
    });

    this.tempFields = [
      'winterIndoorTemp',
      'summerIndoorTemp',
      'humiditySetpoint',
    ]

    this.serFields = [
        'name',
        ser.arrayField('spaces', () => { return ZoneSpace.create(); }),
        'winterIndoorTemp',
        'summerIndoorTemp',
        'humiditySetpoint',
    ]
    this.childObjs = '$auto'
    this.objInfo = {
      _uniqueName: true,
      _name: () => {
        return this.name.value;
      },
    }
  }

  getName() {
    return this.name.value;
  }

  getObjErrors() {
    let errors = [];
    this.updater.addErrors(errors);
    if (this.spaces.length === 0) {
      errors.push('You must add at least one Space to the Zone.');
    }
    return errors;
  }

  createSpace() {
    let zoneSpace = ZoneSpace.create();
    this.spaces.push(zoneSpace);
    return zoneSpace;
  }

  getSpaceAtIndex(spaceIndex) {
    return this.spaces[spaceIndex];
  }

  isOccupied(hr) {
    // The zone is occupied if any space is occupied
    for (const space of this.spaces) {
      if (space.isOccupied(hr)) {
        return true;
      }
    }
    return false;
  }

  getSpaceQuantities() {
    // Return an array of the space quantities
    return this.spaces.map(space => space.quantity.value);
  }
}
setupClass(Zone)