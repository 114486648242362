<script setup>
import { ref } from 'vue'
import { gApp } from '../Globals.js'
import FieldInput from './FieldInput.vue'
import LocationData from './LocationData.vue'
import InputGroup from './InputGroup.vue'
import HelpSection from './HelpSection.vue'
import HelpButton from './HelpButton.vue'

import BuildingGroundTypes from './BuildingGroundTypes.vue'

let proj = gApp.proj();

</script>

<template>
  <div class="FlexHelp">
    <h1 class="PageH1">Building and Environment</h1>
    <HelpButton id="build" :hasSlot="false"></HelpButton>
  </div>
  <HelpSection id="BuildingAndEnv">
    <h4>Build House</h4>
    <p class="mb-s">Use this page to describe your house's interior and exterior.</p>
    <p class="mb-s">Fill out each section. Click the <i class="bi-question-circle pc"></i> icons for more help.</p>
    <p>When you're done, go back to the <b>Overview</b> tab to see your house's load breakdown.</p>
  </HelpSection>
  <div class="mb-m">
    <p class="mute-header">Info</p>
    <p class="help-txt">Provide general info about your building and the environment.</p>
  </div>
  <div>
    <div title="Location" class="Section mb-m">
      <div class="FlexHelp">
        <h4 class="mb-xxs">Location</h4>
        <HelpButton id="Location">
          <h4>Location</h4>
          <p class="mb-s">Select your house's location.</p>
          <p class="mb-s">It is used to obtain the statistical weather data, latitude, longitude, time zone, and more.
            This information will impact the calculations of solar radiation, external wall conduction, and outdoor air temperatures. 
          </p>
          <p class="mb-s">
            All data provided in this section is taken from weather stations at or near the listed locations.
          </p>
          <p>
          You can view and override all data by clicking "Show Data".
          </p>
        </HelpButton>
      </div>
      <LocationData v-model="proj.buildingAndEnv.locationData" />
    </div>
    <div class="Section">
      <h4 class="mb-xxs">Environment Info</h4>
      <InputGroup title="Shelter Type">
        <FieldInput v-model="proj.buildingAndEnv.shelterType" />
      </InputGroup>
      <InputGroup title="Ground Reflectances" class="GroundTypes">
        <div class="mb-m">
          <p class="help-text mt-xs">
            Enter the ground reflectances (aka albedo) around your building. Choose from the list or enter manually.
          </p>
        </div>
        <div class="mb-s">
          <p class="mute-header">Summer Reflectances</p>
          <BuildingGroundTypes :value="proj.buildingAndEnv.summerGroundTypes" />
        </div>
        <div>
          <p class="mute-header">Winter Reflectances</p>
          <BuildingGroundTypes :value="proj.buildingAndEnv.winterGroundTypes" />
        </div>
      </InputGroup>
    </div>
  </div>
</template>

<style scoped>
.Section {
  /*margin-bottom: var(--space-xs);*/
}

.LastSection {
  margin-bottom: var(--space-xl);
}

.WallsTable td {
  vertical-align: top;
}

.RowDetails {
  border-left: 2px solid var(--pc-dark);
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.RowDetailsRow:hover {
  background-color: inherit;
}

.AddBtn {
  margin-top: var(--xs);
}

.CloseDetailsBtn {
  float: right;
}

.GroundTypes {
  padding-right: var(--l);
}
</style>
