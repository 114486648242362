import { makeEnum, makeOptions,
  setupClass, lookupData, 
  interpolateInMap,
} from '../Base.js'
export { Units } from '../Common/Units.js'

import {
  FieldType,
  Field,
} from '../Common/Field.js'

import {
  YesNo,
  } from '../Components/Common.js'


export class HouseInternalsData {
  init() {
    this.useStdMethod = new Field({
      name: 'Use standard internal gain calculation?',
      type: FieldType.Select,
      choices: makeOptions(YesNo),
      bold: true,
    })
    // For YES:
    this.additionalOccupants = new Field({
      name: 'Number occupants',
      type: FieldType.Count,
      defaultValue: 3,
    })
    this.additionalOccupants.setVisibility(() => {
      return this.useStdMethod.value == YesNo.No;
    })
    this.additionalSensibleLoads = new Field({
      name: 'Additional sensible loads',
      type: FieldType.Load,
      defaultValue: 100,
    })
    this.additionalSensibleLoads.setVisibility(() => {
      return this.useStdMethod.value == YesNo.No;
    })
    this.additionalLatentLoads = new Field({
      name: 'Additional latent loads',
      type: FieldType.Load,
      defaultValue: 50,
    })
    this.additionalLatentLoads.setVisibility(() => {
      return this.useStdMethod.value == YesNo.No;
    })

    this.serFields = [
      'useStdMethod',
      'additionalOccupants',
      'additionalSensibleLoads',
      'additionalLatentLoads',
    ];
    this.childObjs = '$auto'
    this.objInfo = {
      _name: 'Internals',
    }
  }

  calcOutputs(ctx) {
    ctx.startSection("Internals")
    ctx.isManual = this.useStdMethod.value == YesNo.No;
    if (ctx.isManual) {
      ctx.N_occ = this.additionalOccupants.value;
      ctx.q_occ_s = ctx.eval('75*N_occ', {}, 'q_occ_s');
      ctx.q_occ_l = ctx.eval('41*N_occ', {}, 'q_occ_l');
      ctx.q_I_s = this.additionalSensibleLoads.value;
      ctx.q_I_l = this.additionalLatentLoads.value;
    } else {
      ctx.N_occ = ctx.eval('N_br + 1', {N_br: ctx.toplevelData.numberBedrooms}, 'N_occ');
      ctx.A_floor = ctx.toplevelData.totalFloorArea;
      ctx.q_occ_s = ctx.eval('75*N_occ', {}, 'q_occ_s');
      ctx.q_occ_l = ctx.eval('41*N_occ', {}, 'q_occ_l');
      ctx.q_I_s = ctx.eval('464 + 0.7*A_floor', {}, 'q_I_s');
      ctx.q_I_l = ctx.eval('68 + 0.07*A_floor', {}, 'q_I_l');
    }
    ctx.q_I_s_tot = ctx.eval('q_occ_s + q_I_s', {}, 'q_I_s_tot');
    ctx.q_I_l_tot = ctx.eval('q_occ_l + q_I_l', {}, 'q_I_l_tot');

    ctx.res.internals = {
      people: {
        cooling: {sensible: ctx.q_occ_s, latent: ctx.q_occ_l},
        heating: {},
      },
      other: {
        cooling: {sensible: ctx.q_I_s, latent: ctx.q_I_l},
        heating: {},
      },
      total: {
        cooling: {sensible: ctx.q_I_s_tot, latent: ctx.q_I_l_tot},
        heating: {},
      },
    }

    ctx.endSection();
  }
}
setupClass(HouseInternalsData);
