
/**
 * Helper to cache/memoize expensive function calls.
 */
export class FunctionCache {
  constructor() {
    this.cache = new Map()
  }

  _makeKey(func, argsMap) {
    return JSON.stringify({
      func: typeof func === 'function' ? func.name : func,
      argsMap
    })
  }

  get(func, argsMap) {
    let entry = this.cache.get(this._makeKey(func, argsMap))
    return entry !== undefined ? entry : null;
  }

  set(func, argsMap, result) {
    this.cache.set(this._makeKey(func, argsMap), result)
  }
}
