
<script setup>
import { ref, reactive, computed, watch, } from 'vue'
import { gApp, } from '../State.js'
import { Space } from '../BuildingComponents/Space.js'
import { formatNum, removeElem, generateItemName, isDupName, prettyJson, } from '../SharedUtils.js'
import { Units, getLabel, makeValStr, } from '../Common/Units.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import ConfirmModal from './ConfirmModal.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import HelpButton from './HelpButton.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import SelectOrManualInput from './SelectOrManualInput.vue'
import PopupRefTable from './PopupRefTable.vue'

import WallsSection from './WallsSection.vue'
import RoofsSection from './RoofsSection.vue'
import PartitionsSection from './PartitionsSection.vue'
import FloorsSection from './FloorsSection.vue'
import SpaceInternalsSection from './SpaceInternalsSection.vue'
import CalcContextView from './CalcContextView.vue'
import DebugOptions from './DebugOptions.vue'

import { GetConstructionWeightsReference } from '../Data/ConstructionWeightsReference.js'

import EditButton from './utils/EditButton.vue'
import DeleteButton from './utils/DeleteButton.vue'
import BackButton from './utils/BackButton.vue'
import ComponentErrorsList from './utils/ComponentErrorsList.vue'

const props = defineProps({
})

let proj = gApp.proj();
let selectedSpace = ref(null);
let deleteModal = ref(null);

let spaces = computed(() => {
  return proj.spaces;
})

function selectSpace(space) {
  selectedSpace.value = space;
}

function deleteSpace(space) {
  deleteModal.value.showModal("Delete", `Delete ${space.name.value}?`, () => {
    removeElem(spaces.value, space);
  });
}

function addSpace() {
  spaces.value.push(Space.create(generateItemName('Space', proj.spaces, 'TYPE_NAME-CTR'), true))
  selectSpace(spaces.value[spaces.value.length - 1]);
}

</script>

<template>
  <div class="Container">
    <HelpSection id="SpacesView">
    </HelpSection>
    <div v-if="selectedSpace">
      <div class="mb-m">
        <BackButton @click="selectedSpace=null" />
        <div class="Flex">
          <h1 class="">{{ selectedSpace.name.value || 'Untitled' }}</h1>
          <!-- <button class="LinkBtn">Rename</button> -->
        </div>
      </div>
      <div class="mb-xl">
        <p class="mute-header Container">Info</p>
        <p class="help-text w-80">
          You are editing {{ selectedSpace.name.value }}. Describe the features of the space.
          Spaces are referenced by System Zones in the <b>Systems</b> tab.
        </p>
      </div>
      <SimpleCollapse class="Section Emphasis" title="General" @onOpen="gApp.proj().setHelpId('GeneralData')">
        <div class="mb-m">
          <p class="mute-header">Info</p>
          <p class="help-text">
            Provide general info for the space.
          </p>
        </div>
        <div class="mb-s">
          <FieldInput v-model="selectedSpace.name" class="MarginBotXS" />
          <WarnText v-if="isDupName(selectedSpace.name.value, spaces)">There is already a system with that name. Please choose another.</WarnText>
        </div>
        <div class="mb-s">
          <p class="mute-header">Size</p>
          <FieldList :obj="selectedSpace" :fieldNames="selectedSpace.sizeFields" />
        </div>
        <div class="mb-m">
          <p class="mute-header">More Info</p>
          <FieldList :obj="selectedSpace" :fieldNames="selectedSpace.moreInfoFields" />
        </div>
        <div class="mt-s">
          <PopupRefTable title="Construction weights reference" :data="GetConstructionWeightsReference()" >
            If you are unsure of the construction weight, use this table to help you estimate.
          </PopupRefTable>  
        </div>
      </SimpleCollapse>
      <SimpleCollapse class="Section Emphasis" title="Ventilation" @onOpen="gApp.proj().setHelpId('Ventilation')">
        <div class="mb-m">
          <p class="mute-header">Info</p>
          <p class="help-text">
            Specify the space's ventilation.
          </p>
        </div>
        <ComponentErrorsList :component="selectedSpace.ventilation" />
        <div class="mb-m">
          <p class="mute-header Container">Breathing Zone</p>
          <FieldInput v-model="selectedSpace.ventilation.ventilationType" />
          <div v-if="selectedSpace.ventilation.ventilationType.value == 'Automatic'" class="mt-s w-80 mb-s">
            <FieldInput v-model="selectedSpace.ventilation.automaticVentilation" />
            <p class="help-text w-80 mb-xs">
              <i class="bi-info-circle mr-xxs"></i>
              In <b>Automatic</b> mode, the ventilation is estimated
              based on data for your space type and your inputs for 
              floor area and number of occupants.
            </p>
            <div class="help-text w-80">
              <p>Data for space type "{{selectedSpace.getSpaceTypeName()}}":</p>
              <ul>
                <li>
                  <p>Ventilation/person: {{ makeValStr(selectedSpace.ventilation.automaticCfmPerPerson, Units.AirFlowPerPerson) }}</p>
                </li>
                <li>
                  <p>Ventilation/area: {{ makeValStr(selectedSpace.ventilation.automaticCfmPerFt2, Units.AirFlowPerArea) }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <FieldInput v-model="selectedSpace.ventilation.manualVentilation" />
            <FieldInput v-model="selectedSpace.ventilation.minimumVentilationRequirement" />
          </div>
        </div>
        <div>
          <p class="mute-header Container">Effectiveness</p>
          <FieldInput v-model="selectedSpace.ventilation.ventilationEffectivenessCooling" />
          <FieldInput v-model="selectedSpace.ventilation.ventilationEffectivenessHeating" />
          <div class="mt-s">
            <PopupRefTable title="Ventilation effectiveness reference" :data="selectedSpace.ventilation.ventilationEffectivenessData" >
              If you are unsure of the ventilation effectiveness, use this table to help you estimate.
            </PopupRefTable>
          </div>
        </div>
        <!--
        <SelectOrManualInput v-model="selectedSpace.ventilationEffectiveness" />
        <FieldInput v-model="selectedSpace.manualVentilation" />
        -->
      </SimpleCollapse>
      <SimpleCollapse class="Section Emphasis" title="Infiltration" @onOpen="gApp.proj().setHelpId('Infiltration')">
        <div class="mb-m">
          <p class="mute-header">Info</p>
          <p class="help-text">
            Specify the space's infiltration.
          </p>
        </div>
        <FieldInput v-model="selectedSpace.summerInfiltration" />
        <FieldInput v-model="selectedSpace.winterInfiltration" />
        <FieldInput v-model="selectedSpace.infiltrationHours" />
      </SimpleCollapse>
      <WallsSection :space="selectedSpace" />
      <RoofsSection :space="selectedSpace" />
      <PartitionsSection :space="selectedSpace" />
      <FloorsSection :space="selectedSpace" />
      <SpaceInternalsSection :space="selectedSpace" />
      <div class="mt-l" v-if="gApp.isAdminUser()">
        <SimpleCollapse title="[Admin only] Space Loads">
          <div class="mb-s">
            <p><b>Debug Options:</b></p>
            <DebugOptions v-model="selectedSpace.debugCalcOptions" />
          </div>
          <button @click="selectedSpace.calculateDebugLoadsAsync()" class="mb-m">Recalculate</button>
          <div v-if="selectedSpace.loadResults">
            <div v-if="selectedSpace.runningCalculations" class="mb-m">
              <p>Recalculating...</p>
              <p>{{ selectedSpace.loadResults.calcContext.getProgressText() }}</p>
            </div>
            <div v-else>
              <div v-if="selectedSpace.loadResults.error" class="mb-m">
                <p class="delete-red">
                  Error during calculations (see log for details).
                </p>
              </div>
              <CalcContextView :ctx="selectedSpace.loadResults.calcContext"/>
            </div>
          </div>
          <div v-else>
            <p>Click 'Recalculate' to calculate space loads.</p>
          </div>
        </SimpleCollapse>
      </div>
    </div>
    <div v-else>
      <h1 class="PageH1">Spaces</h1>
      <div class="mb-l">
        <p class="mute-header Container">Info</p>
        <p class="help-text w-80">
          Add a <b>Space</b> for each different type of Space used in your project. 
          Spaces are referenced by System Zones in the <b>Systems</b> tab.
        </p>
      </div>
      <button @click="addSpace()" class="MarginBotXS CreateBtn">Add Space</button>
      <table class="SimpleTable" v-if="spaces.length">
        <colgroup>
          <col style="width: 300px;">
          <col>
          <!-- other columns -->
        </colgroup>
        <tr>
          <th>Name</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="space in spaces" class="WallSection MarginBotL">
          <td>{{ space.name.value }}</td>
          <td>
            <EditButton @click="selectSpace(space)" />
          </td>
          <td>
            <DeleteButton @click="deleteSpace(space)" />
          </td>
        </tr>
      </table>
    </div>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<style scoped>
.Section {
  margin-bottom: var(--space-xs);
}

.LastSection {
  margin-bottom: var(--space-xl);
}

.WallsTable td {
  vertical-align: top;
}

.RowDetails {
  border-left: 2px solid var(--pc-dark);
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.RowDetailsRow:hover {
  background-color: inherit;
}

.AddBtn {
  margin-top: var(--xs);
}

.CloseDetailsBtn {
  float: right;
}

.Result {
  color: blue;
}
</style>
