export let OverviewPageMarkdown = `# Introduction
Welcome to HeatWise! This is your overview page where you can view and export your simulation results, adjust your settings, and share your projects. 

To manage your basic settings, click the "General" dropdown bar. If you would like to share your project with a friend or coworker, click the "Sharing" dropdown bar and then select "Add User". Once they've been added, this project will show up in their list of projects under "Shared With Me". After sharing a project, you can remove any user who has access to the project, as long as you are the original author of that project. 

At the top right corner of this page, you'll see two buttons. The first is for sending feedback. By using this function, you can send any feedback to the developers about issues you're having, propose new features that would benefit you, or provide general comments about the software. The other button allows you to sign out if you would not like to remain signed in the next time you open the app.

## Getting Started with HeatWise
On the left-hand menu you'll find all the locations where you can build your material libraries, construct spaces and design your systems. You can click on the "Overview" tab at any time to return to this page.
### Building and Environment
Here you can select your location to auto-populate the weather data for the location of your building. After this weather data is populated based on the location, you can modify it to suit your specific needs, or just leave it as is for the selected location. 
### Systems
Here you can create an HVAC system based on your design. You can create as many systems as you like and reuse spaces as many times as you like in a system and between systems. Although you can create as many systems as you like, keep in mind that only one system simulation can be run at a time.
### Spaces
Here you can create the spaces of your system. Spaces consist of the building envelope and any internal loads, people, etc., as well as infiltration and ventilation specifications. Materials used to build your spaces such as wall types, windows, etc., are created in other tabs. This means that whenever you update an item it will automatically be updated in every instance that it is used in spaces that have already been built.
### Walls
The walls tab is where you specify any walls that will be used in your system. You can build walls based on wall construction or through manual entry of R-value and radiative absorptance (&alpha;). Wall areas are not specified until you use a wall in a space.
### Roofs
Creating roofs is almost identical to creating walls, except that they are used in a different spot when building spaces.
### Windows
Under the windows tab you can create windows that can be placed on walls. Windows can be created by specifying the physical characteristics of the window, or by manually entering the relevant fields.
### Doors
Doors work similarly to windows, except that doors may be opaque, transparent, or have portions of each.
### Skylights
Creating skylights is similar to creating windows, except that when building spaces skylights can only be added to roofs instead of walls.
### Internal Shadings
Internal shadings can be added to windows and doors when building a space. Any internal shading created here can be added to any window or door. They can be put on a schedule to simulate a more realistic usage of a shading device.
### External Shadings
These include horizontal overhangs and vertical barriers next to windows that will block solar gains. External shadings can be added to both windows and doors, and only affect solar gains through the glass.
### Buffer Spaces
When building a space, you will be given the option to add a partition. The partition separates the conditioned space from an unconditioned or partially conditioned buffer space. In order to add a partition, you must provide a buffer space that the partition is separating. Buffer spaces can be custom-built so that the internal temperature is a function of adjacent temperatures for more accurate calculations.
### Schedules
Schedules describe the hourly usage for many different loads in a space. Schedules are applied to internal loads, occupancy, and internal shadings. Any particular schedule can be used for many different applications.`